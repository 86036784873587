define("tmp-for-all/components/search-parameters/filters/filter-controls/worksite-work-window/component", ["exports", "tmp-for-all/mixins/components/worksite-filter-field", "tmp-for-all/components/search-parameters/filters/filter-controls/date-options"], function (_exports, _worksiteFilterField, _dateOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    setProperties = Ember.setProperties;
  var MIN_DATE = 0;
  var MAX_DATE = 8640000000000000;
  var _default = Ember.Component.extend(_worksiteFilterField.default, {
    dateOptions: _dateOptions.default,
    minDate: computed('fromDate', function () {
      var fromDate = get(this, 'fromDate');
      return fromDate || new Date(MIN_DATE);
    }),
    maxDate: computed('toDate', function () {
      var toDate = get(this, 'toDate');
      return toDate || new Date(MAX_DATE);
    }),
    isDisabled: computed('selectedDateOption', function () {
      return !get(this, 'selectedDateOption');
    }),
    actions: {
      dateOptionDidChange: function dateOptionDidChange(option) {
        set(this, 'selectedDateOption', option);
        if (option.label === 'Custom range') {
          option.startDate = get(this, 'fromDate');
          option.finishDate = get(this, 'toDate');
          set(this, 'maxDate', new Date(MAX_DATE));
        }
        setProperties(this, {
          fromDate: option.startDate,
          toDate: option.finishDate
        });
      },
      saveFilter: function saveFilter() {
        var _this = this;
        var fromDate = get(this, 'fromDate');
        var toDate = get(this, 'toDate');

        // update the query params through the controller
        var updateFilterParams = get(this, 'updateFilterParams');
        if (fromDate && toDate) {
          var index = get(this, 'addFilter')(get(this, 'filter'));
          updateFilterParams(function (controller) {
            get(_this, 'filter').setQueryParam(controller, [fromDate, toDate], index);
          });

          // Exit modal
          this.toggleModal();
        }
      }
    }
  });
  _exports.default = _default;
});