define("tmp-for-all/components/shared/inline-help-block/component", ["exports", "tmp-for-all/mixins/components/expand-contract-mixin"], function (_exports, _expandContractMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_expandContractMixin.default, {
    classNames: ['expand-outer', 'inline-help-block-wrap'],
    initialHeight: 0,
    dontDisable: true
  });
  _exports.default = _default;
});