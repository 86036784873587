define("tmp-for-all/templates/condition-groups/tmp/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KI7eSEGQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Edit Condition Group\"]],{\"statements\":[[0,\"  \"],[1,[27,\"condition-group-form\",null,[[\"group\",\"actionName\",\"saveConditionGroup\",\"toggleArchiveState\"],[[23,[\"model\"]],\"edit\",[27,\"route-action\",[\"saveConditionGroup\"],null],[27,\"route-action\",[\"toggleArchiveState\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/condition-groups/tmp/edit.hbs"
    }
  });
  _exports.default = _default;
});