define("tmp-for-all/components/form-field/errors/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['form-field__error-message']
  });
  _exports.default = _default;
});