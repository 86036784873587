define("tmp-for-all/components/pagination-controls/component", ["exports", "tmp-for-all/components/page-numbers"], function (_exports, _pageNumbers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = _pageNumbers.default.extend({
    actions: {
      pageClicked: function pageClicked(number) {
        if (get(this, 'content.loading')) return;
        this._super(number);
      },
      incrementPage: function incrementPage(num) {
        if (get(this, 'content.loading')) return;
        this._super(num);
      }
    }
  });
  _exports.default = _default;
});