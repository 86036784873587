define("tmp-for-all/mixins/routes/modals/new-project-route", ["exports", "tmp-for-all/mixins/routes/modals/modal-route-base"], function (_exports, _modalRouteBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NewProjectModalMixin = void 0;
  var get = Ember.get,
    set = Ember.set;

  /**
   * Mixin to be used with all of the new-contact modal routes.
   *
   * Extends modal-route-base mixin
   *
   * Must be used with a parent route that provides saveNewProject action
   */
  var NewProjectModalMixin = Ember.Mixin.create(_modalRouteBase.default, {
    templateName: 'modals/add-new-project-modal',
    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);
      set(model, 'newProject', get(this, 'store').createRecord('project', {
        company: get(model, 'clientCompany')
      }));
    },
    actions: {
      save: function save(project) {
        var _this = this;
        project.save().then(function (project) {
          _this.send('saveNewProject', {
            project: project
          });
          get(_this, 'flashMessages').success('A new project has been created.');
          _this.replaceWith(get(_this, 'parentRouteName'));
        }).catch(function () {
          get(_this, 'flashMessages').danger('There was an error saving the new project.');
        });
      },
      willTransition: function willTransition() {
        var newProject = get(this, 'controller.model.newProject');
        if (newProject) {
          newProject.destroyOrRollback();
        }
      }
    }
  });
  _exports.NewProjectModalMixin = NewProjectModalMixin;
  var _default = Ember.Route.extend(NewProjectModalMixin);
  _exports.default = _default;
});