define("tmp-for-all/components/worksite/modify-warranty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "t0CgxUaq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-blue\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Modify Warranty End Date\"],[10],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"cone\",\"28px\",\"26px\",\"icon--in-heading icon--blue\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"underline-dotted\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"Modify Warranty End Date\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"element-group-wrap\"],[9],[0,\"\\n    \"],[1,[27,\"worksite/modify-warranty/warranty-end-date-selector\",null,[[\"warrantyEndDate\",\"workCompletionDate\",\"selectDate\"],[[23,[\"warrantyEndDate\"]],[23,[\"workCompletionDate\"]],[23,[\"selectDate\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"align-right\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"tertiary-action t-secondary-button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],[23,[\"cancel\"]]]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"primary-action primary-action--listed-right t-primary-button\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"submit\"]]],false],[0,\" Modify warranty end date\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"save\"]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite/modify-warranty/template.hbs"
    }
  });
  _exports.default = _default;
});