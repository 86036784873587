define("tmp-for-all/controllers/worksites/submissions", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin", "tmp-for-all/mixins/controllers/list-order-controller-mixin", "tmp-for-all/constants"], function (_exports, _listPaginationControllerMixin, _listOrderControllerMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_listPaginationControllerMixin.default, _listOrderControllerMixin.default, {
    columnsExcludedByDefault: [_constants.WORKSITE_TABLE_COLUMNS.ENDDATE, _constants.WORKSITE_TABLE_COLUMNS.SUBMITTED, _constants.WORKSITE_TABLE_COLUMNS.DAYSAGO, _constants.WORKSITE_TABLE_COLUMNS.TMPS, _constants.WORKSITE_TABLE_COLUMNS.WORKSTART, _constants.WORKSITE_TABLE_COLUMNS.WORKSTARTLOGGEDAT, _constants.WORKSITE_TABLE_COLUMNS.WORKCOMPLETION, _constants.WORKSITE_TABLE_COLUMNS.WORKCOMPLETIONLOGGEDAT, _constants.WORKSITE_TABLE_COLUMNS.WARRANTYCOMPLETE, _constants.WORKSITE_TABLE_COLUMNS.ASSIGNEDTO]
  });
  _exports.default = _default;
});