define("tmp-for-all/controllers/tmps/search", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin", "tmp-for-all/mixins/controllers/list-order-controller-mixin", "tmp-for-all/utils/search-filter", "tmp-for-all/utils/tmp-filters"], function (_exports, _listPaginationControllerMixin, _listOrderControllerMixin, _searchFilter, _tmpFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
    computed = Ember.computed;
  var SearchController = Ember.Controller.extend(_listPaginationControllerMixin.default, _listOrderControllerMixin.default, {
    filterTypes: _tmpFilters.searchFilters,
    queryParams: R.concat(_searchFilter.default.getFilterQueryParams(_tmpFilters.searchFilters), ['status', 'freetext']),
    queryFilter: computed.alias('model.queryFilter'),
    status: null,
    worksiteReference: null,
    freetext: null,
    actions: {
      // pass the controller to the component for updating the query params
      updateFilterParams: function updateFilterParams(func, params) {
        set(this, 'page', 1);
        func(this, params);
      },
      setFreetext: function setFreetext(freetext) {
        set(this, 'page', 1);
        set(this, 'freetext', freetext);
      },
      setStatus: function setStatus(status) {
        status = status.value;
        set(this, 'status', status);
        set(this, 'page', 1);
      }
    }
  });
  SearchController.reopen(_searchFilter.default.getParamsAssignments(_tmpFilters.searchFilters));
  var _default = SearchController;
  _exports.default = _default;
});