define("tmp-for-all/components/shared/form/boolean-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6OGOne7m",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"shared/form/radio-buttons\",null,[[\"inlineGroupLabel\",\"options\",\"required\",\"value\",\"onChange\"],[[23,[\"label\"]],[23,[\"options\"]],[23,[\"required\"]],[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"update\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"showDetail\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"detailOptions\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"shared/form/select-box\",null,[[\"value\",\"options\",\"didChange\",\"searchEnabled\",\"placeholder\",\"class\"],[[23,[\"detail\"]],[23,[\"detailOptions\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"detail\"]]],null]],null],false,\"Please select\",\"form-field\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"detailsLabel\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[1,[21,\"detailsLabel\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[0,\"Please specify details\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[4,\"if\",[[23,[\"requiredIfTrue\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[27,\"shared/form/text-area\",null,[[\"required\",\"errors\",\"value\"],[[23,[\"requiredIfTrue\"]],[22,0,[\"errors\"]],[23,[\"detail\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/boolean-detail/template.hbs"
    }
  });
  _exports.default = _default;
});