define("tmp-for-all/routes/worksite-submission/edit/ownership", ["exports", "tmp-for-all/mixins/routes/edit-worksite/ownership-route-mixin"], function (_exports, _ownershipRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_ownershipRouteMixin.default, {
    nextStep: 'worksite.edit.details',
    finishedTarget: 'worksite-submission'
  });
  _exports.default = _default;
});