define("tmp-for-all/components/panel-section/panel-sub-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['panel-sub-section'],
    classNameBindings: ['showContent:expanded', 'canToggle:can-toggle'],
    showContent: true,
    actions: {
      toggleSubSectionContent: function toggleSubSectionContent() {
        this.toggleProperty('showContent');
      }
    }
  });
  _exports.default = _default;
});