define("tmp-for-all/components/search-results/user-search-results/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['user-list', 'table-container', 'responsivify-table', 'item-list-table']
  });
  _exports.default = _default;
});