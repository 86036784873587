define("tmp-for-all/templates/companies/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KLuYFqrI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\",\"pageSubHeadingText\"],[[23,[\"model\",\"company\",\"name\"]],[27,\"if\",[[23,[\"model\",\"company\",\"isArchived\"]],\"Archived\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"companies-view\",null,[[\"company\",\"customerNumbers\",\"mergedCompanies\"],[[23,[\"model\",\"company\"]],[23,[\"model\",\"customerNumbers\"]],[23,[\"model\",\"mergedCompanies\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/companies/view.hbs"
    }
  });
  _exports.default = _default;
});