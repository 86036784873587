define("tmp-for-all/mixins/routes/conditions-edit-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    condition: computed.alias('controller.model.condition'),
    dataIsDirty: computed.alias('condition.hasChanges'),
    openModal: function openModal() {
      var _this = this;
      set(this, 'modal.options', {
        title: 'Discard Changes?',
        text: 'You have made unsaved changes to this condition',
        confirmButton: 'Discard',
        confirmAction: function confirmAction() {
          RSVP.resolve(_this.cleanup()).then(function () {
            _this.get('modal').toggle();
            _this.transitionTo("conditions.".concat(get(_this, 'condition.conditionType'), ".list"));
          });
        }
      });
      get(this, 'modal').toggle();
    },
    cleanup: function cleanup() {
      var condition = get(this, 'condition');
      return condition.reloadRelations(['conditionGroupToConditions']).then(function () {
        return condition.destroyOrRollback();
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        // Caveat: because of the way the conditionGroupToConditions is implemented, we don't get dirty
        // when the user changes the conditionGroup selection
        if (get(this, 'dataIsDirty')) {
          transition.abort();
          this.openModal();
        }
      }
    }
  });
  _exports.default = _default;
});