define("tmp-for-all/mixins/authorization-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP;
  var _default = Ember.Mixin.create({
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    /**
     * This sets up a listener for when the user logs in.
     * which covers the case where the user first loads the page
     * unauthenticated and then logs in.
     */
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.get('session').on('authenticationSucceeded', function () {
        return _this.get('authorization').load();
      });
    },
    /**
     * Adds beforeModel hook to resolve permissions on routes.
     * Calling load on authorization service returns a promise which
     * will force the model hook of a route to wait until permissions
     * are in place.
     */
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        return this.get('authorization').load();
      } else {
        return RSVP.Promise.resolve();
      }
    }
  });
  _exports.default = _default;
});