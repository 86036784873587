define("tmp-for-all/helpers/fn", ["exports", "ember-fn-helper-polyfill/helpers/fn"], function (_exports, _fn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fn.default;
    }
  });
  Object.defineProperty(_exports, "fn", {
    enumerable: true,
    get: function get() {
      return _fn.fn;
    }
  });
});