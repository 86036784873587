define("tmp-for-all/components/shared/conditions-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6es0mVty",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[7,\"div\"],[11,\"class\",\"element-group-wrap\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"element-group-label\"],[9],[0,\"Archived Conditions\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"element-wrap\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"checked\"],[\"checkbox\",\"show-archived-conditions\",[23,[\"showArchived\"]]]]],false],[0,\"\\n    \"],[7,\"label\"],[11,\"for\",\"show-archived-conditions\"],[9],[0,\"Show Archived Conditions\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"conditionGroups\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"showArchived\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"shared/conditions-list/group\",null,[[\"conditions\",\"groupName\",\"groupIsArchived\",\"conditionType\",\"createConditionLink\",\"showArchived\",\"color\"],[[22,1,[\"conditions\"]],[22,1,[\"name\"]],[22,1,[\"isArchived\"]],[23,[\"conditionType\"]],[23,[\"createConditionLink\"]],[23,[\"showArchived\"]],[23,[\"color\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[22,1,[\"isArchived\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"shared/conditions-list/group\",null,[[\"conditions\",\"groupName\",\"groupIsArchived\",\"conditionType\",\"createConditionLink\",\"showArchived\",\"color\"],[[22,1,[\"conditions\"]],[22,1,[\"name\"]],[22,1,[\"isArchived\"]],[23,[\"conditionType\"]],[23,[\"createConditionLink\"]],[23,[\"showArchived\"]],[23,[\"color\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/conditions-list/template.hbs"
    }
  });
  _exports.default = _default;
});