define("tmp-for-all/components/shared/fixed-actions-bar/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    classNames: ['actions-bar', 'fixed'],
    windowEl: (0, _jquery.default)(window),
    // Optional expandable section at bottom of page (expands to show final step):
    submitPanel: computed('submitPanelSelector', function () {
      var submitPanel = (0, _jquery.default)(this.get('submitPanelSelector'));
      return submitPanel.length ? submitPanel : null;
    }),
    /*
      If expandable section selector has been passed in, setup scroll handler
      to hide/show actions bar when submit panel is open (actions bar should
      not be visible over top of expanded section):
    */
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var submitPanel = this.get('submitPanel');
      // If no submit panel is present, scroll handler is not required:
      if (!submitPanel) return;
      var actionsBar = (0, _jquery.default)(this.element);
      var windowEl = this.get('windowEl');
      // Calculate actions bar height (including padding):
      var fixedBarHeightAllowance = actionsBar.outerHeight(true);
      windowEl.on('scroll', function () {
        // Distance from top of the document to the bottom of the viewport:
        var currentPageScroll = windowEl.scrollTop() + windowEl.height();
        // Threshold allows bar to stay in view until amount of submit panel in viewport > bar height
        var threshold = submitPanel.offset().top + fixedBarHeightAllowance;
        // If page scroll position has passed threshold and submit panel open, hide actions bar:
        if (currentPageScroll > threshold && submitPanel.hasClass('opened')) actionsBar.addClass('disabled');
        // Otherwise, remove disabled class to show it again when user scrolls out of panel area:
        else actionsBar.removeClass('disabled');
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // Add class to #main element to adjust positioning of alert flash message when action bar present:
      var mainEl = (0, _jquery.default)('#main');
      if (mainEl.length) mainEl.addClass('has-action-bar');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      // Remove window scroll event listener
      var windowEl = this.get('windowEl');
      if (windowEl.length) windowEl.off('scroll');
      // Remove class from #main element to adjust positioning of alert flash message:
      var mainEl = (0, _jquery.default)('#main');
      if (mainEl.length) mainEl.removeClass('has-action-bar');
    }
  });
  _exports.default = _default;
});