define("tmp-for-all/models/layout", ["exports", "ember-data", "tmp-for-all/mixins/models/private-files-mixin"], function (_exports, _emberData, _privateFilesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _default = _emberData.default.Model.extend(_privateFilesMixin.default, {
    ajax: Ember.inject.service(),
    description: attr('string'),
    tmp: belongsTo('tmp', {
      async: true
    }),
    tmpDraft: belongsTo('tmp-draft', {
      async: true
    }),
    tmpSubmission: belongsTo('tmp-submission', {
      async: true
    }),
    files: hasMany('file', {
      async: true
    }),
    category: attr('string'),
    startDate: attr('date'),
    endDate: attr('date'),
    isContinuous: attr('boolean-nullable', {
      defaultValue: null
    }),
    preparationIn: attr('string', {
      defaultValue: ''
    }),
    trafficControlIn: attr('string', {
      defaultValue: ''
    }),
    trafficControlOut: attr('string', {
      defaultValue: ''
    }),
    siteCleared: attr('string', {
      defaultValue: ''
    }),
    daysOfWeek: attr('array'),
    impacts: attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    detours: hasMany('detour', {
      async: true
    }),
    signage: attr('array'),
    trafficImpact: attr('array'),
    parties: attr('array'),
    isAdvertised: attr('boolean-nullable', {
      defaultValue: null
    }),
    notifySCATS: attr('boolean-nullable', {
      defaultValue: null
    }),
    deployments: hasMany('deployment', {
      async: true
    }),
    designer: belongsTo('tmp-user', {
      async: true
    }),
    universalTrafficManagementDiagram: attr('string'),
    isDeployed: attr('boolean', {
      defaultValue: false
    }),
    layoutCode: attr('string'),
    jobReference: attr('string'),
    siteContactNumber: attr('string'),
    extraDirtyCheck: computed.notEmpty('filesForUpload'),
    isRetrospective: attr('boolean'),
    roadClosureFrom: attr('string'),
    roadClosureTo: attr('string'),
    jurisdictionId: computed('tmp', 'tmpsubmission', 'tmpdraft', function () {
      return get(this, 'tmp.jurisdiction.id') || get(this, 'tmpSubmission.jurisdiction.id') || get(this, 'tmpDraft.jurisdiction.id');
    }),
    jurisdiction: computed('tmp', 'tmpsubmission', 'tmpdraft', function () {
      return get(this, 'tmp.jurisdiction') || get(this, 'tmpSubmission.jurisdiction') || get(this, 'tmpDraft.jurisdiction');
    }),
    closureFieldsRequiredWhenAdvertised: computed('jurisdiction', 'isAdvertised', function () {
      var _this = this;
      return get(this, 'jurisdiction').then(function (jur) {
        return _this.ajax.featureFlagEnabledFor('layout-closure-fields', 'jurisdiction', jur.id);
      }).then(function (b) {
        return _this.set('closureFieldsRequiredWhenAdvertised', b);
      });
    }),
    parentInfo: computed(function () {
      var id = get(this, 'id');
      var info = get(this, 'ajax').getLayoutParentInfo(id);
      return _emberData.default.PromiseObject.create({
        promise: info
      });
    }),
    saveFiles: function saveFiles() {
      var _this2 = this;
      return get(this, 'ajax').layoutUploadFiles(this).then(function () {
        return _this2.persistFiles();
      }).then(function () {
        return get(_this2, 'files').reload();
      }).then(function () {
        return _this2;
      });
    },
    detourByOrdinal: function detourByOrdinal(ordinal) {
      return get(this, 'detours').find(function (d) {
        return get(d, 'ordinal') === ordinal && !get(d, 'isDeleted');
      });
    },
    firstDetour: computed('detours.[]', 'detours.@each.ordinal', function () {
      return this.detourByOrdinal(1);
    }),
    secondDetour: computed('detours.[]', 'detours.@each.ordinal', function () {
      return this.detourByOrdinal(2);
    })
  });
  _exports.default = _default;
});