define("tmp-for-all/components/shared/simple-expand-contract/component", ["exports", "tmp-for-all/mixins/components/expand-contract-mixin"], function (_exports, _expandContractMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend(_expandContractMixin.default, {
    classNames: ['simple-expand-contract'],
    // Toggle show more/show less text on expand/contract button:
    expandTriggerText: computed('isExpanded', function () {
      return get(this, 'isExpanded') ? 'less' : 'more';
    })
  });
  _exports.default = _default;
});