define("tmp-for-all/components/maps/static-map-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "K3nlbnUu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"imageUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[21,\"imageUrl\"]],[11,\"alt\",\"Layout Preview\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isOutdated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[0,\"Preview image will be updated after saving\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"layout\",\"impacts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[0,\"Preview image will be available after saving\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/static-map-layout/template.hbs"
    }
  });
  _exports.default = _default;
});