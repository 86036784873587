define("tmp-for-all/templates/worksites/create/wizard/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "T6rxIcJU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"worksite/details-form\",null,[[\"worksite\",\"inWizard\",\"updateWorksiteType\",\"updateRetro\",\"finishDetails\",\"next\",\"nextGlobalPermit\",\"saveProgress\",\"saveAndReturn\"],[[23,[\"model\"]],true,[27,\"route-action\",[\"updateWorksiteType\"],null],[27,\"route-action\",[\"updateRetro\"],null],[27,\"route-action\",[\"finishDetails\"],null],[27,\"route-action\",[\"next\"],null],[27,\"route-action\",[\"nextGlobalPermit\"],null],[27,\"route-action\",[\"saveProgress\"],null],[27,\"route-action\",[\"saveAndReturn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksites/create/wizard/details.hbs"
    }
  });
  _exports.default = _default;
});