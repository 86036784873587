define("tmp-for-all/components/shared/form/select-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xb8IsO7v",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"for\",[21,\"inputID\"]],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"toolTipText\"]]],null,{\"statements\":[[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"        \"],[1,[21,\"toolTipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"search\"]]],null,{\"statements\":[[4,\"power-select\",null,[[\"search\",\"selected\",\"disabled\",\"placeholder\",\"onchange\",\"tagName\",\"class\"],[[27,\"action\",[[22,0,[]],[23,[\"search\"]]],null],[23,[\"value\"]],[23,[\"disabled\"]],[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],[23,[\"didChange\"]]],null],\"div\",[23,[\"cssClasses\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[22,2,[\"name\"]],[22,2,[\"name\"]],[22,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"power-select\",null,[[\"selected\",\"disabled\",\"placeholder\",\"onchange\",\"tagName\",\"class\",\"searchEnabled\",\"searchField\",\"options\"],[[23,[\"value\"]],[23,[\"disabled\"]],[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],[23,[\"didChange\"]]],null],\"div\",[23,[\"cssClasses\"]],[23,[\"searchEnabled\"]],[23,[\"searchField\"]],[23,[\"options\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[22,1,[\"name\"]],[22,1,[\"name\"]],[22,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"],[1,[27,\"shared/error-messages/inline\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/select-box/template.hbs"
    }
  });
  _exports.default = _default;
});