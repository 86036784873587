define("tmp-for-all/mixins/routes/modals/modal-route-base", ["exports", "tmp-for-all/utils/route-helpers"], function (_exports, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ModalRouteBaseMixin = void 0;
  var get = Ember.get,
    computed = Ember.computed;

  /**
   * Mixin to be used with all modal routes.
   *
   * Provides base modal behavior for transitioning back to a parent route.
   *
   * Extend this mixin and provide additional actions and a templateName.
   *
   * NOTE: For this to work, the modal-route has to be a  sub-route of the
   * page that creates the modal. The modal route must be able to send
   * and action up the route hierarchy to the parent route.
   *
   * E.g.
   * worksites.create.ownership
   * worksites.create.ownership.new-contact
   *
   */
  var ModalRouteBaseMixin = Ember.Mixin.create({
    routeNavigator: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!get(this, 'routeNavigator').getLastRoute()) {
        return this.transitionTo(get(this, 'parentRouteName'));
      }
    },
    parentRouteName: computed('routeName', function () {
      return (0, _routeHelpers.getParentRouteName)(get(this, 'routeName'));
    }).readOnly(),
    transitionBack: function transitionBack() {
      return this.replaceWith(get(this, 'parentRouteName'));
    },
    actions: {
      cancel: function cancel() {
        this.transitionBack();
      }
    }
  });
  _exports.ModalRouteBaseMixin = ModalRouteBaseMixin;
  var _default = ModalRouteBaseMixin;
  _exports.default = _default;
});