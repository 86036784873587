define("tmp-for-all/components/worksite/work-items-form/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    inWizard: false,
    workItems: computed.filterBy('worksite.workItems', 'isDeleted', false),
    tooltip: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_WORK.DESCRIPTION),
    lengthTooltip: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_WORK.TRENCH_LENGTH),
    widthTooltip: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_WORK.TRENCH_WIDTH),
    actions: {
      addWorkItemTop: function addWorkItemTop() {
        this.addWorkItem(this.worksite, 'TOP');
      },
      addWorkItem: function addWorkItem() {
        this.addWorkItem(this.worksite);
      },
      finishWorkItems: function finishWorkItems() {
        this.finishWorkItems(this.worksite);
      }
    }
  });
  _exports.default = _default;
});