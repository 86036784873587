define("tmp-for-all/models/work-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    type: attr('string'),
    otherType: attr('string'),
    roadLocations: attr(),
    maxDepth: attr('number'),
    minDepth: attr('number'),
    trenchLength: attr('number'),
    trenchWidth: attr('number'),
    description: attr('string'),
    worksite: belongsTo('worksite', {
      async: true
    }),
    worksiteDraft: belongsTo('worksite-draft', {
      async: true
    }),
    worksiteSubmission: belongsTo('worksite-submission', {
      async: true
    })
  });
  _exports.default = _default;
});