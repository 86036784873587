define("tmp-for-all/utils/map-functions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flattenDeep = flattenDeep;
  _exports.getBoundingBox = getBoundingBox;
  _exports.getCenterofPolygon = getCenterofPolygon;
  _exports.getvisibleFeatures = getvisibleFeatures;
  _exports.isWithinCurrentBounds = isWithinCurrentBounds;
  _exports.lngLatToLatLng = lngLatToLatLng;
  _exports.queryToState = queryToState;
  _exports.stateToQuery = stateToQuery;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /* global Raygun */

  function lngLatToLatLng(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      lng = _ref2[0],
      lat = _ref2[1];
    return [lat, lng];
  }
  function getvisibleFeatures(map, searchResults) {
    // Note, assuming searchResults is a POJO
    var mapBounds = map.getBounds();
    var resultsShown = searchResults.filter(function (result) {
      try {
        var _result$geometry$geom = _slicedToArray(result.geometry.geometry.bbox, 4),
          lng1 = _result$geometry$geom[0],
          lat1 = _result$geometry$geom[1],
          lng2 = _result$geometry$geom[2],
          lat2 = _result$geometry$geom[3];
        return mapBounds.intersects([[lat1, lng1], [lat2, lng2]]);
      } catch (err) {
        // In case of faulty data being returned from the server, continue
        // processing so the map still displays properly.
        Raygun.send(err);
        return false;
      }
    });
    return resultsShown;
  }
  function queryToState(query, structure) {
    // Initial - we know the structure
    var state = {
      status: {}
    };
    state.status = buildFromChildren(structure.children, query.status, function (child, source, target) {
      var includedChildren = source[child.searchKey].include;
      if (includedChildren.length) {
        target[child.searchKey] = {};
        includedChildren.forEach(function (includedChild) {
          target[child.searchKey][includedChild] = true;
        });
      }
    });
    return state;
  }
  function stateToQuery(state, structure) {
    // Initial - we know the structure
    var query = {
      status: {}
    };
    query.status = buildFromChildren(structure.children, state.status, function (child, source, target) {
      var includedChildren = child.children.filter(function (includedChild) {
        return source[child.searchKey][includedChild.searchKey];
      }).map(function (includedChild) {
        return includedChild.searchKey;
      });
      if (includedChildren.length) target[child.searchKey] = {
        include: includedChildren
      };
    });
    return query;
  }
  function buildFromChildren(children, source, childrenFunc) {
    if (!source) return null;
    var target = {};
    children.forEach(function (child) {
      if (source[child.searchKey]) {
        if (!child.childrenAsArray) {
          if (child.children) {
            var _children = buildFromChildren(child.children, source[child.searchKey], childrenFunc);
            if (_children) target[child.searchKey] = _children;
          } else {
            target[child.searchKey] = {};
          }
        } else {
          childrenFunc(child, source, target);
        }
      }
    });
    return Object.keys(target).length ? target : null;
  }
  function getBoundingBox(map) {
    var bounds = map.getBounds();
    var boundingBox = {
      xMax: bounds.getEast(),
      xMin: bounds.getWest(),
      yMax: bounds.getNorth(),
      yMin: bounds.getSouth()
    };
    return boundingBox;
  }
  function isWithinCurrentBounds(mapBounds, map) {
    var existingBounds = mapBounds;
    var newBounds = getBoundingBox(map);
    return newBounds.xMax <= existingBounds.xMax && newBounds.yMax <= existingBounds.yMax && newBounds.xMin >= existingBounds.xMin && newBounds.yMin >= existingBounds.yMin;
  }
  function flattenDeep(arr1) {
    return arr1.reduce(function (acc, val) {
      return Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val);
    }, []);
  }
  function getCenterofPolygon(arrCordinates) {
    var arrNewCordinates = [];
    var arrCenterValues = [];
    arrNewCordinates = arrCordinates.length ? flattenDeep(arrCordinates) : arrCordinates;
    var x = arrNewCordinates.map(function (a, i) {
      if (i % 2 === 0) return a;
    });
    var y = arrNewCordinates.map(function (a, i) {
      if (i % 2 === 1) return a;
    });
    x = x.filter(function (e) {
      return e === 0 || e;
    });
    y = y.filter(function (e) {
      return e === 0 || e;
    });
    var minX = Math.min.apply(Math, _toConsumableArray(x));
    var maxX = Math.max.apply(Math, _toConsumableArray(x));
    var minY = Math.min.apply(Math, _toConsumableArray(y));
    var maxY = Math.max.apply(Math, _toConsumableArray(y));
    arrCenterValues.push({
      lng: (minX + maxX) / 2,
      lat: (minY + maxY) / 2
    });
    return arrCenterValues;
  }
});