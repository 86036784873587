define("tmp-for-all/components/shared/form/radio-buttons-boolean/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qId5DHrK",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"shared/form/radio-buttons\",null,[[\"options\",\"errors\",\"groupLabel\",\"inlineGroupLabel\",\"required\",\"toolTipText\",\"onChange\"],[[23,[\"options\"]],[23,[\"errors\"]],[23,[\"groupLabel\"]],[23,[\"inlineGroupLabel\"]],[23,[\"required\"]],[23,[\"toolTipText\"]],[27,\"action\",[[22,0,[]],\"onChange\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/radio-buttons-boolean/template.hbs"
    }
  });
  _exports.default = _default;
});