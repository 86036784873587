define("tmp-for-all/components/planning-map/detail-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZqrhIYXL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"popupModel\",\"isPending\"]]],null,{\"statements\":[[4,\"component\",[[27,\"concat\",[\"planning-map/detail-popup/\",[23,[\"templateName\"]]],null]],[[\"model\",\"moveForward\",\"moveBack\",\"initialTab\"],[[23,[\"popupModel\"]],[27,\"action\",[[22,0,[]],\"moveForward\"],null],[27,\"action\",[[22,0,[]],\"moveBack\"],null],[23,[\"currentModelName\"]]]],{\"statements\":[[4,\"if\",[[23,[\"navigationRequired\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"popup-footer\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"popup-footer-btn popup-footer-btn--left\"],[9],[0,\"< Previous\"],[3,\"action\",[[22,0,[]],\"moveBack\"]],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"popup-footer-index\"],[9],[0,\"(\"],[1,[21,\"currentModelNumber\"],false],[0,\" of \"],[1,[21,\"totalNumberModels\"],false],[0,\")\"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"popup-footer-btn popup-footer-btn--right\"],[9],[0,\"Next >\"],[3,\"action\",[[22,0,[]],\"moveForward\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/planning-map/detail-popup/template.hbs"
    }
  });
  _exports.default = _default;
});