define("tmp-for-all/authenticators/loopback", ["exports", "ember-simple-auth/authenticators/base", "tmp-for-all/utils/raygun-error"], function (_exports, _base, _raygunError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
    isEmpty = Ember.isEmpty;
  var Promise = RSVP.Promise;
  var _default = _base.default.extend({
    ajax: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    restore: function restore(data) {
      var now = new Date();
      if (!isEmpty(data.created) && !isEmpty(data.ttl)) {
        var createdAt = new Date(data.created);
        var expiresAt = new Date(data.created);
        expiresAt.setSeconds(createdAt.getSeconds() + data.ttl);
        if (now < expiresAt) {
          return Promise.resolve(data);
        }
      }
      return Promise.reject(new Error('Access token has expired'));
    },
    authenticate: function authenticate(email, password) {
      return this.get('ajax').request('tmp-users/login', {
        method: 'POST',
        data: JSON.stringify({
          email: email,
          password: password
        })
      }).catch(function (err) {
        (0, _raygunError.default)('Login', err);
        return Promise.reject(new Error('Could not be authenticated with these credentials.'));
      });
    },
    invalidate: function invalidate(data) {
      var _this = this;
      return new Promise(function (resolve) {
        if (data.logoutUrl) {
          var email = _this.get('authManager.currentUser.email');
          // Allow the session to invalidate if the currentUser didn't load
          if (!email) return resolve();
          var dataForLogout = {
            externalId: data.externalId,
            email: email
          };
          var encodedData = window.btoa(encodeURIComponent(JSON.stringify(dataForLogout)));
          window.location = data.logoutUrl + "?data=".concat(encodedData);
          return;
        }
        _this.get('ajax').request('tmp-users/logout', {
          method: 'POST',
          headers: {
            Authorization: data.id
          }
        }).then(function () {
          // If this was added to local storage by the mws-search application,
          // make sure it gets removed
          localStorage.removeItem('sessionID');
          resolve();
        }).catch(function (err) {
          (0, _raygunError.default)('Logout', err);
          resolve();
        });
      });
    }
  });
  _exports.default = _default;
});