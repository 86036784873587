define("tmp-for-all/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inflector = _emberInflector.default.inflector;
  inflector.uncountable('meta');
  inflector.irregular('worksite-history', 'worksite-history');

  // Meet Ember Inspector's expectation of an export
  var _default = {};
  _exports.default = _default;
});