define("tmp-for-all/components/conditions-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    // determine the condition type based on the first item in the list.
    createConditionLink: computed('conditionType', function () {
      return "conditions.".concat(get(this, 'conditionType'), ".create");
    })
  });
  _exports.default = _default;
});