define("tmp-for-all/mixins/action-create-tmp-draft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    run = Ember.run;
  var _default = Ember.Mixin.create({
    authManager: Ember.inject.service('session'),
    actions: {
      reviseTmp: function reviseTmp(tmp) {
        var _this = this;
        tmp.createDraft().then(function () {
          return get(_this, 'flashMessages').success('TMP draft revision has been created.');
        }).then(function () {
          return _this.transitionTo('tmp-draft.view', tmp.id);
        }).catch(function (err) {
          return get(_this, 'flashMessages').danger(err.message);
        });
      },
      createNewDraftTmp: function createNewDraftTmp(worksite) {
        var _this2 = this;
        return RSVP.Promise.all([get(worksite, 'jurisdiction'), get(this, 'authManager.currentUser')]).then(function (results) {
          var jurisdiction = results[0];
          var user = results[1];
          var company = get(user, 'domain');
          return _this2.store.createRecord('tmp-draft', {
            latestWorksite: worksite,
            jurisdiction: jurisdiction,
            company: company
          }).save();
        }).then(function (tmpDraft) {
          // This prevents an issue where the authorization service doesn't
          // correctly handle two requests (one from the permitted-to component
          // and the other from a beforeModel hook) in quick succession.
          return run.scheduleOnce('afterRender', function () {
            return _this2.transitionTo('tmp-draft.create-with-worksite', tmpDraft);
          });
        });
      }
    }
  });
  _exports.default = _default;
});