define("tmp-for-all/routes/conditions/worksite/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
    get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Route.extend({
    condition: computed.alias('controller.model.condition'),
    model: function model() {
      var worksiteModel = this.modelFor('conditions.worksite');
      return RSVP.hash({
        condition: this.store.createRecord('condition', {
          conditionType: get(worksiteModel, 'conditionType')
        }),
        conditionGroups: get(worksiteModel, 'conditionGroups')
      });
    },
    actions: {
      willTransition: function willTransition() {
        var condition = get(this, 'condition');
        if (condition) {
          condition.destroyOrRollback();
        }
      }
    }
  });
  _exports.default = _default;
});