define("tmp-for-all/utils/dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateOnly = dateOnly;
  function dateOnly(date) {
    if (date != null) return moment(date).format('YYYY-MM-DD');
    return date;
  }
});