define("tmp-for-all/templates/users/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wmQh4NyZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Edit Profile\"]],{\"statements\":[[0,\"  \"],[1,[27,\"user-profile\",null,[[\"user\",\"updateUser\"],[[23,[\"model\"]],[27,\"route-action\",[\"updateUser\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/users/profile.hbs"
    }
  });
  _exports.default = _default;
});