define("tmp-for-all/components/maps/draw-polygon/draw-layer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/CDyMPnp",
    "block": "{\"symbols\":[\"coordinateObject\"],\"statements\":[[0,\"  \"],[1,[27,\"polygon-layer\",null,[[\"weight\",\"locations\",\"onAdd\"],[[23,[\"polyLineWeight\"]],[23,[\"polygonCoordinates\"]],[27,\"action\",[[22,0,[]],\"polygonAdd\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"isActive\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"coordinateObjects\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"marker-layer\",null,[[\"icon\",\"location\",\"draggable\",\"clickable\",\"onDragend\",\"onClick\",\"onDblclick\"],[[23,[\"circleIcon\"]],[22,1,[\"coordinates\"]],[23,[\"isActive\"]],[23,[\"isActive\"]],[27,\"action\",[[22,0,[]],\"markerDragend\",[22,1,[]]],null],[27,\"action\",[[22,0,[]],\"markerClick\"],null],[27,\"action\",[[22,0,[]],\"markerDblclick\",[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/draw-polygon/draw-layer/template.hbs"
    }
  });
  _exports.default = _default;
});