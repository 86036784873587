define("tmp-for-all/mixins/models/private-files-mixin", ["exports", "tmp-for-all/classes/file-wrapper"], function (_exports, _fileWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    setProperties = Ember.setProperties,
    computed = Ember.computed,
    RSVP = Ember.RSVP;
  var _default = Ember.Mixin.create({
    filesForUpload: computed(function () {
      return _fileWrapper.FileWrapperArrayProxy.create({
        content: []
      });
    }),
    hasPrivateFiles: computed('files.@each.isPrivate', function () {
      return get(this, 'files') && get(this, 'files').toArray().some(function (f) {
        return !get(f, 'isDeleted') && get(f, 'isPrivate');
      });
    }),
    allFiles: computed('filesForUpload.content.[]', 'files.[]', function () {
      var files = (get(this, 'files') || []).toArray().concat(get(this, 'filesForUpload.content') || []);
      return files;
    }),
    // Persists deleted files as well as updates private booleans and reasons
    persistFiles: function persistFiles() {
      return RSVP.all(get(this, 'files').map(function (file) {
        return file.save();
      }));
    },
    validatePrivateFiles: function validatePrivateFiles() {
      if (get(this, 'hasPrivateFiles') && !get(this, 'allFiles').every(function (f) {
        return get(f, 'isPrivate') ? get(f, 'privateReason') : true;
      })) {
        return 'Some of the commercially sensitive files do not have a reason provided.';
      }
      if (!get(this, 'hasPrivateFiles')) {
        get(this, 'allFiles').forEach(function (f) {
          if (!get(f, 'isDeleted')) setProperties(f, {
            isPrivate: false,
            privateReason: null
          });
        });
      }
      return null;
    }
  });
  _exports.default = _default;
});