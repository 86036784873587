define("tmp-for-all/models/tmp-submission", ["exports", "ember-data", "tmp-for-all/models/tmp-base", "tmp-for-all/constants", "tmp-for-all/utils/conditions-util", "tmp-for-all/utils/html-comment-helpers", "tmp-for-all/utils/animations"], function (_exports, _emberData, _tmpBase, _constants, _conditionsUtil, _htmlCommentHelpers, _animations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    RSVP = _Ember.RSVP,
    get = _Ember.get,
    set = _Ember.set,
    $ = _Ember.$;
  var _default = _tmpBase.default.extend({
    acceptComment: attr('string'),
    declineComment: attr('string'),
    type: _constants.TMP_TYPES.SUBMISSION,
    ajax: Ember.inject.service(),
    acceptanceConditions: hasMany('acceptance-condition', {
      async: true
    }),
    customConditions: hasMany('custom-condition'),
    owner: belongsTo('tmp-user', {
      async: true
    }),
    saveConditions: function saveConditions(selectedConditions, rcaId) {
      var _this = this;
      var tmpId = get(this, 'id');
      var selectedConditionIds = selectedConditions.map(function (condition) {
        return get(condition, 'id');
      });
      return get(this, 'ajax').updateTmpConditions(tmpId, rcaId, selectedConditionIds).then(function () {
        return (0, _conditionsUtil.refreshAcceptanceConditions)(_this, 'tmpSubmission', _this.store);
      });
    },
    accept: function accept(commentText, conditions) {
      var _this2 = this;
      this.set('isAccepting', true);
      this.set('acceptComment', commentText);
      var saveFilesOrNoop = get(this, 'isGenericTmp') ? this.saveFiles() : RSVP.resolve();
      return saveFilesOrNoop.catch(function () {
        return RSVP.reject(new Error('Failed to upload files'));
      }).then(function () {
        return _this2.get('ajax').tmpAction(_this2, 'accept');
      }).then(function () {
        return get(_this2, 'latestWorksite.tmps').reload();
      }).then(function () {
        return get(_this2, 'latestWorksite.tmpSubmissions').reload();
      }).then(function () {
        return _this2.unloadRecord();
      }).catch(function () {
        _this2.get('history').reload();
        return Promise.reject(new Error('TMP could not be accepted, please try again'));
      }).finally(function () {
        _this2.set('isAccepting', false);
      });
    },
    decline: function decline(commentText) {
      var _this3 = this;
      this.set('isDeclining', true);
      if (!commentText || !(0, _htmlCommentHelpers.hasHtmlComment)(commentText)) {
        this.set('isDeclining', false);
        (0, _animations.jiggleALittleBit)($('.t-decline-textarea'));
        return RSVP.reject(new Error('Please enter a declined comment before clicking confirm'));
      }
      this.set('declineComment', commentText);
      return this.get('ajax').tmpAction(this, 'decline').catch(function () {
        return RSVP.reject(new Error('TMP could not be declined, please try again'));
      }).finally(function () {
        _this3.get('history').reload();
        _this3.set('isDeclining', false);
      });
    },
    createDraft: function createDraft() {
      var _this4 = this;
      this.set('isCreatingDraft', true);
      return this.get('ajax').tmpAction(this, 'draft').then(function () {
        var tmp = _this4.store.peekRecord('tmp-draft', get(_this4, 'id'));
        if (tmp) return tmp.hasMany('history').reload();
      }).catch(function () {
        return RSVP.reject(new Error('TMP draft could not be created, please try again'));
      }).finally(function () {
        return _this4.set('isCreatingDraft', false);
      });
    },
    assign: function assign(user) {
      return this.get('ajax').tmpAction(this, 'assign', get(user, 'id')).catch(function () {
        return RSVP.reject(new Error('TMP could not be assigned, please try again'));
      });
    },
    claim: function claim(isSecondary) {
      var _this5 = this;
      set(this, 'isClaiming', true);
      return this.get('ajax').tmpAction(this, 'claim', isSecondary).catch(function () {
        return RSVP.reject(new Error('TMP could not be claimed, please try again'));
      }).finally(function () {
        return set(_this5, 'isClaiming', false);
      });
    },
    unClaim: function unClaim() {
      var _this6 = this;
      set(this, 'isClaiming', true);
      return this.get('ajax').tmpAction(this, 'unClaim').then(function () {
        return set(_this6, 'owner', null);
      }).catch(function () {
        return RSVP.reject(new Error('TMP claim could not be removed'));
      }).finally(function () {
        return set(_this6, 'isClaiming', false);
      });
    }
  });
  _exports.default = _default;
});