define("tmp-for-all/components/condition-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    archiveButtonText: computed('condition.isArchived', function () {
      return this.get('condition.isArchived') ? 'Unarchive' : 'Archive';
    }),
    selectedGroup: computed('conditionGroups', 'condition.conditionGroups', 'groupId', function () {
      var group = get(this, 'condition.conditionGroups').objectAt(0);
      var groupId = get(this, 'groupId');
      if (group) return this.get('conditionGroups').find(function (cg) {
        return cg.get('id') === group.get('id');
      });
      if (groupId) return this.get('conditionGroups').find(function (cg) {
        return cg.get('id') === groupId;
      });
      return get(this, 'conditionGroups').objectAt(0);
    }),
    actions: {
      toggleArchiveState: function toggleArchiveState() {
        this.toggleProperty('condition.isArchived');
        this.saveCondition(this.condition, this.selectedGroup, this.actionName);
      }
    }
  });
  _exports.default = _default;
});