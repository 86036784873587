define("tmp-for-all/components/password/request-reset/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    classNames: ['password-reset-wrap'],
    ajax: Ember.inject.service(),
    inputIconColor: computed('email', function () {
      return get(this, 'email') ? 'blue' : 'grey';
    }),
    actions: {
      requestPasswordReset: function requestPasswordReset() {
        var _this = this;
        var email = get(this, 'email');
        get(this, 'ajax').userAction(null, 'request-password-reset', email).then(function () {
          return set(_this, 'success', true);
        }).catch(function (err) {
          // Check, was this just a multi-click by the user? If so, just dump the error as it is not relevant
          if ((0, _emberConcurrency.didCancel)(err)) return;
          var errorDetail;
          if (err.errors) {
            errorDetail = err.errors[0].detail;
          } else if (err.payload && err.payload.errors) {
            errorDetail = err.payload.errors[0].detail;
          } else {
            Raygun.send(err);
            errorDetail = 'Error processing password reset';
          }
          set(_this, 'message', errorDetail);
        });
      }
    }
  });
  _exports.default = _default;
});