define("tmp-for-all/models/worksite", ["exports", "ember-data", "tmp-for-all/models/worksite-base", "tmp-for-all/constants", "tmp-for-all/utils/html-comment-helpers", "tmp-for-all/utils/animations"], function (_exports, _emberData, _worksiteBase, _constants, _htmlCommentHelpers, _animations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    RSVP = _Ember.RSVP,
    computed = _Ember.computed,
    get = _Ember.get,
    set = _Ember.set,
    $ = _Ember.$;
  var _default = _worksiteBase.default.extend({
    ajax: Ember.inject.service(),
    isExternalAcceptance: attr('boolean'),
    declineComment: attr('string'),
    notices: hasMany('notice'),
    acceptanceConditions: hasMany('acceptance-condition', {
      async: true
    }),
    customConditions: hasMany('custom-condition'),
    status: computed('isDeclined', function () {
      return get(this, 'isDeclined') ? 'Declined' : 'Accepted';
    }),
    type: _constants.WORKSITE_MODEL_TYPES.ACCEPTED,
    owner: belongsTo('tmp-user', {
      async: true
    }),
    addEmptyWorkItem: function addEmptyWorkItem() {
      this.store.createRecord('work-item', {
        worksite: this
      });
    },
    createDraft: function createDraft() {
      var _this = this;
      set(this, 'isCreatingDraft', true);
      return get(this, 'ajax').worksiteAction(this, 'draft').then(function () {
        var draft = _this.store.peekRecord('worksite-draft', _this.id);
        if (draft) {
          get(_this, 'store').unloadRecord(draft);
        }
      }).catch(function () {
        return RSVP.reject(new Error('Worksite draft could not be created, please try again'));
      }).finally(function () {
        return set(_this, 'isCreatingDraft', false);
      });
    },
    decline: function decline(commentText) {
      var _this2 = this;
      set(this, 'isDeclining', true);
      if (!commentText || !(0, _htmlCommentHelpers.hasHtmlComment)(commentText)) {
        set(this, 'isDeclining', false);
        (0, _animations.jiggleALittleBit)($('.t-decline-textarea'));
        return RSVP.reject(new Error('Please enter a declined comment before clicking confirm'));
      }
      set(this, 'declineComment', commentText);
      return get(this, 'ajax').worksiteAction(this, 'decline').then(function () {
        var worksite = _this2.store.peekRecord('worksite', _this2.id);
        if (worksite) worksite.reload();
      }).catch(function () {
        return RSVP.reject(new Error('Worksite could not be declined, please try again'));
      }).finally(function () {
        get(_this2, 'history').reload();
        set(_this2, 'isDeclining', false);
        set(_this2, 'hasDeclined', true);
      });
    },
    startWork: function startWork(payload) {
      return get(this, 'ajax').worksiteAction(this, 'start-work', payload);
    },
    completeWork: function completeWork(payload, filesForUpload) {
      return get(this, 'ajax').worksiteAction(this, 'complete-work', payload, filesForUpload);
    },
    completeMaintenance: function completeMaintenance(payload, filesForUpload) {
      return get(this, 'ajax').worksiteAction(this, 'complete-maintenance', payload, filesForUpload);
    },
    modifyWarrantyDate: function modifyWarrantyDate(warrantyEndDate) {
      return get(this, 'ajax').worksiteAction(this, 'modify-warranty', warrantyEndDate);
    },
    stopWork: function stopWork(payload, filesForUpload) {
      return get(this, 'ajax').worksiteAction(this, 'stop-work', payload, filesForUpload);
    },
    nonConformance: function nonConformance(payload, filesForUpload) {
      return get(this, 'ajax').worksiteAction(this, 'non-conformance', payload, filesForUpload);
    },
    unclaim: function unclaim() {
      var _this3 = this;
      set(this, 'isClaiming', true);
      return this.get('ajax').worksiteAction(this, 'unclaim').then(function () {
        // See tmp-submission.js for note on this unorthodox approach\
        set(_this3, 'owner', null);
        set(_this3, 'currentState', _emberData.default.RootState.loaded.saved);
      }).catch(function () {
        return RSVP.reject(new Error('Worksite could not be unclaimed, please try again'));
      }).finally(function () {
        return set(_this3, 'isClaiming', false);
      });
    },
    claim: function claim(user) {
      var _this4 = this;
      set(this, 'isClaiming', true);
      return this.get('ajax').worksiteAction(this, 'claim', get(user, 'id')).then(function () {
        // See tmp-submission.js for note on this unorthodox approach\
        set(_this4, 'owner', user);
        set(_this4, 'currentState', _emberData.default.RootState.loaded.saved);
      }).catch(function () {
        return RSVP.reject(new Error('Worksite could not be claimed, please try again'));
      }).finally(function () {
        return set(_this4, 'isClaiming', false);
      });
    }
  });
  _exports.default = _default;
});