define("tmp-for-all/components/planning-map/component", ["exports", "ember-copy", "tmp-for-all/constants", "tmp-for-all/utils/map-functions"], function (_exports, _emberCopy, _constants, _mapFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    run = Ember.run;

  // Stolen and modified from https://stackoverflow.com/a/9229821/403264
  // Designed for performance because can be determining a large number of markers
  function getClassNames(clusterMarkers) {
    var seen = {};
    var out = [];
    var len = clusterMarkers.length;
    var j = 0;
    for (var i = 0; i < len; i++) {
      var item = clusterMarkers[i].typed;
      if (seen[item] !== 1) {
        seen[item] = 1;
        out[j++] = item;
      }

      // Note: could be further enhanced by stopping the loop once all possible classnames are represented once.
      // Propose to do this by a simple count
    }

    return out.join(' ');
  }

  /* global L */
  var _default = Ember.Component.extend({
    ZOOM_LEVEL_CUTOVER: _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER,
    isLayersPanelVisible: true,
    selectedTileComponentName: 'mapbox-streets-tile-layer',
    loadingModels: false,
    detailModels: [],
    init: function init() {
      this._super.apply(this, arguments);
      var markerClusterGroup = L.markerClusterGroup({
        iconCreateFunction: this.createClusterIcon.bind(this),
        showCoverageOnHover: false
      });
      markerClusterGroup.on('click', this.selectWorksitePoint.bind(this));
      set(this, 'markerClusterGroup', markerClusterGroup);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // only set currentZoom and currentCenter on the first load of the component
      if (!get(this, 'currentZoom') && !get(this, 'currentCenter')) {
        set(this, 'currentZoom', get(this, 'zoom'));
        set(this, 'currentCenter', get(this, 'center'));
      }
      var data = get(this, 'data');
      var markerClusterGroup = get(this, 'markerClusterGroup');
      markerClusterGroup.clearLayers();
      if (data && data.length) {
        var markers = data.map(function (datum) {
          var center = get(datum, 'center');
          var centerCoords = center && get(center, 'coordinates');
          if (centerCoords) {
            var mark;
            if (datum.type === 'worksite') {
              var markerIcon = {
                icon: L.icon({
                  iconUrl: '/assets/images/marker-worksite.svg',
                  iconSize: [35, 42],
                  iconAnchor: [21, 35]
                })
              };
              mark = L.marker(L.latLng((0, _mapFunctions.lngLatToLatLng)(centerCoords)), markerIcon);
            } else if (datum.type === 'impact') {
              var _markerIcon = {
                icon: L.icon({
                  iconUrl: '/assets/images/marker-impact.svg',
                  iconSize: [35, 42],
                  iconAnchor: [21, 35]
                })
              };
              mark = L.marker(L.latLng((0, _mapFunctions.lngLatToLatLng)(centerCoords)), _markerIcon);
            } else {
              mark = L.marker(L.latLng((0, _mapFunctions.lngLatToLatLng)(centerCoords)));
            }
            mark.typed = datum.type;
            return mark;
          }
        });
        markerClusterGroup.addLayers(markers);
      }

      // If we're zoomed in enough to show polygons, then we need to refresh them if the data has changed
      if (get(this, 'currentZoom') >= _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER) this.updatevisibleFeatures();

      // If we get new data, we need to remove any highlighted layers
      // This sometimes means we remove a highlight when adding data to an existing set, but better that than leaving
      // a highlight there when the underlying data has been removed
      this.send('removeHighlightedFeature');
    },
    markers: computed('data.@each.center', function () {
      return get(this, 'data').map(function (datum) {
        return L.marker(L.latLng((0, _mapFunctions.lngLatToLatLng)(get(datum, 'center.coordinates'))));
      });
    }),
    loadingMapData: computed.alias('isLoadingData'),
    selectWorksitePoint: function selectWorksitePoint(e) {
      // This action only fires if the point is a marker, and not a cluster
      // Presumable ember-leaflet-marker-cluster intercepts the onClick event and doesn't propagate, which is fine by us
      set(this, 'currentCenter', [e.latlng.lat, e.latlng.lng]);

      // Because of map animation events (I believe), we need to wait for the move to complete before we can set the
      // zoom level.  Therefore set a flag that the moveend event can check
      set(this, 'targetZoom', _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER + 1);
    },
    worksiteIcon: L.icon({
      iconUrl: '/assets/images/worksite-pin.svg'
    }),
    createClusterIcon: function createClusterIcon(cluster) {
      var distinctClusterClasses = getClassNames(cluster.getAllChildMarkers());
      return L.divIcon({
        className: 'cluster-marker ' + distinctClusterClasses,
        html: '<span class="cluster-marker-count">' + cluster.getChildCount() + '</span>',
        iconSize: L.Point(55, 55)
      });
    },
    updatevisibleFeatures: function updatevisibleFeatures() {
      var _this = this;
      // Fetch just the polys that have their centre point visible on the currently bounded map
      var visibleFeatures = (0, _mapFunctions.getvisibleFeatures)(get(this, 'leafletMap'), get(this, 'data'));
      var featureTypes = visibleFeatures.reduce(function (acc, feature) {
        var ref = get(feature, 'worksitetype') || get(feature, 'impacttype') || 'detour';
        if (!acc[ref]) {
          acc[ref] = {
            style: _this.setPolyStyle(feature),
            geoJson: []
          };
        }
        acc[ref].geoJson.push(feature.geometry);
        return acc;
      }, {});
      set(this, 'visibleFeatures', featureTypes);
    },
    setPolyStyle: function setPolyStyle(poly) {
      var style = {};
      if (get(poly, 'worksitetype')) {
        style.fillColor = _constants.MAP_OBJECT_COLORS[get(poly, 'worksitetype')].fillColor;
        style.fillOpacity = 0.4;
        style.color = _constants.MAP_OBJECT_COLORS[get(poly, 'worksitetype')].color;
        style.weight = 2;
      } else if (get(poly, 'impacttype')) {
        style.fill = false;
        style.color = _constants.MAP_OBJECT_COLORS[get(poly, 'impacttype')];
        style.weight = 3;
        style.opacity = 0.8;
      } else {
        // detour
        style.fill = false;
        style.color = _constants.MAP_OBJECT_COLORS.detour;
        style.weight = 3;
        style.opacity = 0.8;
      }
      return style;
    },
    actions: {
      toggleLayerPanel: function toggleLayerPanel() {
        this.toggleProperty('isLayersPanelVisible');
      },
      setTileComponentName: function setTileComponentName(tileComponentName) {
        set(this, 'selectedTileComponentName', tileComponentName);
      },
      setMapObject: function setMapObject(e) {
        // Stores the leaflet map instance object into a local variable for use later
        var map = e.target;
        set(this, 'leafletMap', map);
        map.addLayer(get(this, 'markerClusterGroup'));
        var attribution = L.control.attribution({
          position: 'bottomleft'
        });
        map.addControl(attribution);

        // Now trigger the fetch for the initial data
        var boundingBox = (0, _mapFunctions.getBoundingBox)(get(this, 'leafletMap'));
        get(this, 'updateSearchQuery')({
          boundingBox: boundingBox
        });
        set(this, 'mapBounds', boundingBox);

        // Limit map view to NZ only - maxBounds prevents panning and minZoom from zooming too far away
        map.setMaxBounds(_constants.DEFAULT_NZ_BOUNDS);
        map.setMinZoom(_constants.DEFAULT_MIN_ZOOM);
      },
      renderPolysOrClusters: function renderPolysOrClusters(e) {
        var zoomLevel = e.target.getZoom();
        var map = get(this, 'leafletMap');
        var markerClusterGroup = get(this, 'markerClusterGroup');
        if (map && map.hasLayer(markerClusterGroup) && zoomLevel >= _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER) {
          // Zoom in, hide clusters, show layouts
          map.removeLayer(markerClusterGroup);
        } else if (map && !map.hasLayer(markerClusterGroup) && zoomLevel < _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER) {
          // Zoom out, show clusters, hide layouts
          map.addLayer(markerClusterGroup);
          this.send('removeHighlightedFeature');
        }
      },
      handlePanOrZoom: function handlePanOrZoom(e) {
        // If the user came here by clicking a point, we just need to carry out the zoom action now
        if (get(this, 'targetZoom')) {
          // There is an occasional bug with setting currentZoom twice within the same render cycle that is
          // very difficult to track down.  This fix makes it work, but I could only arm-wave an answer as to why
          // Something to do with Ember runloops and Leaflet events/animations
          Ember.run.next(this, function () {
            set(this, 'currentZoom', get(this, 'targetZoom'));
            set(this, 'targetZoom', null); // reset
          });

          return;
        }
        // Pans and zooms can fire multiple instances of this in quick succession, so debounce to save client-side
        // calculation of visibleFeatures
        run.debounce(this, function () {
          var newZoom = e.target.getZoom();
          // Need this to avoid "calling set on destroyed object" error in tests
          if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
            if (get(this, 'currentZoom') !== newZoom) {
              set(this, 'currentZoom', newZoom);
              return;
            }

            // Only fetch data if not already been retrieved
            if (!(0, _mapFunctions.isWithinCurrentBounds)(get(this, 'mapBounds'), get(this, 'leafletMap'))) {
              var boundingBox = (0, _mapFunctions.getBoundingBox)(get(this, 'leafletMap'));
              get(this, 'updateSearchQuery')({
                boundingBox: boundingBox
              });
              set(this, 'mapBounds', boundingBox);
            }
            if (get(this, 'currentZoom') >= _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER) {
              // We only build polygons for the points in the current display area.  This might be an over-optimisation, as
              // leaflet will only ever render visible Polys, but it does save on creating a couple of thousand unused objects
              this.updatevisibleFeatures();
            }
          }
        }, 250);
      },
      updateMap: function updateMap() {
        var coords = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        if (coords.lat && coords.lng && coords.zoom) {
          set(this, 'currentCenter', [coords.lat, coords.lng]);
          set(this, 'targetZoom', coords.zoom);
        }
      },
      determineSelectedItems: function determineSelectedItems(e) {
        var _this2 = this;
        var currentZoom = get(this, 'leafletMap').getZoom();
        if (currentZoom < _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER) return;
        set(this, 'detailModels', []);
        set(this, 'loadingModels', true);
        get(this, 'getDetail')([e.latlng.lat, e.latlng.lng], currentZoom).then(function (models) {
          if (models.length === 0) {
            models.push({
              constructor: {
                modelName: 'nothing'
              }
            });
          }
          set(_this2, 'detailModels', models);
          set(_this2, 'loadingModels', false);
        });
      },
      showHighlightedFeature: function showHighlightedFeature(featureGeom) {
        var options = {
          zIndexOffset: 100,
          style: function style() {
            return {
              color: _constants.MAP_OBJECT_COLORS.worksite,
              fillColor: _constants.MAP_OBJECT_COLORS.worksite,
              fillOpacity: 0.4,
              weight: 2
            };
          }
        };
        var optionsWithPointToLayer = (0, _emberCopy.copy)(options, true);
        optionsWithPointToLayer.pointToLayer = function (geoJson, latlng) {
          if (geoJson.properties.iconPath) {
            // If we've set a a particular iconPath, then we know it's a marker and we need a custom icon
            return L.marker(latlng, {
              icon: L.icon({
                iconUrl: geoJson.properties.iconPath,
                iconSize: [35, 42],
                iconAnchor: [16, 42]
              })
            });
          } else {
            // If we've not set an iconPath then call the static funciton on L.GeoJson that does the rendering
            return L.GeoJSON.geometryToLayer(geoJson, options);
          }
        };
        this.send('removeHighlightedFeature');
        var highlightedLayer = L.geoJSON(featureGeom, optionsWithPointToLayer);
        get(this, 'leafletMap').addLayer(highlightedLayer);
        set(this, 'highlightedLayer', highlightedLayer);
      },
      removeHighlightedFeature: function removeHighlightedFeature() {
        var highlightedLayer = get(this, 'highlightedLayer');
        if (highlightedLayer) {
          get(this, 'leafletMap').removeLayer(highlightedLayer);
          set(this, 'highlightedLayer', null);
        }
      }
    }
  });
  _exports.default = _default;
});