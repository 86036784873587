define("tmp-for-all/components/worksite-view/documents/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['margin-btm-50'],
    allDocumentationBlocks: computed('worksite.documentationBlocks', function () {
      var documentationBlocks = get(this, 'worksite.documentationBlocks');
      return Object.keys(_constants.DOC_TYPES).map(function (key) {
        return documentationBlocks.findBy('type', key) || {
          text: null,
          type: key
        };
      });
    })
  });
  _exports.default = _default;
});