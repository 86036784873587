define("tmp-for-all/components/shared/form/text-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yKKqiU3P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"text-editor\"],[9],[0,\"\\n  \"],[1,[27,\"pell-editor\",null,[[\"value\",\"onChange\",\"pellOptions\"],[[22,0,[\"value\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[22,0,[\"value\"]]],null]],null],[23,[\"options\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/text-editor/template.hbs"
    }
  });
  _exports.default = _default;
});