define("tmp-for-all/classes/file-wrapper", ["exports", "tmp-for-all/constants", "tmp-for-all/config/environment", "tmp-for-all/utils/animations"], function (_exports, _constants, _environment, _animations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FileWrapperArrayProxy = _exports.FileWrapper = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    $ = Ember.$;

  /**
   * A wrapper around file objects that come from a <input type="file"> element
   *
   * The purpose of this is to contain all of the validation and
   * other state regarding the file object itself.
   *
   **/
  var FileWrapper = Ember.Object.extend({
    file: null,
    maxFileSize: _environment.default.maxFileSize,
    acceptableExtensions: _constants.DEFAULT_FILE_EXTENSIONS,
    isPrivate: false,
    privateReason: null,
    init: function init() {
      this._super.apply(this, arguments);
      if (!get(this, 'file')) {
        console.error('FileWrapper object created without a file. Must be an error.');
      }
    },
    validSize: computed('file.size', function () {
      return get(this, 'file.size') < get(this, 'maxFileSize');
    }).readOnly(),
    invalidSize: computed.not('validSize').readOnly(),
    validExtension: computed('file.name', function () {
      var fileName = get(this, 'file.name') || '';
      return get(this, 'acceptableExtensions').some(function (extension) {
        return fileName.toLowerCase().endsWith(extension);
      });
    }).readOnly(),
    invalidExtension: computed.not('validExtension').readOnly(),
    validPrivacy: computed('isPrivate', 'privateReason', function () {
      return !(this.isPrivate && !this.privateReason);
    }),
    isValid: computed.and('validSize', 'validExtension'),
    isInvalid: computed('isValid', 'validPrivacy', function () {
      return !this.isValid || !this.validPrivacy;
    }).readOnly(),
    isImage: computed('file.type', function () {
      return /image\/.*/.test(get(this, 'file.type'));
    }).readOnly()
  });
  _exports.FileWrapper = FileWrapper;
  FileWrapper.toString = function () {
    return 'file-upload:FileWrapper';
  };

  /**
   * An array proxy meant to house an array of FileWrapper objects.
   * Provides an interface to the FileWrapper collection to ease validation and logic
   *
   * Usage:
   *
   * const fileData = FileData.create({file})
   * const files = FileWrapperArrayProxy.create({content: []})
   *
   * Reference:
   * https://emberjs.com/api/classes/Ember.ArrayProxy.htm
   */
  var FileWrapperArrayProxy = Ember.ArrayProxy.extend({
    invalidFiles: computed.filterBy('content', 'isInvalid').readOnly(),
    isValid: computed.empty('invalidFiles').readOnly(),
    isInvalid: computed.not('isValid').readOnly(),
    /**
     * Returns plain object versions of this array and the contents
     */
    simplify: function simplify() {
      return get(this, 'content').getEach('file');
    },
    /**
     * @param {boolean} jiggle If true, then jiggle the invalid files
     * @returns {boolean} whether or not the files are valid
     */
    validate: function validate() {
      var jiggle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (get(this, 'isInvalid')) {
        if (jiggle) {
          (0, _animations.jiggleALittleBit)($('.invalid-file'));
        }
        return false;
      }
      return true;
    }
  });
  _exports.FileWrapperArrayProxy = FileWrapperArrayProxy;
  FileWrapperArrayProxy.toString = function () {
    return 'file-upload:FileWrapperArrayProxy';
  };
});