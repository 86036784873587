define("tmp-for-all/components/shared/form/file-upload/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['file-item', 'file-to-upload'],
    classNameBindings: ['fileData.isInvalid:invalid-file'],
    displayText: computed('fileData.file.name', 'fileData.{invalidSize,invalidExtension}', function () {
      var fileName = get(this, 'fileData.file.name');
      var msg;
      if (get(this, 'fileData.invalidSize')) {
        msg = '(File too large)';
      } else if (get(this, 'fileData.invalidExtension')) {
        msg = '(Invalid file type)';
      } else {
        msg = '';
      }
      return "".concat(fileName, " ").concat(msg).trim();
    })
  });
  _exports.default = _default;
});