define("tmp-for-all/mixins/components/expand-contract-mixin", ["exports", "velocity-animate"], function (_exports, _velocityAnimate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Mixin.create({
    // Expand element and disable expand/contract functionality if height difference is minimal:
    didInsertElement: function didInsertElement() {
      // Pass in dontDisable=true to override disabling:
      if (get(this, 'dontDisable')) return;
      // Set min height difference threshold for enabling expand/contract functionality:
      var heightDifferenceThreshold = 40;
      var innerElementHeight = get(this, 'expandableElementHeight');
      var outerElementHeight = get(this, 'initialHeight');
      // Disable expand/contract functionality if inner content is shorter than outer wrap:
      if (innerElementHeight < outerElementHeight + heightDifferenceThreshold) {
        set(this, 'isDisabled', true);
      }
    },
    classNameBindings: ['isExpanded:expanded', 'isDisabled:expand-disabled', 'isAnimating:is-animating'],
    attributeBindings: ['style'],
    /*
      Add inline style attribute to set height of element to auto when expand
      animation complete (allows for dynamic content within expanded element)
    */
    style: computed('initialHeight', 'isExpanded', 'isDisabled', function () {
      return get(this, 'isExpanded') || get(this, 'isDisabled') ? Ember.String.htmlSafe('height: auto') : Ember.String.htmlSafe("height: ".concat(get(this, 'initialHeight'), "px;"));
    }),
    isExpanded: false,
    // Hack to allow parent component to trigger toggleHeight action...
    manualToggle: false,
    triggerToggle: Ember.observer('manualToggle', function () {
      this.send('toggleHeight');
    }),
    // Default initial height in pixels (override as needed):
    initialHeight: 140,
    expandableElementHeight: computed(function () {
      return this.$().find('.expandable-content').outerHeight();
    }),
    // Minimum animation speed for expand/contract animation (override setting as needed):
    minAnimationSpeed: 250,
    // Sets height (in pixels) to animate per second (override as needed):
    animatePixelsPerSecond: 1000,
    /*
      Dynamically calculate animation speed (in milliseconds) based on height
      difference between contracted/expanded states. minAnimationSpeed is returned
      instead if calculated speed is less than the minimum
    */
    animationSpeed: computed('minAnimationSpeed', 'animatePixelsPerSecond', 'initialHeight', 'expandableElementHeight', function () {
      var heightDifference = get(this, 'initialHeight') - get(this, 'expandableElementHeight');
      if (heightDifference < 0) heightDifference *= -1;
      var speed = parseFloat(heightDifference / get(this, 'animatePixelsPerSecond')) * 1000; // 1000ms
      return Math.max(speed, get(this, 'minAnimationSpeed'));
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _velocityAnimate.default.animate(this.element, 'stop');
    },
    actions: {
      toggleHeight: function toggleHeight() {
        var _this = this;
        set(this, 'isAnimating', true);
        // Contract element to initial height:
        if (get(this, 'isExpanded')) {
          _velocityAnimate.default.animate(this.element, {
            height: this.initialHeight
          }, {
            duration: this.animationSpeed
          }).then(function () {
            _this.setProperties({
              isExpanded: false,
              isAnimating: false
            });
          });
          // Expand element to match height of inner element:
        } else {
          _velocityAnimate.default.animate(this.element, {
            height: this.expandableElementHeight
          }, {
            duration: this.animationSpeed
          }).then(function () {
            _this.setProperties({
              isExpanded: true,
              isAnimating: false
            });
          });
        }
      }
    }
  });
  _exports.default = _default;
});