define("tmp-for-all/components/search-parameters/filters/filter-controls/options-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "S4HO/iVV",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"form-field/wrapper\",null,[[\"label\",\"isParentLabel\"],[[23,[\"filter\",\"label\"]],true]],{\"statements\":[[4,\"power-select\",null,[[\"renderInPlace\",\"onchange\",\"selected\",\"searchEnabled\",\"searchField\",\"tagName\",\"class\",\"options\"],[true,[27,\"action\",[[22,0,[]],\"selectOption\"],null],[23,[\"selectedOption\"]],false,\"name\",\"div\",\"ember-power-select\",[23,[\"options\"]]]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row end-xs\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"tertiary-action\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],[23,[\"toggleModal\"]]]],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"primary-action primary-action--listed-right \",[27,\"if\",[[23,[\"isDisabled\"]],\"secondary-action--disabled\"],null]]]],[12,\"disabled\",[21,\"isDisabled\"]],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",null,[[\"type\",\"classNames\"],[\"filter\",\"primary-action__icon-left\"]]],false],[0,\" Add filter\\n    \"],[3,\"action\",[[22,0,[]],\"saveFilter\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-parameters/filters/filter-controls/options-filter/template.hbs"
    }
  });
  _exports.default = _default;
});