define("tmp-for-all/templates/users/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/Ea8Q2TH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Create User\"]],{\"statements\":[[0,\"  \"],[1,[27,\"user-form\",null,[[\"user\",\"companyRoles\",\"jurisdictions\",\"jurisdictionRoles\",\"unscopedRoles\",\"saveUser\",\"addPermissionAssignment\",\"deletePermissionAssignment\",\"updatePermissionAssignment\",\"isSuperUser\",\"hasAssignedCompany\",\"hasAssignedJurisdiction\",\"searchCompanies\",\"assignments\"],[[23,[\"model\",\"user\"]],[23,[\"model\",\"companyRoles\"]],[23,[\"model\",\"jurisdictions\"]],[23,[\"model\",\"jurisdictionRoles\"]],[23,[\"model\",\"unscopedRoles\"]],[27,\"route-action\",[\"saveUser\"],null],[27,\"route-action\",[\"addPermissionAssignment\"],null],[27,\"route-action\",[\"deletePermissionAssignment\"],null],[27,\"route-action\",[\"updatePermissionAssignment\"],null],[23,[\"model\",\"isSuperUser\"]],false,false,[27,\"route-action\",[\"searchCompanies\"],null],[23,[\"model\",\"assignments\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/users/create.hbs"
    }
  });
  _exports.default = _default;
});