define("tmp-for-all/components/shared/form/check-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    observer = Ember.observer;
  var _default = Ember.Component.extend({
    classNames: ['checkbox'],
    classNameBindings: ['notInline:element-wrap:inline-element-wrap', 'useButtonStyle', 'checked'],
    notInline: computed('inline', function () {
      var inline = get(this, 'inline');
      if (inline) {
        return !inline;
      }
      return true;
    }),
    inputID: computed('elementId', function () {
      return "input-".concat(get(this, 'elementId'));
    }),
    observeCheckbox: observer('checked', function () {
      var update = get(this, 'update');
      if (update) {
        update(get(this, 'checked'), get(this, 'label'), get(this, 'value'));
      }
    })
  });
  _exports.default = _default;
});