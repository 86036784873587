define("tmp-for-all/templates/rca-admin/holidays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Zmzfp2+X",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Edit Holidays\"]],{\"statements\":[[0,\"  \"],[1,[27,\"admin/edit-holidays\",null,[[\"holidays\",\"jurisdiction\",\"selectedDate\",\"newHolidays\",\"addHoliday\",\"deleteHoliday\"],[[23,[\"model\",\"holidays\"]],[23,[\"model\",\"jurisdiction\"]],[23,[\"model\",\"selectedDate\"]],[23,[\"model\",\"newHolidays\"]],[27,\"route-action\",[\"addHoliday\"],null],[27,\"route-action\",[\"deleteHoliday\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/rca-admin/holidays.hbs"
    }
  });
  _exports.default = _default;
});