define("tmp-for-all/components/shared/form/file-upload/component", ["exports", "tmp-for-all/constants", "tmp-for-all/classes/file-wrapper"], function (_exports, _constants, _fileWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;

  /**
   * This component keeps its own internal state for tracking the files
   * that have been selected for upload.
   * Whenever something is added or removed, the new list is bubbled up
   *
   */
  var _default = Ember.Component.extend({
    tagName: 'span',
    /**
     * Bind pendingFiles to an outside source if you need state to persist across routes.
     * See layouts implementation for an example
     */
    pendingFiles: computed(function () {
      return _fileWrapper.FileWrapperArrayProxy.create({
        content: []
      });
    }),
    acceptFilter: _constants.DEFAULT_ACCEPT_FILE_TYPES,
    acceptableExtensions: computed('acceptFilter', function () {
      return get(this, 'acceptFilter').split(',');
    }).readOnly(),
    /**
     * Placeholder function to document the update api
     * @param {FileWrapperArrayProxy<FileWrapper>} pendingFiles
     * @param {FileWrapperArrayProxy<FileWrapper>} filesAdded
     * @param {FileWrapper[]} filesRemoved
     */
    filesUpdated: function filesUpdated(pendingFiles, filesAdded, filesRemoved) {
      throw new Error('filesUpdated must be defined on the file-upload component');
    },
    actions: {
      didSelectFiles: function didSelectFiles() {
        var filesAdded = _fileWrapper.FileWrapperArrayProxy.create({
          content: []
        });
        var files = this.$('input')[0].files;
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          filesAdded.pushObject(_fileWrapper.FileWrapper.create({
            file: file,
            acceptableExtensions: get(this, 'acceptableExtensions')
          }));
        }

        // Remove the file selection so the change event
        // triggers properly in the future
        this.$('input').val('');
        var pendingFiles = get(this, 'pendingFiles') || _fileWrapper.FileWrapperArrayProxy.create({
          content: []
        });
        if (pendingFiles.constructor !== _fileWrapper.FileWrapperArrayProxy) {
          pendingFiles = _fileWrapper.FileWrapperArrayProxy.create({
            content: pendingFiles
          });
        }
        pendingFiles.pushObjects(filesAdded.toArray());
        set(this, 'pendingFiles', pendingFiles);
        get(this, 'filesUpdated')(pendingFiles, filesAdded, []);
      },
      /**
       * @param {FileWrapper} fileData
       */
      didRemoveFile: function didRemoveFile(fileData) {
        var pendingFiles = get(this, 'pendingFiles');
        pendingFiles.removeObject(fileData);
        set(this, 'pendingFiles', pendingFiles); // this might be unnecessary

        get(this, 'filesUpdated')(pendingFiles, [], [fileData]);
      }
    }
  });
  _exports.default = _default;
});