define("tmp-for-all/components/worksite-view/submission-error-modal/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;

  /**
   * Returns a computed property that returns the subset of submission errors that match
   * the attribute names in the `filterFields` array
   *
   * @param {String[]} filterFields an array of model attribute names
   * @returns {Ember.computed}
   */
  function errorGroup(filterFields) {
    return computed('cleanSubmissionErrors', function () {
      return (get(this, 'cleanSubmissionErrors') || []).filter(function (error) {
        return R.contains(error.attribute, filterFields);
      });
    });
  }
  var _default = Ember.Component.extend({
    classNames: ['t-submission-error-modal'],
    cleanSubmissionErrors: computed('submissionErrors', function () {
      var displayAttributes = {
        project: 'Project',
        jurisdiction: 'Road Controlling Authority',
        startDate: 'Work Window "From"',
        endDate: 'Work Window "To"',
        duration: '"Actual Work Duration"',
        timeOfDayStart: '"Start time of work"',
        timeOfDayEnd: '"End time of work"',
        address: '"Worksite Address"',
        trafficManagementLevel: '"Traffic Management Level"',
        reference: '"Worksite Reference"',
        purchaseOrder: '"Purchase Order"',
        PRINCIPAL: _constants.WORKSITE_CONTACT_TYPES.PRINCIPAL,
        APPLICANT: _constants.WORKSITE_CONTACT_TYPES.APPLICANT,
        PAYER: _constants.WORKSITE_CONTACT_TYPES.PAYER,
        'documentation-blocks': 'Documentation',
        'work-items': 'Work',
        worksiteType: 'Type of worksite',
        'tmp-submissions': 'Worksite'
      };
      return (get(this, 'submissionErrors') || []).map(function (err) {
        err.attribute = R.pipe(R.split('/'), R.last)(err.source.pointer);
        set(err, 'attributeDisplay', displayAttributes[err.attribute]);
        return err;
      });
    }),
    ownershipErrors: errorGroup(['project', 'jurisdiction']),
    detailErrors: errorGroup(['startDate', 'endDate', 'duration', 'timeOfDayStart', 'timeOfDayEnd', 'address', 'reference', 'purchaseOrder', 'worksiteType']),
    tmpErrors: errorGroup(['tmp-submissions']),
    extentErrors: errorGroup(['trafficManagementLevel', 'legacyData', 'location']),
    documentationErrors: errorGroup(['documentation-blocks']),
    workItemErrors: errorGroup(['work-items']),
    contactErrors: errorGroup(['PRINCIPAL', 'APPLICANT', 'PAYER'])
  });
  _exports.default = _default;
});