define("tmp-for-all/templates/tmp/view/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2FWW+LkK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"tmp-view\",null,[[\"tmp\",\"openLayoutsOnly\",\"conditionGroups\",\"selectedConditions\",\"userIsContractor\",\"declineTmp\",\"addComment\",\"createDraft\",\"tmpToPermittedCompanies\",\"addPermittedCompany\",\"removePermittedCompany\",\"searchCompanies\",\"changeDeployment\",\"toggleOpenLayoutsOnly\"],[[23,[\"model\",\"tmp\"]],[23,[\"model\",\"openLayoutsOnly\"]],[23,[\"model\",\"conditionGroups\"]],[23,[\"model\",\"selectedConditions\"]],[23,[\"model\",\"userIsContractor\"]],[27,\"route-action\",[\"declineTmp\"],null],[27,\"route-action\",[\"addComment\"],null],[27,\"route-action\",[\"createDraft\"],null],[23,[\"model\",\"tmpToPermittedCompanies\"]],[27,\"route-action\",[\"addPermittedCompany\"],null],[27,\"route-action\",[\"removePermittedCompany\"],null],[27,\"route-action\",[\"searchCompanies\"],null],[27,\"route-action\",[\"changeDeployment\"],null],[27,\"route-action\",[\"toggleOpenLayoutsOnly\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmp/view/index.hbs"
    }
  });
  _exports.default = _default;
});