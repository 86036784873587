define("tmp-for-all/components/form-field/radios/radio/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    set = Ember.set;
  var _default = Ember.Component.extend({
    classNames: ['form-field__radio'],
    classNameBindings: ['isChecked:form-field__radio--checked', 'useButtonStyle:form-field__radio--btn-style'],
    inputId: computed('elementId', function () {
      return "input-".concat(this.get('elementId'));
    }),
    checked: false,
    disabled: false,
    isChecked: computed('checked', function () {
      return this.get('checked') ? 'checked' : '';
    }),
    isDisabled: computed('disabled', function () {
      return this.get('disabled') ? 'disabled' : '';
    }),
    click: function click(e) {
      if (this.$(e.target).is('div')) return false;
      if (!this.get('disabled')) {
        set(this, 'checked', true);
        this.get('onChange')(this.get('value'));
      }
    }
  });
  _exports.default = _default;
});