define("tmp-for-all/routes/tmp-submission/layouts/create/details", ["exports", "tmp-for-all/mixins/routes/layout-create-mixin", "tmp-for-all/constants"], function (_exports, _layoutCreateMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_layoutCreateMixin.default, {
    relatedModel: 'tmpSubmission',
    tmpType: _constants.TMP_TYPES.SUBMISSION,
    siteSpecificRoutePermission: 'editSubmittedLayout'
  });
  _exports.default = _default;
});