define("tmp-for-all/serializers/worksite-fee", ["exports", "tmp-for-all/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    attrs: {
      createdAt: {
        serialize: false
      },
      updatedAt: {
        serialize: false
      },
      createdBy: {
        serialize: false
      }
    }
  });
  _exports.default = _default;
});