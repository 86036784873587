define("tmp-for-all/components/panel-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dZTK9jI+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\"],[11,\"class\",\"panel-section-heading\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"t-section-heading\"],[9],[1,[21,\"panelSectionHeading\"],false],[10],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"chevron-up\",\"32px\",\"22px\",\"panel-section-heading-icon\"]]],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"toggleSectionContent\"]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"panel-section-content\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/panel-section/template.hbs"
    }
  });
  _exports.default = _default;
});