define("tmp-for-all/utils/password-validation", ["exports", "owasp-password-strength-test"], function (_exports, _owaspPasswordStrengthTest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.passwordRequirements = passwordRequirements;
  _exports.passwordTest = passwordTest;
  _owaspPasswordStrengthTest.default.config({
    allowPassphrases: false,
    maxLength: 1024,
    minLength: 7,
    minOptionalTestsToPass: 3
  });
  function passwordTest(password) {
    if (!password) return null;
    return _owaspPasswordStrengthTest.default.test(password);
  }
  function passwordRequirements() {
    return "".concat(_owaspPasswordStrengthTest.default.tests.required.map(function (r) {
      return r('');
    }).join(' '), " And have at least ").concat(_owaspPasswordStrengthTest.default.configs.minOptionalTestsToPass, " of the following: ").concat(_owaspPasswordStrengthTest.default.tests.optional.map(function (r) {
      return r('');
    }).join(' '));
  }
});