define("tmp-for-all/components/shared/conditions-list/group/condition/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JL+q7wgZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"listed-condition-text\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"t-condition-text\"],[9],[1,[23,[\"condition\",\"text\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"condition\",\"isArchived\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-grey-dark text-12\"],[9],[0,\"(archived)\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"listed-condition-links\"],[9],[0,\"\\n\"],[4,\"link-to\",[[23,[\"conditionEditLink\"]],[23,[\"condition\",\"id\"]]],[[\"class\"],[\"secondary-action t-edit-condition-link\"]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"secondary-action__icon-left\",\"edit\"]]],false],[0,\" Edit\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/conditions-list/group/condition/template.hbs"
    }
  });
  _exports.default = _default;
});