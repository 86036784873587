define("tmp-for-all/utils/search-filter", ["exports", "escape-regex-string", "moment"], function (_exports, _escapeRegexString, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flattenFilter = flattenFilter;
  _exports.getRouteQueryParamArgs = _exports.getParamsAssignments = _exports.getFilterQueryParams = void 0;
  _exports.makeBooleanFilter = makeBooleanFilter;
  _exports.makeDiscreteList = makeDiscreteList;
  _exports.makeOptionsFilter = makeOptionsFilter;
  _exports.makeSingleDateFilter = makeSingleDateFilter;
  _exports.makeTextFilter = makeTextFilter;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;
  // get all the query params for filter types
  // [iFilterType] -> [string]
  var getFilterQueryParams = R.pipe(R.pluck('queryParams'), R.flatten);

  // get the queryparam names so we can merge it with the created object
  // [iFilterType] -> {paramName: null}
  _exports.getFilterQueryParams = getFilterQueryParams;
  var getParamsAssignments = R.pipe(getFilterQueryParams, R.reduce(function (acc, val) {
    return R.merge(acc, _defineProperty({}, val, null));
  }, {}));

  // builds the model for setting search filter params on the route
  // [iFilterType] -> {[queryParam]: {refreshModel:true}}
  _exports.getParamsAssignments = getParamsAssignments;
  var getRouteQueryParamArgs = R.pipe(R.pluck('queryParams'), R.flatten, R.map(function (p) {
    return [p, {
      refreshModel: true
    }];
  }), R.fromPairs);

  /**
   *
   * @param  {string} groupName      the group which this filter type sits under
   * @param  {string} label          the name for this discrete list filter
   * @param  {string} model          model name for listing out type of id
   * @param  {string} queryParamName name of the queryparam
   * @return {iFilterType}                the queryParamFilterType config object
   */
  _exports.getRouteQueryParamArgs = getRouteQueryParamArgs;
  function makeDiscreteList(groupName, label, model, queryParamName) {
    var propName = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'id';
    var instance = {
      name: 'discrete-list',
      label: label,
      group: groupName,
      disabled: false,
      queryParams: [queryParamName],
      appliedOptions: [],
      init: function init(controller) {
        return _genericInit(controller, instance);
      },
      addFilter: function addFilter() {
        get(instance, 'appliedOptions').pushObject('');
        return get(instance, 'appliedOptions').length - 1;
      },
      onRemove: function onRemove(controller, index) {
        _handleRemove(controller, instance, index);
      },
      getOptions: function getOptions(controller) {
        return controller.store.findAll(model);
      },
      getModelName: function getModelName() {
        return model;
      },
      setOption: function setOption(controller, selection, index) {
        get(instance, 'appliedOptions').replace(index, 1, [get(selection, propName)]);
        set(controller, queryParamName, instance.appliedOptions.join('|'));
      },
      // (DS.Controller, DS.recordArray) - > Maybe<DS.Project>
      getInitialSelection: function getInitialSelection(controller, options, index) {
        if (instance.appliedOptions.length && instance.appliedOptions[index]) {
          return options.find(function (option) {
            return get(option, propName) === instance.appliedOptions[index];
          });
        }
      },
      updateWhereFilter: function updateWhereFilter(params, whereFilter) {
        _updateWhereGeneric(params, whereFilter, instance);
      }
    };
    return instance;
  }

  /**
   * make a date filter that allows choosing a date range to filter on a single date column
   * @param  {string} groupName      the group for the filter in the select box
   * @param  {string} label          the name of the filter in the select box
   * @param  {string} queryParamName the name of the queryparam to be set on the route
   * @return {iFilterType}           the filter defintion instance
   */
  function makeSingleDateFilter(groupName, label, queryParamName) {
    var instance = {
      name: 'single-date',
      group: groupName,
      label: label,
      queryParams: ['start-' + queryParamName, 'end-' + queryParamName],
      appliedOptions: [],
      init: function init(controller) {
        var _instance$queryParams = _slicedToArray(instance.queryParams, 2),
          startParam = _instance$queryParams[0],
          finishParam = _instance$queryParams[1];
        var start = get(controller, startParam);
        var finish = get(controller, finishParam);
        var exists = false;
        if (start && finish) {
          var startParts = start.split('|');
          var finishParts = finish.split('|');
          if (startParts.length === finishParts.length) {
            exists = true;
            set(instance, 'appliedOptions', startParts.map(function (val, index) {
              return [val, finishParts[index]];
            }));
          }
        }
        if (!exists && (startParam || finishParam)) {
          // If the params don't match but are populated, then we want to unset them.
          // If there are not an even balance of dates, set the dates to null.
          set(controller, startParam, null);
          set(controller, finishParam, null);
        }
        return exists;
      },
      addFilter: function addFilter() {
        get(instance, 'appliedOptions').pushObject(['', '']);
        return get(instance, 'appliedOptions').length - 1;
      },
      onRemove: function onRemove(controller, index) {
        var _instance$queryParams2 = _slicedToArray(instance.queryParams, 2),
          startParam = _instance$queryParams2[0],
          finishParam = _instance$queryParams2[1];
        if (instance.appliedOptions.length === 1) {
          set(instance, 'appliedOptions', []);
          set(controller, startParam, null);
          set(controller, finishParam, null);
        } else {
          get(instance, 'appliedOptions').removeAt(index);
          var _instance$getQueryStr = instance.getQueryString(),
            _instance$getQueryStr2 = _slicedToArray(_instance$getQueryStr, 2),
            start = _instance$getQueryStr2[0],
            finish = _instance$getQueryStr2[1];
          set(controller, startParam, start);
          set(controller, finishParam, finish);
        }
      },
      getQueryParams: function getQueryParams(controller, index) {
        var toDate = function toDate(dateString) {
          return dateString ? (0, _moment.default)(dateString).toDate() : null;
        };
        var _instance$appliedOpti = instance.appliedOptions[index].map(toDate),
          _instance$appliedOpti2 = _slicedToArray(_instance$appliedOpti, 2),
          startDate = _instance$appliedOpti2[0],
          finishDate = _instance$appliedOpti2[1];
        if (instance.useCustomParams) {
          var _ref;
          return _ref = {}, _defineProperty(_ref, instance.queryParams[0], startDate), _defineProperty(_ref, instance.queryParams[1], finishDate), _ref;
        }
        return {
          startDate: startDate,
          finishDate: finishDate
        };
      },
      getQueryString: function getQueryString() {
        var _instance$appliedOpti3 = instance.appliedOptions.reduce(function (acc, val) {
            // Make all dates iso strings, just in case there was some unexpected input in the URL
            acc[0].push((0, _moment.default)(val[0]).toISOString());
            acc[1].push((0, _moment.default)(val[1]).toISOString());
            return acc;
          }, [[], []]),
          _instance$appliedOpti4 = _slicedToArray(_instance$appliedOpti3, 2),
          start = _instance$appliedOpti4[0],
          finish = _instance$appliedOpti4[1];
        return [start.join('|'), finish.join('|')];
      },
      setQueryParam: function setQueryParam(controller, _ref2, index) {
        var _ref3 = _slicedToArray(_ref2, 2),
          newStart = _ref3[0],
          newFinish = _ref3[1];
        if (newStart && newFinish) {
          get(instance, 'appliedOptions').replace(index, 1, [[(0, _moment.default)(newStart).toISOString(), (0, _moment.default)(newFinish).toISOString()]]);
          var _instance$getQueryStr3 = instance.getQueryString(),
            _instance$getQueryStr4 = _slicedToArray(_instance$getQueryStr3, 2),
            start = _instance$getQueryStr4[0],
            finish = _instance$getQueryStr4[1];
          var _instance$queryParams3 = _slicedToArray(instance.queryParams, 2),
            startParam = _instance$queryParams3[0],
            finishParam = _instance$queryParams3[1];
          set(controller, startParam, start);
          set(controller, finishParam, finish);
        }
      },
      updateWhereFilter: function updateWhereFilter(params, whereFilter) {
        var _instance$queryParams4 = _slicedToArray(instance.queryParams, 2),
          startParam = _instance$queryParams4[0],
          finishParam = _instance$queryParams4[1];
        if (!whereFilter.and) {
          whereFilter.and = [];
        }
        if (params[startParam] && params[finishParam]) {
          if (!instance.appliedOptions.length) {
            var startParts = params[startParam].split('|');
            var finishParts = params[finishParam].split('|');
            set(instance, 'appliedOptions', startParts.map(function (val, index) {
              return [val, finishParts[index]];
            }));
          }
          var parts = instance.appliedOptions;
          var ands = R.flatten(parts.map(function (val, index) {
            // Construct moment strings. If the val provided is falsy, create an invalid moment
            var startDate = (0, _moment.default)(val[0] || 'invalid');
            var finishDate = (0, _moment.default)(val[1] || 'invalid');

            // Construct the individual parts of the `and` filter
            var startPart = startDate.isValid() ? _defineProperty({}, queryParamName, {
              gte: startDate.startOf('day').toISOString()
            }) : null;
            var finishPart = finishDate.isValid() ? _defineProperty({}, queryParamName, {
              lte: finishDate.endOf('day').toISOString()
            }) : null;

            // Construct the and filter, filtering out invalid parts.
            var and = [startPart, finishPart].filter(Boolean);

            // Raise warning if an invalid part was detected.
            if (and.length < 2) {
              console.warn("query param at index ".concat(index, " for ").concat(instance.label, " is not a valid date"));
            }
            return and;
          }));
          for (var i = 0; i < ands.length; i++) {
            whereFilter.and.push(ands[i]);
          }
        }
        delete params[startParam];
        delete params[finishParam];
      }
    };
    return instance;
  }

  /**
   * make a text filter for the shared/filter component, allows text filtering with sql `LIKE`
   * @param  {string} group           the group for the filter in the select box
   * @param  {string} label           the printed name of the filter in the slect box
   * @param  {string} placeHolderText the placeholder for the text filter component
   * @param  {string} paramName       the name of the query param to be set on the route
   * @return {iFilterType]}                 the filter instance
   */
  function makeTextFilter(group, label, placeHolderText, paramName) {
    var instance = {
      name: 'text-filter',
      placeHolderText: placeHolderText,
      label: label,
      queryParams: [paramName],
      group: group,
      appliedOptions: [],
      init: function init(controller) {
        return _genericInit(controller, instance);
      },
      getQueryParams: function getQueryParams(controller, index) {
        var paramValue = get(controller, instance.queryParams[0]);
        var textValue = paramValue ? instance.appliedOptions[index] : paramValue;
        return {
          textValue: textValue,
          textPlaceholder: instance.placeHolderText
        };
      },
      setQueryParam: function setQueryParam(controller, textValue, index) {
        get(instance, 'appliedOptions').replace(index, 1, [textValue]);
        set(controller, instance.queryParams[0], instance.appliedOptions.join('|'));
      },
      onRemove: function onRemove(controller, index) {
        _handleRemove(controller, instance, index);
      },
      addFilter: function addFilter() {
        get(instance, 'appliedOptions').pushObject('');
        return get(instance, 'appliedOptions').length - 1;
      },
      updateWhereFilter: function updateWhereFilter(params, whereFilter) {
        var queryParam = instance.queryParams[0];
        if (!whereFilter.and) {
          whereFilter.and = [];
        }
        if (params[queryParam]) {
          var parts = params[queryParam].split('|').filter(Boolean);
          whereFilter.and.push(_buildOrFilter(parts, function (part) {
            return _defineProperty({}, queryParam, {
              regexp: "/".concat((0, _escapeRegexString.default)(part), "/i")
            });
          }));
        }
        delete params[queryParam];
      }
    };
    return instance;
  }
  function makeBooleanFilter(groupName, label, queryParamName, filterBy) {
    var instance = {
      name: 'boolean-filter',
      label: label,
      group: groupName,
      disabled: false,
      queryParams: [queryParamName],
      appliedOptions: [],
      init: function init(controller) {
        var queryParam = get(controller, queryParamName);
        if (queryParam) {
          set(instance, 'disabled', true);
          // there should never be more than one appliedOption
          if (get(instance, 'appliedOptions').length === 0) {
            get(instance, 'appliedOptions').pushObject(true);
          }
        }
        return instance.disabled;
      },
      addFilter: function addFilter() {
        get(instance, 'appliedOptions').pushObject(true);
        set(instance, 'disabled', true);
      },
      onRemove: function onRemove(controller) {
        set(instance, 'appliedOptions', []);
        set(instance, 'disabled', false);
        set(controller, queryParamName, null);
      },
      setQueryParam: function setQueryParam(controller) {
        set(controller, queryParamName, filterBy);
      },
      updateWhereFilter: function updateWhereFilter(params, whereFilter) {
        if (params[queryParamName]) {
          if (!whereFilter.and) {
            whereFilter.and = [];
          }
          whereFilter.and.push(_defineProperty({}, queryParamName, params[queryParamName]));
        }
        delete params[queryParamName];
      }
    };
    return instance;
  }
  /**
   * make a option list to filter a property
   * @param  {string} group     the group for the filter in the select box
   * @param  {string} label     the printed name of the filter in the slect box
   * @param  {array}  options   array of options [{displayName: '', value: ''}, ...]
   * @param  {string} paramName the name of the query param to be set on the route
   * @return {iFilterType]}     the filter instance
   */
  function makeOptionsFilter(group, label, options, paramName) {
    var instance = {
      name: 'options-filter',
      label: label,
      queryParams: [paramName],
      group: group,
      appliedOptions: [],
      init: function init(controller) {
        return _genericInit(controller, instance);
      },
      getOptions: function getOptions() {
        return RSVP.resolve(options);
      },
      getInitialSelection: function getInitialSelection(controller, options, index) {
        if (instance.appliedOptions.length && instance.appliedOptions[index]) {
          return options.find(function (option) {
            return option.value === instance.appliedOptions[index];
          });
        }
      },
      setOption: function setOption(controller, selectedValue, index) {
        get(instance, 'appliedOptions').replace(index, 1, [selectedValue]);
        set(controller, paramName, instance.appliedOptions.join('|'));
      },
      getQueryParams: function getQueryParams(controller, index) {
        var paramValue = get(controller, instance.queryParams[0]);
        var textValue = paramValue ? instance.appliedOptions[index] : paramValue;
        return {
          textValue: textValue
        };
      },
      addFilter: function addFilter() {
        get(instance, 'appliedOptions').pushObject('');
        return get(instance, 'appliedOptions').length - 1;
      },
      onRemove: function onRemove(controller, index) {
        _handleRemove(controller, instance, index);
      },
      updateWhereFilter: function updateWhereFilter(params, whereFilter) {
        _updateWhereGeneric(params, whereFilter, instance);
      }
    };
    return instance;
  }
  function flattenFilter(where) {
    if (where.and) {
      if (where.and.length === 1) {
        // There is no point having an 'and' with only one criteria
        where = where.and[0];
      } else {
        for (var i = 0; i < where.and.length; i++) {
          var newAnd = flattenFilter(where.and[i]);
          where.and[i] = newAnd;
        }
      }
    }
    if (where.or) {
      if (where.or.length === 1) {
        // Or an or
        where = where.or[0];
      } else {
        for (var _i2 = 0; _i2 < where.or.length; _i2++) {
          var _newAnd = flattenFilter(where.or[_i2]);
          where.or[_i2] = _newAnd;
        }
      }
    }
    return where;
  }
  var _genericInit = function _genericInit(controller, instance) {
    var paramValue = get(controller, instance.queryParams[0]);
    var exists = !!paramValue;
    if (exists) {
      set(instance, 'appliedOptions', paramValue.split('|'));
    }
    return exists;
  };
  var _handleRemove = function _handleRemove(controller, instance, index) {
    if (instance.appliedOptions.length === 1) {
      set(instance, 'appliedOptions', []);
      set(controller, instance.queryParams[0], null);
    } else {
      get(instance, 'appliedOptions').removeAt(index);
      var queryString = instance.appliedOptions.join('|');
      set(controller, instance.queryParams[0], queryString);
    }
  };
  var _updateWhereGeneric = function _updateWhereGeneric(params, whereFilter, instance) {
    var queryParam = instance.queryParams[0];
    if (!whereFilter.and) whereFilter.and = [];
    if (params[queryParam]) {
      var parts = params[queryParam].split('|').filter(Boolean);

      // For a field where we want to match many values, we use
      // an 'inq'.  If we only have one value we use 'eq'
      if (parts.length === 1) {
        whereFilter.and.push(_defineProperty({}, queryParam, {
          eq: parts[0]
        }));
      } else {
        whereFilter.and.push(_defineProperty({}, queryParam, {
          inq: parts
        }));
      }
    }
    delete params[queryParam];
  };
  var _buildOrFilter = function _buildOrFilter(parts, transform) {
    var or = parts.map(transform);
    return or.length > 1 ? {
      or: or
    } : or.length ? or[0] : or;
  };
});