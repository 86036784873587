define("tmp-for-all/templates/tmps/drafts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MekidMuI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"isFullWidth\",\"isListingPage\"],[true,true]],{\"statements\":[[0,\"\\n  \"],[4,\"listing-page-heading\",null,null,{\"statements\":[[0,\"My Draft TMPs\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"search-results\",null,[[\"handlePaginationClick\",\"order\",\"queryFilter\",\"showFilters\",\"results\",\"searchType\",\"setOrder\"],[[27,\"action\",[[22,0,[]],\"pageClicked\"],null],[23,[\"order\"]],[23,[\"model\",\"queryFilter\"]],false,[23,[\"model\",\"tmps\"]],\"tmps\",[27,\"action\",[[22,0,[]],\"setOrder\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmps/drafts.hbs"
    }
  });
  _exports.default = _default;
});