define("tmp-for-all/components/worksite-view/clashes-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    classNames: ['margin-btm-50'],
    numberOfClashes: computed('clashes', function () {
      return Object.keys(get(this, 'clashes')).length;
    }),
    boxTitle: computed('numberOfClashes', function () {
      var numberOfClashes = get(this, 'numberOfClashes');
      return "Work coordination (".concat(numberOfClashes, ")");
    })
  });
  _exports.default = _default;
});