define("tmp-for-all/helpers/if-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ifContains = ifContains;
  function ifContains(params) {
    var returnVal = false;
    if (params.length > 1) {
      var elToFind = params[0];
      var arrayToSearch = params[1];
      if (arrayToSearch.includes(elToFind)) {
        returnVal = true;
      }
    }
    return returnVal;
  }
  var _default = Ember.Helper.helper(ifContains);
  _exports.default = _default;
});