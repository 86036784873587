define("tmp-for-all/mixins/routes/add-global-permit-layout-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var CONTRACTOR_ROLE_ID = '2';
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    isUserAContractor: function isUserAContractor() {
      return get(this, 'session.currentUser').then(function (user) {
        return get(user, 'permissionAssignments').then(function (userPermissionAssignments) {
          return userPermissionAssignments.toArray().some(function (upa) {
            return get(upa, 'permission.id') === CONTRACTOR_ROLE_ID;
          });
        });
      }).catch(function () {
        return false;
      });
    }
  });
  _exports.default = _default;
});