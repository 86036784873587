define("tmp-for-all/components/days-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oWD2BpkS",
    "block": "{\"symbols\":[\"day\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"days-of-week\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"weekDays\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"strong\"],[9],[1,[27,\"days-of-week\",[[22,1,[]]],[[\"format\"],[\"short\"]]],false],[10],[0,\"\\n      \"],[2,\" Think this is working but could probably be implemented better? \"],[0,\"\\n\"],[4,\"if\",[[27,\"if-contains\",[[22,1,[]],[23,[\"activeDays\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"dot bg-orange day-of-week\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"dot bg-grey\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/days-indicator/template.hbs"
    }
  });
  _exports.default = _default;
});