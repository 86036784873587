define("tmp-for-all/mixins/routes/layout-create-mixin", ["exports", "tmp-for-all/mixins/stms-search", "tmp-for-all/utils/route-helpers"], function (_exports, _stmsSearch, _routeHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;

  /**
   * This mixin is for Creating layouts only, not editing layouts.
   * Edit layouts has different cleanup/transition requirements
   */
  var _default = Ember.Mixin.create(_stmsSearch.default, {
    authorization: Ember.inject.service(),
    tmp: computed.alias('controller.model.tmp'),
    model: function model() {
      return RSVP.hash({
        layout: this.modelFor((0, _routeHelpers.getParentRouteName)(get(this, 'routeName'))),
        tmp: this.modelFor(get(this, 'tmpType'))
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;
      // Needed to do the permission check in the afterModel instead of beforeModel because the model was needed for the permission check
      var permission = get(model.tmp, 'isGenericTmp') ? 'editGlobalPermissionLayout' : get(this, 'siteSpecificRoutePermission');
      var tmpType = get(this, 'tmpType');
      return get(this, 'authorization').checkAllowableNavigation(this, permission, this.modelFor(tmpType)).catch(function () {
        return _this.replaceWith('index');
      });
    },
    actions: {
      addNewUser: function addNewUser() {
        this.replaceWith((0, _routeHelpers.getChildRouteName)(get(this, 'routeName'), 'new-contact'));
      },
      saveNewUser: function saveNewUser(_ref) {
        var _this2 = this;
        var contact = _ref.contact,
          replaceWithRoute = _ref.replaceWithRoute;
        contact.set('meta', {
          creationType: 'addLayoutDesigner',
          tmpCode: get(this, 'tmp.tmpCode')
        });
        (contact.get('isNew') ? contact.save() : Promise.resolve()).then(function () {
          return set(_this2, 'controller.model.layout.designer', contact);
        }).then(function () {
          return _this2.replaceWith(replaceWithRoute);
        }).then(function () {
          return get(_this2, 'flashMessages').success('A new contact has been added.');
        }).catch(function () {
          return get(_this2, 'flashMessages').danger('There was an error saving the new contact.');
        });
      },
      saveLayout: function saveLayout(layout) {
        var _this3 = this;
        var relatedModel = get(this, 'relatedModel');
        var layoutSaveSuccess = function layoutSaveSuccess() {
          return layout.saveFiles().then(function () {
            get(_this3, 'flashMessages').success('Layout was created successfully');
            return _this3.transitionTo("".concat(get(_this3, 'tmpType'), ".view"), get(_this3, 'tmp'));
          }).catch(function () {
            get(_this3, 'flashMessages').danger('There was a problem uploading your file(s)');
          });
        };
        var layoutSaveError = function layoutSaveError() {
          get(_this3, 'flashMessages').danger('There was a problem saving this Layout');
        };
        return layout.save().then(function () {
          var detours = get(layout, 'detours');
          if (!detours || !detours.length) return;
          return RSVP.all(detours.map(function (detour) {
            return detour.save();
          }));
        }).then(function () {
          return layout.reload();
        }) // Needed for layoutcode generated via db trigger
        .then(function () {
          return layout.get(relatedModel).then(function (tmp) {
            return tmp.reload();
          });
        }).then(function () {
          return layout.get("".concat(relatedModel, ".history")).reload();
        }).then(layoutSaveSuccess, layoutSaveError); // layoutSaveError should not catch errors from layoutSaveSuccess
      },
      cancelLayout: function cancelLayout() {
        return this.transitionTo("".concat(get(this, 'tmpType'), ".view"), get(this, 'tmp'));
      },
      willTransition: function willTransition(transition) {
        var nestedRoutes = ["".concat(get(this, 'tmpType'), ".layouts.create.map"), "".concat(get(this, 'tmpType'), ".layouts.create.details.new-contact"), "".concat(get(this, 'tmpType'), ".layouts.create.details.index"), "".concat(get(this, 'tmpType'), ".layouts.create.detour")];
        if (nestedRoutes.indexOf(transition.targetName) > -1) return;
        var currentLayout = get(this, 'controller.model.layout');
        if (currentLayout) {
          currentLayout.destroyOrRollback();
        }
      }
    }
  });
  _exports.default = _default;
});