define("tmp-for-all/components/maps/map-control-panel/rca/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SDq1bG58",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"control-panel-field-group\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"Primary RCA\"],[10],[0,\"\\n  \"],[1,[27,\"shared/form/check-boxes\",null,[[\"options\",\"update\"],[[23,[\"rcaCollection\"]],[27,\"action\",[[22,0,[]],\"updateQuery\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/map-control-panel/rca/template.hbs"
    }
  });
  _exports.default = _default;
});