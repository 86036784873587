define("tmp-for-all/instance-initializers/model-cleanup-utils", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Snapshot = void 0;
  _exports.initialize = initialize;
  _exports.trackDirtyHasMany = trackDirtyHasMany;
  _exports.triggerDirtyBelongsTo = triggerDirtyBelongsTo;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP,
    computed = Ember.computed,
    setProperties = Ember.setProperties,
    getProperties = Ember.getProperties,
    getWithDefault = Ember.getWithDefault,
    defineProperty = Ember.defineProperty;

  /**
   * A simple named ember object to house a model snapshot.
   *
   * TODO: prevent modifying this.
   */
  var Snapshot = Ember.Object.extend({});
  _exports.Snapshot = Snapshot;
  Snapshot.toString = function () {
    return 'Model:BelongsToSnapshot';
  };

  /**
   * Define an observer that will trigger a recalculation of `belongsToIsDirty` whenever
   * a belongsTo attribute changes.
   *
   * Note: This will trigger a recalculation for *all* belongsTo property changes, not just snapshotted ones
   * Note: The observer seems to trigger three times when a belongsTo changes.
   *        I cannot see a way to circumvent that, but it shouldn't be a major performance issue
   *
   * @param {DS.Model} Model a DS.Model subclass, not instance
   * @param {String} key a DS.belongsTo model attribute name
   */
  function triggerDirtyBelongsTo(Model, key) {
    Model.reopen({
      init: function init() {
        var _this = this;
        this._super.apply(this, arguments);
        this.addObserver(key, function () {
          _this.toggleProperty('_triggerDirtyBelongsTo');
        });
      }
    });
  }

  /**
   * Define a magic property on a model to track the dirtiness of a hasMany relationship
   *
   * E.g. a worksite has a hasMany relationship, `documentationBlocks: hasMany('documentation-block')`
   *    so we add a property to check its dirtiness called `documentationBlocksIsDirty`
   *
   * Note: This is a naive implementation of checking dirtiness that just checks `hasChanges`
   *        on each of the related models in the hasMany property
   * Note: if the relationship is not loaded, then this will always return false,
   *        which is OK, because an unloaded relationship is implicitly not dirty
   *
   * @param {DS.Model} Model a DS.Model subclass, not instance
   * @param {String} key a DS.hasMany model attribute name
   */
  /**
   *
   */
  function trackDirtyHasMany(Model, key) {
    defineProperty(Model, "".concat(key, "IsDirty"), computed("".concat(key, ".@each.hasChanges"), function () {
      var rel = this.hasMany(key).value();
      if (!rel) return false; // an unloaded relation is not dirty
      return R.any(function (i) {
        return get(i, 'hasChanges');
      }, rel);
    }));
  }

  // Notes: waiting for code freeze, because other PRs are changing forms - recommend pulling in for the next sprint
  function initialize() {
    _emberData.default.Model.reopen({
      /**
       * Define a property that tracks any aspect of the model that could constitute dirtiness
       *
       * Note: This should usually be used instead of hasDirtyAttributes,
       *      unless you have a specific reason to only check for dirty attributes
       * Note: A new, unsaved model is *always* considered dirty here
       *
       * Caveat: hasChanges does not track dirtiness of hasMany relationships - you have to track that manually
       * Caveat: belongsToIsDirty will only trigger dirtiness if you have previously called `snapshotBelongsTo`
       */
      hasChanges: computed.or('isNew', 'hasDirtyAttributes', 'belongsToIsDirty', 'extraDirtyCheck'),
      /**
       * This is an extra attribute that may be overridden by a model to add dirty checking to attributes
       * that are not natively tracked by ember data.
       *
       * Example: filesForUpload on documentation-block and layout is a buffer to store files to be uploaded.
       *    It is not a DS.attr, so we need to manually track its dirty state
       *
       * Usage: When setting this on a model, it should be a synchronous computed property that returns a boolean
       */
      extraDirtyCheck: computed(function () {
        return false;
      }),
      /**
       * Check each of the snapshotted relations to see if the current value has changed from the snapshotted value
       */
      belongsToIsDirty: computed('snapshot', '_triggerDirtyBelongsTo', function () {
        var _this2 = this;
        // Caveats: if there is no snapshot, we assume not dirty
        var snapshot = get(this, 'snapshot');
        if (!snapshot) return false;
        return R.keys(snapshot).some(function (attr) {
          var snapshotValue = getWithDefault(snapshot, "".concat(attr, ".id"), null);
          // Caveat: if the snapshot is manually manipulated to include a relation that hasn't been loaded,
          //        then this id check will return undefined and then load the relation in the background
          var currentValue = getWithDefault(_this2, "".concat(attr, ".id"), null);
          return currentValue !== snapshotValue;
        });
      }),
      /**
       * @see http://emberjs.com/api/data/classes/DS.Model.html#method_didDefineProperty
       * @param {DS.Model} Model A model Class, not an instance
       * @param {String} key the model property
       * @param {Object} descriptor computed property descriptor
       */
      didDefineProperty: function didDefineProperty(Model, key, descriptor) {
        this._super.apply(this, arguments);
        var meta = descriptor && descriptor.meta && descriptor.meta();
        if (!meta) return;
        if (get(meta, 'kind') === 'belongsTo') {
          triggerDirtyBelongsTo(Model, key);
        } else if (get(meta, 'kind') === 'hasMany') {
          trackDirtyHasMany(Model, key);
        }
      },
      /**
       * Creates a snapshot of the properties defined by attrs
       * Dynamically created a computed property `belongsToIsDirty` that will tell you if any of the attrs is dirty
       * Stores the snapshot on the model, which can be used to rollback state or listened to for dirty tracking
       *
       * @param {String[]} attrs
       * @returns {Snapshot}
       */
      snapshotBelongsTo: function snapshotBelongsTo(attrs) {
        var _this3 = this;
        // TODO: Add some checks to make sure we don't try to snapshot hasMany (requires more cleanup)
        return RSVP.hash(getProperties(this, attrs), "Snapshot belongsTo: ".concat(this)).then(function (data) {
          // Use an Ember Object so it works with computed listeners
          var snapshot = Snapshot.create(data);
          set(_this3, 'snapshot', snapshot);
          return snapshot;
        });
      },
      /**
       * Caveat: Only rollback belongsTo relations that were stored in a snapshot from snapshotBelongsTo
       * Caveat: This will be problematic if any of the belongsTo properties have been deleted/unloaded from the store
       *        e.g. imagine worksite.set('project', proj), where proj has been deleted
       *
       * @returns {boolean}
       */
      rollbackBelongsTo: function rollbackBelongsTo() {
        var snapshot = get(this, 'snapshot');
        if (snapshot) {
          setProperties(this, snapshot);
        }
      },
      /**
       * Creates a new belongsToSnapshot of the same attributes as the existing snapshot
       * @returns {Snapshot}
       */
      refreshSnapshot: function refreshSnapshot() {
        var snapshot = get(this, 'snapshot');
        if (!snapshot) return;
        return this.snapshotBelongsTo(R.keys(snapshot));
      },
      /**
       * Don't use rollbackAttributes on new models as it sets the properties to null
       * but then calls update on the computed properties before the model
       * has been destroyed, resulting in syntax errors
       *
       * Note: If the model has a snapshot, it will automatically rollback to the snapshot
       *
       * @returns {Promise.<DS.Model>}
       */
      destroyOrRollback: function destroyOrRollback() {
        // TODO: Idea: allow the user to pass a function to check if the model should be destroyed
        if (get(this, 'isNew')) {
          return this.destroyRecord();
        } else {
          this.rollbackBelongsTo();
          this.rollbackAttributes();
          // return a promise that looks the same as the destroyRecord promise
          return RSVP.resolve(this);
        }
      },
      /**
       * Hooks the model save method to automatically create a new snapshot at the point the model was saved
       * @returns {DS.Model}
       */
      save: function save() {
        var _this4 = this;
        return this._super.apply(this, arguments).then(function () {
          return _this4.refreshSnapshot();
        }) // TODO: should we be checking isDeleted first?
        .then(function () {
          return _this4;
        });
      },
      /**
       * Helper function to reload all relations on an object
       * @returns {Promise.<DS.Model>}
       */
      reloadAllRelations: function reloadAllRelations() {
        var relnames = get(this.constructor, 'relationshipNames');
        var attrs = R.concat(relnames.belongsTo, relnames.hasMany);
        return this.reloadRelations(attrs);
      },
      /**
       * Reloads any relationships from the server.
       * See notes on _reloadBelongsTo and _reloadHasMany for usage and caveats for the respective relationship types
       *
       * @param {String[]} attrs a list of attribute names. Any attributes that are not belongsTo or hasMany are ignored
       * @returns {Promise.<DS.Model>}
       */
      reloadRelations: function reloadRelations(attrs) {
        var _this5 = this;
        // TODO: should we add a check here to do nothing if `this.get('isDeleted')` is true?
        return RSVP.all(R.props(attrs, this._getRelationshipKindMapping()).filter(function (rel) {
          return !R.isNil(rel);
        }).map(function (_ref) {
          var kind = _ref.kind,
            attr = _ref.attr;
          return kind === 'belongsTo' ? _this5._reloadBelongsTo(attr) : _this5._reloadHasMany(attr);
        }));
      },
      /**
       * returns e.g. {documentationBlocks: {attr: 'documentationBlocks', kind: 'hasMany'}}
       * @returns {Object} a mapping of attribute names to the kind of relationship: 'belongsTo' or 'hasMany'
       * @private
       */
      _getRelationshipKindMapping: function _getRelationshipKindMapping() {
        var relationshipMapping = {};
        this.eachRelationship(function (attr, _ref2) {
          var kind = _ref2.kind;
          relationshipMapping[attr] = {
            kind: kind,
            attr: attr
          };
        });
        return relationshipMapping;
      },
      /**
       * Rollback a single hasMany relationship
       *
       * Note: simply calling `.reload()` on a relationship will not clean up "new" related model instances
       *      This method reloads the relationship and deletes any new related instances.
       *
       * @param {String} attr a DS.hasMany attribute name
       * @returns {Promise.<*>}
       * @private
       */
      _reloadHasMany: function _reloadHasMany(attr) {
        var relationship = this.hasMany(attr);
        var relatedModels = relationship.value() || [];
        R.reject(R.isNil, relatedModels).map(function (m) {
          return m.destroyOrRollback();
        });
        return relationship.reload();
      },
      /**
       * Rollback a single belongsTo relationship
       * NOTE: This does not use a belongsToSnapshot, so it will always hard-reload from the server.
       * @param {String} attr a DS.belongsTo attribute name
       * @returns {Promise.<*>}
       * @private
       */
      _reloadBelongsTo: function _reloadBelongsTo(attr) {
        var relationship = this.belongsTo(attr);
        var relatedModel = relationship.value();
        if (!R.isNil(relatedModel)) {
          relatedModel.destroyOrRollback();
        }
        // TODO: I wonder if the hard reload is really necessary here...
        return relationship.reload();
      }
    });
  }
  var _default = {
    name: 'model-snapshot',
    initialize: initialize
  };
  _exports.default = _default;
});