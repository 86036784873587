define("tmp-for-all/components/worksite-view/work-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['margin-btm-50'],
    workItems: computed.filterBy('worksite.workItems', 'isDeleted', false),
    sortedWorkItems: computed('workItems', function () {
      return get(this, 'workItems').toArray().reverse();
    })
  });
  _exports.default = _default;
});