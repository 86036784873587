define("tmp-for-all/components/search-parameters/filters/filter-controls/single-date/component", ["exports", "tmp-for-all/mixins/components/worksite-filter-field", "tmp-for-all/components/search-parameters/filters/filter-controls/date-options"], function (_exports, _worksiteFilterField, _dateOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    setProperties = Ember.setProperties;
  var MIN_DATE = 0;
  var MAX_DATE = 8640000000000000;
  var _default = Ember.Component.extend(_worksiteFilterField.default, {
    dateOptions: _dateOptions.default,
    minDate: computed('finishDate', function () {
      var startDate = get(this, 'startDate');
      return startDate || new Date(MIN_DATE);
    }),
    maxDate: computed('startDate', function () {
      var finishDate = get(this, 'finishDate');
      return finishDate || new Date(MAX_DATE);
    }),
    isDisabled: computed('selectedDateOption', function () {
      return !get(this, 'selectedDateOption');
    }),
    actions: {
      dateOptionDidChange: function dateOptionDidChange(option) {
        set(this, 'selectedDateOption', option);
        if (option.label === 'Custom range') {
          option.startDate = get(this, 'startDate');
          option.finishDate = get(this, 'finishDate');
          set(this, 'maxDate', new Date(MAX_DATE));
        }
        setProperties(this, {
          startDate: option.startDate,
          finishDate: option.finishDate,
          selectedDateOption: option
        });
      },
      saveFilter: function saveFilter() {
        var _this = this;
        var startDate = get(this, 'startDate');
        var finishDate = get(this, 'finishDate');

        // update query params on controller with the new value
        var updateFilterParams = get(this, 'updateFilterParams');
        if (startDate && finishDate) {
          var index = get(this, 'addFilter')(get(this, 'filter'));
          updateFilterParams(function (controller) {
            get(_this, 'filter').setQueryParam(controller, [startDate, finishDate], index);
          });

          // Exit modal
          this.toggleModal();
        }
      }
    }
  });
  _exports.default = _default;
});