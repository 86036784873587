define("tmp-for-all/utils/upload-helper", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
    $ = Ember.$;
  var _default = {
    uploadFile: function uploadFile(url, file) {
      return new RSVP.Promise(function (resolve, reject) {
        $.ajax({
          url: url,
          type: 'POST',
          data: file,
          processData: false,
          contentType: 'application/octet-stream',
          headers: {
            'Content-Disposition': "attachment; filename=\"".concat(file.name, "\"")
          }
        }).done(resolve).fail(reject);
      });
    },
    uploadFiles: function uploadFiles(url, files) {
      var _this = this;
      files = files || [];
      return RSVP.all(files.map(function (file) {
        return _this.uploadFile(url, file);
      }));
    },
    buildLayoutFilesUrl: function buildLayoutFilesUrl(layoutId) {
      return "".concat(_environment.default.apiPath, "/layouts/").concat(layoutId, "/upload-file");
    }
  };
  _exports.default = _default;
});