define("tmp-for-all/components/form-field/radios/radio/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4kIfU35V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"name\",[21,\"name\"]],[12,\"value\",[21,\"value\"]],[12,\"checked\",[21,\"isChecked\"]],[12,\"disabled\",[21,\"isDisabled\"]],[12,\"id\",[21,\"inputId\"]],[11,\"class\",\"form-field__radio-input\"],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n\"],[4,\"form-field/label\",null,[[\"for\",\"toolTipText\",\"isCheckableFieldLabel\"],[[23,[\"inputId\"]],[23,[\"toolTipText\"]],true]],{\"statements\":[[0,\"  \"],[1,[21,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/form-field/radios/radio/template.hbs"
    }
  });
  _exports.default = _default;
});