define("tmp-for-all/routes/worksites/create/wizard/details", ["exports", "tmp-for-all/mixins/routes/edit-worksite/details-route-mixin"], function (_exports, _detailsRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_detailsRouteMixin.default, {
    nextStep: 'worksites.create.wizard.extent',
    nextStepGlobalPermit: 'worksites.create.wizard.work-items',
    finishedTarget: 'worksite-draft'
  });
  _exports.default = _default;
});