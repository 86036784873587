define("tmp-for-all/components/planning-map/detail-popup/component", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set,
    run = Ember.run;
  var modelTypes = Object.freeze({
    WORKSITE: 'worksite',
    LAYOUT: 'layout',
    DETOUR: 'detour'
  });
  function getWorksiteGeoJson(currentModel) {
    var geoJsonObj = {
      type: 'Feature'
    };
    geoJsonObj.geometry = (0, _emberCopy.copy)(get(currentModel, 'location'), true);
    return geoJsonObj;
  }
  function getImpactsGeoJson(currentModel) {
    var impacts = get(currentModel, 'impacts');
    var geoJsonObj = impacts.reduce(function (prev, current) {
      var lines = current.features.filter(function (feature) {
        return feature.geometry.type === 'LineString';
      });
      return prev.concat(lines);
    }, []);
    return geoJsonObj;
  }
  function getDetoursGeoJson(detour) {
    var geoJsonObj = [];
    var location = get(detour, 'location');
    geoJsonObj.push(location);

    // Take the first and last points for markers
    var markers = [location.coordinates[0], location.coordinates[location.coordinates.length - 1]];
    return geoJsonObj.concat(markers.map(function (marker, i) {
      return {
        type: 'Point',
        coordinates: marker,
        properties: {
          iconPath: "/assets/images/impact-editor/marker-".concat(i === 0 ? 'a' : 'b', ".png")
        }
      };
    }));
  }
  var _default = Ember.Component.extend({
    currentModelIndex: 0,
    totalNumberModels: computed('detailModels.[]', function () {
      return get(this, 'detailModels.length');
    }),
    currentModelNumber: computed('currentModelIndex', function () {
      return get(this, 'currentModelIndex') + 1;
    }),
    currentModel: computed('currentModelIndex', 'detailModels.[]', function () {
      return get(this, "detailModels.".concat(get(this, 'currentModelIndex')));
    }),
    currentModelName: computed('currentModel', function () {
      var model = get(this, 'currentModel');
      if (!model) return 'loading';
      var baseModelName = get(model, 'constructor.modelName').replace(/-.+/, '');
      return baseModelName;
    }),
    popupModel: computed('currentModel', 'currentModelName', function () {
      if (get(this, 'currentModelName') === modelTypes.DETOUR) return get(this, 'currentModel.layout');
      return get(this, 'currentModel');
    }),
    templateName: computed('currentModelName', function () {
      var modelName = get(this, 'currentModelName');
      if ([modelTypes.DETOUR, modelName.LAYOUT].indexOf(modelName) > -1) return modelTypes.LAYOUT;else return modelName;
    }),
    featurePropertyName: computed('currentModelName', function () {
      var modelName = get(this, 'currentModelName');
      var propertyMap = {
        worksite: 'location',
        layout: 'impacts'
      };
      return propertyMap[modelName];
    }),
    navigationRequired: computed('totalNumberModels', function () {
      return get(this, 'totalNumberModels') > 1;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (get(this, 'detailModels')) this.highLightModel();
    },
    highLightModel: function highLightModel() {
      if (get(this, 'showHighlightedFeature')) {
        // Run in the next run loop so that the computed properties have updated
        run.next(this, function () {
          var currentModelName = get(this, 'currentModelName');
          if (currentModelName === modelTypes.WORKSITE) {
            get(this, 'showHighlightedFeature')(getWorksiteGeoJson(get(this, 'currentModel')));
          } else if (currentModelName === modelTypes.LAYOUT) {
            get(this, 'showHighlightedFeature')(getImpactsGeoJson(get(this, 'currentModel')));
          } else if (currentModelName === modelTypes.DETOUR) {
            get(this, 'showHighlightedFeature')(getDetoursGeoJson(get(this, 'currentModel')));
          }
        });
      }
    },
    actions: {
      moveForward: function moveForward() {
        if (get(this, 'currentModelIndex') + 1 < get(this, 'totalNumberModels')) {
          set(this, 'currentModelIndex', get(this, 'currentModelIndex') + 1);
        } else {
          set(this, 'currentModelIndex', 0);
        }
        this.highLightModel();
      },
      moveBack: function moveBack() {
        if (get(this, 'currentModelIndex') > 0) {
          set(this, 'currentModelIndex', get(this, 'currentModelIndex') - 1);
        } else {
          set(this, 'currentModelIndex', get(this, 'totalNumberModels') - 1);
        }
        this.highLightModel();
      }
    }
  });
  _exports.default = _default;
});