define("tmp-for-all/components/search-results/projects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hyRwAD/u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"results\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"search-results/projects/list\",null,[[\"projects\"],[[23,[\"results\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-14 text-grey-dark\"],[9],[0,\"No projects matched current search filters. Please refine your search and try again.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"results\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"page-numbers\",null,[[\"content\",\"action\"],[[23,[\"results\"]],[27,\"action\",[[22,0,[]],[23,[\"handlePaginationClick\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/projects/template.hbs"
    }
  });
  _exports.default = _default;
});