define("tmp-for-all/components/worksite-view/purchase-order-fee-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rmu67n89",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\"],[true]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"submit-confirmation\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-blue has-cross\"],[9],[0,\"\\n        \"],[7,\"h1\"],[11,\"class\",\"float-left\"],[9],[0,\"Purchase Order Required\"],[10],[0,\"\\n         \"],[7,\"span\"],[9],[0,\"\\n          \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"tick\",\"25px\",\"20px\",\"icon--in-heading icon--blue\"]]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],[23,[\"cancelPurchaseOrderModal\"]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n     \"],[7,\"p\"],[11,\"class\",\"text-14 margin-btm-20\"],[9],[0,\"Contractors must enter a Purchase Order number. This is required for approvals. If not provided an extra fee will be charged. \"],[4,\"if-permitted\",[[27,\"permission/edit-worksite\",[[23,[\"worksite\",\"type\"]]],null]],[[\"model\"],[[23,[\"worksite\"]]]],{\"statements\":[[4,\"link-to\",[[27,\"concat\",[[23,[\"worksite\",\"type\"]],\".edit.ownership\"],null]],[[\"preventDefault\",\"class\"],[false,\"bold\"]],{\"statements\":[[0,\"Click here\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\" to enter your PO number.\\n    \"],[10],[0,\"\\n     \"],[7,\"button\"],[11,\"class\",\"secondary-action\"],[9],[0,\"Ok\"],[3,\"action\",[[22,0,[]],[23,[\"acceptPurchaseOrderFee\"]],[23,[\"worksite\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/purchase-order-fee-modal/template.hbs"
    }
  });
  _exports.default = _default;
});