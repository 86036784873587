define("tmp-for-all/templates/companies/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E8x5ewC/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"isFullWidth\",\"isListingPage\"],[true,true]],{\"statements\":[[0,\"\\n  \"],[4,\"listing-page-heading\",null,null,{\"statements\":[[0,\"Search Organisations and Private Individuals\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"search-parameters\",null,[[\"disableFilters\",\"freetext\",\"searchType\",\"setSearchText\",\"showArchivedResults\",\"updateShowArchivedResults\",\"updateRelatedPartiesFilter\",\"relatedPartiesFilter\",\"relatedPartiesOptions\"],[true,[23,[\"searchText\"]],\"organisations\",[27,\"action\",[[22,0,[]],\"searchUpdated\"],null],[23,[\"showArchivedResults\"]],[27,\"action\",[[22,0,[]],\"updateShowArchivedResults\"],null],[27,\"action\",[[22,0,[]],\"updateRelatedPartiesFilter\"],null],[23,[\"relatedPartiesFilter\"]],[23,[\"relatedPartiesOptions\"]]]]],false],[0,\"\\n\\n  \"],[1,[27,\"search-results\",null,[[\"handlePaginationClick\",\"results\",\"searchType\",\"showArchived\"],[[27,\"action\",[[22,0,[]],\"pageClicked\"],null],[23,[\"model\"]],\"organisations\",[23,[\"showArchivedResults\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/companies/list.hbs"
    }
  });
  _exports.default = _default;
});