define("tmp-for-all/components/worksite/start-work/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KlAu8iae",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-blue\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Start Work\"],[10],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"cone\",\"28px\",\"26px\",\"icon--in-heading icon--blue\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"underline-dotted\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"Start work date\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"element-group-wrap\"],[9],[0,\"\\n    \"],[1,[27,\"shared/work-date-selector\",null,[[\"date\",\"selectDate\"],[[23,[\"statedIssueDate\"]],[27,\"action\",[[22,0,[]],[23,[\"selectDate\"]]],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"align-right\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"tertiary-action t-secondary-button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],[23,[\"cancel\"]]]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"primary-action primary-action--listed-right t-primary-button\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"submit\"]]],false],[0,\" Issue work started notice\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"save\"]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite/start-work/template.hbs"
    }
  });
  _exports.default = _default;
});