define("tmp-for-all/components/shared/app-alerts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TGMdIUOk",
    "block": "{\"symbols\":[\"alert\"],\"statements\":[[7,\"div\"],[11,\"class\",\"max-1210\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"alerts\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"shared/app-alerts/app-alert\",null,[[\"alert\",\"dismissAlert\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"dismissAlert\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/app-alerts/template.hbs"
    }
  });
  _exports.default = _default;
});