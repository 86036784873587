define("tmp-for-all/components/shared/fixed-actions-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sNswGnwM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"deprecated-grid\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"deprecated-col-1-2 actions-bar__help\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"helpTextLabel\"]]],null,{\"statements\":[[0,\"        \"],[7,\"strong\"],[9],[1,[21,\"helpTextLabel\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[21,\"helpText\"],false],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"helpLinkText\"]],[23,[\"helpLinkAction\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,[\"helpLinkPermission\"]]],null,{\"statements\":[[4,\"if-permitted\",[[23,[\"helpLinkPermission\"]]],[[\"model\"],[[23,[\"helpLinkPermissionModel\"]]]],{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"class\",\"tertiary-action actions-bar-help-link\"],[9],[1,[21,\"helpLinkText\"],false],[3,\"action\",[[22,0,[]],[23,[\"helpLinkAction\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"             \\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"tertiary-action\"],[9],[1,[21,\"helpLinkText\"],false],[3,\"action\",[[22,0,[]],[23,[\"helpLinkAction\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"actions-bar__actions deprecated-col-2-4 align-right\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/fixed-actions-bar/template.hbs"
    }
  });
  _exports.default = _default;
});