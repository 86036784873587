define("tmp-for-all/components/conditions-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vLcYhPlZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"shared/conditions-list\",null,[[\"conditionGroups\",\"conditionType\"],[[23,[\"conditionGroups\"]],[23,[\"conditionType\"]]]]],false],[0,\"\\n\\n\"],[4,\"shared/fixed-actions-bar\",null,null,{\"statements\":[[4,\"link-to\",[[23,[\"createConditionLink\"]]],[[\"class\"],[\"primary-action t-create-condition\"]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"plus\"]]],false],[0,\" Add condition\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/conditions-list/template.hbs"
    }
  });
  _exports.default = _default;
});