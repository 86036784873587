define("tmp-for-all/utils/map-search-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    data: [{
      id: 109204,
      type: 'worksite',
      startDate: '2017-10-22 11:00:00.000000',
      endDate: '2017-11-24 11:00:00.000000',
      geometry: {
        type: 'Polygon',
        coordinates: [[[174.70444500446322, -36.77823890101391], [174.70468640327456, -36.778664270951325], [174.70457375049594, -36.778711534131986], [174.7043323516846, -36.77829046113205], [174.70444500446322, -36.77823890101391]]]
      },
      center: {
        type: 'Point',
        coordinates: [174.70450910364, -36.7784756446081]
      }
    }, {
      id: 109205,
      type: 'worksite',
      startDate: '2017-10-09 11:00:00.000000',
      endDate: '2017-11-11 11:00:00.000000',
      geometry: {
        type: 'Polygon',
        coordinates: [[[174.78984117507935, -36.94040827191022], [174.79048490524292, -36.94025391922791], [174.78969097137454, -36.937441215591804], [174.78939056396487, -36.93752697028579], [174.78984117507935, -36.94040827191022]]]
      },
      center: {
        type: 'Point',
        coordinates: [174.789889436713, -36.9390781301382]
      }
    }, {
      id: 97880,
      type: 'worksite',
      startDate: '2017-08-31 12:00:00.000000',
      endDate: '2018-02-27 11:00:00.000000',
      geometry: {
        type: 'Polygon',
        coordinates: [[[174.671083688736, -36.619958153303024], [174.67126607894897, -36.62028106768882], [174.67168450355533, -36.62013898552571], [174.67159330844885, -36.61983759825224], [174.671083688736, -36.619958153303024]]]
      },
      center: {
        type: 'Point',
        coordinates: [174.671399891134, -36.6200513498179]
      }
    }, {
      id: 146616,
      type: 'worksite',
      startDate: '2017-10-25 11:00:00.000000',
      endDate: '2018-04-04 12:00:00.000000',
      geometry: {
        type: 'Polygon',
        coordinates: [[[174.84983146190646, -36.896645347849706], [174.84925746917725, -36.8972974298331], [174.84926819801333, -36.897657788538936], [174.84863519668582, -36.89824551277756], [174.84880149364474, -36.89826696248167], [174.8493325710297, -36.89769639829935], [174.84941840171814, -36.89773071806999], [174.8501425981522, -36.89711295983715], [174.85020697116855, -36.89704431972483], [174.84983146190646, -36.896645347849706]]]
      },
      center: {
        type: 'Point',
        coordinates: [174.849577662881, -36.8973114238139]
      }
    }]
  };
  _exports.default = _default;
});