define("tmp-for-all/models/history", ["exports", "ember-data", "tmp-for-all/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var attr = _emberData.default.attr;
  var _default = _emberData.default.Model.extend({
    tmpId: attr('number'),
    tmpNumber: attr('number'),
    majorRevisionNumber: attr('number'),
    minorRevisionNumber: attr('number'),
    eventType: attr('string'),
    description: attr('string'),
    comment: attr('string'),
    createdAt: attr('date'),
    files: attr(),
    diff: attr(),
    organisationName: attr('string'),
    number: computed.alias('tmpNumber'),
    revisionNumber: computed('majorRevisionNumber', 'minorRevisionNumber', function () {
      var major = this.get('majorRevisionNumber') || '0';
      var minor = this.get('minorRevisionNumber') || '0';
      return "".concat(major, ".").concat(minor);
    }),
    isCommentCategory: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.COMMENTED),
    isAuditorCommentCategory: computed('eventType', function () {
      return R.contains(get(this, 'eventType'), [_constants.HISTORY_EVENT_TYPES.AUDITORCOMMENTED]);
    }),
    isRevision: computed('eventType', function () {
      return R.contains(get(this, 'eventType'), [_constants.HISTORY_EVENT_TYPES.SUBMITTED]);
    }),
    category: computed('isCommentCategory', 'isFileCategory', function () {
      if (get(this, 'isCommentCategory')) return _constants.HISTORY_ITEM_CATEGORIES.COMMENT;
      if (get(this, 'isFileCategory')) return _constants.HISTORY_ITEM_CATEGORIES.FILE;
      if (get(this, 'isAuditorCommentCategory')) return _constants.HISTORY_ITEM_CATEGORIES.AUDITORCOMMENT;
      if (get(this, 'isRevision')) return _constants.HISTORY_ITEM_CATEGORIES.REVISION;
      return _constants.HISTORY_ITEM_CATEGORIES.EVENT;
    }),
    IS_ACCEPTED: computed('eventType', function () {
      var acceptedTypes = [_constants.HISTORY_EVENT_TYPES.ACCEPTED, _constants.HISTORY_EVENT_TYPES.ACCEPTED_BY_PRIMARY, _constants.HISTORY_EVENT_TYPES.ACCEPTED_BY_SECONDARY];
      return acceptedTypes.includes(get(this, 'eventType'));
    }),
    IS_DECLINED: computed('eventType', function () {
      var declinedTypes = [_constants.HISTORY_EVENT_TYPES.DECLINED, _constants.HISTORY_EVENT_TYPES.DECLINED_BY_PRIMARY, _constants.HISTORY_EVENT_TYPES.DECLINED_BY_SECONDARY];
      return declinedTypes.includes(get(this, 'eventType'));
    }),
    isFileCategory: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.UPLOAD)
  });
  _exports.default = _default;
});