define("tmp-for-all/routes/worksite-submission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var worksiteSubmissionId = _ref['worksite-submission_id'];
      return this.store.findRecord('worksite-submission', worksiteSubmissionId, {
        adapterOptions: {
          prefetch: true
        }
      });
    }
  });
  _exports.default = _default;
});