define("tmp-for-all/controllers/nzta-detours/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    featureFilter: {
      closures: {
        published: true,
        draft: true
      },
      detours: {
        published: false,
        draft: false
      }
    },
    queryParams: ['detourNumber'],
    afterSetup: function afterSetup() {
      this.set('filter', this.featureFilter);
      this.getFeatureCollection();
    },
    getFeatureCollection: function getFeatureCollection() {
      var _this = this;
      this.ajax.getNztaDetourFeatureCollection(this.featureFilter).then(function (features) {
        _this.set('model.features', features);
      });
    },
    actions: {
      updateFeatures: function updateFeatures() {
        this.getFeatureCollection();
      }
    }
  });
  _exports.default = _default;
});