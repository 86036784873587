define("tmp-for-all/components/shared/nz-business-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wk7+p6A+",
    "block": "{\"symbols\":[\"error\",\"item\"],\"statements\":[[7,\"label\"],[9],[1,[21,\"label\"],false],[0,\" \"],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10]],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"power-select\",null,[[\"search\",\"selected\",\"class\",\"disabled\",\"placeholder\",\"onchange\",\"noMatchesMessage\"],[[27,\"action\",[[22,0,[]],\"search\"],null],[23,[\"selected\"]],\"t-nz-business-search-select ember-power-select\",[23,[\"disabled\"]],\"Search by company name\",[27,\"action\",[[22,0,[]],\"setBusiness\"],null],\"No results found. If your company does not have an NZBN, select the 'Other organisation' option above\"]],{\"statements\":[[0,\"  \"],[1,[22,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[7,\"div\"],[11,\"class\",\"text-12 text-grey-dark italic\"],[9],[0,\"\\n  MyWorksites sources your core business data from the details supplied to the MBIE NZBN register\\n\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"error\"],[9],[1,[22,1,[\"message\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/nz-business-search/template.hbs"
    }
  });
  _exports.default = _default;
});