define("tmp-for-all/components/planning-map/panel-filters/filter-control/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    checkboxId: computed('elementId', 'structure.searchKey', function () {
      // To protect against child elements with the same searchKey string, which we have lots of
      return "".concat(get(this, 'elementId'), "-").concat(get(this, 'structure.searchKey'));
    }),
    value: computed('state', 'structure.{children,searchKey}', function () {
      var state = get(this, 'state');
      var stateValue = get(state, get(this, 'structure.searchKey'));
      if (!stateValue) return 'unchecked';
      var children = get(this, 'structure.children');
      if (children) {
        // The checkbox is defined as checked/unchecked/partial depending on the checked nature of the children
        // how many child values are true
        var values = Object.keys(stateValue).reduce(function (acc, key) {
          acc.push(get(stateValue, key));
          return acc;
        }, []);
        var trueCount = values.filter(function (value) {
          return value === true;
        }).length;
        if (trueCount === children.length) {
          return 'checked';
        } else if (trueCount === 0) {
          return 'unchecked';
        } else {
          return 'partial';
        }
      } else {
        return stateValue ? 'checked' : 'unchecked';
      }
    }),
    actions: {
      onChange: function onChange(isChecked) {
        var newValue;
        var children = get(this, 'structure.children');
        if (children) {
          newValue = {};
          // Need to ensure the value is passed to the children
          // Note: will not work beyond two levels deep at this point
          children.forEach(function (child) {
            newValue[get(child, 'searchKey')] = isChecked;
          });
        } else {
          newValue = isChecked;
        }
        get(this, 'updateState')(get(this, 'structure.searchKey'), newValue);
      },
      updateState: function updateState(updatedChildKey, updatedChildValue) {
        var key = get(this, 'structure.searchKey');
        // make the changes to the current object reference.  Will need a new object reference to refire all the computed
        // properties, but that's okay, it'll happen at the root component
        var state = R.assocPath([key, updatedChildKey], updatedChildValue, get(this, 'state'));
        get(this, 'updateState')(key, state[key]);
      }
    }
  });
  _exports.default = _default;
});