define("tmp-for-all/components/user-form/role-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VzI8biO4",
    "block": "{\"symbols\":[\"assignment\",\"option\",\"@addPermissionAssignment\",\"@deletePermissionAssignment\"],\"statements\":[[4,\"each\",[[23,[\"assignments\"]]],null,{\"statements\":[[4,\"unless\",[[22,1,[\"isDeleted\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"deprecated-grid input-group-panel\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"deprecated-col-1-3\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"element-wrap\"],[9],[0,\"\\n          \"],[7,\"label\"],[9],[0,\"Role:\"],[10],[0,\"\\n\"],[4,\"power-select\",null,[[\"selected\",\"searchEnabled\",\"searchField\",\"class\",\"onchange\",\"options\"],[[22,1,[\"permission\"]],true,\"displayName\",\"domain-item-role-selector\",[27,\"action\",[[22,0,[]],\"roleDidChange\",[22,1,[]]],null],[23,[\"unassignedRoles\"]]]],{\"statements\":[[0,\"            \"],[1,[22,2,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"deprecated-col-1-3\"],[9],[0,\"\\n        \"],[7,\"label\"],[9],[0,\" \"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"secondary-action\"],[9],[0,\"\\n          \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"cross\"]]],false],[0,\" Remove role\\n        \"],[3,\"action\",[[22,0,[]],[22,4,[]],[22,1,[]]]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showAddButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"secondary-action\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"plus\"]]],false],[0,\" Add role\\n  \"],[3,\"action\",[[22,0,[]],[22,3,[]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/user-form/role-selector/template.hbs"
    }
  });
  _exports.default = _default;
});