define("tmp-for-all/components/tmp-view/conditions/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    RSVP = Ember.RSVP;
  var _default = Ember.Component.extend({
    filteredConditions: computed('conditions.[]', 'filterId', function () {
      var filterId = get(this, 'filterId');
      return _emberData.default.PromiseArray.create({
        promise: RSVP.filter(get(this, 'conditions').toArray(), function (condition) {
          return get(condition, 'jurisdiction').then(function (jurisdiction) {
            return !!jurisdiction && get(jurisdiction, 'id') === filterId;
          });
        })
      });
    }),
    filteredConditionGroups: computed('allConditionGroups', 'filterId', function () {
      var filterId = get(this, 'filterId');
      return _emberData.default.PromiseArray.create({
        promise: RSVP.filter(get(this, 'allConditionGroups').toArray(), function (group) {
          return get(group, 'jurisdiction').then(function (jurisdiction) {
            return !!jurisdiction && get(jurisdiction, 'id') === filterId;
          });
        })
      });
    })
  });
  _exports.default = _default;
});