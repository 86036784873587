define("tmp-for-all/components/tmp-view/component", ["exports", "ember-data", "tmp-for-all/mixins/submit-panel-mixin", "tmp-for-all/config/environment", "tmp-for-all/constants", "tmp-for-all/utils/html-comment-helpers"], function (_exports, _emberData, _submitPanelMixin, _environment, _constants, _htmlCommentHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set,
    setProperties = Ember.setProperties;
  var PromiseObject = _emberData.default.PromiseObject;
  var _default = Ember.Component.extend(_submitPanelMixin.default, {
    classNames: ['tmp-view'],
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    acceptanceBoxOpen: false,
    declineBoxOpen: false,
    editPermission: computed('tmp.type', 'tmp.isGenericTmp', function () {
      if (get(this, 'tmp.isGenericTmp')) return 'editGlobalPermissionLayout';
      return {
        'tmp-draft': 'editDraftLayout',
        'tmp-submission': 'editSubmittedLayout',
        tmp: 'editAcceptedLayout'
      }[get(this, 'tmp.type')];
    }),
    submitOptionsOpen: computed('acceptanceBoxOpen', 'declineBoxOpen', function () {
      return get(this, 'acceptanceBoxOpen') || get(this, 'declineBoxOpen') ? 'opened' : '';
    }),
    isDraft: computed.not('tmp.isSubmitted'),
    isAcceptedOrDeclined: computed.or('tmp.isAccepted', 'tmp.isDeclined'),
    isAwaitingAcceptance: computed('tmp.isSubmitted', 'isAcceptedOrDeclined', function () {
      return get(this, 'tmp.isSubmitted') && !get(this, 'isAcceptedOrDeclined');
    }),
    latestNonDraftWorksitePromise: computed('tmp.latestSubmittedOrAccepted', function () {
      return get(this, 'tmp.latestSubmittedOrAccepted');
    }),
    latestNonDraftWorksite: computed('latestNonDraftWorksitePromise.content', function () {
      return get(this, 'latestNonDraftWorksitePromise.content');
    }),
    hasConditions: computed.or('selectedConditions.length', 'tmp.customConditions.length'),
    isNotDeclined: computed.not('tmp.isDeclined'),
    isSubmittedAndNotDeclined: computed.and('tmp.isSubmitted', 'isNotDeclined'),
    acceptIsInFlight: computed.bool('tmp.isAccepting'),
    declineIsInFlight: computed.bool('tmp.isDeclining'),
    acceptedComment: '',
    declinedComment: '',
    statusColour: computed('tmp.status', function () {
      var status = get(this, 'tmp.status');
      switch (status) {
        case 'Draft':
          return 'grey-light';
        case 'Submitted':
          return 'orange';
        case 'Accepted':
          return 'green';
        case 'Declined':
          return 'red';
        default:
          return 'grey-light';
      }
    }),
    computedStatusPromise: computed('tmp.secondaryJurisdictions', function () {
      return PromiseObject.create({
        promise: get(this, 'tmp.secondaryJurisdictions').then(function (secondaries) {
          if (get(secondaries, 'firstObject.acceptedAt')) {
            return 'Accepted by Secondary';
          }
          return 'Accepted by Primary';
        })
      });
    }),
    computedStatus: computed('computedStatusPromise.content', function () {
      if (get(this, 'tmp.isDeclined')) return get(this, 'tmp.status');
      return get(this, 'computedStatusPromise.content');
    }),
    conditionsSectionOpen: false,
    token: computed.alias('session.data.authenticated.id'),
    pdfLink: computed('tmp.id', function () {
      var id = get(this, 'tmp.id');
      return "".concat(_environment.default.apiPath, "/tmps/").concat(id, "/pdf-certificate?access_token=").concat(get(this, 'token'));
    }),
    pdfLinkWithExtras: computed('tmp.id', function () {
      var id = get(this, 'tmp.id');
      return "".concat(_environment.default.apiPath, "/tmps/").concat(id, "/pdf-certificate?includeExtras=true&access_token=").concat(get(this, 'token'));
    }),
    pdfWithoutLayoutsLink: computed('tmp.id', function () {
      var id = get(this, 'tmp.id');
      return "".concat(_environment.default.apiPath, "/tmps/").concat(id, "/pdf-certificate?excludeLayouts=true&access_token=").concat(get(this, 'token'));
    }),
    pdfWithoutLayoutsLinkWithExtras: computed('tmp.id', function () {
      var id = get(this, 'tmp.id');
      return "".concat(_environment.default.apiPath, "/tmps/").concat(id, "/pdf-certificate?includeExtras=true&excludeLayouts=true&access_token=").concat(get(this, 'token'));
    }),
    permittedCompanies: Ember.computed.mapBy('tmpToPermittedCompanies', 'company'),
    permittedCompanyIds: Ember.computed.mapBy('permittedCompanies', 'id'),
    selectedCompany: null,
    addCompanyButtonDisabled: computed.not('selectedCompany'),
    toolTips: {
      layouts: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.TMP.LAYOUTS),
      activeLayouts: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.TMP.ACTIVE_LAYOUTS),
      permissions: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.TMP.PERMISSIONS)
    },
    helpText: computed('tmp.{isCrossRcaApproval,currentSecondaryRcaId}', 'userIsCurrentSecondary', function () {
      if (get(this, 'userIsNotJurisdiction')) {
        return 'Waiting for RCA acceptance';
      }
      if (!get(this, 'tmp.isCrossRcaApproval')) {
        return 'Proceed to condition selection and TMP acceptance';
      }
      if (get(this, 'tmp.currentSecondaryRcaId')) {
        if (get(this, 'userIsCurrentSecondary')) {
          return 'Proceed to condition selection and TMP acceptance';
        } else {
          return 'Awaiting secondary RCA approval';
        }
      }
      return 'Waiting for Primary acceptance';
    }),
    tmpJurisdictionId: computed('tmp.jurisdiction.id', function () {
      return Number(get(this, 'tmp.jurisdiction.id'));
    }),
    userIsPrimary: computed('tmpJurisdictionId', 'userJurisdictionId', function () {
      return get(this, 'tmpJurisdictionId') === get(this, 'userJurisdictionId');
    }),
    userIsNotPrimary: computed.not('userIsPrimary'),
    userIsCurrentSecondary: computed('tmp.currentSecondaryRcaId', 'userJurisdictionId', function () {
      return Number(get(this, 'tmp.currentSecondaryRcaId')) === get(this, 'userJurisdictionId');
    }),
    userIsNotJurisdiction: computed.not('userJurisdictionId'),
    notAcceptedByPrimary: computed.not('tmp.primaryAcceptedAt'),
    canEditPrimaryConditions: computed.and('userIsPrimary', 'notAcceptedByPrimary'),
    showSecondaryConditions: computed.and('tmp.secondaryJurisdictions', 'userIsNotPrimary'),
    showAcceptButton: computed('tmpJurisdictionId', 'userIsPrimary', 'userIsCurrentSecondary', 'tmp.{isCrossRcaApproval,inApproval}', 'currentSecondaryRcaId', function () {
      if (!get(this, 'tmp.isCrossRcaApproval')) return true;
      if (get(this, 'tmp.inApproval')) {
        if (get(this, 'userIsCurrentSecondary')) return true;
      } else {
        if (get(this, 'userIsPrimary')) return true;
      }
      return false;
    }),
    disableSubmit: computed('acceptTermsConditions', 'displaySubmissionModal', 'submittingInProgress', function () {
      return !get(this, 'acceptTermsConditions') && get(this, 'displaySubmissionModal') || get(this, 'submittingInProgress');
    }),
    checkedLayoutsOnly: computed.bool('openLayoutsOnly'),
    actions: {
      openAcceptanceBox: function openAcceptanceBox() {
        set(this, 'acceptanceBoxOpen', true);
        set(this, 'declineBoxOpen', false);
        this.send('scrollToSubmitPanel');
      },
      openDeclineBox: function openDeclineBox() {
        set(this, 'declineBoxOpen', true);
        set(this, 'acceptanceBoxOpen', false);
        this.send('scrollToSubmitPanel');
      },
      acceptTmp: function acceptTmp(tmp, selectedConditions, rcaId) {
        var comment = (0, _htmlCommentHelpers.sanitizeHtmlComment)(get(this, 'acceptedComment'));
        var acceptedComment = (0, _htmlCommentHelpers.hasHtmlComment)(comment) ? comment : 'Accepted';
        get(this, 'acceptTmp')(tmp, acceptedComment, selectedConditions, rcaId);
      },
      declineTmp: function declineTmp(tmp) {
        var comment = (0, _htmlCommentHelpers.sanitizeHtmlComment)(get(this, 'declinedComment'));
        get(this, 'declineTmp')(tmp, comment);
      },
      createDraft: function createDraft(tmp) {
        get(this, 'createDraft')(tmp);
      },
      closeAcceptanceAndDeclineBoxes: function closeAcceptanceAndDeclineBoxes() {
        set(this, 'acceptanceBoxOpen', false);
        set(this, 'declineBoxOpen', false);
        this.send('toggleMainPaddingBottom', false);
      },
      selectPermittedCompany: function selectPermittedCompany(company) {
        set(this, 'selectedCompany', company);
      },
      addPermittedCompany: function addPermittedCompany() {
        var _this = this;
        var selectedCompany = get(this, 'selectedCompany');
        set(this, 'selectedCompany', null);
        var tmp = get(this, 'tmp');
        get(this, 'addPermittedCompany')(tmp, selectedCompany).catch(function (err) {
          get(_this, 'flashMessages').danger(err.message);
        });
      },
      removePermittedCompany: function removePermittedCompany(tmpToPermittedCompany) {
        var _this2 = this;
        var tmp = get(this, 'tmp');
        get(this, 'removePermittedCompany')(tmp, tmpToPermittedCompany).catch(function (err) {
          get(_this2, 'flashMessages').danger(err.message);
        });
      },
      searchCompanies: function searchCompanies(permittedCompanyIds, q) {
        var options = {
          notIn: {
            id: permittedCompanyIds
          },
          all: true
        };
        return get(this, 'searchCompanies')(q, options);
      },
      cancelSubmission: function cancelSubmission() {
        get(this, 'toggleSubmissionModal')();
        setProperties(this, {
          isSubmitting: false,
          acceptTermsConditions: false
        });
        set(this, 'tmp.isSubmitting', false);
      },
      submitTmp: function submitTmp(tmp) {
        var _this3 = this;
        set(this, 'submittingInProgress', true);
        get(this, 'submitTmp')(tmp).then(function () {
          return set(_this3, 'submittingInProgress', false);
        }).catch(function (err) {
          // if a dropped task, then still submitting (note the American single `l` in Cancelation is correct)
          if (err.name !== 'TaskCancelation') {
            set(_this3, 'submittingInProgress', false);
          }
        });
      }
    }
  });
  _exports.default = _default;
});