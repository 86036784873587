define("tmp-for-all/initializers/leaflet", ["exports", "leaflet.markercluster", "leaflet-routing-machine"], function (_exports, _leaflet, _leafletRoutingMachine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // Need to import leaflet.markercluster once to have it available

  function initialize( /* container, application */
  ) {
    // no-op
  }
  var _default = {
    name: 'leaflet-initializer',
    initialize: initialize
  };
  _exports.default = _default;
});