define("tmp-for-all/components/shared/object-diff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HY/Pz45e",
    "block": "{\"symbols\":[\"item\",\"key\",\"val\",\"rev\"],\"statements\":[[7,\"div\"],[11,\"style\",\"height: 150px; overflow-y: scroll; border: 1px solid #ccc; margin-left: -15px;\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"blue\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"margin-right-15\"],[9],[0,\"Field\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"revisions\"]]],null,{\"statements\":[[0,\"        \"],[7,\"th\"],[9],[0,\"Revision \"],[1,[22,4,[]],false],[0,\" value\"],[10],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"    \"],[10],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"displayDiffs\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[11,\"class\",\"margin-right-15\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"td\"],[9],[1,[27,\"shared/array-or-primitive-display\",null,[[\"value\"],[[22,3,[]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/object-diff/template.hbs"
    }
  });
  _exports.default = _default;
});