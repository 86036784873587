define("tmp-for-all/components/worksite-view/tmps/tmp/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    classNames: ['panel', 'table-panel', 'bg-grey-light', 'margin-btm-20', 'tmp-collection'],
    isGenericTmp: computed('tmpRevisions.@each.isGenericTmp', function () {
      return get(this, 'tmpRevisions').some(function (tmp) {
        return get(tmp, 'isGenericTmp');
      });
    }),
    actions: {
      updateSelectedRow: function updateSelectedRow(tmp) {
        set(this, 'tmpRevisions', get(this, 'tmpRevisions').map(function (r) {
          set(r, 'isSelected', tmp === r);
          return r;
        }));
      }
    }
  });
  _exports.default = _default;
});