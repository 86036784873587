define("tmp-for-all/templates/tmp-draft/view/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "O+CqF+Jd",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"tmp-view\",null,[[\"tmp\",\"worksite\",\"tmpToPermittedCompanies\",\"rcas\",\"userIsContractor\",\"submitTmp\",\"deleteDraftTmp\",\"deleteDraftTmpPending\",\"addPermittedCompany\",\"removePermittedCompany\",\"searchCompanies\",\"setPrimaryRCA\",\"addSecondaryRCA\",\"updateSecondaryRCA\",\"removeSecondaryRCA\",\"setSecondaryAssignee\",\"toggleSubmissionModal\",\"displaySubmissionModal\",\"acceptTermsConditions\",\"submissionConfirmed\",\"applicantName\"],[[23,[\"model\",\"tmp\"]],[23,[\"model\",\"worksite\"]],[23,[\"model\",\"tmpToPermittedCompanies\"]],[23,[\"model\",\"rcas\"]],[23,[\"model\",\"userIsContractor\"]],[27,\"route-action\",[\"submitTmp\"],null],[27,\"route-action\",[\"deleteDraftTmp\"],null],[23,[\"deleteDraftTmpPending\"]],[27,\"route-action\",[\"addPermittedCompany\"],null],[27,\"route-action\",[\"removePermittedCompany\"],null],[27,\"route-action\",[\"searchCompanies\"],null],[27,\"route-action\",[\"setPrimaryRCA\"],null],[27,\"route-action\",[\"addSecondaryRCA\"],null],[27,\"route-action\",[\"updateSecondaryRCA\"],null],[27,\"route-action\",[\"removeSecondaryRCA\"],null],[27,\"route-action\",[\"setSecondaryAssignee\"],null],[27,\"route-action\",[\"toggleSubmissionModal\"],null],[23,[\"displaySubmissionModal\"]],[23,[\"acceptTermsConditions\"]],[23,[\"submissionConfirmed\"]],[23,[\"model\",\"applicantName\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmp-draft/view/index.hbs"
    }
  });
  _exports.default = _default;
});