define("tmp-for-all/components/worksite-view/documents/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Naeb4OIp",
    "block": "{\"symbols\":[\"documentationBlock\"],\"statements\":[[4,\"if-permitted\",[[27,\"permission/edit-documentation\",[[23,[\"worksite\",\"type\"]]],null]],[[\"model\"],[[23,[\"worksite\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-blue has-tab-text-btn\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Documentation\"],[10],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"document\",\"28px\",\"26px\",\"icon--in-heading icon--blue\"]]],false],[0,\"\\n    \"],[4,\"link-to\",[[27,\"concat\",[[23,[\"worksite\",\"type\"]],\".edit.documentation\"],null],[23,[\"worksite\"]]],[[\"class\"],[\"tab-btn tab-btn-text t-edit-documents\"]],{\"statements\":[[0,\"Edit\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-blue\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Documentation\"],[10],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"document\",\"28px\",\"26px\",\"icon--in-heading icon--blue\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table no-hover-effect\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"allDocumentationBlocks\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"worksite-view/documents/documentation-block\",null,[[\"documentationBlock\",\"worksite\"],[[22,1,[]],[23,[\"worksite\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/documents/template.hbs"
    }
  });
  _exports.default = _default;
});