define("tmp-for-all/components/tmp-view/layouts/list/item/detour-text-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    detourLastIndex: computed('detours.length', function () {
      return get(this, 'detours.length') - 1;
    })
  });
  _exports.default = _default;
});