define("tmp-for-all/components/permissions-tree/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1iXgRcZq",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[0,\"id: \"],[1,[23,[\"permission\",\"id\"]],false],[0,\"\\nname: \"],[1,[23,[\"permission\",\"name\"]],false],[0,\"\\ndisplayName: \"],[1,[23,[\"permission\",\"displayName\"]],false],[0,\"\\nisRole: \"],[1,[23,[\"permission\",\"isRole\"]],false],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"t-permissions-tree\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"permission\",\"children\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[1,[27,\"permissions-tree\",null,[[\"permission\"],[[22,1,[]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/permissions-tree/template.hbs"
    }
  });
  _exports.default = _default;
});