define("tmp-for-all/components/shared/threaded-history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aMBkEqJi",
    "block": "{\"symbols\":[\"history\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"heading-block underline-coloured-\",[21,\"color\"]]]],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"History\"],[10],[0,\"\\n  \"],[1,[27,\"shared/form/radio-buttons\",null,[[\"options\",\"onChange\",\"inlineGroupLabel\",\"class\",\"useButtonStyle\"],[[23,[\"filterByOptions\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"filterBy\"]]],null]],null],\"\",\"float-right\",true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredHistoryItems\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"shared/threaded-history/item\",null,[[\"history\",\"filterBy\",\"refreshHistory\"],[[22,1,[]],[23,[\"filterBy\"]],[27,\"action\",[[22,0,[]],\"refreshHistory\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[23,[\"filterBy\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-14 text-grey-dark\"],[9],[0,\"No history found. Please check the filters above.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-14 text-grey-dark\"],[9],[0,\"No history added yet.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/threaded-history/template.hbs"
    }
  });
  _exports.default = _default;
});