define("tmp-for-all/components/search-results/tmps/list/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    classNames: ['table-container'],
    assigneeColName: computed('isProcessingQueue', function () {
      return get(this, 'isProcessingQueue') ? _constants.TMP_TABLE_COLUMNS.ASSIGNED_TO : _constants.TMP_TABLE_COLUMNS.PRIMARY_RCA_ASSIGNEE;
    }),
    columns: computed(function () {
      return [Ember.Object.create({
        key: 'ID',
        columnName: _constants.TMP_TABLE_COLUMNS.ID,
        sortName: 'tmpId'
      }), Ember.Object.create({
        key: 'REVISION',
        columnName: _constants.TMP_TABLE_COLUMNS.REVISION
      }), Ember.Object.create({
        key: 'WORKSITE_ID',
        columnName: _constants.TMP_TABLE_COLUMNS.WORKSITE_ID,
        sortName: 'worksiteId'
      }), Ember.Object.create({
        key: 'WORKSITE_NAME',
        columnName: _constants.TMP_TABLE_COLUMNS.WORKSITE_NAME,
        sortName: 'worksiteName'
      }), Ember.Object.create({
        key: 'WORKSITE_REFERENCE',
        columnName: _constants.TMP_TABLE_COLUMNS.WORKSITE_REFERENCE,
        sortName: 'worksiteReference'
      }), Ember.Object.create({
        key: 'DESCRIPTION',
        columnName: _constants.TMP_TABLE_COLUMNS.DESCRIPTION,
        sortName: 'description'
      }), Ember.Object.create({
        key: 'FIRST_LAYOUT_START_DATE',
        columnName: _constants.TMP_TABLE_COLUMNS.FIRST_LAYOUT_START_DATE,
        sortName: 'layoutMinDate'
      }), Ember.Object.create({
        key: 'LAST_LAYOUT_END_DATE',
        columnName: _constants.TMP_TABLE_COLUMNS.LAST_LAYOUT_END_DATE,
        sortName: 'layoutMaxDate'
      }), Ember.Object.create({
        key: 'STATUS',
        columnName: _constants.TMP_TABLE_COLUMNS.STATUS,
        sortName: 'type'
      }), Ember.Object.create({
        key: 'PROJECT',
        columnName: _constants.TMP_TABLE_COLUMNS.PROJECT,
        sortName: 'projectName'
      }), Ember.Object.create({
        key: 'ASSIGNED_TO',
        columnName: this.assigneeColName,
        sortName: 'ownerFullname'
      }), Ember.Object.create({
        key: 'NUMBER_OF_LAYOUTS',
        columnName: _constants.TMP_TABLE_COLUMNS.NUMBER_OF_LAYOUTS
      }), Ember.Object.create({
        key: 'SUBMITTED_AGO',
        columnName: _constants.TMP_TABLE_COLUMNS.SUBMITTED_AGO,
        sortName: 'submittedAt'
      })];
    }),
    columnsExcludedByDefault: ['REVISION', 'WORKSITE_REFERENCE', 'NUMBER_OF_LAYOUTS'],
    orderByName: null,
    orderByDirection: null,
    init: function init() {
      this._super.apply(this, arguments);
      // Set visible columns to match page default (set in controller):
      this.revertToDefaultColumns();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, 'order')) {
        var orderProps = get(this, 'order').split(' ');
        set(this, 'orderByName', orderProps[0]);
        set(this, 'orderByDirection', orderProps[1]);
      }
    },
    toggleText: 'Show all columns',
    noclaimHeading: '',
    // Set visible columns to page default (defaults set in controller)
    revertToDefaultColumns: function revertToDefaultColumns() {
      var columns = get(this, 'columns');
      var columnsToHide = get(this, 'columnsExcludedByDefault');
      set(this, 'columns', columns.map(function (column) {
        set(column, 'isVisible', !columnsToHide.includes(column.key));
        return column;
      }));
    },
    actions: {
      toggleColumn: function toggleColumn(column) {
        column.toggleProperty('isVisible');
        // If all columns are now shown, change toggle all link to read "Revert to default":
        var allColumnsAreVisible = get(this, 'columns').every(function (column) {
          return get(column, 'isVisible');
        });
        if (allColumnsAreVisible) {
          set(this, 'toggleText', 'Revert to default');
        }
      },
      toggleAllColumns: function toggleAllColumns() {
        var columns = get(this, 'columns');
        var allColumnsAreVisible = columns.every(function (column) {
          return get(column, 'isVisible');
        });
        // If all columns are already shown, action reverts visible columns back to page default instead:
        if (allColumnsAreVisible) {
          this.revertToDefaultColumns();
          set(this, 'toggleText', 'Show all columns');
          // Otherwise, update all columns to be visible:
        } else {
          set(this, 'columns', columns.map(function (column) {
            set(column, 'isVisible', true);
            return column;
          }));
          set(this, 'toggleText', 'Revert to default');
        }
      }
    }
  });
  _exports.default = _default;
});