define("tmp-for-all/components/project-view/information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    companyName: computed.alias('project.company.name')
  });
  _exports.default = _default;
});