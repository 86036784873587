define("tmp-for-all/components/planning-map/panel-filters/component", ["exports", "tmp-for-all/utils/map-functions"], function (_exports, _mapFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    run = Ember.run;
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      set(this, 'state', (0, _mapFunctions.queryToState)(get(this, 'searchQuery'), get(this, 'structure')));
    },
    actions: {
      updateState: function updateState(updatedChildKey, updatedChildValue) {
        // Create a new reference for the state by deep copying
        var key = get(this, 'structure.searchKey');
        var newState = R.assocPath([key, updatedChildKey], updatedChildValue, get(this, 'state'));

        // Propagate down to the children
        set(this, 'state', newState);
        var transformedState = (0, _mapFunctions.stateToQuery)(newState, get(this, 'structure'));

        // Minimise the impact of the 'OPTIONS' request on UI rendering
        run.next(this, function () {
          get(this, 'updateSearchQuery')(transformedState);
        });
      }
    }
  });
  _exports.default = _default;
});