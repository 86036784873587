define("tmp-for-all/components/search-results/projects/list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['project-list-item'],
    projectTitle: computed('project.name', 'project.reference', function () {
      return this.get('project.reference') + ': ' + this.get('project.name');
    }),
    companyName: computed.alias('project.company.name')
  });
  _exports.default = _default;
});