define("tmp-for-all/components/shared/feedback-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['feedback-message'],
    /**
     * Pass in following paramaters to theme message appropriately:
     *
     * isNotice: yellow top border
     * isWarning: orange top border
     * isError: red top border
     */
    classNameBindings: ['isNotice:feedback-message--notice', 'isWarning:feedback-message--warning', 'isError:feedback-message--error']
  });
  _exports.default = _default;
});