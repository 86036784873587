define("tmp-for-all/components/worksite-view/clashes-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eSPG5oHx",
    "block": "{\"symbols\":[\"clash\"],\"statements\":[[4,\"if\",[[23,[\"clashes\",\"length\"]]],null,{\"statements\":[[4,\"shared/expand-contract-box\",null,[[\"title\",\"color\",\"icon\",\"initialHeight\",\"animatePixelsPerSecond\"],[[23,[\"boxTitle\"]],\"blue\",\"cone\",50,2000]],{\"statements\":[[0,\"\\t  \"],[7,\"div\"],[11,\"class\",\"table-container align-left margin-btm-10\"],[9],[0,\"\\n\\t    \"],[7,\"table\"],[11,\"class\",\"table blue no-hover-effect has-double-rows no-colour-borders\"],[9],[0,\"\\n\\t      \"],[7,\"tr\"],[9],[0,\"\\n\\t        \"],[7,\"th\"],[9],[0,\"Worksite ID & Date\"],[10],[0,\"\\n\\t        \"],[7,\"th\"],[9],[0,\"Affected party overlaps\"],[10],[0,\"\\n\\t        \"],[7,\"th\"],[9],[0,\"Contact\"],[10],[0,\"\\n\"],[0,\"\\t        \"],[7,\"th\"],[9],[0,\"Notes\"],[10],[0,\"\\n\\t        \"],[7,\"th\"],[9],[0,\"Status\"],[10],[0,\"\\n\\t      \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"clashes\"]]],null,{\"statements\":[[0,\"\\t        \"],[1,[27,\"worksite-view/clashes-list/clash-item\",null,[[\"worksite\",\"clash\"],[[23,[\"worksite\"]],[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t    \"],[10],[0,\"\\n\\t  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/clashes-list/template.hbs"
    }
  });
  _exports.default = _default;
});