define("tmp-for-all/mixins/routes/delete-draft-worksite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP;
  var _default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    setupController: function setupController(controller, models) {
      this._super(controller, models);
      set(this, 'controller.deleteDraftWorksitePending', false);
    },
    // Will run after successful delete, override for other behaviour such
    // as transitioning or refreshing the model.
    postDeleteDraftWorksiteHook: function postDeleteDraftWorksiteHook() {},
    openDeleteDraftWorksiteConfirmModal: function openDeleteDraftWorksiteConfirmModal(confirmAction, cancelAction) {
      var _this = this;
      return new RSVP.Promise(function (resolve, reject) {
        set(_this, 'modal.options', {
          title: 'Delete Worksite Draft?',
          html: 'This will only delete the Worksite draft.<br><br>Any existing submitted or accepted versions of this worksite, or TMPs will not be deleted.',
          confirmButton: 'Delete',
          confirmAction: function confirmAction() {
            set(_this, 'modal.show', false);
            resolve();
          },
          cancelAction: function cancelAction() {
            set(_this, 'modal.show', false);
            reject();
          }
        });
        get(_this, 'modal').toggle();
      });
    },
    actions: {
      deleteDraftWorksite: function deleteDraftWorksite(worksite) {
        var _this2 = this;
        set(this, 'controller.deleteDraftWorksitePending', true);
        return this.openDeleteDraftWorksiteConfirmModal().then(function () {
          worksite.destroyRecord().then(function () {
            var draft = _this2.store.peekRecord('worksite-draft', worksite.id);
            if (draft) {
              get(_this2, 'store').unloadRecord(draft);
            }
            get(_this2, 'flashMessages').success('Draft worksite has been deleted.');
            _this2.postDeleteDraftWorksiteHook();
          }).catch(function (err) {
            return get(_this2, 'flashMessages').danger(err.message);
          });
        }).finally(function () {
          set(_this2, 'controller.deleteDraftWorksitePending', false);
        });
      }
    }
  });
  _exports.default = _default;
});