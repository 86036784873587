define("tmp-for-all/templates/nzta-detours/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "c0tafEZX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"nzta-detour/map\",null,[[\"features\",\"nocAreas\",\"routeLoadedDetourSet\",\"createDetour\",\"addClosure\",\"getDetourSetByCoords\",\"getDetourSetForModel\",\"updateFeatures\",\"filter\"],[[22,0,[\"model\",\"features\"]],[22,0,[\"model\",\"nocAreas\"]],[22,0,[\"model\",\"activeDetourSet\"]],[27,\"route-action\",[\"createDetour\"],null],[27,\"route-action\",[\"addClosure\"],null],[27,\"route-action\",[\"getDetourSetByCoords\"],null],[27,\"route-action\",[\"getDetourSetForModel\"],null],[27,\"action\",[[22,0,[]],\"updateFeatures\"],null],[23,[\"filter\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/nzta-detours/map.hbs"
    }
  });
  _exports.default = _default;
});