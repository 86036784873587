define("tmp-for-all/components/condition-groups-list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sjlw2GdF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"condition-group-name\"],[9],[0,\"\\n  \"],[1,[23,[\"group\",\"name\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"group\",\"isArchived\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-grey-dark text-12\"],[9],[0,\"(archived)\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"group\",\"isDefault\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"tick\",\"12px\",\"12px\",\"icon--green margin-right-5\"]]],false],[0,\" Yes\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"cross\",\"10px\",\"10px\",\"icon--red margin-right-5\"]]],false],[0,\" No\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"links-column\"],[9],[0,\"\\n  \"],[4,\"link-to\",[[23,[\"editConditionGroupLink\"]],[23,[\"group\"]]],[[\"class\"],[\"tertiary-action\"]],{\"statements\":[[0,\"Edit\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/condition-groups-list/item/template.hbs"
    }
  });
  _exports.default = _default;
});