define("tmp-for-all/components/shared/read-only-conditions/group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "M6+nvRhK",
    "block": "{\"symbols\":[\"condition\",\"index\"],\"statements\":[[7,\"label\"],[11,\"class\",\"group-label\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"group-name\"],[9],[1,[23,[\"group\",\"name\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"selectedGroupCondition\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"static-condition condition-\",[22,1,[\"id\"]]]]],[9],[0,\"\\n      \"],[7,\"strong\"],[9],[1,[27,\"add\",[[22,2,[]],[23,[\"startNumber\"]]],null],false],[0,\".\"],[10],[0,\" \"],[1,[22,1,[\"text\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/read-only-conditions/group/template.hbs"
    }
  });
  _exports.default = _default;
});