define("tmp-for-all/routes/tmps/submissions", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/mixins/routes/tmp-search-route-mixin", "tmp-for-all/constants"], function (_exports, _routeMixin, _tmpSearchRouteMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    RSVP = Ember.RSVP;
  var _default = Ember.Route.extend(_routeMixin.default, _tmpSearchRouteMixin.default, {
    queryFilter: computed.alias('model.queryFilter'),
    tmps: computed.alias('model.tmps'),
    session: Ember.inject.service(),
    queryParams: {
      page: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.filter = {
        where: {
          type: 'tmp-submission'
        }
      };
      var domainProperty = get(this, 'session.currentUser.selectedDomain') === _constants.DOMAIN_TYPES.JURISDICTION ? 'jurisdictionId' : 'companyId';
      var domainId = get(this, 'session.currentUser.selectedDomainId');
      if (domainProperty === 'companyId') {
        // company_id is a special param for a serverside join
        params.company_id = domainId;
      } else {
        // this route is not for rcaUsers
        this.transitionTo('index');
      }
      params.filter.order = params.order ? params.order : 'isDeclined DESC, submittedAt DESC';
      delete params.order;
      return RSVP.hash({
        tmps: this.findPaged('tmp-search-result', params),
        queryFilter: params.filter,
        companyId: params.company_id
      });
    }
  });
  _exports.default = _default;
});