define("tmp-for-all/helpers/permission/edit-documentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.editDocumentationCheck = editDocumentationCheck;
  function editDocumentationCheck(worksiteType) {
    return {
      'worksite-draft': 'editDraftWorksite',
      'worksite-submission': 'editSubmittedDocuments',
      worksite: 'editSubmittedDocuments',
      'tmp-draft': 'editDraftLayout',
      'tmp-submission': 'editSubmittedLayout',
      tmp: 'editAcceptedLayout'
    }[R.head(worksiteType)];
  }
  var _default = Ember.Helper.helper(editDocumentationCheck);
  _exports.default = _default;
});