define("tmp-for-all/components/x-modal-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XuhJwo3f",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"show\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\"],[true]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-orange big-heading\"],[9],[0,\"\\n      \"],[7,\"h2\"],[9],[1,[21,\"dialogTitle\"],false],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"float-right\"],[9],[0,\"\\n        \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"cross\",\"20px\",\"25px\",\"icon--black\"]]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-18 margin-btm-40\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"dialogHtml\"]]],null,{\"statements\":[[0,\"        \"],[1,[21,\"dialogHtml\"],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[21,\"dialogText\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"clearfix align-right\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"removeCancelLink\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"tertiary-action\"],[9],[1,[21,\"cancelText\"],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"button\"],[11,\"class\",\"primary-action primary-action--listed-right\"],[9],[0,\"\\n        \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"submit\"]]],false],[0,\" \"],[1,[21,\"confirmText\"],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"confirm\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/x-modal-dialog/template.hbs"
    }
  });
  _exports.default = _default;
});