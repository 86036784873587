define("tmp-for-all/routes/users/edit", ["exports", "tmp-for-all/mixins/company-search", "tmp-for-all/models/permission"], function (_exports, _companySearch, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP,
    computed = Ember.computed;
  function createAssignment(a, store) {
    var domainType = get(a, 'domainType');
    var domainId = get(a, 'domainId');
    return RSVP.hash({
      assignment: a,
      domainId: domainId,
      domainType: domainType,
      domainItem: domainId ? store.findRecord(domainType, domainId) : null,
      permission: get(a, 'permission'),
      user: get(a, 'user')
    });
  }
  var _default = Ember.Route.extend(_companySearch.default, {
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    modal: Ember.inject.service(),
    user: computed.alias('controller.model.user'),
    defaultDomain: computed.alias('user.permissionAssignments.firstObject'),
    relationsAreDirty: computed.alias('user.permissionAssignmentsIsDirty'),
    dataIsDirty: computed.or('user.hasChanges', 'relationsAreDirty'),
    afterSavePath: computed('controller.model.isSuperUser', function () {
      return get(this, 'controller.model.isSuperUser') ? 'users.list' : 'company-admin.edit-users';
    }),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (currentUser) {
        set(_this, 'currentUser', currentUser);
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'editUsers', get(currentUser, 'domain'));
      }).catch(function () {
        return _this.replaceWith('index');
      });
    },
    model: function model(params) {
      var _this2 = this;
      var that = this;
      function getResolvedAssignments(user) {
        return get(user, 'permissionAssignments').then(function (assignments) {
          return RSVP.all(assignments.map(function (a) {
            return createAssignment(a, that.store);
          }));
        }).then(function (assignments) {
          return RSVP.all(assignments.map(function (a) {
            return get(that, 'authorization').can('editUsers', get(a, 'domainItem')).then(function (isAllowed) {
              if (isAllowed) return a;
            });
          }));
        }).then(function (assignments) {
          return assignments.filter(function (a) {
            return a;
          });
        });
      }
      return this.store.findRecord('tmp-user', params.user_id).then(function (user) {
        return RSVP.hash({
          companyRoles: _this2.store.query('permission', _permission.default.companyRoleFilter),
          jurisdictionRoles: _this2.store.query('permission', _permission.default.jurisdictionRoleFilter),
          unscopedRoles: _this2.store.query('permission', _permission.default.unscopedRoleFilter),
          jurisdictions: _this2.store.findAll('jurisdiction'),
          user: user,
          assignments: getResolvedAssignments(user),
          currentUser: get(_this2, 'currentUser'),
          isSuperUser: get(_this2, 'currentUser.isSuperUser')
        });
      });
    },
    afterModel: function afterModel(_ref) {
      var user = _ref.user;
      // create snapshots of the permissionAssignments
      return get(user, 'permissionAssignments').then(function (assignments) {
        return RSVP.all(assignments.map(function (assignment) {
          return assignment.snapshotBelongsTo(['permission']);
        }));
      });
    },
    openModal: function openModal(user, transition) {
      var _this3 = this;
      this.set('modal.options', {
        title: 'Discard Changes?',
        text: 'You have unsaved changes for this user',
        confirmButton: 'Discard',
        confirmAction: function confirmAction() {
          _this3.cleanup().then(function () {
            _this3.get('modal').toggle();
            transition.retry();
          });
        }
      });
      this.get('modal').toggle();
    },
    cleanup: function cleanup() {
      var _this4 = this;
      var user = get(this, 'user');
      return user.reloadRelations(['permissionAssignments']).then(function () {
        return user.destroyOrRollback();
      }).then(function () {
        return _this4.reloadSideEffects();
      });
    },
    save: function save() {
      var _this5 = this;
      var user = get(this, 'user');
      return user.save()
      // Clean up permissionAssignments
      .then(function () {
        return RSVP.all(get(user, 'permissionAssignments').filter(function (assignment) {
          if (get(_this5, 'controller.model.isSuperUser')) return true;
          return get(_this5, 'currentUser.selectedDomain') === get(assignment, 'domainType') && get(_this5, 'currentUser.selectedDomainId') === get(assignment, 'domainId');
        }).map(function (assignment) {
          if (get(assignment, 'domainId') || get(assignment, 'permission') && get(assignment, 'permission.domainType') === null) {
            return assignment.save();
          } else {
            return assignment.destroyRecord();
          }
        }));
      })
      // Possibly reset the users selected domain
      .then(function () {
        var userDomainId = get(user, 'selectedDomainId');
        var userSelectedDomain = get(user, 'selectedDomain');
        var currentAssigned = get(user, 'permissionAssignments').find(function (item) {
          return get(item, 'domainType') === userSelectedDomain && get(item, 'domainId') === userDomainId;
        });
        if (!currentAssigned) {
          // The user's current selectedDomain has been removed, so set the next from the top of the list
          set(user, 'selectedDomain', get(_this5, 'defaultDomain.domainType'));
          set(user, 'selectedDomainId', get(_this5, 'defaultDomain.domainId'));
          return user.save();
        }
      }).then(function () {
        return _this5.reloadSideEffects();
      });
    },
    /**
     * Reload other app data affected by a user being edited
     * @returns {*}
     */
    reloadSideEffects: function reloadSideEffects() {
      return RSVP.all(this.store.peekAll('company').map(function (company) {
        return company.reloadRelations(['tmpUsers']);
      }));
    },
    actions: {
      addPermissionAssignment: function addPermissionAssignment() {
        var _this6 = this;
        var domainType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var newAssignment = this.store.createRecord('permission-assignment', {
          user: get(this, 'user'),
          domainType: domainType,
          domainId: get(this, 'currentUser.selectedDomainId')
        });
        createAssignment(newAssignment, this.store).then(function (assignment) {
          get(_this6, 'controller.model.assignments').pushObject(assignment);
        });
      },
      /**
       * Hack upon hack update: @pnw 2017-07-21
       * item is either
       * - a wrapper object around a permission assignment (from `createAssignment`) function (if the assignment is scoped)
       * - a permission assignment (if the assignment is unscoped)
       *
       * The two types of permission assignments are treated completely differently in the
       * role selections components.
       *
       * @param {PermissionAssignment|Object} item
       */
      deletePermissionAssignment: function deletePermissionAssignment(item) {
        if (item.assignment) {
          get(this, 'controller.model.assignments').removeObject(item);
          return item.assignment.deleteRecord();
        } else {
          item.deleteRecord();
        }
      },
      updatePermissionAssignment: function updatePermissionAssignment(assignment, domainId, permission) {
        assignment.setProperties({
          domainId: domainId,
          permission: permission
        });
      },
      saveUser: function saveUser() {
        var _this7 = this;
        this.save().then(function () {
          get(_this7, 'flashMessages').success('User was updated successfully.');
          _this7.transitionTo(get(_this7, 'afterSavePath'));
        }).catch(function () {
          get(_this7, 'flashMessages').danger('There was a problem updating this User.');
        });
      },
      toggleArchiveState: function toggleArchiveState(user) {
        var _this8 = this;
        var isNowArchived = user.toggleProperty('isArchived');
        var message = isNowArchived ? 'User has been archived.' : 'User has been un-archived.';
        this.save().then(function () {
          get(_this8, 'flashMessages').success(message);
          _this8.transitionTo(get(_this8, 'afterSavePath'));
        }).catch(function () {
          get(_this8, 'flashMessages').danger('There was a problem archiving this User.');
        });
      },
      becomeUser: function becomeUser(user) {
        this.get('session').authenticate('authenticator:superuser', user.id).then(function () {
          window.location.reload();
        });
      },
      willTransition: function willTransition(transition) {
        var user = get(this, 'user');
        if (get(this, 'dataIsDirty')) {
          transition.abort();
          this.openModal(user, transition);
        }
      },
      resendInvite: function resendInvite(user) {
        var _this9 = this;
        user.resendInvite().then(function () {
          var successMessage = 'Invite email resent successfully!';
          get(_this9, 'flashMessages').success(successMessage);
        });
      }
    }
  });
  _exports.default = _default;
});