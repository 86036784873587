define("tmp-for-all/components/worksite/ownership-form/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var applicationTypes = Object.keys(_constants.WORKSITE_APPLICATION_TYPES).map(function (key) {
    return {
      name: _constants.WORKSITE_APPLICATION_TYPES[key],
      value: key
    };
  });
  var _default = Ember.Component.extend({
    inWizard: false,
    authManager: Ember.inject.service('session'),
    toolTips: {
      name: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_OWNERSHIP.NAME),
      rca: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_OWNERSHIP.RCA),
      purchaseOrder: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_DETAILS.PURCHASE_ORDER)
    },
    worksite: computed.alias('model.worksite'),
    clientCompany: computed.alias('model.clientCompany'),
    jurisdictions: computed.alias('model.jurisdictions'),
    clientContact: computed.alias('model.clientContact'),
    jurisdictionIsCCC: computed('worksite.jurisdiction.name', function () {
      return get(this, 'worksite.jurisdiction.name') === _constants.JURISDICTION_NAMES.CCC || get(this, 'worksite.jurisdiction.name') === _constants.JURISDICTION_NAMES.CCCERC;
    }),
    applicationTypeOptions: applicationTypes,
    selectedApplicationType: computed('worksite.applicationType', function () {
      var _this = this;
      return applicationTypes.find(function (ato) {
        return ato.value === get(_this, 'worksite.applicationType');
      });
    }),
    isGlobalPermit: computed.equal('worksite.applicationType', _constants.WORKSITE_APPLICATION_TYPE_KEYS.GLOBALPERMIT),
    newCompany: {},
    hasTmps: computed('worksite.tmps', function () {
      return get(this, 'worksite.tmps').length > 0;
    }),
    actions: {
      next: function next(worksite) {
        var _this2 = this;
        get(worksite, 'company').then(function (company) {
          if (worksite.isNew && !company) {
            get(_this2, 'authManager.currentUser').then(function (user) {
              var domain = get(user, 'domain');
              set(worksite, 'company', domain);
              _this2.next(worksite);
            });
          } else {
            _this2.next(worksite);
          }
        });
      },
      selectApplicationType: function selectApplicationType(appType) {
        set(this, 'worksite.applicationType', appType.value);
        if (get(this, 'isGlobalPermit') && get(this, 'worksite.jurisdiction')) {
          set(this, 'model.worksite.address', get(this, 'worksite.jurisdiction.address'));
          set(this, 'model.worksite.location', get(this, 'worksite.jurisdiction.location'));
        }
      }
    }
  });
  _exports.default = _default;
});