define("tmp-for-all/templates/tmp-submission/view/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DSsHI07+",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"maps/lane-selector\",null,[[\"worksite\",\"tmpLayout\",\"clashes\",\"readOnly\",\"onCancel\",\"onUpdateImpacts\",\"onError\",\"onClickClashFeature\",\"updateClashes\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"layout\"]],[23,[\"model\",\"clashes\"]],true,[27,\"route-action\",[\"close\"],null],[27,\"route-action\",[\"noop\"],null],[27,\"route-action\",[\"error\"],null],[27,\"route-action\",[\"getTooltipTemplate\"],null],[27,\"route-action\",[\"updateClashes\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmp-submission/view/map.hbs"
    }
  });
  _exports.default = _default;
});