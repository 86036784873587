define("tmp-for-all/components/page-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b1qW5rpU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[12,\"class\",[28,[[21,\"headingTestClass\"]]]],[9],[1,[21,\"headingText\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"subHeadingText\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h2\"],[11,\"class\",\"sub-heading\"],[9],[1,[21,\"subHeadingText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/page-heading/template.hbs"
    }
  });
  _exports.default = _default;
});