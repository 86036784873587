define("tmp-for-all/components/companies-view/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    isEmpty = Ember.isEmpty;
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    currentUser: computed.alias('authManager.currentUser'),
    hideFirstCol: computed('company.url', 'company.contact', 'company.phone', function () {
      var allFieldsAreEmpty = isEmpty(get(this, 'company.url')) && isEmpty(get(this, 'company.contact')) && isEmpty(get(this, 'company.phone'));
      return allFieldsAreEmpty;
    }),
    supportEmail: _environment.default.supportEmail
  });
  _exports.default = _default;
});