define("tmp-for-all/models/condition-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    get = _Ember.get,
    computed = _Ember.computed;
  var _default = _emberData.default.Model.extend({
    name: attr('string'),
    isDefault: attr('boolean'),
    isArchived: attr('boolean'),
    conditions: hasMany('condition', {
      async: true
    }),
    nonArchivedConditions: computed.filter('conditions', function (condition) {
      return !get(condition, 'isArchived');
    }),
    conditionType: attr('string'),
    conditionGroupToConditions: hasMany('condition-group-to-condition', {
      async: true
    }),
    jurisdiction: belongsTo('jurisdiction')
  });
  _exports.default = _default;
});