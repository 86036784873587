define("tmp-for-all/models/company-search-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _emberData.default.Model.extend({
    contact: attr('string'),
    isArchived: attr('boolean'),
    name: attr('string'),
    physicalAddress: attr('string'),
    url: attr('string'),
    customerNumber: attr('string'),
    jurisdictionId: attr('number'),
    jurisdictionName: attr('string'),
    jurisdictionDisplayName: attr('string'),
    isRelatedParty: attr('boolean'),
    modelId: Ember.computed.alias('id'),
    modelName: Ember.computed.alias('type')
  });
  _exports.default = _default;
});