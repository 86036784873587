define("tmp-for-all/models/documentation-block", ["exports", "ember-data", "tmp-for-all/classes/file-wrapper"], function (_exports, _emberData, _fileWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _Ember = Ember,
    get = _Ember.get,
    RSVP = _Ember.RSVP,
    computed = _Ember.computed;
  var _default = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),
    files: hasMany('file', {
      async: true
    }),
    worksite: belongsTo('worksite'),
    worksiteDraft: belongsTo('worksite-draft'),
    worksiteSubmission: belongsTo('worksite-submission'),
    type: attr('string'),
    text: attr('string'),
    filesForUpload: computed(function () {
      return _fileWrapper.FileWrapperArrayProxy.create({
        content: []
      });
    }),
    hasDirtyFilesForUpload: computed.alias('filesForUpload.isInvalid').readOnly(),
    extraDirtyCheck: computed.notEmpty('filesForUpload'),
    saveFiles: function saveFiles() {
      return get(this, 'ajax').documentBlockAction(this, 'upload-file');
    },
    resetUploadList: function resetUploadList() {
      get(this, 'filesForUpload').clear();
    },
    persistDeletedFiles: function persistDeletedFiles() {
      return RSVP.all(get(this, 'files').map(function (file) {
        return get(file, 'isDeleted') ? file.save() : null;
      }));
    }
  });
  _exports.default = _default;
});