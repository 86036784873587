define("tmp-for-all/templates/condition-groups/tmp/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BCIldYXO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"TMP Condition Groups\"]],{\"statements\":[[0,\"  \"],[1,[27,\"condition-groups-list\",null,[[\"groups\",\"conditionType\"],[[23,[\"model\",\"conditionGroups\"]],[23,[\"model\",\"conditionType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/condition-groups/tmp/list.hbs"
    }
  });
  _exports.default = _default;
});