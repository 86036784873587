define("tmp-for-all/components/search-results/organisations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PsbGnz/m",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"search-results/organisations/list\",null,[[\"companies\",\"showArchived\"],[[23,[\"results\"]],[23,[\"showArchived\"]]]]],false],[0,\"\\n\\n\"],[1,[27,\"page-numbers\",null,[[\"content\",\"action\"],[[23,[\"results\"]],[27,\"action\",[[22,0,[]],[23,[\"handlePaginationClick\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if-permitted\",[\"createCompany\"],null,{\"statements\":[[4,\"shared/fixed-actions-bar\",null,null,{\"statements\":[[4,\"link-to\",[\"companies.create\"],[[\"class\"],[\"primary-action\"]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"plus\"]]],false],[0,\" Add organisation\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/organisations/template.hbs"
    }
  });
  _exports.default = _default;
});