define("tmp-for-all/helpers/format-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatPrice = formatPrice;
  function formatPrice(params) {
    var totalPrice = params[0] * params[1];
    var dollarsAndCents = typeof params[0] === 'number' ? (totalPrice / 100).toFixed(2) : totalPrice.toFixed(2);
    return '$' + dollarsAndCents;
  }
  var _default = Ember.Helper.helper(formatPrice);
  _exports.default = _default;
});