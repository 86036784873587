define("tmp-for-all/components/worksite/modify-warranty/warranty-end-date-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    date: null,
    init: function init() {
      var _return = this._super.apply(this, arguments);
      set(this, 'date', get(this, 'warrantyEndDate'));
      set(this, 'disabledDateSelector', true);
      var workCompletion = moment(get(this, 'workCompletionDate')).freeze();
      set(this, 'minDate', workCompletion.toDate());
      return _return;
    },
    actions: {
      selectTwoYears: function selectTwoYears() {
        var workCompletion = moment(get(this, 'workCompletionDate')).add(2, 'year');
        set(this, 'disabledDateSelector', true);
        get(this, 'selectDate')(workCompletion.format());
      },
      selectNoWarranty: function selectNoWarranty() {
        set(this, 'disabledDateSelector', true);
        get(this, 'selectDate')(null);
      },
      selectCustom: function selectCustom() {
        set(this, 'disabledDateSelector', false);
        set(this, 'warrantyEndDate', get(this, 'date'));
      },
      userPickDate: function userPickDate(date) {
        get(this, 'selectDate')(moment(date).format());
        set(this, 'date', date);
      }
    }
  });
  _exports.default = _default;
});