define("tmp-for-all/models/fee", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    get = _Ember.get,
    computed = _Ember.computed;
  var _default = _emberData.default.Model.extend({
    itemCode: attr('string'),
    name: attr('string'),
    description: attr('string'),
    accountingCode: attr('string'),
    notes: attr('string'),
    quantityCalculatedFrom: attr('string'),
    defaultQuantity: attr('string'),
    defaultUnit: attr('string'),
    costs: hasMany('cost', {
      async: true
    }),
    jurisdiction: belongsTo('jurisdiction', {
      async: true
    }),
    costsBeforeNow: computed.filter('costs', function (cost) {
      return moment().isAfter(get(cost, 'startsAt'));
    }),
    sortedCostsBeforeNow: computed('costsBeforeNow.[]', function () {
      return get(this, 'costsBeforeNow').sort(function (a, b) {
        return get(b, 'startsAt') - get(a, 'startsAt');
      });
    }),
    currentCost: computed('sortedCostsBeforeNow.[]', function () {
      return get(this, 'sortedCostsBeforeNow')[0];
    }),
    searchString: computed('itemcode', 'name', function () {
      return "".concat(get(this, 'itemCode'), " ").concat(get(this, 'name'));
    })
  });
  _exports.default = _default;
});