define("tmp-for-all/components/search-results/worksites/map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aglN0BsR",
    "block": "{\"symbols\":[\"worksite\"],\"statements\":[[4,\"leaflet-map\",null,[[\"bounds\",\"scrollWheelZoom\",\"class\",\"id\",\"onLoad\",\"attributionControl\"],[[23,[\"bounds\"]],true,\"map-container margin-btm-10\",\"worksite-search-map\",[27,\"action\",[[22,0,[]],\"mapDidLoad\"],null],false]],{\"statements\":[[4,\"if\",[[23,[\"mapboxStreetsLayer\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"maps/mapbox-streets-tile-layer\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"mapboxSatelliteLayer\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"maps/mapbox-satellite-tile-layer\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"linzAerialLayer\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"maps/linz-aerial-tile-layer\"],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[4,\"each\",[[23,[\"worksites\"]]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[22,1,[\"location\"]],[27,\"not\",[[27,\"eq\",[[22,1,[\"applicationType\"]],\"GLOBALPERMIT\"],null]],null]],null]],null,{\"statements\":[[0,\"      \"],[4,\"geojson-layer\",null,[[\"geoJSON\"],[[22,1,[\"location\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"worksiteFocus\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"secondary-action float-right\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"secondary-action__icon-left\",\"cross\"]]],false],[0,\" Remove focus\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"showAllLocations\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"shared/form/radio-buttons\",null,[[\"groupLabel\",\"options\",\"onChange\"],[\"Select Tile Layer\",[23,[\"tileLayerOptions\"]],[23,[\"setMapStyle\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/worksites/map/template.hbs"
    }
  });
  _exports.default = _default;
});