define("tmp-for-all/templates/users/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "R9jV2vBq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"isFullWidth\",\"isListingPage\"],[true,true]],{\"statements\":[[0,\"\\n  \"],[4,\"listing-page-heading\",null,null,{\"statements\":[[0,\"Users\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"search-parameters\",null,[[\"disableFilters\",\"freetext\",\"searchType\",\"setSearchText\",\"showArchivedUpdated\",\"setOrgType\",\"orgType\"],[true,[23,[\"search\"]],\"user-search-results\",[27,\"action\",[[22,0,[]],\"searchUpdated\"],null],[27,\"action\",[[22,0,[]],\"showArchivedUpdated\"],null],[27,\"action\",[[22,0,[]],\"setOrgType\"],null],[23,[\"orgType\"]]]]],false],[0,\"\\n\\n  \"],[1,[27,\"search-results\",null,[[\"handlePaginationClick\",\"results\",\"searchType\",\"becomeUser\"],[[27,\"action\",[[22,0,[]],\"pageClicked\"],null],[23,[\"model\"]],\"user-search-results\",[27,\"route-action\",[\"becomeUser\"],null]]]],false],[0,\"\\n\\n\"],[4,\"shared/fixed-actions-bar\",null,null,{\"statements\":[[4,\"if-permitted\",[\"admin\"],[[\"model\"],[[23,[\"authManager\",\"currentUser\",\"domain\"]]]],{\"statements\":[[4,\"link-to\",[\"users.create\"],[[\"class\"],[\"primary-action primary-action--listed-right\"]],{\"statements\":[[0,\"        \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"plus\"]]],false],[0,\" Add user\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"link-to\",[\"company-admin.invite-user\"],[[\"class\"],[\"primary-action primary-action--listed-right\"]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"mail\"]]],false],[0,\" Invite user\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/users/list.hbs"
    }
  });
  _exports.default = _default;
});