define("tmp-for-all/components/planning-map/detail-popup/worksite/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    set = Ember.set,
    get = Ember.get;
  var tabs = {
    DETAILS: 'DETAILS',
    CONTACTS: 'CONTACTS',
    WORKITEMS: 'WORKITEMS'
  };
  function getWorksiteUrlEnding(type) {
    return {
      worksite: '',
      'worksite-submission': '/submission',
      'worksite-draft': '/draft'
    }[type];
  }
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      get(this, 'model.globalPermitLayout.tmp.latestWorksite'); // Fire off global permit parent requests
    },

    tabs: tabs,
    activeTab: tabs.DETAILS,
    isDetailTabActive: computed.equal('activeTab', tabs.DETAILS),
    isContactsTabActive: computed.equal('activeTab', tabs.CONTACTS),
    isWorkItemsTabActive: computed.equal('activeTab', tabs.WORKITEMS),
    globalPermitWorksite: computed('model.globalPermitLayout.tmp.latestWorksite.isPending', function () {
      if (get(this, 'model.globalPermitLayout.tmp.latestWorksite.isPending')) return null;
      return get(this, 'model.globalPermitLayout.tmp.latestWorksite');
    }),
    globalPermitTmp: computed('model.globalPermitLayout.tmp.isPending', function () {
      if (get(this, 'model.globalPermitLayout.tmp.isPending')) return null;
      return get(this, 'model.globalPermitLayout.tmp');
    }),
    globalPermitLayout: computed('model.globalPermitLayout.isPending', function () {
      if (get(this, 'model.globalPermitLayout.isPending')) return null;
      return get(this, 'model.globalPermitLayout');
    }),
    worksiteLink: computed('model.{id,constructorName}', function () {
      var model = get(this, 'model');
      if (!model) return '';
      return "/worksites/".concat(get(model, 'id')).concat(getWorksiteUrlEnding(get(model, 'constructor.modelName')));
    }),
    actions: {
      setActiveTab: function setActiveTab(type) {
        set(this, 'activeTab', type);
      }
    }
  });
  _exports.default = _default;
});