define("tmp-for-all/routes/worksite-submission/edit/ownership/new-company", ["exports", "tmp-for-all/mixins/routes/modals/new-company-route"], function (_exports, _newCompanyRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _newCompanyRoute.default;
    }
  });
});