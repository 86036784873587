define("tmp-for-all/components/if-permitted/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = Ember.Component,
    computed = Ember.computed,
    get = Ember.get,
    isEmpty = Ember.isEmpty;
  var IfPermittedComponent = Component.extend({
    tagName: '',
    authorization: Ember.inject.service(),
    isPermitted: computed('authorization.permissionChangeFlag', 'permission', 'model', function () {
      var permission = this.get('permission');
      var model = get(this, 'model');
      if (!permission) return true;

      // If the permission cache doesn't have a value for this permission, then go and get it
      // When it is populated it will caused this computed property to fire again, and then we can render the correct
      // value to the template
      // Implements the caching, so no server requests made once the permissionCache object is populated

      var cachedPermission = get(this, 'authorization').getCachedPermission(permission, model);
      if (isEmpty(cachedPermission)) {
        this.get('authorization').can(permission, model);
        return false;
      }
      return cachedPermission;
    })
  });
  IfPermittedComponent.reopenClass({
    positionalParams: ['permission']
  });
  var _default = IfPermittedComponent;
  _exports.default = _default;
});