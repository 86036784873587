define("tmp-for-all/components/pill-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AMeA7oSj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"handleDeleteClick\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"pill-box__delete-btn\"],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\"],[\"cross\",\"13px\",\"13px\"]]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"handleDeleteClick\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"handleTextClick\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"pill-box__text pill-box__text--has-action\"],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"handleTextClick\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"pill-box__text\"],[9],[14,1],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/pill-box/template.hbs"
    }
  });
  _exports.default = _default;
});