define("tmp-for-all/components/search-results/deployments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Yt9ff8AW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"results\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"search-results/deployments/list\",null,[[\"deployments\",\"setOrder\",\"order\",\"changeDeployment\",\"isSTMS\"],[[23,[\"results\"]],[23,[\"setOrder\"]],[23,[\"order\"]],[23,[\"changeDeployment\"]],[23,[\"isSTMS\"]]]]],false],[0,\"\\n  \"],[1,[27,\"page-numbers\",null,[[\"content\"],[[23,[\"results\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-14 text-grey-dark\"],[9],[0,\"No Deployments matched current search filters. Please refine your search and try again.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/deployments/template.hbs"
    }
  });
  _exports.default = _default;
});