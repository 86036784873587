define("tmp-for-all/routes/worksites/create/wizard/documentation", ["exports", "tmp-for-all/mixins/routes/edit-worksite/documentation-route-mixin"], function (_exports, _documentationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_documentationRouteMixin.default, {
    nextStep: 'worksites.create.wizard.contacts',
    finishedTarget: 'worksite-draft'
  });
  _exports.default = _default;
});