define("tmp-for-all/components/condition-groups-list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['condition-group-list-item'],
    editConditionGroupLink: computed('conditionType', function () {
      return "condition-groups.".concat(get(this, 'conditionType'), ".edit");
    })
  });
  _exports.default = _default;
});