define("tmp-for-all/components/form-field/radios/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Km8m/Q5n",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"form-field/radios/radio\",null,[[\"name\",\"value\",\"label\",\"checked\",\"disabled\",\"useButtonStyle\",\"onChange\"],[[23,[\"name\"]],[22,1,[\"value\"]],[22,1,[\"label\"]],[22,1,[\"checked\"]],[27,\"or\",[[23,[\"disabled\"]],[22,1,[\"disabled\"]]],null],[23,[\"useButtonStyle\"]],[27,\"action\",[[22,0,[]],\"onChange\",[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/form-field/radios/template.hbs"
    }
  });
  _exports.default = _default;
});