define("tmp-for-all/templates/worksite/work/stop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9HFgg3vo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\"],[true]],{\"statements\":[[0,\"  \"],[1,[27,\"worksite/stop-work\",null,[[\"worksite\",\"formContent\",\"save\",\"cancel\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"formContent\"]],[27,\"route-action\",[\"save\"],null],[27,\"route-action\",[\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite/work/stop.hbs"
    }
  });
  _exports.default = _default;
});