define("tmp-for-all/translations/en-nz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "terms.disclaimer": "",
    "terms.jurisdiction": "These Terms of Use are a legal agreement. They are governed by the laws of New Zealand, and You submit to the exclusive jurisdiction of the New Zealand courts for any matter or dispute arising in relation to these Terms.",
    "user.form.address": "445 Mount Eden Road, Mount Eden, Auckland",
    "user.form.postal": "PO Box 39100, Howick, Auckland"
  };
  _exports.default = _default;
});