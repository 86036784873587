define("tmp-for-all/components/tmp-view/conditions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "48rxKxKS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"editable\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"shared/add-conditions\",null,[[\"conditionGroups\",\"selectedConditions\",\"addCondition\",\"removeCondition\",\"parent\",\"jurisdiction\",\"saveCustomCondition\",\"removeCustomCondition\",\"filterId\"],[[23,[\"filteredConditionGroups\"]],[23,[\"filteredConditions\"]],[23,[\"addCondition\"]],[23,[\"removeCondition\"]],[23,[\"tmp\"]],[23,[\"jurisdiction\"]],[23,[\"saveCustomCondition\"]],[23,[\"removeCustomCondition\"]],[23,[\"filterId\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"align-right\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"secondary-action secondary-action--listed-right\"],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"secondary-action__icon-left\",\"submit\"]]],false],[0,\" Save conditions\\n    \"],[3,\"action\",[[22,0,[]],[23,[\"saveConditions\"]],[23,[\"tmp\"]],[23,[\"filteredConditions\"]],[23,[\"filterId\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"shared/read-only-conditions\",null,[[\"conditionGroups\",\"selectedConditions\",\"parent\",\"jurisdiction\",\"filterId\"],[[23,[\"filteredConditionGroups\"]],[23,[\"filteredConditions\"]],[23,[\"tmp\"]],[23,[\"jurisdiction\"]],[23,[\"filterId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/tmp-view/conditions/template.hbs"
    }
  });
  _exports.default = _default;
});