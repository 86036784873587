define("tmp-for-all/helpers/format-time", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatTime = formatTime;
  function formatTime(params) {
    var date = params[0] || new Date();
    var format = params[1] || "".concat(_environment.default.dateFormat, " ").concat(_environment.default.timeFormat);
    var dateTime = moment(date).format(format);
    var fromNow = moment(date).fromNow();
    return "".concat(dateTime, " ").concat(fromNow);
  }
  var _default = Ember.Helper.helper(formatTime);
  _exports.default = _default;
});