define("tmp-for-all/translations/en-au", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "terms.disclaimer": "Please note that these terms and conditions are from New Zealand and are being reviewed for use in Australia.",
    "terms.jurisdiction": "These Terms of Use are a legal agreement.",
    "user.form.address": "220 Collins St, Manchester Unity Building, Melbourne, Victoria 3000",
    "user.form.postal": "PO Box 914, Geelong, Vic 3220"
  };
  _exports.default = _default;
});