define("tmp-for-all/components/shared/show-hide-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fz6Ass3T",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"heading-block underline-coloured-\",[21,\"color\"],\" has-tab-btn\"]]],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"classNames\",\"height\",\"width\"],[[23,[\"icon\"]],[27,\"concat\",[\"icon--in-heading icon--\",[23,[\"color\"]]],null],\"28px\",\"22px\"]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"tab-btn unstyle-btn\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showHideElementIsVisible\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\"],[\"chevron-up\",\"20px\",\"20px\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\"],[\"chevron-down\",\"20px\",\"20px\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[3,\"action\",[[22,0,[]],\"toggleShowHideElement\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showHideElementIsVisible\"]]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"show-hide-element\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"expand-baseline\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"secondary-action t-show-expand-contract\"],[9],[0,\"Show \"],[1,[21,\"buttonText\"],false],[3,\"action\",[[22,0,[]],\"toggleShowHideElement\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/show-hide-box/template.hbs"
    }
  });
  _exports.default = _default;
});