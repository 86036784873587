define("tmp-for-all/instance-initializers/ember-preferences", ["exports", "ember-preferences/setup"], function (_exports, _setup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(application) {
    var registry;

    // FIXME: We test the application to know if we're using ember 1.12, 1.13 or +2.0
    if (application.inject) {
      registry = application;
    } else if (application.registry && application.registry.injection) {
      registry = application.registry;
    } else if (application.container && application.container.injection) {
      registry = application.container;
    } else {
      registry = application;
    }
    (0, _setup.inject)(registry);
  }
  var _default = {
    name: 'ember-preferences',
    initialize: initialize
  };
  _exports.default = _default;
});