define("tmp-for-all/models/viewed-alert", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    tmpUser: belongsTo('tmp-user'),
    alert: belongsTo('alert')
  });
  _exports.default = _default;
});