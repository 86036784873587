define("tmp-for-all/models/tmp-base", ["exports", "ember-data", "tmp-for-all/constants", "tmp-for-all/mixins/models/private-files-mixin"], function (_exports, _emberData, _constants, _privateFilesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _Ember = Ember,
    computed = _Ember.computed,
    get = _Ember.get;
  var _default = _emberData.default.Model.extend(_privateFilesMixin.default, {
    tmpComments: hasMany('tmp-comment', {
      async: true
    }),
    history: hasMany('history', {
      async: true
    }),
    tmpCode: attr('string'),
    createdAt: attr('date'),
    acceptedAt: attr('date'),
    approvedAt: attr('date'),
    declinedAt: attr('date'),
    isAccepted: attr('boolean', {
      defaultValue: false
    }),
    isApproved: attr('boolean', {
      defaultValue: false
    }),
    isDeclined: attr('boolean', {
      defaultValue: false
    }),
    isSubmitted: attr('boolean', {
      defaultValue: false
    }),
    isCrossRcaApproval: attr('boolean', {
      defaultValue: false
    }),
    majorRevisionNumber: attr('number', {
      defaultValue: 0
    }),
    minorRevisionNumber: attr('number', {
      defaultValue: 0
    }),
    updatedAt: attr('date'),
    submittedAt: attr('date'),
    isGenericTmp: attr('boolean'),
    jurisdiction: belongsTo('jurisdiction', {
      async: true
    }),
    secondaryJurisdictions: hasMany('tmp-to-secondary-jurisdiction'),
    currentSecondaryRcaId: attr('number'),
    company: belongsTo('company', {
      async: true
    }),
    latestWorksite: belongsTo('worksite-base', {
      async: true,
      polymorphic: true
    }),
    hasLatestWorksite: computed('latestWorksite', function () {
      return get(this, 'latestWorksite').then(function (ws) {
        return true;
      }).catch(function (e) {
        return false;
      });
    }),
    previousRevisions: computed('latestWorksite', function () {
      var _this = this;
      return _emberData.default.PromiseArray.create({
        promise: get(this, 'latestWorksite').then(function (worksite) {
          return worksite ? get(worksite, 'history') : Ember.RSVP.resolve();
        }).then(function (worksiteHistories) {
          if (worksiteHistories) {
            // A draft cannot be a previous revision, it is always the latest revision, so when
            // looking at the history we want to just look at accepted and submitted events.
            // Also make sure that we do not include the current revision number.
            // Also if there was a history entry with the same type as the latest history entry, then
            // that previous revision does not exist any more.  E.g. if we are on submission 3.0
            // and there was an accepted 1.1 and a submitted 2.0, then the 2.0 submission has been
            // overwritten by the 3.0 submission, so the previous revision we can retrieve is 1.1
            // Confused yet?

            // Just in case there is no history event for the current revision number, use a loop,
            // and count backwards to the history entry closest to our latest revision number.
            // Otherwise we can get null errors if, for instance, latestWorksite is a draft and
            // there is therefore no worksiteHistory for its revision number.
            var latestEvent;
            var maj = get(_this, 'latestWorksite.majorRevisionNumber');
            var min = get(_this, 'latestWorksite.getMinorRevisionNumber');
            while (!latestEvent && maj >= 0 && min >= 0) {
              latestEvent = worksiteHistories.find(function (h) {
                return maj === h.maj && min === h.min;
              });
              if (min === 0) {
                maj = maj - 1;
              } else {
                min = min - 1;
              }
            }
            return [].concat(worksiteHistories.filterBy('eventType', 'Accepted').toArray()).concat(worksiteHistories.filterBy('eventType', 'Submitted').toArray()).filter(function (wh) {
              return !!latestEvent && wh.id !== latestEvent.id && wh.eventType !== latestEvent.eventType;
            });
          }
          return [];
        })
      });
    }),
    latestSubmittedOrAccepted: computed('previousRevisions', function () {
      var _this2 = this;
      return _emberData.default.PromiseObject.create({
        promise: get(this, 'previousRevisions').then(function (res) {
          var latest = R.pipe(R.flatten, R.reject(R.isNil), R.sortBy(R.props(['majorRevisionNumber', 'minorRevisionNumber'])), R.last)(res || []);
          if (latest) {
            return _this2.store.findRecord('worksite-history', latest.id).then(function (record) {
              if (record.eventType === 'Accepted') {
                return _this2.store.findRecord('worksite', record.worksiteNumber);
              } else {
                return _this2.store.findRecord('worksite-submission', record.worksiteNumber);
              }
            });
          } else {
            return undefined;
          }
        })
      });
    }),
    worksiteId: attr('number'),
    layouts: hasMany('layout', {
      async: true
    }),
    customConditions: attr(),
    acceptedComment: belongsTo('tmp-comment', {
      async: true
    }),
    declinedComment: belongsTo('tmp-comment', {
      async: true
    }),
    permittedCompanies: hasMany('company', {
      async: true,
      inverse: null
    }),
    files: hasMany('file', {
      async: true
    }),
    description: attr('string'),
    historyWithWorksiteNotices: computed('history', 'latestWorksite', function () {
      var _this3 = this;
      return _emberData.default.PromiseArray.create({
        promise: get(this, 'latestWorksite').then(function (worksite) {
          // Unrelated client tests fail without this check
          return worksite ? get(worksite, 'history') : Ember.RSVP.resolve();
        }).then(function (worksiteHistory) {
          return Ember.RSVP.hash({
            worksiteHistory: worksiteHistory,
            history: get(_this3, 'history')
          });
        }).then(function (_ref) {
          var worksiteHistory = _ref.worksiteHistory,
            history = _ref.history;
          if (worksiteHistory) {
            return history.toArray().concat(worksiteHistory.filterBy('category', _constants.HISTORY_ITEM_CATEGORIES.NOTICE).toArray());
          }
          return history;
        })
      });
    }),
    historySorting: ['createdAt:desc'],
    sortedHistoryDesc: computed.sort('historyWithWorksiteNotices', 'historySorting'),
    status: computed('isSubmitted', 'isAccepted', 'isDeclined', function () {
      if (get(this, 'isDeclined')) return 'Declined';
      if (get(this, 'isAccepted')) return 'Accepted';
      if (get(this, 'isSubmitted')) return 'Submitted';
      return 'Draft';
    }),
    inApproval: computed.and('currentSecondaryRcaId', 'isCrossRcaApproval'),
    primaryAcceptedAt: attr('date'),
    isInitialRevision: computed.equal('majorRevisionNumber', 0),
    isNotInitialRevision: computed.not('isInitialRevision'),
    commentService: Ember.inject.service(),
    saveFiles: function saveFiles() {
      var _this4 = this;
      return get(this, 'ajax').tmpAction(this, 'upload-file').then(function () {
        return _this4.persistFiles();
      }).then(function () {
        return get(_this4, 'files').reload();
      }).then(function () {
        return _this4;
      });
    },
    addComment: function addComment(text, files) {
      return get(this, 'commentService').addComment(this, text, files);
    }
  });
  _exports.default = _default;
});