define("tmp-for-all/components/shared/add-user/component", ["exports", "tmp-for-all/mixins/components/check-existing-user-mixin"], function (_exports, _checkExistingUserMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend(_checkExistingUserMixin.default, {
    contact: computed.alias('user'),
    textFieldDisabled: computed('user.{id,email}', 'searching', function () {
      return !get(this, 'user.email') || get(this, 'searching') || get(this, 'isExistingUser');
    })
  });
  _exports.default = _default;
});