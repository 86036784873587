define("tmp-for-all/components/organisations-selector/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    hasLoadedCompanyContacts: computed('companyContacts', 'didSelectByDropdown', function () {
      var companyContacts = get(this, 'companyContacts');
      var didSelectByDropdown = get(this, 'didSelectByDropdown');
      return didSelectByDropdown && companyContacts && companyContacts.length ? '' : 'hide';
    }),
    hasEmptyCompanyContacts: computed('companyContacts', 'didSelectByDropdown', function () {
      var companyContacts = get(this, 'companyContacts');
      var didSelectByDropdown = get(this, 'didSelectByDropdown');
      return didSelectByDropdown && companyContacts && companyContacts.length === 0 ? '' : 'hide';
    }),
    supportEmail: _environment.default.supportEmail,
    actions: {
      selectCompany: function selectCompany(company) {
        var _this = this;
        set(this, 'didSelectByDropdown', true);
        set(this, 'selectedCompany', company);
        return get(this, 'ajax').getAdminUsers(company).then(function (adminTeam) {
          set(_this, 'companyContacts', adminTeam);
        }).catch(function (err) {
          console.error('getAdminUsers for company is down', company, err);
          get(_this, 'flashMessages').danger("Error getting admin users for ".concat(company.name));
        });
      }
    }
  });
  _exports.default = _default;
});