define("tmp-for-all/components/svg-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Jl23YZMB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[12,\"class\",[28,[\"icon__svg icon__svg--\",[21,\"type\"]]]],[12,\"style\",[21,\"styleAttr\"]],[9],[0,\"\\n  \"],[7,\"use\"],[12,\"xlink:href\",[28,[[21,\"path\"],\"/assets/icons/icons.svg#svg-\",[21,\"type\"]]],\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/svg-icon/template.hbs"
    }
  });
  _exports.default = _default;
});