define("tmp-for-all/components/tmp-view/layouts/list/item/component", ["exports", "tmp-for-all/utils/dates"], function (_exports, _dates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    classNames: ['panel', 'bg-grey-light', 'margin-btm-20', 'layout-list-item'],
    modal: Ember.inject.service(),
    schedulingDeployment: false,
    session: Ember.inject.service(),
    currentUser: computed.alias('session.currentUser'),
    isSTMS: computed.equal('currentUser.role', 'stms'),
    showLayoutDatesWarning: computed('tmpLayout.startDate', 'tmpLayout.endDate', function () {
      return (0, _dates.dateOnly)(get(this, 'tmp.latestWorksite.startDate')) > (0, _dates.dateOnly)(get(this, 'tmpLayout.startDate')) || (0, _dates.dateOnly)(get(this, 'tmp.latestWorksite.endDate')) < (0, _dates.dateOnly)(get(this, 'tmpLayout.endDate'));
    }),
    actions: {
      openModal: function openModal(layout) {
        var _this = this;
        this.set('modal.options', {
          title: 'Delete Layout?',
          text: 'Are you sure you want to delete this layout?',
          confirmButton: 'Delete',
          confirmAction: function confirmAction() {
            layout.destroyRecord().then(function () {
              _this.get('modal').toggle();
              get(_this, 'flashMessages').success('Layout has been deleted.');
            }).catch(function (err) {
              console.error(err);
              get(_this, 'flashMessages').danger('There was a problem deleting this Layout.');
            });
          }
        });
        this.get('modal').toggle();
      },
      confirmEditModal: function confirmEditModal(layout) {
        var _this2 = this;
        set(this, 'modal.options', {
          title: 'Edit Layout?',
          text: 'Modifying this layout will cause your scheduled deployments to be deleted',
          confirmButton: 'Edit layout',
          confirmAction: function confirmAction() {
            get(_this2, 'editLayout')(layout).then(function () {
              return get(_this2, 'modal').toggle();
            });
          }
        });
        get(this, 'tmpLayout.deployments').then(function (deployments) {
          if (deployments.length) {
            get(_this2, 'modal').toggle();
          } else {
            get(_this2, 'editLayout')(layout);
          }
        });
      },
      createSiteSpecificWorksite: function createSiteSpecificWorksite(layout) {
        if (get(this, 'createSiteSpecificWorksite')) get(this, 'createSiteSpecificWorksite')(layout);
      },
      toggleDeploymentModal: function toggleDeploymentModal() {
        this.toggleProperty('schedulingDeployment');
      }
    }
  });
  _exports.default = _default;
});