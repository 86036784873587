define("tmp-for-all/components/search-results/worksites/results-table/item/component", ["exports", "tmp-for-all/constants", "tmp-for-all/config/environment"], function (_exports, _constants, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    inject = Ember.inject;
  var _default = Ember.Component.extend({
    applyPrefix: _environment.default.mwsApplyPrefix,
    authManager: Ember.inject.service('session'),
    authToken: computed.alias('authManager.data.authenticated.id'),
    store: inject.service(),
    tagName: 'tr',
    classNames: ['worksites-list-item'],
    worksiteVersion: computed('searchResult.majorRevisionNumber', 'searchResult.minorRevisionNumber', function () {
      var majorRevisionNumber = get(this, 'searchResult.majorRevisionNumber');
      var minorRevisionNumber = get(this, 'searchResult.minorRevisionNumber');
      return "v".concat(majorRevisionNumber, ".").concat(minorRevisionNumber);
    }),
    isDraft: computed('searchResult', function () {
      return get(this, 'searchResult.type') === 'worksite-draft';
    }),
    worksiteStatus: computed('searchResult.type', 'searchResult.isDeclined', function () {
      var isDeclined = get(this, 'searchResult.isDeclined');
      if (isDeclined) {
        return 'Declined';
      } else {
        var type = get(this, 'searchResult.type');
        switch (type) {
          case 'worksite-draft':
            return 'Draft';
          case 'worksite-submission':
            return 'Submitted';
          case 'worksite':
            return 'Accepted';
        }
      }
    }),
    workStatus: computed('searchResult.workStatus', function () {
      return _constants.WORKSITE_META_STATUSES[get(this, 'searchResult.workStatus')];
    }),
    isSubmission: computed.equal('searchResult.type', _constants.WORKSITE_MODEL_TYPES.SUBMITTED),
    ownerName: computed.alias('searchResult.assignee')
  });
  _exports.default = _default;
});