define("tmp-for-all/mixins/routes/modals/new-company-route", ["exports", "tmp-for-all/mixins/routes/modals/modal-route-base"], function (_exports, _modalRouteBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NewCompanyModalMixin = void 0;
  var get = Ember.get,
    set = Ember.set;

  /**
   * Mixin to be used for all create new company modal routes
   *
   * Extends modal-route-base mixin
   *
   * Must be used with a parent route that provides selectClientCompany action
   */
  var NewCompanyModalMixin = Ember.Mixin.create(_modalRouteBase.default, {
    templateName: 'modals/add-new-company-modal',
    saveActionName: 'selectClientCompany',
    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);
      set(model, 'newCompany', get(this, 'store').createRecord('company'));
    },
    actions: {
      save: function save(company) {
        var _this = this;
        company.save().then(function (company) {
          _this.send(get(_this, 'saveActionName'), company);
          get(_this, 'flashMessages').success('A new company has been created.');
          _this.replaceWith(get(_this, 'parentRouteName'));
        }).catch(function () {
          get(_this, 'flashMessages').danger('There was an error saving the new company.');
        });
      },
      willTransition: function willTransition() {
        var newCompany = get(this, 'controller.model.newCompany');
        if (newCompany) {
          newCompany.destroyOrRollback();
        }
      }
    }
  });
  _exports.NewCompanyModalMixin = NewCompanyModalMixin;
  var _default = Ember.Route.extend(NewCompanyModalMixin);
  _exports.default = _default;
});