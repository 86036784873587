define("tmp-for-all/components/shared/form/check-boxes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wQlu30cW",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[23,[\"groupLabel\"]]],null,{\"statements\":[[7,\"h3\"],[11,\"class\",\"element-group-label\"],[9],[0,\"\\n  \"],[1,[21,\"groupLabel\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"toolTipText\"]]],null,{\"statements\":[[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"      \"],[1,[21,\"toolTipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"each\",[[23,[\"checkboxes\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"shared/form/check-box\",null,[[\"checked\",\"disabled\",\"label\",\"value\",\"update\",\"useButtonStyle\"],[[22,1,[\"checked\"]],[22,1,[\"disabled\"]],[22,1,[\"label\"]],[22,1,[\"value\"]],[27,\"action\",[[22,0,[]],\"update\"],null],[23,[\"useButtonStyle\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[1,[27,\"shared/error-messages/inline\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/check-boxes/template.hbs"
    }
  });
  _exports.default = _default;
});