define("tmp-for-all/utils/nzta-detour-map-side-panel-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.structure = _exports.state = void 0;
  var structure = {
    searchKey: 'status',
    children: [{
      label: 'Closures',
      searchKey: 'closures',
      sectionHeader: true,
      children: [{
        label: 'Published',
        searchKey: 'published'
      }, {
        label: 'Draft',
        searchKey: 'draft'
      }]
    }, {
      label: 'Detours',
      searchKey: 'detours',
      sectionHeader: true,
      children: [{
        label: 'Published',
        searchKey: 'published'
      }, {
        label: 'Draft',
        searchKey: 'draft'
      }]
    }]
  };
  _exports.structure = structure;
  var state = {
    status: {
      closures: {
        published: true,
        draft: false
      },
      detours: {
        published: false,
        draft: false
      }
    }
  };
  _exports.state = state;
});