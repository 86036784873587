define("tmp-for-all/components/search-results/deployments/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var columnDefinitions = [{
    key: 'TMPID',
    columnName: 'TMP ID',
    sortName: 'tmpId'
  }, {
    key: 'LAYOUTID',
    columnName: 'Layout Reference',
    sortName: 'layoutId'
  }, {
    key: 'SIGNIN',
    columnName: 'First Sign-in (scheduled)'
  }, {
    key: 'ACTUALSIGNIN',
    columnName: 'First Sign-in (actual)'
  }, {
    key: 'SITECLEARED',
    columnName: 'Site Cleared (scheduled)'
  }, {
    key: 'ACTUALSITECLEARED',
    columnName: 'Site Cleared (actual)'
  }, {
    key: 'CLOSURE',
    columnName: 'Closure Type'
  }, {
    key: 'DESCRIPTION',
    columnName: 'Description'
  }, {
    key: 'STMS',
    columnName: 'STMS'
  }, {
    key: 'STMSMOB',
    columnName: 'STMS Mobile'
  }, {
    key: 'STATUS',
    columnName: 'Status'
  }, {
    key: 'FIRSTDETOURSTEPS',
    columnName: 'Description of Detour 1'
  }, {
    key: 'FIRSTDETOURDISTANCE',
    columnName: 'Detour 1 distance (km)'
  }, {
    key: 'FIRSTDETOURTRAVELTIME',
    columnName: 'Travel time (min) for detour 1'
  }, {
    key: 'SECONDDETOURSTEPS',
    columnName: 'Description of Detour 2'
  }, {
    key: 'SECONDDETOURDISTANCE',
    columnName: 'Detour 2 distance (km)'
  }, {
    key: 'SECONDDETOURTRAVELTIME',
    columnName: 'Travel time (min) for detour 2'
  }];
  var columnsExcludedByDefault = ['ACTUALSIGNIN', 'SITECLEARED', 'ACTUALSITECLEARED', 'DESCRIPTION', 'STMSMOB', 'SECONDDETOURSTEPS', 'SECONDDETOURDISTANCE', 'SECONDDETOURTRAVELTIME'];
  var _default = Ember.Component.extend({
    columns: columnDefinitions.map(function (column) {
      column.isVisible = true;
      return Ember.Object.create(column);
    }),
    columnsExcludedByDefault: columnsExcludedByDefault,
    orderByName: null,
    orderByDirection: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.showDefaultColumns();
    },
    toggleText: 'Show all columns',
    showDefaultColumns: function showDefaultColumns() {
      var columns = get(this, 'columns') || [];
      var columnsToHide = get(this, 'columnsExcludedByDefault') || [];
      set(this, 'columns', columns.map(function (col) {
        var isVisible = true;
        if (columnsToHide && columnsToHide.includes(col.key)) isVisible = false;
        set(col, 'isVisible', isVisible);
        return col;
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, 'order')) {
        var orderProps = get(this, 'order').split(' ');
        set(this, 'orderByName', orderProps[0]);
        set(this, 'orderByDirection', orderProps[1]);
      }
    },
    actions: {
      toggleColumn: function toggleColumn(column) {
        column.toggleProperty('isVisible');
        // If all columns are now shown, change toggle all link to read "Revert to default":
        var allColumnsAreVisible = get(this, 'columns').every(function (col) {
          return get(col, 'isVisible');
        });
        if (allColumnsAreVisible) {
          set(this, 'toggleText', 'Revert to default');
        }
      },
      toggleAllColumns: function toggleAllColumns() {
        var columns = get(this, 'columns');
        var allColumnsAreVisible = columns.every(function (col) {
          return get(col, 'isVisible');
        });
        // If all columns are already shown, action reverts visible columns back to page default instead:
        if (allColumnsAreVisible) {
          this.showDefaultColumns();
          set(this, 'toggleText', 'Show all columns');
          // Otherwise, update all columns to be visible:
        } else {
          set(this, 'columns', columns.map(function (col) {
            set(col, 'isVisible', true);
            return col;
          }));
          set(this, 'toggleText', 'Revert to default');
        }
      }
    }
  });
  _exports.default = _default;
});