define("tmp-for-all/components/shared/file-list/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    RSVP = Ember.RSVP,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    modal: Ember.inject.service(),
    permittedFiles: computed('restrictPrivateFiles', 'files.isPending', function () {
      if (get(this, 'files.isPending')) return [];
      if (!get(this, 'restrictPrivateFiles')) return get(this, 'files');
      return get(this, 'files').filter(function (f) {
        return !get(f, 'isPrivate');
      });
    }),
    actions: {
      removeFile: function removeFile(file) {
        file.deleteRecord();
      },
      showPrivateReason: function showPrivateReason(file) {
        var _this = this;
        set(this, 'modal.options', {
          title: 'Reason',
          text: get(file, 'privateReason'),
          confirmButton: 'Ok',
          removeCancelLink: true,
          confirmAction: function confirmAction() {
            RSVP.resolve().then(function () {
              get(_this, 'modal').toggle();
            });
          }
        });
        get(this, 'modal').toggle();
      }
    },
    token: computed.alias('session.data.authenticated.id'),
    apiPath: _environment.default.apiPath
  });
  _exports.default = _default;
});