define("tmp-for-all/components/worksite-view/outstanding-notices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8PxlqzMI",
    "block": "{\"symbols\":[\"notice\"],\"statements\":[[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-blue\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"Outstanding Notices\"],[10],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"bell\",\"28px\",\"26px\",\"icon--in-heading icon--blue\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table blue no-hover-effect has-double-rows no-colour-borders\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[11,\"class\",\"no-wrap\"],[9],[0,\"Notice Type\"],[10],[0,\"\\n        \"],[7,\"th\"],[11,\"class\",\"no-wrap\"],[9],[0,\"Effective From\"],[10],[0,\"\\n        \"],[7,\"th\"],[11,\"class\",\"no-wrap\"],[9],[0,\"Resolution Deadline\"],[10],[0,\"\\n        \"],[7,\"th\"],[11,\"class\",\"no-wrap\"],[9],[0,\"Comment\"],[10],[0,\"\\n        \"],[7,\"th\"],[11,\"class\",\"links-column\"],[9],[10],[0,\"\\n        \"],[7,\"th\"],[11,\"class\",\"links-column\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"outstandingNotices\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"worksite-view/outstanding-notices/notice-row\",null,[[\"notice\",\"worksite\",\"clearNotice\",\"rejectNotice\"],[[22,1,[]],[23,[\"worksite\"]],[23,[\"clearNotice\"]],[23,[\"rejectNotice\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/outstanding-notices/template.hbs"
    }
  });
  _exports.default = _default;
});