define("tmp-for-all/helpers/worksite-contact-type", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.worksiteContactType = worksiteContactType;
  function worksiteContactType(params) {
    return _constants.WORKSITE_CONTACT_TYPES[params[0]];
  }
  var _default = Ember.Helper.helper(worksiteContactType);
  _exports.default = _default;
});