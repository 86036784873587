define("tmp-for-all/components/maps/map-control-panel/search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "osLc33bG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"control-panel-field-group\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"control-panel-field-label\"],[9],[0,\"Keyword search\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"name\",\"placeholder\",\"id\",\"class\",\"value\"],[\"text\",\"search\",\"\",\"search\",\"control-panel-search-input\",[22,0,[\"search\"]]]]],false],[0,\"\\n  \"],[7,\"input\"],[11,\"id\",\"keyword-search-button\"],[11,\"class\",\"primary-action\"],[11,\"value\",\"Search\"],[11,\"type\",\"submit\"],[9],[10],[0,\"\\n  \"],[7,\"input\"],[11,\"id\",\"keyword-search-clear-button\"],[11,\"class\",\"secondary-action\"],[11,\"value\",\"Clear\"],[11,\"type\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"clearQuery\"]],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"updateQuery\"],[[\"on\"],[\"submit\"]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/map-control-panel/search/template.hbs"
    }
  });
  _exports.default = _default;
});