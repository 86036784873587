define("tmp-for-all/components/tmp-view/layouts/list/item/detour-text-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5YxI1RzZ",
    "block": "{\"symbols\":[\"detour\",\"index\",\"step\"],\"statements\":[[4,\"each\",[[23,[\"detours\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"distance\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"key-value-wrap\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"key\"],[9],[0,\"Description of detour \"],[1,[27,\"format-array-index\",[[22,2,[]]],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[\"steps\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,3,[\"text\"]],false],[4,\"unless\",[[27,\"eq\",[[22,3,[]],[22,1,[\"steps\",\"lastObject\"]]],null]],null,{\"statements\":[[0,\", \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"key-value-wrap\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"key\"],[9],[0,\"Detour \"],[1,[27,\"format-array-index\",[[22,2,[]]],null],false],[0,\" distance (km)\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[1,[27,\"format-meters\",[[22,1,[\"distance\"]]],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"key-value-wrap\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"key\"],[9],[0,\"Travel time (min) for detour \"],[1,[27,\"format-array-index\",[[22,2,[]]],null],false],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"value\"],[9],[1,[27,\"format-seconds\",[[22,1,[\"time\"]]],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/tmp-view/layouts/list/item/detour-text-display/template.hbs"
    }
  });
  _exports.default = _default;
});