define("tmp-for-all/templates/company-admin/pending-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wC9WQY/4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Pending Users\"]],{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"margin-btm-20\"],[9],[0,\"The following users are waiting to join \"],[1,[23,[\"model\",\"domain\",\"name\"]],false],[0,\":\"],[10],[0,\"\\n  \"],[1,[27,\"pending-users-list\",null,[[\"users\",\"roles\",\"approveUser\",\"declineUser\",\"addRole\",\"removeRole\"],[[23,[\"model\",\"users\"]],[23,[\"model\",\"roles\"]],[27,\"route-action\",[\"approveUser\"],null],[27,\"route-action\",[\"declineUser\"],null],[27,\"route-action\",[\"addRole\"],null],[27,\"route-action\",[\"removeRole\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/company-admin/pending-users.hbs"
    }
  });
  _exports.default = _default;
});