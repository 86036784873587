define("tmp-for-all/components/maps/draw-polygon/select-layer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      set(this, 'color', get(this, 'color') || '#333');
    },
    stroke: true,
    opacity: 0.5,
    weight: 2,
    geometry: computed('worksiteClashes', function () {
      var worksiteClashes = get(this, 'worksiteClashes');
      return worksiteClashes.features;
    })
  });
  _exports.default = _default;
});