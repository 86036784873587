define("tmp-for-all/components/worksite/work-items-form/work-item-form/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var typeIsOtherSpecifyBelow = function typeIsOtherSpecifyBelow(type) {
    return type ? type.toLowerCase() === 'Other (specify Below)'.toLowerCase() : false;
  };
  var _default = Ember.Component.extend({
    workTypes: _constants.WORKSITE_WORKITEM_TYPES,
    selectedWorkType: computed('workItem.type', function () {
      var value = get(this, 'workItem.type');
      return {
        value: value
      };
    }),
    isOtherType: computed('workItem.type', function () {
      var type = get(this, 'workItem.type');
      return typeIsOtherSpecifyBelow(type);
    }),
    roadLocations: computed('workItem.roadLocations.[]', function () {
      var workItem = get(this, 'workItem');
      return _constants.WORKSITE_WORKITEM_LOCATIONS.map(function (loc) {
        var roadLocations = get(workItem, 'roadLocations');
        return Ember.Object.create({
          label: loc,
          checked: roadLocations ? roadLocations.indexOf(loc) !== -1 : false,
          value: loc
        });
      });
    }),
    actions: {
      addRoadLocationsToWorkItem: function addRoadLocationsToWorkItem(locations) {
        this.addRoadLocationsToWorkItem(locations, this.workItem);
      },
      onWorkTypeChanged: function onWorkTypeChanged(type) {
        if (!typeIsOtherSpecifyBelow(type.value)) {
          set(this, 'workItem.otherType', null);
        }
        set(this, 'workItem.type', type.value);
      }
    }
  });
  _exports.default = _default;
});