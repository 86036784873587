define("tmp-for-all/routes/companies/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    RSVP = Ember.RSVP,
    set = Ember.set;
  var _default = Ember.Route.extend({
    authorization: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    company: computed.alias('controller.model.company'),
    currentUser: null,
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (user) {
        set(_this, 'currentUser', user);
        var isSuperUser = !get(user, 'selectedDomain') && !get(user, 'selectedDomainId');
        set(_this, 'isSuperUser', isSuperUser);
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'editCompany', get(user, 'domain')).catch(function () {
          return _this.replaceWith('index');
        });
      });
    },
    model: function model(params) {
      var filter;
      if (get(this, 'isSuperUser')) {
        filter = {
          filter: {
            where: {
              companyId: params.company_id
            }
          }
        };
      } else {
        var jurisdictionId = get(this, 'currentUser.selectedDomainId');
        filter = {
          filter: {
            where: {
              and: [{
                companyId: params.company_id
              }, {
                jurisdictionId: jurisdictionId
              }]
            }
          }
        };
      }
      return RSVP.hash({
        id: params.company_id,
        currentUser: get(this, 'currentUser'),
        company: this.store.findRecord('company', params.company_id),
        customerNumbers: this.store.query('customer-number', filter)
      });
    },
    openModal: function openModal(company, transition) {
      var _this2 = this;
      this.set('modal.options', {
        title: 'Discard Changes?',
        text: 'You have made unsaved changes to this organisation',
        confirmButton: 'Discard',
        confirmAction: function confirmAction() {
          company.destroyOrRollback();
          get(_this2, 'modal').toggle();
          transition.retry();
        }
      });
      this.get('modal').toggle();
    },
    actions: {
      saveCompany: function saveCompany(company, customerNumbers) {
        var _this3 = this;
        company.save().then(function () {
          return RSVP.all(customerNumbers.map(function (cn) {
            if (get(cn, 'isNew') || get(cn, 'hasDirtyAttributes')) return cn.save();
          }));
        }).then(function () {
          get(_this3, 'flashMessages').success('Organisation has been updated successfully.');
          _this3.transitionTo('companies.view', get(company, 'id'));
        }).catch(function () {
          get(_this3, 'flashMessages').danger('There was a problem updating this Organisation');
        });
      },
      toggleArchiveState: function toggleArchiveState(company) {
        var _this4 = this;
        var isNowArchived = company.toggleProperty('isArchived');
        var message = isNowArchived ? 'Organisation has been archived.' : 'Organisation has been un-archived.';
        company.save().then(function () {
          get(_this4, 'flashMessages').success(message);
          _this4.transitionTo('companies.view', company);
        }).catch(function () {
          get(_this4, 'flashMessages').danger('There was a problem archiving this Organisation');
        });
      },
      addCustomerNumber: function addCustomerNumber(rcaId, customerNumbers) {
        var _this5 = this;
        return this.store.findRecord('jurisdiction', rcaId).then(function (userRca) {
          return _this5.store.createRecord('customer-number', {
            company: get(_this5, 'controller.model.company'),
            jurisdiction: userRca
          });
        }).then(function (newCustomerNumber) {
          return customerNumbers.pushObject(newCustomerNumber);
        });
      },
      willTransition: function willTransition(transition) {
        var company = get(this, 'company');
        if (get(company, 'hasChanges')) {
          transition.abort();
          this.openModal(company, transition);
        }
      }
    }
  });
  _exports.default = _default;
});