define("tmp-for-all/mixins/return-path-mixin", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    isEmpty = Ember.isEmpty;
  var sessionStore = _constants.BrowserSession.sessionStore,
    returnPathKey = _constants.BrowserSession.returnPathKey;
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);

      // Handle return path even after full redirect
      get(this, 'session').on('authenticationSucceeded', function () {
        var returnPath = sessionStore && sessionStore.getItem(returnPathKey);
        if (returnPath && returnPath !== 'undefined') {
          // Only use the return transition once after login
          sessionStore.removeItem(returnPathKey);
          _this.transitionTo(returnPath);
        } else {
          _this.transitionTo('index');
        }
      });

      // Clean up return path, reload Ember app
      get(this, 'session').on('invalidationSucceeded', function () {
        if (sessionStore) sessionStore.removeItem(returnPathKey);
        if (!Ember.testing) window.location.replace('/login');
      });
    },
    actions: {
      error: function error(_error, transition) {
        if (_error && _error.isAdapterError && !isEmpty(_error.errors) && _error.errors[0].status === 401 && !get(this, 'session.isAuthenticated')) {
          set(this, 'session.attemptedTransition', transition);
          return this.transitionTo('login');
        } else {
          return this._super.apply(this, arguments);
        }
      }
    }
  });
  _exports.default = _default;
});