define("tmp-for-all/components/worksite-view/clashes-list/clash-item/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: '',
    // Determine which clashParent this worksite is
    thisClashParentId: computed('worksite.id', 'clash.clashParent1', 'clash.clashParent2', function () {
      var worksiteId = get(this, 'worksite.id');
      var clashParent1 = get(this, 'clash.clashParent1');
      var clashParent2 = get(this, 'clash.clashParent2');
      var id = clashParent1.parentId === Number(worksiteId) ? clashParent1.id : clashParent2.id;
      return id;
    }),
    thisWorksiteResolution: computed('thisClashParentId', function () {
      var clashParent1Resolution = get(this, 'clash.clashParent1Resolution');
      var clashParent2Resolution = get(this, 'clash.clashParent2Resolution');
      var clashingWorksiteResolution = clashParent1Resolution.clashParentId === get(this, 'thisClashParentId') ? clashParent1Resolution : clashParent2Resolution;
      return clashingWorksiteResolution;
    }),
    thisWorksiteClashParent: computed.alias('worksite.id', function () {
      var worksiteId = get(this, 'worksite.id');
      var clashParent1 = get(this, 'clash.clashParent1WorksiteSearchResult');
      var clashParent2 = get(this, 'clash.clashParent2WorksiteSearchResult');
      var clashParentWorksiteSearchResult = clashParent1.worksiteId === Number(worksiteId) ? clashParent1 : clashParent2;
      return clashParentWorksiteSearchResult;
    }),
    clashingClashParentId: computed('thisClashParentId', 'clash.clashParent1', 'clash.clashParent2', function () {
      var thisClashParentId = get(this, 'thisClashParentId');
      var clashParent1 = get(this, 'clash.clashParent1');
      var clashParent2 = get(this, 'clash.clashParent2');
      var id = clashParent1.parentId !== Number(thisClashParentId) ? clashParent1.id : clashParent2.id;
      return id;
    }),
    clashingClashParentType: computed('clashingClashParentId', 'clash.clashParent1WorksiteSearchResult', 'clash.clashParent2WorksiteSearchResult', function () {
      var clashingClashParentId = get(this, 'clashingClashParentId');
      var clashParent1 = get(this, 'clash.clashParent1WorksiteSearchResult');
      var clashParent2 = get(this, 'clash.clashParent2WorksiteSearchResult');
      var item = clashParent1.worksiteId === Number(clashingClashParentId) ? clashParent1 : clashParent2;
      if (Object.values(_constants.WORKSITE_MODEL_TYPES).includes(item.type)) {
        return 'WORKSITE_CLASH';
      }
      return 'PT_CLASH';
    }),
    clashingClashItem: computed('clashingClashParentId', 'clashingClashParentType', 'clash.clashParent1WorksiteSearchResult', 'clash.clashParent2WorksiteSearchResult', function () {
      var clashingClashParentId = get(this, 'clashingClashParentId');
      var clashParent1 = get(this, 'clash.clashParent1WorksiteSearchResult');
      var clashParent2 = get(this, 'clash.clashParent2WorksiteSearchResult');
      var item = clashParent1.worksiteId === Number(clashingClashParentId) ? clashParent1 : clashParent2;
      return item;
    }),
    clashingWorksiteContactName: computed('clashingClashItem.applicantName', function () {
      return get(this, 'clashingClashItem.applicantName');
    }),
    clashingWorksiteContactDetails: computed('clashingClashItem.applicantContact', function () {
      return get(this, 'clashingClashItem.applicantContact');
    }),
    isClashingItemWorksite: computed('clashingClashItem', function () {
      var type = get(this, 'clashingClashItem.type');
      if (Object.values(_constants.WORKSITE_MODEL_TYPES).includes(type)) {
        return true;
      }
      return false;
    }),
    clashingWorksiteResolution: computed('thisClashParentId', 'clash.clashParent1Resolution', 'clash.clashParent2Resolution', function () {
      var clashParent1Resolution = get(this, 'clash.clashParent1Resolution');
      var clashParent2Resolution = get(this, 'clash.clashParent2Resolution');
      var clashingWorksiteResolution = clashParent1Resolution.clashParentId !== get(this, 'thisClashParentId') ? clashParent1Resolution : clashParent2Resolution;
      return clashingWorksiteResolution;
    }),
    // TODO: when we want to specify a juridiction other than Auckland
    // clashingPtJurisdiciton: computed('clashingParentId', function() {
    // }),
    // clashingPtJurisdictionOverlaps: computed(),

    hasOverlaps: computed('clash.clashDetails', function () {
      return !!Object.keys(get(this, 'clash.clashDetails')).length;
    }),
    extentOverlaps: computed('clash.clashDetails', function () {
      var _this = this;
      var count = get(this, 'clash.clashDetails').filter(function (clash) {
        return clash.clashCauseType === _constants.WORKSITE_MODEL_TYPES.ACCEPTED && clash.clashParentId === get(_this, 'thisClashParentId') || clash.clashCauseType === _constants.WORKSITE_MODEL_TYPES.DRAFT && clash.clashParentId === get(_this, 'thisClashParentId') || clash.clashCauseType === _constants.WORKSITE_MODEL_TYPES.SUBMITTED && clash.clashParentId === get(_this, 'thisClashParentId');
      }).length;
      if (count < 1) return;
      return count > 1 ? "".concat(count, " worksite extents") : "".concat(count, " worksite extent");
    }),
    layoutOverlaps: computed('clash.clashDetails', function () {
      var _this2 = this;
      var count = get(this, 'clash.clashDetails').filter(function (clash) {
        return clash.clashCauseType === 'layout' && clash.clashParentId === get(_this2, 'thisClashParentId');
      }).length;
      if (count < 1) return;
      return count > 1 ? "".concat(count, " layouts") : "".concat(count, " layout");
    }),
    detourOverlaps: computed('clash.clashDetails', function () {
      var _this3 = this;
      var count = get(this, 'clash.clashDetails').filter(function (clash) {
        return clash.clashCauseType === 'detour' && clash.clashParentId === get(_this3, 'thisClashParentId');
      }).length;
      if (count < 1) return;
      return count > 1 ? "".concat(count, " detours") : "".concat(count, " detour");
    }),
    ptRouteOverlaps: computed('clash.clashDetails', function () {
      var _this4 = this;
      var count = get(this, 'clash.clashDetails').filter(function (clash) {
        return clash.clashCauseType === 'pt-route' && clash.clashParentId === get(_this4, 'thisClashParentId');
      }).length;
      if (count >= 1) return "Bus routes";
    }),
    ptStopOverlaps: computed('clash.clashDetails', function () {
      var _this5 = this;
      var count = get(this, 'clash.clashDetails').filter(function (clash) {
        return clash.clashCauseType === 'pt-stop' && clash.clashParentId === get(_this5, 'thisClashParentId');
      }).length;
      if (count >= 1) return "Bus stops";
    }),
    // TODO: Not certain what this is intended to be yet
    // workTypes: computed('clashingWorksite', function() {
    //   return get(this, 'clashingWorksite').workItemTypes
    // }),

    combinedResolutionStatus: computed('thisWorksiteResolution.status', 'clashingWorksiteResolution.status', function () {
      var thisWorksiteResolutionStatus = get(this, 'thisWorksiteResolution.status');
      var clashingWorksiteResolutionStatus = get(this, 'clashingWorksiteResolution.status');
      var status = thisWorksiteResolutionStatus !== clashingWorksiteResolutionStatus ? _constants.CLASH_RESOLUTION_STATUS.PARTLY_RESOLVED : thisWorksiteResolutionStatus === clashingWorksiteResolutionStatus && thisWorksiteResolutionStatus === _constants.CLASH_RESOLUTION_STATUS.RESOLVED || _constants.CLASH_RESOLUTION_STATUS.AUTO_RESOLVED ? _constants.CLASH_RESOLUTION_STATUS.RESOLVED : _constants.CLASH_RESOLUTION_STATUS.UNRESOLVED;
      return status;
    }),
    isResolved: computed('combinedResolutionStatus', function () {
      return get(this, 'combinedResolutionStatus') === _constants.CLASH_RESOLUTION_STATUS.RESOLVED;
    }),
    isPartlyResolved: computed('combinedResolutionStatus', function () {
      return get(this, 'combinedResolutionStatus') === _constants.CLASH_RESOLUTION_STATUS.PARTLY_RESOLVED;
    }),
    isNotResolved: computed('combinedResolutionStatus', function () {
      return get(this, 'combinedResolutionStatus') === _constants.CLASH_RESOLUTION_STATUS.UNRESOLVED;
    }),
    thisWorksiteNotResolved: computed('thisWorksiteResolution.status', function () {
      return get(this, 'thisWorksiteResolution.status') === _constants.CLASH_RESOLUTION_STATUS.UNRESOLVED;
    }),
    thisWorksiteResolutionNote: computed('thisWorksiteResolution.note', function () {
      return get(this, 'thisWorksiteResolution.note');
    }),
    clashingWorksiteResolutionNote: computed('clashingWorksiteResolution.note', function () {
      return get(this, 'clashingWorksiteResolution.note');
    }),
    createClashingWorksiteLink: computed('clashingClashItem.type', 'clashingClashItem.id', function () {
      return "worksites/".concat(get(this, 'clashingClashItem.worksiteId'), "/submission");
    }),
    clashModalShown: false,
    actions: {
      toggleClashModal: function toggleClashModal() {
        this.toggleProperty('clashModalShown');
      }
    }
  });
  _exports.default = _default;
});