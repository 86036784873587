define("tmp-for-all/components/shared/add-conditions/group/component", ["exports", "tmp-for-all/mixins/components/show-hide-mixin"], function (_exports, _showHideMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend(_showHideMixin.default, {
    classNames: ['condition-group', 'check-boxes-group', 'rounded-wrapper', 'margin-btm-20', 'hide-overflow'],
    attributeBindings: ['style'],
    // Hide element if group doesn't contain any conditions:
    style: computed('allConditionIds', function () {
      return get(this, 'allConditionsCount') ? Ember.String.htmlSafe('') : Ember.String.htmlSafe('display: none');
    }),
    allConditionIds: computed('conditions.[]', function () {
      return get(this, 'conditions').map(function (condition) {
        return get(condition, 'id');
      });
    }),
    allConditionsCount: computed('allConditionIds.[]', function () {
      return get(this, 'allConditionIds').length;
    }),
    selectedConditionIds: computed('selectedConditions.[]', function () {
      var allConditionIds = get(this, 'allConditionIds');
      var selectedConditions = get(this, 'selectedConditions') || [];
      return selectedConditions.filter(function (selectedCondition) {
        return allConditionIds.indexOf(get(selectedCondition, 'id')) > -1;
      }).map(function (selectedCondition) {
        return get(selectedCondition, 'id');
      }).uniq();
    }),
    selectedConditionsCount: computed('selectedConditionIds.[]', function () {
      return get(this, 'selectedConditionIds').length;
    }),
    mappedConditions: computed('conditions.[]', 'selectedConditionIds.[]', function () {
      var conditions = get(this, 'conditions') || [];
      var selected = get(this, 'selectedConditionIds');
      return conditions.map(function (condition) {
        return {
          condition: condition,
          isChecked: selected.indexOf(get(condition, 'id')) > -1
        };
      }).sort(function (conditionA, conditionB) {
        return get(conditionA, 'condition.id') - get(conditionB, 'condition.id');
      });
    }),
    hasConditions: computed.gt('conditions.length', 0),
    conditionsSameLengths: computed('selectedConditionIds.length', 'conditions.length', function () {
      return get(this, 'selectedConditionIds.length') === get(this, 'conditions.length');
    }),
    allConditionsSelected: computed('conditionsSameLengths', 'hasConditions', function () {
      return get(this, 'conditionsSameLengths') && get(this, 'hasConditions');
    }),
    actions: {
      toggleGroup: function toggleGroup() {
        var _this = this;
        if (get(this, 'allConditionsSelected')) {
          get(this, 'conditions').forEach(function (condition) {
            get(_this, 'onChange')(condition, false);
          });
        } else {
          get(this, 'conditions').forEach(function (condition) {
            var isSelected = get(_this, 'selectedConditionIds').includes(condition.id);
            if (!isSelected) get(_this, 'onChange')(condition, true);
          });
        }
      }
    }
  });
  _exports.default = _default;
});