define("tmp-for-all/controllers/users/list", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin"], function (_exports, _listPaginationControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set;
  var _default = Ember.Controller.extend(_listPaginationControllerMixin.default, {
    queryParams: ['search', 'showArchived', 'orgType'],
    search: null,
    orgType: null,
    showArchived: false,
    actions: {
      searchUpdated: function searchUpdated(term) {
        set(this, 'page', 1);
        set(this, 'search', term);
      },
      showArchivedUpdated: function showArchivedUpdated(showArchived) {
        set(this, 'showArchived', showArchived);
      },
      setOrgType: function setOrgType(orgType) {
        orgType = orgType.value;
        set(this, 'orgType', orgType);
        set(this, 'page', 1);
      }
    }
  });
  _exports.default = _default;
});