define("tmp-for-all/components/maps/map-control-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    classNames: ['map-control-panel'],
    showControlPanel: true,
    activeControlsTab: 'date-range',
    selectedRcaCollection: computed('rcaCollection', 'searchQuery.primaryRCA.[]', function () {
      var rcaSelected = get(this, 'searchQuery.primaryRCA');
      var rcaCollection = get(this, 'rcaCollection');
      return rcaCollection.map(function (rcaItem) {
        if (rcaSelected.indexOf(rcaItem.value) !== -1) set(rcaItem, 'checked', true);else set(rcaItem, 'checked', false);
        return rcaItem;
      });
    }),
    actions: {
      toggleControlPanel: function toggleControlPanel() {
        set(this, 'showControlPanel', !get(this, 'showControlPanel'));
      },
      switchActiveControlTab: function switchActiveControlTab(componentName) {
        set(this, 'showControlPanel', true);
        set(this, 'activeControlsTab', componentName);
      }
    }
  });
  _exports.default = _default;
});