define("tmp-for-all/models/acceptance-condition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    condition: belongsTo('condition', {
      async: true
    }),
    worksite: belongsTo('worksite', {
      async: true
    }),
    worksiteSubmission: belongsTo('worksite-submission', {
      async: true
    }),
    tmp: belongsTo('tmp', {
      async: true
    }),
    tmpSubmission: belongsTo('tmp-submission', {
      async: true
    })
  });
  _exports.default = _default;
});