define("tmp-for-all/components/panel-section/panel-sub-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zEcr/w5h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"canToggle\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"panel-sub-section-toggle\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\"],[\"chevron-up\",\"32px\",\"22px\"]]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"toggleSubSectionContent\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"panel-sub-section-content\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/panel-section/panel-sub-section/template.hbs"
    }
  });
  _exports.default = _default;
});