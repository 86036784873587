define("tmp-for-all/components/planning-map/panel-filters/filter-category/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Component.extend({
    actions: {
      updateState: function updateState(updatedChildKey, updatedChildValue) {
        var key = get(this, 'structure.searchKey');
        // make the changes to the current object reference.  Will need a new object reference to refire all the computed
        // properties, but that's okay, it'll happen at the root component
        // Ramda func makes the change safely in case the object structure is not present yet
        // Equiv of doing this (if obj structure was there):  state[key][updatedChildKey] = updatedChildValue
        var state = R.assocPath([key, updatedChildKey], updatedChildValue, get(this, 'state'));
        get(this, 'updateState')(key, state[key]);
      }
    }
  });
  _exports.default = _default;
});