define("tmp-for-all/components/leaflet-overrides/popup-layer/component", ["exports", "ember-leaflet/components/popup-layer"], function (_exports, _popupLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Overriding to allow the popup to call a closure action when it is closed
   * BRITTLE.  This is all internals, and future versions of Ember-Leaflet may well break this, though the fix should
   * be straight-forward enough
   */
  var _default = _popupLayer.default.extend({
    _onLayerRemove: function _onLayerRemove(_ref) {
      var layer = _ref.layer;
      this._super.apply(this, arguments);
      if (layer === this._layer) {
        if (this.get('popupClosed')) this.get('popupClosed')();
      }
    },
    willDestroyLayer: function willDestroyLayer() {
      // This extension fixing what appears to be a syntax error in the module:
      // this._layer._close() is not a function
      this._layer.close();
    }
  });
  _exports.default = _default;
});