define("tmp-for-all/components/shared/add-conditions/component", ["exports", "tmp-for-all/helpers/start-position"], function (_exports, _startPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['add-conditions', 't-edit-conditions'],
    actions: {
      update: function update(condition, add) {
        var action = add ? 'addCondition' : 'removeCondition';
        get(this, action)(condition);
      }
    },
    customConditionStartVal: Ember.computed('conditionGroups.[]', function () {
      var conditionGroups = this.get('conditionGroups');
      return conditionGroups.reduce(function (acc, val) {
        return acc + get(val, 'nonArchivedConditions').length;
      }, 1);
    }),
    customStart: Ember.computed('conditionGroups.[]', function () {
      // This is in the component.js rather than the template because we want this to recalculate whenever the conditionGroups att is updated,
      // which usually happens AFTER the custom conditions block is rendered
      return (0, _startPosition.startPosition)([get(this, 'conditionGroups'), 'nonArchivedConditions', null]);
    })
  });
  _exports.default = _default;
});