define("tmp-for-all/templates/companies/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dHnaPRuj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Create Organisation\"]],{\"statements\":[[0,\"  \"],[1,[27,\"companies-form\",null,[[\"company\",\"customerNumbers\",\"user\",\"saveCompany\"],[[23,[\"model\",\"company\"]],[23,[\"model\",\"customerNumbers\"]],[23,[\"model\",\"user\"]],[27,\"route-action\",[\"saveCompany\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/companies/create.hbs"
    }
  });
  _exports.default = _default;
});