define("tmp-for-all/components/planning-map/detail-popup/layout/component", ["exports", "tmp-for-all/helpers/days-of-week"], function (_exports, _daysOfWeek) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var tabs = {
    DETAILS: 'DETAILS',
    IMPACT: 'IMPACT',
    DETOUR: 'DETOUR'
  };
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      get(this, 'layout.detours'); // Fire off detour request
      if (get(this, 'initialTab') === 'detour') set(this, 'activeTab', tabs.DETOUR);
    },
    tabs: tabs,
    activeTab: tabs.DETAILS,
    isDetailTabActive: computed.equal('activeTab', tabs.DETAILS),
    isImpactTabActive: computed.equal('activeTab', tabs.IMPACT),
    isDetourTabActive: computed.equal('activeTab', tabs.DETOUR),
    layoutModel: computed.alias('model'),
    tmpInfo: computed('model.parentInfo.isPending', function () {
      if (get(this, 'model.parentInfo.isPending')) return {};
      return get(this, 'model.parentInfo.content');
    }),
    tmpLink: computed('tmpInfo', function () {
      var info = get(this, 'tmpInfo');
      var link = '/tmps/';
      if (!info.id) return;
      if (info.type === 'tmp-submissions') link += 'submissions/';
      return link + info.id;
    }),
    formattedDays: computed('layoutModel.daysOfWeek', function () {
      return get(this, 'layoutModel.daysOfWeek').map(function (day) {
        return (0, _daysOfWeek.daysOfWeek)([day], {
          format: 'short'
        });
      }).join(', ');
    }),
    impactDetails: computed('layoutModel.impacts', function () {
      var impacts = get(this, 'layoutModel.impacts');
      var output = impacts.map(function (impact) {
        var featureName = impact.features.filter(function (feature) {
          return feature.geometry.type === 'LineString';
        }).map(function (feature) {
          return feature.properties.name;
        });
        return {
          name: featureName,
          laneDrop: impact.properties.impact.laneDrop
        };
      });
      return output;
    }),
    detourByOrdinal: function detourByOrdinal(ordinal) {
      if (get(this, 'layoutModel.detours.isPending')) return null;
      return get(this, 'layoutModel.detours').find(function (d) {
        return get(d, 'ordinal') === ordinal;
      });
    },
    firstDetour: computed('layoutModel.detours.isPending', function () {
      return this.detourByOrdinal(1);
    }),
    secondDetour: computed('firstDetour', function () {
      return this.detourByOrdinal(2);
    }),
    actions: {
      setActiveTab: function setActiveTab(type) {
        set(this, 'activeTab', type);
      }
    }
  });
  _exports.default = _default;
});