define("tmp-for-all/controllers/projects/list", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin"], function (_exports, _listPaginationControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set;
  var _default = Ember.Controller.extend(_listPaginationControllerMixin.default, {
    queryParams: ['search'],
    search: null,
    actions: {
      searchUpdated: function searchUpdated(term) {
        set(this, 'page', 1);
        set(this, 'search', term);
      }
    }
  });
  _exports.default = _default;
});