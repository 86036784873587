define("tmp-for-all/components/shared/tool-tip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FKhzvBpW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"clock\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"clock\",\"16px\",\"16px\",\"icon--blue tooltip-clock-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"tooltip-icon\"],[9],[0,\"?\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"ember-tooltip\",null,[[\"pin\"],[true]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/tool-tip/template.hbs"
    }
  });
  _exports.default = _default;
});