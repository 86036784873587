define("tmp-for-all/models/worksite-base", ["exports", "ember-data", "@turf/bbox", "@turf/buffer", "@turf/bbox-polygon", "tmp-for-all/constants"], function (_exports, _emberData, _bbox, _buffer, _bboxPolygon, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _Ember = Ember,
    computed = _Ember.computed,
    get = _Ember.get,
    RSVP = _Ember.RSVP,
    set = _Ember.set,
    setProperties = _Ember.setProperties;
  var _default = _emberData.default.Model.extend({
    contacts: hasMany('worksite-contact'),
    documentationBlocks: hasMany('documentation-block'),
    name: attr('string'),
    reference: attr('string'),
    startDate: attr('date'),
    endDate: attr('date'),
    duration: attr('number'),
    timeOfDayStart: attr('string'),
    timeOfDayEnd: attr('string'),
    address: attr('string'),
    location: attr(),
    project: belongsTo('project', {
      async: true
    }),
    company: belongsTo('company', {
      async: true
    }),
    jurisdiction: belongsTo('jurisdiction', {
      async: true
    }),
    jurisdictionArea: belongsTo('jurisdiction-area'),
    customConditions: attr(),
    history: hasMany('worksite-history', {
      async: true,
      readOnly: true
    }),
    workItems: hasMany('work-item', {
      async: true
    }),
    status: attr('string'),
    isSubmitted: attr('boolean'),
    isApproved: attr('boolean'),
    isDeclined: attr('boolean'),
    isAccepted: attr('boolean'),
    submittedAt: attr('date'),
    approvedAt: attr('date'),
    acceptedAt: attr('date'),
    declinedAt: attr('date'),
    worksiteType: attr('string'),
    isRetrospective: attr('boolean'),
    trafficManagementLevel: attr('string'),
    manualRoadLevel: attr('boolean'),
    roadType: attr('string'),
    motorwayWorkType: attr('string'),
    roadPosition: attr('string'),
    isFullClosureRequested: attr('boolean'),
    majorRevisionNumber: attr('number'),
    minorRevisionNumber: attr('number'),
    worksiteCode: attr('string'),
    overlapsMultipleJurisdictionAreas: attr('boolean'),
    accessActs: attr('array'),
    localGovernmentAct: attr('string'),
    applicationType: attr('string', {
      defaultValue: 'WORKSITE'
    }),
    purchaseOrder: attr('string'),
    workflowStatus: attr('string'),
    tmpSubmissions: hasMany('tmp-submission', {
      async: true
    }),
    tmpDrafts: hasMany('tmp-draft', {
      async: true
    }),
    tmps: hasMany('tmp', {
      async: true
    }),
    worksiteMeta: belongsTo('worksite-meta', {
      async: true
    }),
    worksiteComments: hasMany('worksite-comment', {
      async: true
    }),
    declinedComment: belongsTo('worksite-comment', {
      async: true
    }),
    worksiteFees: hasMany('worksite-fee', {
      async: true
    }),
    revision: computed('majorRevisionNumber', 'minorRevisionNumber', function () {
      return "".concat(get(this, 'majorRevisionNumber'), ".").concat(get(this, 'minorRevisionNumber'));
    }),
    isNotDeclined: computed.not('isDeclined'),
    isSubmittedAndNotDeclined: computed.and('isSubmitted', 'isNotDeclined'),
    sortedHistoryDesc: computed.sort('history', function (a, b) {
      var aid = parseInt(a.id, 10);
      var bid = parseInt(b.id, 10);
      if (aid < bid) {
        return 1;
      } else if (bid < aid) {
        return -1;
      }
      return 0;
    }),
    isInitialRevision: computed.equal('majorRevisionNumber', 1),
    isNotInitialRevision: computed.not('isInitialRevision'),
    commentService: Ember.inject.service(),
    addComment: function addComment(text, files) {
      return get(this, 'commentService').addComment(this, text, files);
    },
    saveWorkItems: function saveWorkItems() {
      var _this = this;
      set(this, 'isSavingWorkItems', true);
      return RSVP.Promise.all(get(this, 'workItems').map(function (item) {
        return item.save();
      })).finally(function () {
        set(_this, 'isSavingWorkItems', false);
      });
    },
    docBlocksWithDirtyFiles: computed.filterBy('documentationBlocks', 'hasDirtyFilesForUpload'),
    hasDirtyFilesForUpload: computed.notEmpty('docBlocksWithDirtyFiles'),
    saveDocumentationBlocks: function saveDocumentationBlocks() {
      var _this2 = this;
      set(this, 'isSubmitting', true);
      set(this, 'isSavingDocumentationBlocks', true);
      return RSVP.all(get(this, 'documentationBlocks').map(function (block) {
        return block.save().then(function (block) {
          if (get(block, 'isDeleted')) return;
          return RSVP.all([block.persistDeletedFiles(), block.saveFiles()]).then(function () {
            return block.resetUploadList();
          }).then(function () {
            return get(block, 'files').reload();
          });
        });
      })).finally(function () {
        set(_this2, 'isSubmitting', false);
        set(_this2, 'isSavingDocumentationBlocks', false);
      });
    },
    isInFlight: computed.or('isSaving', 'isSavingWorkItems', 'isSavingDocumentationBlocks'),
    saveContacts: function saveContacts() {
      var _this3 = this;
      set(this, 'isSubmitting', true);
      return RSVP.all(get(this, 'contacts').map(function (contact) {
        return contact.save();
      })).finally(function () {
        return set(_this3, 'isSubmitting', false);
      });
    },
    addFee: function addFee(fee) {
      var _this4 = this;
      return RSVP.hash({
        costs: get(fee, 'costs'),
        jurisdiction: get(fee, 'jurisdiction')
      }).then(function (models) {
        var cost = fee.get('currentCost');
        var worksiteFee = _this4.store.createRecord('worksite-fee', {
          itemCode: get(fee, 'itemCode'),
          costPerUnit: get(cost, 'value') || 0,
          unit: get(fee, 'defaultUnit'),
          name: get(fee, 'name'),
          description: get(fee, 'description'),
          accountingCode: get(fee, 'accountingCode'),
          quantity: get(fee, 'defaultQuantity')
        });
        setProperties(worksiteFee, {
          worksite: _this4,
          fee: fee,
          jurisdiction: models.jurisdiction,
          cost: cost
        });
        return get(_this4, 'worksiteFees').then(function () {
          get(_this4, 'worksiteFees').pushObject(worksiteFee);
        });
      });
    },
    saveFees: function saveFees() {
      return Promise.all(get(this, 'worksiteFees').map(function (fee) {
        return Promise.resolve().then(function () {
          if (get(fee, 'hasDirtyAttributes')) {
            return fee.save();
          }
        }).then(function () {
          var changedStatus = get(fee, 'currentStatus');
          if (changedStatus) {
            return fee.changeStatus(changedStatus);
          }
        });
      }));
    },
    rollbackFees: function rollbackFees() {
      return get(this, 'worksiteFees').reload() // TODO: Required to make tests work.
      .then(function (fees) {
        return fees.forEach(function (fee) {
          return fee.destroyOrRollback();
        });
      });
    },
    hasValidStartDate: computed.notEmpty('startDate'),
    hasValidEndDate: computed.notEmpty('endDate'),
    hasValidDateRange: computed.and('hasValidStartDate', 'hasValidEndDate'),
    getDateRange: function getDateRange() {
      return {
        startDate: get(this, 'startDate').toISOString(),
        endDate: get(this, 'endDate').toISOString()
      };
    },
    getBounds: function getBounds() {
      var location = get(this, 'location');
      return (0, _bbox.default)({
        type: 'Feature',
        geometry: location
      });
    },
    getBufferedBounds: function getBufferedBounds(distance) {
      var location = get(this, 'location');
      return (0, _bbox.default)((0, _buffer.default)({
        type: 'Feature',
        geometry: location
      }, distance, 'meters'));
    },
    getBufferedBoundsGeoJson: function getBufferedBoundsGeoJson(distance) {
      // `this` needs to be referenced this way due to context issues
      return (0, _bboxPolygon.default)(this.getBufferedBounds(distance));
    },
    getClashes: function getClashes(bufferDistance) {
      // `this` needs to be referenced this way due to context issues
      if (!get(this, 'location') || !get(this, 'location.coordinates') || !get(this, 'hasValidDateRange')) {
        var emptyCollection = {
          type: 'FeatureCollection',
          features: []
        };
        return Promise.resolve(emptyCollection);
      }
      var geometry = this.getBufferedBounds(bufferDistance);
      var dateRange = this.getDateRange();
      return get(this, 'ajax').clashes(geometry, dateRange);
    },
    clientCompany: computed('project', function () {
      return get(this, 'project').then(function (project) {
        return project ? get(project, 'company') : null;
      });
    }),
    isGlobalPermit: computed.equal('applicationType', _constants.WORKSITE_APPLICATION_TYPE_KEYS.GLOBALPERMIT),
    globalPermitLayout: belongsTo('layout', {
      async: true
    }),
    hasAuditor: computed('worksite', 'contacts', function () {
      return get(this, 'contacts').then(function (contacts) {
        var hasAuditor = false;
        contacts.forEach(function (contact) {
          var user = get(contact, 'user');
          if (get(user, 'isAuditor')) {
            hasAuditor = true;
          }
        });
        return hasAuditor;
      });
    })
  });
  _exports.default = _default;
});