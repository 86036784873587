define("tmp-for-all/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var get = Ember.get,
    isEmpty = Ember.isEmpty;
  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.camelize(attr);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      // do not serialize readonly relationships, causes poor behaviour at the server
      if (relationship.options && relationship.options.readOnly) return;
      this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      // Attach a valid meta object from the record
      // e.g. if .set('meta', {key: val}) has been used
      var meta = get(snapshot, 'record.meta');
      meta = _typeof(meta) === 'object' && !isEmpty(meta) ? meta : {};
      json.meta = meta;
      return json;
    }
  });
  _exports.default = _default;
});