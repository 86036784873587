define("tmp-for-all/routes/company-admin/pending-users", ["exports", "tmp-for-all/models/permission"], function (_exports, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP;
  var _default = Ember.Route.extend({
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'session.currentUser');
      }).then(function (user) {
        var domainType = get(user, 'selectedDomain');
        if (domainType !== 'company') {
          get(_this, 'flashMessages').danger('Must be operating under a company');
          return _this.router.replaceWith('index');
        }
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'editUsers', {
          modelName: get(user, 'selectedDomain'),
          modelId: get(user, 'selectedDomainId')
        });
      }).catch(function () {
        return _this.router.replaceWith('index');
      });
    },
    model: function model() {
      var _this2 = this;
      return get(this, 'session.currentUser').then(function (user) {
        return RSVP.hash({
          users: get(_this2, 'ajax').getPendingUsers(get(user, 'selectedDomainId')),
          domain: get(user, 'domain'),
          roles: _this2.store.query('permission', _permission.default.companyRoleFilter),
          currentUser: user
        });
      });
    },
    afterModel: function afterModel(model) {
      return Promise.all(model.users.map(function (u) {
        return get(u, 'permissionAssignments').then(function (assignments) {
          var filtered = assignments.filter(function (assignment) {
            return get(model.currentUser, 'selectedDomain') === get(assignment, 'domainType') && get(model.currentUser, 'selectedDomainId') === get(assignment, 'domainId');
          });
          set(u, 'permissionAssignments', filtered);
        });
      }));
    },
    actions: {
      approveUser: function approveUser(user, comment) {
        return get(this, 'session.currentUser').then(function (currentUser) {
          return user.approveUser(comment, currentUser);
        });
      },
      declineUser: function declineUser(user, comment) {
        return get(this, 'session.currentUser').then(function (currentUser) {
          return user.declineUser(comment, currentUser);
        });
      },
      addRole: function addRole(user) {
        var permissionAssignment = get(this, 'store').createRecord('permission-assignment', {
          user: user,
          domainType: get(this, 'session.currentUser.selectedDomain'),
          domainId: get(this, 'session.currentUser.selectedDomainId')
        });
        get(user, 'permissionAssignments').pushObject(permissionAssignment);
      },
      removeRole: function removeRole(user, assignment) {
        get(user, 'permissionAssignments').removeObject(assignment);
      }
    }
  });
  _exports.default = _default;
});