define("tmp-for-all/components/shared/threaded-history/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    // Default color property (used for styling purposes only)
    color: 'blue',
    // Set up radio button options used for filtering history items
    filterByOptions: computed('filterBy', function () {
      var filterBy = get(this, 'filterBy');
      var options = Object.keys(_constants.HISTORY_ITEM_CATEGORIES).map(function (key) {
        var option = {
          value: _constants.HISTORY_ITEM_CATEGORIES[key],
          label: _constants.HISTORY_ITEM_CATEGORIES[key],
          checked: filterBy === _constants.HISTORY_ITEM_CATEGORIES[key]
        };
        return option;
      });

      // Add default option for removing any filters
      options.insertAt(0, {
        value: null,
        label: 'All',
        checked: !filterBy
      });
      return options;
    }),
    filteredHistoryItems: computed('historyItems.[]', 'filterBy', function () {
      var itemsToFilter = get(this, 'historyItems');
      var filterBy = get(this, 'filterBy');
      // If filterBy not set (e.g. "All" option is selected), return unfiltered list of items:
      if (!filterBy) return itemsToFilter;
      return itemsToFilter.filterBy('category', filterBy);
    }),
    actions: {
      refreshHistory: function refreshHistory() {
        // Create a no-op function for TMPs.  May be removed later if files added to TMP History need deleting
        if (get(this, 'refreshHistory')) get(this, 'refreshHistory')();
        return null;
      }
    }
  });
  _exports.default = _default;
});