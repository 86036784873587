define("tmp-for-all/components/search-results/organisations/list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ptDA9PDS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"company-name no-wrap\"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"companies.view\",[23,[\"company\",\"id\"]]],[[\"class\"],[\"tertiary-action\"]],{\"statements\":[[1,[23,[\"company\",\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"company\",\"isArchived\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"text-grey-dark text-12\"],[9],[0,\"(archived)\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"company-address\"],[9],[1,[23,[\"company\",\"physicalAddress\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"company-url\"],[9],[7,\"a\"],[12,\"href\",[28,[[23,[\"company\",\"url\"]]]]],[11,\"target\",\"_blank\"],[11,\"class\",\"text-blue\"],[9],[1,[23,[\"company\",\"url\"]],false],[10],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showCustomerNumbers\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"company\",\"customerNumber\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,[\"company\",\"jurisdictionName\"]],false],[0,\": \"],[1,[23,[\"company\",\"customerNumber\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"company\",\"isRelatedParty\"]]],null,{\"statements\":[[0,\"        (Related party)\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"td\"],[11,\"class\",\"align-right\"],[9],[0,\"\\n\"],[4,\"if-permitted\",[\"editCompany\"],[[\"model\"],[[23,[\"company\"]]]],{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"companies.edit\",[23,[\"company\",\"id\"]]],[[\"class\"],[\"tertiary-action\"]],{\"statements\":[[0,\"Edit\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/organisations/list/item/template.hbs"
    }
  });
  _exports.default = _default;
});