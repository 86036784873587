define("tmp-for-all/components/shared/auditor-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6iLdowh8",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"element-wrap contact-selector\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    Auditor Contact\\n\"],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[4,\"power-select\",null,[[\"onchange\",\"selected\",\"searchField\",\"searchEnabled\",\"class\",\"tagName\",\"options\"],[[27,\"action\",[[22,0,[]],[23,[\"selectUser\"]]],null],[23,[\"selectedUser\"]],\"fullname\",true,\"ember-power-select\",\"div\",[23,[\"auditors\"]]]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"preferredName\"]],false],[0,\" \"],[1,[22,1,[\"lastName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/auditor-selector/template.hbs"
    }
  });
  _exports.default = _default;
});