define("tmp-for-all/models/user-search-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var attr = _emberData.default.attr;
  var _Ember = Ember,
    get = _Ember.get,
    computed = _Ember.computed;
  var _default = _emberData.default.Model.extend({
    isArchived: attr('boolean'),
    preferredName: attr('string'),
    lastName: attr('string'),
    fullName: attr('string'),
    email: attr('string'),
    stmsNumber: attr('string'),
    username: attr('string'),
    companyNamesAndPermissions: attr('string'),
    jurisdictionNamesAndPermissions: attr('string'),
    freeTextSearch: attr('string'),
    hasPlainOrganisation: attr('boolean'),
    hasUtilityOrganisation: attr('boolean'),
    hasPrivateIndividualOrganisation: attr('boolean'),
    hasGovernmentAgencyOrganisation: attr('boolean'),
    hasJurisdiction: attr('boolean'),
    organisationNames: computed('companyNamesAndPermissions', 'jurisdictionNamesAndPermissions', function () {
      var companies = get(this, 'companyNamesAndPermissions');
      var jurisdictions = get(this, 'jurisdictionNamesAndPermissions');
      var regex = /\|.*/gi;
      var orgNames = [];
      if (companies) {
        orgNames.push(companies.split(',').map(function (c) {
          return c.trim().replace(regex, '');
        }));
      }
      if (jurisdictions) {
        orgNames.push(companies.split(',').map(function (c) {
          return c.trim().replace(regex, '');
        }));
      }
      var uniqueOrgs = new Set(orgNames.flatten());
      return uniqueOrgs;
    }),
    organisationNamesAndPermissions: computed('companyNamesAndPermissions', 'jurisdictionNamesAndPermissions', function () {
      var companies = get(this, 'companyNamesAndPermissions');
      var jurisdictions = get(this, 'jurisdictionNamesAndPermissions');
      var orgPermissions = {};
      var orgObjects = [];
      this.addOrgPermissions(companies, orgPermissions);
      this.addOrgPermissions(jurisdictions, orgPermissions);
      for (var key in orgPermissions) {
        orgObjects.push({
          name: key,
          permissions: Array.from(new Set(orgPermissions[key])).join(', ')
        });
      }
      return orgObjects;
    }),
    addOrgPermissions: function addOrgPermissions(orgs, orgPermissions) {
      if (orgs) {
        orgs.split(',').map(function (c) {
          var _c$split = c.split('|'),
            _c$split2 = _slicedToArray(_c$split, 2),
            name = _c$split2[0],
            permission = _c$split2[1];
          name = name.trim();
          if (!orgPermissions[name]) orgPermissions[name] = [];
          orgPermissions[name].push(permission);
        });
      }
    }
  });
  _exports.default = _default;
});