define("tmp-for-all/templates/errors/auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AUPUwThF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Authorisation Error\"]],{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[0,\"An attempt was made to access a route or resource without the appropriate permissions.\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"If you're sure that you have the appropriate permissions perhaps you need to check which profile you are currently operating under.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/errors/auth.hbs"
    }
  });
  _exports.default = _default;
});