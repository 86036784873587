define("tmp-for-all/mixins/routes/edit-worksite/work-item-route-mixin", ["exports", "tmp-for-all/mixins/routes/edit-worksite/base-mixin", "tmp-for-all/helpers/permission/edit-workitems"], function (_exports, _baseMixin, _editWorkitems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var get = Ember.get,
    set = Ember.set,
    isEmpty = Ember.isEmpty,
    computed = Ember.computed;

  /**
   * This mixin extends the base mixin from ./base-mixin
   * providing stuff specific to the work-item edit page on the worksite wizard
   * This mixin should only be used in routes where the user edits work items for a worksite
   */
  var _default = Ember.Mixin.create(_baseMixin.default, {
    relationsAreDirty: computed.alias('worksite.workItemsIsDirty'),
    afterModel: function afterModel(model) {
      var _this = this;
      this._super.apply(this, arguments);
      var worksiteType = get(model, 'type');
      var permission = (0, _editWorkitems.editWorkItemsCheck)([worksiteType]);
      return get(this, 'authorization').checkAllowableNavigation(this, permission, model).catch(function () {
        return _this.replaceWith('index');
      }).then(function () {
        return get(model, 'workItems');
      }).then(function (workItems) {
        // if there are no work items then add an empty one for editing UI purposes
        if (isEmpty(workItems)) model.addEmptyWorkItem();
      });
    },
    save: function save() {
      var worksite = get(this, 'worksite');
      return worksite.saveWorkItems();
    },
    cleanup: function cleanup() {
      var worksite = get(this, 'worksite');
      worksite.destroyOrRollback();
      return worksite.reloadRelations(['workItems']);
    },
    actions: {
      addRoadLocationsToWorkItem: function addRoadLocationsToWorkItem(locations, workItem) {
        set(workItem, 'roadLocations', locations);
      },
      addWorkItem: function addWorkItem(worksite, location) {
        var newRecord = this.store.createRecord('work-item', _defineProperty({}, get(worksite, 'type'), worksite));
        if (location === 'TOP') {
          return get(worksite, 'workItems').unshiftObject(newRecord);
        }
        return get(worksite, 'workItems').pushObject(newRecord);
      },
      removeWorkItem: function removeWorkItem(workItem) {
        workItem.deleteRecord();
      }
    }
  });
  _exports.default = _default;
});