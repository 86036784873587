define("tmp-for-all/templates/worksite/edit/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bGsgv2hU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"worksite/details-form\",null,[[\"worksite\",\"updateWorksiteType\",\"updateRetro\",\"updateAccessActs\",\"updateLocalGovernmentAct\",\"finishDetails\",\"next\",\"nextGlobalPermit\",\"saveProgress\",\"saveAndReturn\"],[[23,[\"model\"]],[27,\"route-action\",[\"updateWorksiteType\"],null],[27,\"route-action\",[\"updateRetro\"],null],[27,\"route-action\",[\"updateAccessActs\"],null],[27,\"route-action\",[\"updateLocalGovernmentAct\"],null],[27,\"route-action\",[\"finishDetails\"],null],[27,\"route-action\",[\"next\"],null],[27,\"route-action\",[\"nextGlobalPermit\"],null],[27,\"route-action\",[\"saveProgress\"],null],[27,\"route-action\",[\"saveAndReturn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite/edit/details.hbs"
    }
  });
  _exports.default = _default;
});