define("tmp-for-all/components/shared/form/boolean-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var OPTIONS = {
    YES: 'Yes',
    NO: 'No',
    UNKNOWN: 'Unknown'
  };
  var _default = Ember.Component.extend({
    classNames: ['boolean-detail-element'],
    inputName: Ember.computed('label', function () {
      return this.label.dasherize();
    }),
    options: Ember.computed('value', function () {
      return [{
        name: this.inputName,
        value: OPTIONS.YES,
        label: OPTIONS.YES,
        checked: this.value === OPTIONS.YES
      }, {
        name: this.inputName,
        value: OPTIONS.NO,
        label: OPTIONS.NO,
        checked: this.value === OPTIONS.NO
      }, {
        name: this.inputName,
        value: OPTIONS.UNKNOWN,
        label: OPTIONS.UNKNOWN,
        checked: this.value === OPTIONS.UNKNOWN
      }];
    }),
    showDetail: Ember.computed.equal('value', 'Yes'),
    actions: {
      update: function update(selectedValue) {
        this.set('value', selectedValue);
        if (selectedValue !== 'Yes') this.set('detail', null);
      }
    }
  });
  _exports.default = _default;
});