define("tmp-for-all/mixins/controllers/worksite-type", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Mixin.create({
    worksiteTypes: Object.keys(_constants.WORKSITE_TYPES).map(function (key) {
      return {
        value: key,
        name: _constants.WORKSITE_TYPES[key]
      };
    }),
    actions: {
      setWorksiteType: function setWorksiteType(selected) {
        var value = selected ? get(selected, 'value') : null;
        set(this, 'worksiteType', value);
      }
    }
  });
  _exports.default = _default;
});