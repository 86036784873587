define("tmp-for-all/components/svg-icon/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['icon'],
    height: '16px',
    width: '16px',
    fill: 'currentColor',
    styleAttr: computed('width', 'height', 'fill', function () {
      // eslint-disable-next-line new-cap
      return new Ember.String.htmlSafe('height:' + get(this, 'height') + '; ' + 'width: ' + get(this, 'width') + '; ' + 'fill: ' + get(this, 'fill'));
    }),
    path: computed(function () {
      // HACK:: use ember assets in development otherwise relative uri
      return _environment.default.environment === 'development' ? '' : _environment.default.apiHost;
    })
  });
  _exports.default = _default;
});