define("tmp-for-all/routes/tmps/queue", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/mixins/routes/tmp-search-route-mixin", "tmp-for-all/utils/search-filter", "tmp-for-all/utils/tmp-filters", "tmp-for-all/constants"], function (_exports, _routeMixin, _tmpSearchRouteMixin, _searchFilter, _tmpFilters, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    RSVP = Ember.RSVP;
  var processingQueueFilters = _tmpFilters.default.processingQueueFilters;
  var _default = Ember.Route.extend(_routeMixin.default, _tmpSearchRouteMixin.default, {
    queryFilter: computed.alias('model.queryFilter'),
    tmps: computed.alias('model.tmps'),
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    routeNavigator: Ember.inject.service(),
    queryParams: R.merge({
      page: {
        refreshModel: true
      },
      freetext: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      }
    }, _searchFilter.default.getRouteQueryParamArgs(processingQueueFilters)),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'session.currentUser');
      }).then(function (user) {
        return get(user, 'selectedDomain');
      }).then(function (domain) {
        if (domain !== 'jurisdiction') {
          get(_this, 'flashMessages').danger('Must be operating under an RCA');
          return _this.transitionTo('index');
        }
      });
    },
    model: function model(params) {
      get(this, 'routeNavigator').setWayPoint(this);
      var selectedDomainId = get(this, 'session.currentUser.selectedDomainId');
      params.filter = {
        where: {
          and: [{
            or: [{
              jurisdictionId: selectedDomainId
            }, {
              currentSecondaryRcaId: selectedDomainId
            }]
          }, {
            isDeclined: false
          }, {
            type: _constants.TMP_TYPES.SUBMISSION
          }]
        },
        order: 'submittedAt ASC'
      };
      if (params.order) params.filter.order = params.order;
      delete params.order;
      processingQueueFilters.forEach(function (filter) {
        filter.updateWhereFilter(params, params.filter.where);
      });
      params.customFilter = JSON.stringify(params.filter.where);
      return RSVP.hash({
        tmps: this.findPaged('tmp-search-result', params),
        queryFilter: params.filter,
        userDomainId: selectedDomainId
      });
    }
  });
  _exports.default = _default;
});