define("tmp-for-all/mixins/controllers/list-order-controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var setProperties = Ember.setProperties;
  var _default = Ember.Mixin.create({
    queryParams: ['order'],
    actions: {
      setOrder: function setOrder(orderBy) {
        setProperties(this, {
          order: orderBy,
          page: 1
        });
      }
    }
  });
  _exports.default = _default;
});