define("tmp-for-all/routes/projects/create", ["exports", "tmp-for-all/mixins/company-search"], function (_exports, _companySearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP;
  var _default = Ember.Route.extend(_companySearch.default, {
    authorization: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'session.currentUser');
      }).then(function (user) {
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'createProjects', {
          modelName: get(user, 'selectedDomain'),
          modelId: get(user, 'selectedDomainId')
        });
      }).catch(function () {
        return _this.router.replaceWith('index');
      });
    },
    model: function model() {
      var _this2 = this;
      return get(this, 'session.currentUser').then(function (user) {
        return get(user, 'selectedDomain') === 'jurisdiction' ? _this2.store.findRecord(get(user, 'selectedDomain'), get(user, 'selectedDomainId')) : RSVP.resolve();
      }).then(function (jurisdiction) {
        return _this2.store.createRecord('project', {
          jurisdiction: jurisdiction
        });
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('projects.list');
      },
      updateCompany: function updateCompany(company) {
        set(this, 'controller.model.company', company);
      },
      createProject: function createProject() {
        var _this3 = this;
        get(this, 'controller.model').save().then(function () {
          get(_this3, 'flashMessages').success('Project creation was successful');
          _this3.transitionTo('projects.view', get(_this3, 'controller.model.id'));
        }).catch(function () {
          get(_this3, 'flashMessages').danger('There was a problem creating this Project');
        });
      },
      willTransition: function willTransition() {
        var currentProject = this.get('controller.model');
        if (currentProject) {
          currentProject.destroyOrRollback();
        }
      }
    }
  });
  _exports.default = _default;
});