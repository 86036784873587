define("tmp-for-all/models/company-to-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    company: belongsTo('company', {
      async: true
    }),
    role: attr('string', {
      defaultValue: 'read-only'
    }),
    tmpUser: belongsTo('tmp-user', {
      async: true
    })
  });
  _exports.default = _default;
});