define("tmp-for-all/components/search-results/user-search-results/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tnC8c4Ed",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"table\"],[11,\"class\",\"table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n  \"],[7,\"tr\"],[9],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Name\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Email Address\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Organisation\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"STMS Number\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[27,\"await\",[[27,\"permitted\",[\"admin\"],[[\"model\"],[[23,[\"authManager\",\"currentUser\",\"domain\"]]]]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"th\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"users\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"search-results/user-search-results/list/item\",null,[[\"user\",\"becomeUser\"],[[22,1,[]],[23,[\"becomeUser\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/user-search-results/list/template.hbs"
    }
  });
  _exports.default = _default;
});