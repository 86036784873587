define("tmp-for-all/helpers/file-size", ["exports", "filesize"], function (_exports, _filesize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filesize = filesize;
  function filesize(params) {
    if (_filesize.default && params[0]) {
      return (0, _filesize.default)(params[0]);
    }
    return '';
  }
  var _default = Ember.Helper.helper(filesize);
  _exports.default = _default;
});