define("tmp-for-all/mixins/components/worksite-filter-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    setProperties = Ember.setProperties;
  var _default = Ember.Mixin.create({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var getQueryParams = get(this, 'getQueryParams');
      var updateFilterParams = get(this, 'updateFilterParams');
      var index = get(this, 'index');
      if (getQueryParams && updateFilterParams) {
        updateFilterParams(function (controller) {
          // set the initial query parameters on load
          setProperties(_this, getQueryParams(controller, index));
        });
      }
    }
  });
  _exports.default = _default;
});