define("tmp-for-all/components/tmp-view/conditions-wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    isOpen: false,
    userCanEdit: computed('secondaryJurisdictionId', 'userJurisdictionId', 'userIsCurrentSecondary', function () {
      var sjId = Number(get(this, 'secondaryJurisdictionId'));
      var ujId = get(this, 'userJurisdictionId');
      return sjId === ujId && get(this, 'userIsCurrentSecondary');
    })
  });
  _exports.default = _default;
});