define("tmp-for-all/mixins/routes/add-auditor-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Mixin.create({
    actions: {
      addAuditor: function addAuditor(user) {
        var type = 'AUDITOR';
        var newContact = this.store.createRecord('worksite-contact', {
          type: type
        });
        set(newContact, 'user', user);
        set(newContact, 'jurisdiction', get(user, 'domain'));
        set(newContact, 'company', get(user, 'domain.company'));
        newContact.snapshotBelongsTo(['company', 'user']);
        var worksite = this.modelFor('worksite-submission');
        var contacts = get(worksite, 'contacts');
        contacts.pushObject(newContact);
        worksite.saveContacts();
      }
    }
  });
  _exports.default = _default;
});