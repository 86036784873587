define("tmp-for-all/components/shared/form/currency-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Lv5/HIMO",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"for\",[21,\"inputID\"]],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"toolTipText\"]]],null,{\"statements\":[[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"        \"],[1,[21,\"toolTipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"input\"],[12,\"id\",[21,\"inputId\"]],[12,\"value\",[21,\"formattedValue\"]],[11,\"min\",\"0\"],[12,\"focusOut\",[21,\"focusOut\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"class\",[21,\"class\"]],[12,\"required\",[21,\"required\"]],[12,\"step\",[21,\"stepWithDefault\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"updateValue\"],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"number\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"error\"],[9],[1,[22,1,[\"message\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/currency-field/template.hbs"
    }
  });
  _exports.default = _default;
});