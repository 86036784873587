define("tmp-for-all/components/content-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Q3bB7qB3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"pageHeadingText\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"page-heading\",null,[[\"headingText\",\"subHeadingText\",\"headingTestClass\"],[[23,[\"pageHeadingText\"]],[23,[\"pageSubHeadingText\"]],[23,[\"pageHeadingTestClass\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/content-container/template.hbs"
    }
  });
  _exports.default = _default;
});