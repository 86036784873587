define("tmp-for-all/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;
  function add(params) {
    return params.reduce(function (acc, val) {
      return acc + val;
    }, 0);
  }
  var _default = Ember.Helper.helper(add);
  _exports.default = _default;
});