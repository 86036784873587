define("tmp-for-all/routes/activate/new-company", ["exports", "tmp-for-all/mixins/routes/modals/new-company-route"], function (_exports, _newCompanyRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.UnprotectedRoute.extend(_newCompanyRoute.NewCompanyModalMixin, {
    actions: {
      // @override of NewCompanyModalMixin.actions.save
      save: function save(company) {
        this.send('selectNewCompany', company);
        this.replaceWith(get(this, 'parentRouteName'));
      }
    }
  });
  _exports.default = _default;
});