define("tmp-for-all/components/maps/static-map-layout/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    isOutdated: computed('tmpLayout.hasDirtyAttributes', function () {
      var changedAttributes = get(this, 'tmpLayout').changedAttributes();
      return Object.keys(changedAttributes).indexOf('impacts') >= 0;
    }),
    session: Ember.inject.service('session'),
    imageUrl: computed('tmpLayout.id', 'width', 'height', 'tmpLayout.impacts', function () {
      var layoutId = this.tmpLayout.id;
      var width = get(this, 'width');
      var height = get(this, 'height');
      var authToken = get(this, 'session.data.authenticated.id');
      // Append timestamp to force Ember to reload the image
      var timestamp = Date.now();
      if (!layoutId || !width || !height || !authToken) {
        return '';
      } else {
        return "".concat(_environment.default.apiHost, "/api/layouts/").concat(layoutId, "/geo-json-image.png?width=").concat(width, "&height=").concat(height, "&access_token=").concat(authToken, "&t=").concat(timestamp);
      }
    })
  });
  _exports.default = _default;
});