define("tmp-for-all/routes/conditions/worksite/edit", ["exports", "tmp-for-all/mixins/routes/conditions-edit-base"], function (_exports, _conditionsEditBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
    get = Ember.get;
  var _default = Ember.Route.extend(_conditionsEditBase.default, {
    model: function model(_ref) {
      var conditionId = _ref.condition_id;
      var worksiteModel = this.modelFor('conditions.worksite');
      return RSVP.hash({
        condition: this.store.findRecord('condition', conditionId, {
          include: 'condition-group-to-conditions'
        }),
        conditionGroups: get(worksiteModel, 'conditionGroups'),
        conditionType: get(worksiteModel, 'conditionType')
      });
    }
  });
  _exports.default = _default;
});