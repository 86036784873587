define("tmp-for-all/templates/worksites/create/wizard/work-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YA6kb9rT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[[27,\"concat\",[\"Add Work to \",[23,[\"model\",\"name\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"worksite/work-items-form\",null,[[\"worksite\",\"inWizard\",\"addWorkItem\",\"removeWorkItem\",\"finishWorkItems\",\"next\",\"addRoadLocationsToWorkItem\",\"saveProgress\",\"saveAndReturn\"],[[23,[\"model\"]],true,[27,\"route-action\",[\"addWorkItem\"],null],[27,\"route-action\",[\"removeWorkItem\"],null],[27,\"route-action\",[\"finish\"],null],[27,\"route-action\",[\"next\"],null],[27,\"route-action\",[\"addRoadLocationsToWorkItem\"],null],[27,\"route-action\",[\"saveProgress\"],null],[27,\"route-action\",[\"saveAndReturn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksites/create/wizard/work-items.hbs"
    }
  });
  _exports.default = _default;
});