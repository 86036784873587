define("tmp-for-all/components/worksite/resolve-notice/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'maxResolveDate', new Date());
    },
    resolveHow: 'resolve',
    noticeType: computed.alias('notice.noticeType'),
    actionWord: computed('resolveHow', function () {
      switch (get(this, 'resolveHow')) {
        case 'resolve':
          return 'Accept';
        case 'reject':
          return 'Reject';
        default:
          throw new Error('Invalid resolve action');
      }
    }),
    descriptiveActionWord: computed('resolveHow', function () {
      switch (get(this, 'resolveHow')) {
        case 'resolve':
          return 'Acceptance';
        case 'reject':
          return 'Rejection';
        default:
          throw new Error('Invalid resolve action');
      }
    }),
    resolveButtonText: computed('noticeType', 'actionWord', function () {
      switch (get(this, 'noticeType')) {
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK:
          return "".concat(get(this, 'actionWord'), " work completion notice");
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.START_WORK_INFRINGEMENT:
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK_INFRINGEMENT:
          return 'Acknowledge Notice';
        default:
          return "Resolve ".concat(_constants.WORKSITE_NOTICE_TYPES[get(this, 'noticeType')], " Notice");
      }
    }),
    headerText: computed('noticeType', 'actionWord', function () {
      switch (get(this, 'noticeType')) {
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK:
          return "".concat(get(this, 'actionWord'), " Work Completion Notice");
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.STOP_WORK:
          return 'Resolve Stop Work Notice';
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.NON_CONFORMANCE:
          return 'Resolve Non Conformance Notice';
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.START_WORK_INFRINGEMENT:
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK_INFRINGEMENT:
          return 'Acknowledge Notice';
        default:
          return 'Resolve Notice';
      }
    }),
    dateInputLabel: computed('noticeType', 'descriptiveActionWord', function () {
      switch (get(this, 'noticeType')) {
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK:
          return "Date of ".concat(get(this, 'descriptiveActionWord'));
        default:
          return 'Date of Resolution';
      }
    }),
    textAreaLabel: computed('noticeType', 'descriptiveActionWord', function () {
      switch (get(this, 'noticeType')) {
        case _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK:
          return "".concat(get(this, 'descriptiveActionWord'), " Details");
        default:
          return 'Resolution Details';
      }
    })
  });
  _exports.default = _default;
});