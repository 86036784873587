define("tmp-for-all/components/shared/form/file-upload/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vwA77RUV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"file-name\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"fileData\",\"isImage\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"picture\",\"16px\",\"15px\",\"icon--align-top t-picture-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"document\",\"15px\",\"15px\",\"icon--align-top t-document-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[1,[21,\"displayText\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"a\"],[11,\"class\",\"file-icon delete-file\"],[11,\"href\",\"#\"],[9],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\"],[\"cross\",\"10px\",\"10px\"]]],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[23,[\"removeFile\"]],[23,[\"fileData\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/file-upload/item/template.hbs"
    }
  });
  _exports.default = _default;
});