define("tmp-for-all/components/maps/address-finder/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    intl: Ember.inject.service(),
    results: [],
    /**
     * zoomFromSuggestion
     * Determine zoom level from chosen search suggestion
     * @param  {String} suggestion
     * @return {Number}
     */
    zoomFromSuggestion: function zoomFromSuggestion(suggestion) {
      if (suggestion && this.get('useZoomSuggestion')) {
        var regions = Object.keys(_constants.REGIONS).filter(function (x) {
          return suggestion.toLowerCase().startsWith(x + ',');
        });
        if (regions.length === 1) {
          this.get('router').transitionTo('planning-map.region', [regions[0]]);
        }
      }
      return _constants.MAP_SETTINGS.DEFAULT[get(this, 'intl.locale')[0].toUpperCase()].ADDRESS_ZOOM;
    },
    updateTerm: function updateTerm(term) {
      var _this = this;
      this.get('ajax').getWorksiteAddressSuggestions(term).then(function (suggestions) {
        _this.set('results', suggestions);
      });
    },
    selectResult: function selectResult(result) {
      this.$('#address').val(result.address);
      this.send('clearSearch');
      this.send('chooseSuggestion', result);
    },
    actions: {
      clearSearch: function clearSearch() {
        this.set('results', []);
      },
      chooseSuggestion: function chooseSuggestion(suggestion) {
        var _this2 = this;
        this.get('ajax').getWorksiteAddressCoordinates(suggestion.address, suggestion.id).then(function (coords) {
          if (coords && coords.lat && coords.lng) {
            coords.zoom = _this2.zoomFromSuggestion(suggestion.address);
            _this2.get('updateMap')(coords);
          }
        });
      }
    }
  });
  _exports.default = _default;
});