define("tmp-for-all/templates/companies/merge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aIOgiLlU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"heading-block underline-dotted\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"Merge Organisation: \"],[1,[23,[\"model\",\"name\"]],false],[0,\" (ID: \"],[1,[23,[\"model\",\"id\"]],false],[0,\")\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"company-merge\",null,[[\"company\",\"searchCompanies\",\"mergeOrganisation\",\"showSpinner\"],[[23,[\"model\"]],[27,\"route-action\",[\"searchCompaniesToMerge\"],null],[27,\"route-action\",[\"mergeOrganisation\"],null],[23,[\"showSpinner\"]]]]],false],[0,\"\\n  \"],[1,[27,\"loading-overlay\",null,[[\"showOverlay\"],[[23,[\"showSpinner\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/companies/merge.hbs"
    }
  });
  _exports.default = _default;
});