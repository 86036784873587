define("tmp-for-all/routes/worksites/queue", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/constants"], function (_exports, _routeMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP;
  var _default = Ember.Route.extend(_routeMixin.default, {
    authorization: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    queryParams: {
      order: {
        refreshModel: true
      },
      jurisdictionAreaName: {
        refreshModel: true
      },
      worksiteType: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'authManager.currentUser');
      }).then(function (user) {
        var domainType = get(user, 'selectedDomain');
        if (domainType !== 'jurisdiction') {
          get(_this, 'flashMessages').danger('Must be operating under an RCA');
          return _this.transitionTo('index');
        }
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'camReviewer', {
          modelName: get(user, 'selectedDomain'),
          modelId: get(user, 'selectedDomainId')
        });
      }).catch(function () {
        return _this.replaceWith('index');
      });
    },
    model: function model(params, transition) {
      var _this2 = this;
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this2, 'authManager.currentUser');
      }).then(function (user) {
        var selectedDomainId = get(user, 'selectedDomainId');
        var selectedWorksiteType = null;
        // query all worksites for jurisdiction
        var whereQuery = {
          order: params.order ? params.order : 'submittedAt DESC',
          where: {
            type: _constants.WORKSITE_MODEL_TYPES.SUBMITTED,
            jurisdictionId: selectedDomainId,
            isDeclined: false
          }
        };
        delete params.order;
        if (params.jurisdictionAreaName) {
          whereQuery.where.jurisdictionAreaName = params.jurisdictionAreaName;
          params.page = 1;
          delete transition.queryParams.page;
        }
        if (params.worksiteType) {
          whereQuery.where.worksiteType = params.worksiteType;
          params.page = 1;
          delete transition.queryParams.page;
          selectedWorksiteType = {
            name: _constants.WORKSITE_TYPES[params.worksiteType],
            value: params.worksiteType
          };
        }
        return RSVP.hash({
          user: user,
          worksites: _this2.findPaged('worksite-search-result', R.merge(params, {
            filter: whereQuery
          })),
          jurisdictionAreas: _this2.store.query('jurisdiction-area', {
            filter: {
              where: {
                jurisdictionId: selectedDomainId
              }
            }
          }),
          selectedWorksiteType: selectedWorksiteType,
          selectedJurisdictionArea: _this2.store.query('jurisdiction-area', {
            filter: {
              where: {
                name: params.jurisdictionAreaName
              }
            }
          }).then(function (jurisdictionAreas) {
            return jurisdictionAreas.objectAt(0);
          })
        });
      });
    },
    actions: {
      claimWorksite: function claimWorksite(worksite) {
        return worksite.claim(get(this, 'authManager.currentUser'));
      }
    }
  });
  _exports.default = _default;
});