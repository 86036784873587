define("tmp-for-all/components/shared/contact-selector/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var UTILITY = 'utility';
  var COMPANY = 'company';
  var PRIVATE = 'private';
  var GOVT = 'government';
  var _default = Ember.Component.extend({
    classNames: ['client-details'],
    allowPrivateCompanyOption: false,
    disableCompanySelect: false,
    groupLabel: 'Client Details',
    companyFilterOptions: computed('allowPrivateCompanyOption', 'filterCompanyType', function () {
      var nameKey = "companyFilter-".concat(get(this, 'elementId'));
      var companyType = get(this, 'filterCompanyType');
      var options = [{
        name: nameKey,
        value: UTILITY,
        label: 'Utility',
        checked: companyType === UTILITY
      }, {
        name: nameKey,
        value: COMPANY,
        label: 'Organisation or Private Individual',
        checked: companyType === COMPANY
      }, {
        name: nameKey,
        value: GOVT,
        label: 'Government Agency',
        checked: companyType === GOVT
      }];
      if (get(this, 'allowPrivateCompanyOption')) {
        options.push({
          name: nameKey,
          value: PRIVATE,
          label: 'Private',
          checked: companyType === PRIVATE
        });
      }
      return options;
    }),
    filterCompanyType: UTILITY,
    init: function init() {
      this._super.apply(this, arguments);
      var selectedCompany = get(this, 'selectedCompany');
      if (!selectedCompany) return;
      if (get(selectedCompany, 'isUtility')) {
        set(this, 'filterCompanyType', UTILITY);
      } else if (get(selectedCompany, 'isGovernmentAgency')) {
        set(this, 'filterCompanyType', GOVT);
      } else if (get(selectedCompany, 'isPrivateIndividual')) {
        set(this, 'filterCompanyType', PRIVATE);
      } else {
        // It can only be a Company if it got here
        set(this, 'filterCompanyType', COMPANY);
      }
    },
    viewingUtilityCompanies: computed.equal('filterCompanyType', UTILITY),
    viewingRegularCompanies: computed.equal('filterCompanyType', COMPANY),
    viewingPrivateCompanies: computed.equal('filterCompanyType', PRIVATE),
    viewingGovernmentAgencies: computed.equal('filterCompanyType', GOVT),
    companyNotSelected: computed.not('selectedCompany.id'),
    allowNewContact: computed.not('companyNotSelected'),
    allowNewCompany: computed.alias('viewingRegularCompanies'),
    toolTip: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_OWNERSHIP.CLIENT_TYPE),
    actions: {
      updateFilter: function updateFilter(type) {
        get(this, 'selectCompany')(null);
        var selectUser = get(this, 'selectUser');
        if (selectUser) selectUser(null);
        set(this, 'filterCompanyType', type);
      },
      searchCompanies: function searchCompanies(q) {
        var isUtility = get(this, 'viewingUtilityCompanies');
        var isGovernmentAgency = get(this, 'viewingGovernmentAgencies');
        return get(this, 'searchCompanies')(q, {
          isUtility: isUtility,
          isGovernmentAgency: isGovernmentAgency
        });
      },
      createCompanySuggestion: function createCompanySuggestion(term) {
        return 'Can\'t find your company? Click here to add it';
      }
    }
  });
  _exports.default = _default;
});