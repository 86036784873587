define("tmp-for-all/routes/tmp-draft/layouts/view/map", ["exports", "tmp-for-all/mixins/routes/layout-map-readonly-mixin"], function (_exports, _layoutMapReadonlyMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_layoutMapReadonlyMixin.default, {
    returnRoute: 'tmp-draft.view'
  });
  _exports.default = _default;
});