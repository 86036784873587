define("tmp-for-all/components/user-form/domain-role-selectors/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    set = Ember.set,
    isEmpty = Ember.isEmpty;
  var GOVT = 'government';
  var COMPANY = 'company';
  var UTILITY = 'utility';
  var _default = Ember.Component.extend({
    orgTypes: computed('filterCompanyType', function () {
      var companyType = get(this, 'filterCompanyType');
      var options = [{
        value: UTILITY,
        label: 'Utility',
        checked: companyType === UTILITY
      }, {
        value: COMPANY,
        label: 'Organisation or Private Individual',
        checked: companyType === COMPANY
      }, {
        value: GOVT,
        label: 'Government Agency',
        checked: companyType === GOVT
      }];
      return options;
    }),
    filterCompanyType: UTILITY,
    init: function init() {
      this._super.apply(this, arguments);
      var assignment = get(this, 'assignment');
      if (!assignment || assignment && !assignment.domainItem) return;
      if (get(assignment.domainItem, 'isUtility')) {
        set(this, 'filterCompanyType', UTILITY);
      } else if (get(assignment.domainItem, 'isGovernmentAgency')) {
        set(this, 'filterCompanyType', GOVT);
      } else {
        // It can only be a Company if it got here
        set(this, 'filterCompanyType', COMPANY);
      }
    },
    domainDisplayName: computed('filterCompanyType', 'domainType', function () {
      if (get(this, 'domainType') === 'jurisdiction') {
        return 'RCA';
      } else if (get(this, 'filterCompanyType') === GOVT) {
        return 'Government agencies';
      } else if (get(this, 'filterCompanyType') === UTILITY) {
        return 'Utility';
      } else {
        return 'Organisation';
      }
    }),
    viewingGovernmentAgencies: computed.equal('filterCompanyType', GOVT),
    viewingUtility: computed.equal('filterCompanyType', UTILITY),
    // An assignment for a user to a domain can only be changed by the current user
    // if the current user has permissions in that same domain, and is acting under
    // that domain.
    readonly: computed('assignment', function () {
      var _this = this;
      var assignment = get(this, 'assignment');
      var editable = get(this, 'userPermissionAssignments');
      var enabled = editable.find(function (e) {
        return e.domainType === get(_this, 'domainType') && e.domainId === assignment.domainId;
      });
      return !enabled;
    }),
    actions: {
      domainItemDidChange: function domainItemDidChange(assignmentObject, domainItem) {
        set(assignmentObject, 'domainItem', domainItem);
        set(assignmentObject, 'domainId', get(domainItem, 'id'));
        var assignment = assignmentObject.assignment,
          domainId = assignmentObject.domainId,
          permission = assignmentObject.permission;
        if (permission && !isEmpty(get(permission, 'id'))) {
          get(this, 'updatePermissionAssignment')(assignment, domainId, permission);
        }
      },
      domainItemRoleDidChange: function domainItemRoleDidChange(assignmentObject, permission) {
        set(assignmentObject, 'permission', permission);
        var assignment = assignmentObject.assignment,
          domainId = assignmentObject.domainId;
        if (domainId) {
          get(this, 'updatePermissionAssignment')(assignment, domainId, permission);
        }
      },
      searchCompaniesFiltered: function searchCompaniesFiltered(q) {
        var isGovernmentAgency = get(this, 'viewingGovernmentAgencies');
        var isUtility = get(this, 'viewingUtility');
        return get(this, 'searchCompanies')(q, {
          isGovernmentAgency: isGovernmentAgency,
          isUtility: isUtility
        });
      }
    }
  });
  _exports.default = _default;
});