define("tmp-for-all/components/reports/processing-time-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'numberOfDays', 30);
      if (!get(this, 'modelNameLink')) set(this, 'modelNameLink', 'worksite-processing-times');
    },
    fromDateLocal: computed('fromDate', function () {
      if (!get(this, 'fromDate')) return;
      return moment(get(this, 'fromDate')).format('YYYY-MM-DD');
    }),
    toDateLocal: computed('toDate', function () {
      if (!get(this, 'toDate')) return;
      return moment(get(this, 'toDate')).format('YYYY-MM-DD');
    }),
    days: computed(function () {
      var numberOfDays = get(this, 'numberOfDays');
      var arrayOfNumbers = new Array(numberOfDays);
      for (var i = 0; i < numberOfDays; i++) {
        arrayOfNumbers[i] = {
          val: i,
          display: "".concat(i, " days")
        };
      }
      arrayOfNumbers[0] = {
        val: null,
        display: 'Any'
      };
      arrayOfNumbers[1].display = '1 day';
      return arrayOfNumbers;
    }),
    months: [{
      index: 0,
      display: 'This Month'
    }, {
      index: 1,
      display: 'Last Month'
    }, {
      index: 2,
      display: 'Two Months Ago'
    }, {
      index: 3,
      display: 'Three Months Ago'
    }],
    selectedMonth: computed('months', 'selectedMonthIndex', function () {
      var index = get(this, 'selectedMonthIndex') || 1;
      return get(this, 'months').objectAt(index);
    }),
    selectedProcessingTime: computed('days', 'processingTime', function () {
      return get(this, 'days').objectAt(get(this, 'processingTime'));
    }),
    count: computed.alias('results.length'),
    processingUsers: computed('results.meta.processingUsers', function () {
      var userArray = [{
        id: null,
        fullname: 'All Users'
      }];
      if (get(this, 'results.meta.processingUsers')) {
        var processingUsers = get(this, 'results.meta.processingUsers').toArray();
        return userArray.concat(processingUsers);
      } else {
        return userArray;
      }
    })
  });
  _exports.default = _default;
});