define("tmp-for-all/templates/reports/worksite-processing-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZCP7yr2Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"isFullWidth\",\"isListingPage\"],[true,true]],{\"statements\":[[0,\"\\n  \"],[4,\"listing-page-heading\",null,null,{\"statements\":[[0,\"Worksite Processing Times\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"reports/processing-time-filter\",null,[[\"fromDate\",\"fromDateDidChange\",\"headingUnderlineColor\",\"monthDidChange\",\"processingTime\",\"processingTimeDidChange\",\"queryFilter\",\"resetFilter\",\"results\",\"selectedMonthIndex\",\"selectedUser\",\"toDate\",\"toDateDidChange\",\"userDidChange\"],[[23,[\"fromDate\"]],[27,\"action\",[[22,0,[]],\"fromDateDidChange\"],null],\"blue\",[27,\"action\",[[22,0,[]],\"monthDidChange\"],null],[23,[\"processingTime\"]],[27,\"action\",[[22,0,[]],\"processingTimeDidChange\"],null],[23,[\"model\",\"queryFilter\"]],[27,\"action\",[[22,0,[]],\"resetFilter\"],null],[23,[\"model\",\"results\"]],[23,[\"model\",\"selectedMonthIndex\"]],[23,[\"model\",\"selectedUser\"]],[23,[\"toDate\"]],[27,\"action\",[[22,0,[]],\"toDateDidChange\"],null],[27,\"action\",[[22,0,[]],\"userDidChange\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/reports/worksite-processing-time.hbs"
    }
  });
  _exports.default = _default;
});