define("tmp-for-all/components/nzta-detour/closure/component", ["exports", "tmp-for-all/utils/nzta-detour-helper"], function (_exports, _nztaDetourHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    closureHelpExpanded: true,
    closure: Ember.computed('draft', 'published', function () {
      return this.draft || this.published;
    }),
    hasPublished: Ember.computed.bool('published'),
    noPublished: Ember.computed.not('hasPublished'),
    hasDraft: Ember.computed.bool('draft'),
    noDraft: Ember.computed.not('hasDraft'),
    stateHighwayNumbers: _nztaDetourHelper.stateHighwayNumbers,
    showLinkCopiedMessage: false,
    linkCopied: function linkCopied() {
      var _this = this;
      this.set('showLinkCopiedMessage', true);
      Ember.run.later(function () {
        return _this.set('showLinkCopiedMessage', false);
      }, 2000);
    },
    actions: {
      editPart: function editPart() {
        if (!this.draft) {
          this.set('draft', this.createDraft(this.published));
        }
        this.editSection(this.draft);
      },
      stateHighwayChanged: function stateHighwayChanged(selection) {
        this.set('closure.stateHighway', selection.value);
      },
      copyLink: function copyLink(detourLink) {
        var _this2 = this;
        if (navigator.clipboard) {
          navigator.clipboard.writeText(detourLink).then(function () {
            console.log('Async: Copying to clipboard was successful!');
            _this2.linkCopied();
          }, function (err) {
            console.error('Async: Could not copy text: ', err);
          });
        } else {
          try {
            var aux = document.createElement('div');
            aux.setAttribute('contentEditable', true);
            aux.innerHTML = this.$('.detour-copy-link__link-value').text();
            document.body.appendChild(aux);
            window.getSelection().selectAllChildren(aux);
            var successful = document.execCommand('copy');
            document.body.removeChild(aux);
            console.log('Fallback: Copying text command was ' + (successful ? 'successful' : 'unsuccessful'));
            this.linkCopied();
          } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
          }
        }
      }
    }
  });
  _exports.default = _default;
});