define("tmp-for-all/templates/companies/edit/customer-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lcbefnqU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\",\"pageSubHeadingText\"],[\"Add customer number\",[23,[\"model\",\"companyName\"]]]],{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[0,\"Enter the organisation customer number below to update the organisation details and update all fees associated with the customer\"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[1,[27,\"shared/customer-number-form\",null,[[\"customerNumber\",\"saveCustomerNumber\"],[[23,[\"model\",\"customerNumber\"]],[27,\"route-action\",[\"saveCustomerNumber\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/companies/edit/customer-number.hbs"
    }
  });
  _exports.default = _default;
});