define("tmp-for-all/components/maps/draw-polygon/draw-layer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    observer = Ember.observer,
    run = Ember.run,
    computed = Ember.computed;

  /**
   * factory method for creating a circle icon
   * @param integer size of the icon in pixels
   * @returns L.icon
   */
  function createCircleIcon(size) {
    return L.icon({
      iconUrl: '/assets/images/marker-icon-mobile.png',
      iconRetina: '/assets/image/marker-icon-mobile-2x.png',
      iconSize: [size, size]
    });
  }

  // ratios which for  zoom level of the map
  var ICON_ZOOMFACTOR = 0.9;
  var DRAW_LINE_WEIGHT_FACTOR = 0.2;
  var _default = Ember.Component.extend({
    polyLineWeight: computed('zoom', function () {
      var zoom = get(this, 'zoom');
      return zoom * DRAW_LINE_WEIGHT_FACTOR;
    }),
    circleIcon: computed('zoom', function () {
      var zoom = get(this, 'zoom');
      var newSize = zoom * ICON_ZOOMFACTOR;
      return createCircleIcon(newSize);
    }),
    leafletPolygon: null,
    // Leaflet.polygon type
    updateGeometry: function updateGeometry() {
      var _this = this;
      run.next(function () {
        get(_this, 'onPolygonUpdated')(get(_this, 'leafletPolygon').toGeoJSON());
      });
    },
    coordinateObjects: computed('coordinates', function () {
      var coordinates = get(this, 'coordinates');
      // New worksite will pass in undefined - so default to nested array
      coordinates = coordinates || [[]];
      // GeoJSON supports rings - but we will only support a closed bounding
      // polygon, so select only the outside ring
      coordinates = R.head(coordinates);
      // Take all but the last coordinate as leaflet does not expect the first
      // coordinate to be repeated at the end of the list
      return Ember.A(R.init(coordinates).map(function (coordinate) {
        return Ember.Object.create({
          coordinates: {
            lng: coordinate[0],
            lat: coordinate[1]
          }
        });
      }));
    }),
    // Coordinates for drawing the polygon in ember-leaflet
    polygonCoordinates: Ember.computed.mapBy('coordinateObjects', 'coordinates'),
    onClick: observer('latestClickEvent', function () {
      var event = get(this, 'latestClickEvent');
      if (!get(this, 'isActive')) return; // do no click actions

      // Insert a new coordinate inbetween the closest polygon edge
      var map = event.target;
      var lineSegmentCoordinates = get(this, 'coordinateObjects').toArray();
      var coordinateSpliceIndex = lineSegmentCoordinates.length;
      // Capture last line segment connecting to starting coordinate
      lineSegmentCoordinates.push(R.head(lineSegmentCoordinates));
      var smallestDistance = Number.MAX_VALUE;
      // Iterate over pairs of coordinates as segments
      // Don't iterate if we only have 3 markers
      for (var i = 0; lineSegmentCoordinates.length >= 4 && i < lineSegmentCoordinates.length - 1; i++) {
        var distance = L.LineUtil.pointToSegmentDistance(map.latLngToLayerPoint(event.latlng), map.latLngToLayerPoint(lineSegmentCoordinates[i].coordinates), map.latLngToLayerPoint(lineSegmentCoordinates[i + 1].coordinates));
        if (distance < smallestDistance) {
          smallestDistance = distance;
          // Splice after this segment
          coordinateSpliceIndex = i + 1;
        }
      }
      // Reset / Clear geometry features break if we call set on the computed
      // coordinateObjects property.
      // Hack is to empty the array using Ember methods then fill it again...
      // TODO: Find out why splice isn't supported
      var modifiedCoordinateObjects = get(this, 'coordinateObjects').toArray();
      modifiedCoordinateObjects.splice(coordinateSpliceIndex, 0, Ember.Object.create({
        coordinates: event.latlng
      }));
      var coordinateObjects = get(this, 'coordinateObjects');
      while (coordinateObjects.length) coordinateObjects.popObject();
      modifiedCoordinateObjects.forEach(function (coord) {
        coordinateObjects.pushObject(coord);
      });
      this.updateGeometry();
    }),
    didRenderElement: function didRenderElement() {
      this._super.apply(this, arguments);
      // Store the original coordinates when first loaded - so we can restore them
      var originalCoordinates = get(this, 'coordinates');
      set(this, 'originalCoordinates', originalCoordinates);
      // Zoom and center map
      this.resetMap();
    },
    resetMap: function resetMap() {
      // Reset our geometry
      var originalCoordinates = get(this, 'originalCoordinates');
      set(this, 'coordinates', originalCoordinates);
      var polygonCoordinates = get(this, 'polygonCoordinates');
      if (polygonCoordinates.length) {
        var internalPoly = L.polygon(polygonCoordinates);
        set(this, 'bounds', internalPoly.getBounds());
      }
      // Notify that the geometry was reset
      this.updateGeometry();
    },
    actions: {
      polygonAdd: function polygonAdd(event) {
        // Set our local reference to the leaflet polygon object
        set(this, 'leafletPolygon', event.target);
        this.onPolygonAdd(event);
      },
      markerDragend: function markerDragend(coordinateObject, event) {
        set(coordinateObject, 'coordinates', event.target.getLatLng());
        this.updateGeometry();
      },
      markerClick: function markerClick(event) {
        event.originalEvent.stopPropagation();
      },
      markerDblclick: function markerDblclick(coordinateObject, event) {
        get(this, 'coordinateObjects').removeObject(coordinateObject);
        this.updateGeometry();
        event.originalEvent.stopPropagation();
      }
    }
  });
  _exports.default = _default;
});