define("tmp-for-all/helpers/deployment-status", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.deploymentStatus = deploymentStatus;
  function deploymentStatus(status) {
    if (!_constants.DEPLOYMENT_STATUSES[status]) return '';
    return _constants.DEPLOYMENT_STATUSES[status];
  }
  var _default = Ember.Helper.helper(deploymentStatus);
  _exports.default = _default;
});