define("tmp-for-all/components/worksite-view/tmps/tmp/revision/additional-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hTw5aP9a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"colspan\",\"5\"],[9],[0,\"\\n\"],[4,\"link-to\",[[27,\"concat\",[[23,[\"tmp\",\"type\"]],\".view.map\"],null],[23,[\"tmp\"]]],[[\"class\"],[\"image-wrap hide-mob\"]],{\"statements\":[[0,\"    \"],[1,[27,\"maps/static-map-tmp\",null,[[\"tmp\",\"width\",\"height\"],[[23,[\"tmp\"]],1103,365]]],false],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"image-actions-overlay align-right\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"secondary-action\"],[9],[0,\"View TMP extent\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/tmps/tmp/revision/additional-row/template.hbs"
    }
  });
  _exports.default = _default;
});