define("tmp-for-all/components/invite-user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var GOVT = 'government';
  var COMPANY = 'company';
  var UTILITY = 'utility';
  var _default = Ember.Component.extend({
    filterCompanyType: null,
    contact: computed.alias('user'),
    addToCompany: computed.equal('selectedDomain', 'company'),
    domainTypes: computed('addToCompany', function () {
      return [{
        label: 'Organisation',
        value: 'company',
        checked: get(this, 'addToCompany')
      }, {
        label: 'Jurisdiction',
        value: 'jurisdiction',
        checked: !get(this, 'addToCompany')
      }];
    }),
    orgTypes: computed('addToCompany', function () {
      return [{
        label: 'Companies',
        value: COMPANY,
        checked: true
      }, {
        label: 'Government agencies',
        value: GOVT,
        checked: false
      }, {
        label: 'Utility',
        value: UTILITY,
        checked: false
      }];
    }),
    organisationTitle: computed('filterCompanyType', function () {
      return get(this, 'filterCompanyType') === GOVT ? 'Government agencies' : 'Organisation';
    }),
    rolesForSelectedDomain: computed('selectedDomain', 'roles', function () {
      var domain = get(this, 'selectedDomain');
      return get(this, 'roles').filter(function (role) {
        return get(role, 'domainType') === domain;
      });
    }),
    filteredPermissionAssignments: computed.filter('user.permissionAssignments', function (pa, index, array) {
      return get(pa, 'domainId') === (get(this, 'selectedDomainId') || get(this, 'org.id') || null) && get(pa, 'domainType') === get(this, 'selectedDomain');
    }),
    userPermissionAssignments: computed('filteredPermissionAssignments', function () {
      if (!get(this, 'filteredPermissionAssignments').length) this.addRole(this.org);
      return get(this, 'filteredPermissionAssignments');
    }),
    viewingGovernmentAgencies: computed.equal('filterCompanyType', GOVT),
    viewingUtility: computed.equal('filterCompanyType', UTILITY),
    actions: {
      selectedDomainDidChange: function selectedDomainDidChange(selectedDomain) {
        set(this, 'selectedDomain', selectedDomain);
        set(this, 'org', null);
        set(this, 'role', get(this, 'rolesForSelectedDomain')[0]);
      },
      changeOrg: function changeOrg(org) {
        get(this, 'user.permissionAssignments').filter(function (a) {
          return get(a, 'isNew');
        }).forEach(function (pa) {
          return set(pa, 'domainId', get(org, 'id'));
        });
        set(this, 'org', org);
      },
      searchCompaniesFiltered: function searchCompaniesFiltered(q) {
        var isGovernmentAgency = get(this, 'viewingGovernmentAgencies');
        var isUtility = get(this, 'viewingUtility');
        return get(this, 'searchCompanies')(q, {
          isGovernmentAgency: isGovernmentAgency,
          isUtility: isUtility
        });
      }
    }
  });
  _exports.default = _default;
});