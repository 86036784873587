define("tmp-for-all/routes/tmp-draft/layouts/edit/details", ["exports", "tmp-for-all/mixins/routes/layout-edit-mixin", "tmp-for-all/constants"], function (_exports, _layoutEditMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_layoutEditMixin.default, {
    relatedModel: 'tmpDraft',
    siteSpecificRoutePermission: 'editDraftLayout',
    tmpType: _constants.TMP_TYPES.DRAFT
  });
  _exports.default = _default;
});