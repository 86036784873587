define("tmp-for-all/components/shared/form/radio-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    classNames: ['element-group-wrap'],
    classNameBindings: ['useButtonStyle:button-style-radios'],
    actions: {
      onChange: function onChange(selectedValue) {
        get(this, 'onChange')(selectedValue);
        get(this, 'options').forEach(function (option) {
          set(option, 'checked', option.value === selectedValue);
        });
      }
    }
  });
  _exports.default = _default;
});