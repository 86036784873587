define("tmp-for-all/components/shared/clash-resolution-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EvDrvvRO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-green\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"Publish resolution\"],[10],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"users\",\"20px\",\"28px\",\"icon--in-heading icon--green\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"fieldset\"],[11,\"class\",\"underline-dotted transfer-worksite-modal__contact-selector\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"element-wrap transfer-worksite-modal__textarea\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"element-group-label\"],[9],[0,\"Notes\"],[10],[0,\"\\n    \"],[1,[27,\"textarea\",null,[[\"value\"],[[23,[\"clashMessage\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"deprecated-grid align-right\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"tertiary-action t-secondary-button\"],[9],[0,\"\\n      Cancel\\n    \"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"primary-action\"],[9],[0,\"\\n      Publish\\n    \"],[3,\"action\",[[22,0,[]],\"saveClashResolution\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/clash-resolution-modal/template.hbs"
    }
  });
  _exports.default = _default;
});