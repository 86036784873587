define("tmp-for-all/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7QhE+EkZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-72 bold\"],[9],[0,\"Oops\"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text-20 margin-btm-40\"],[9],[0,\"Sorry about this, but it looks like the page you’re looking for doesn’t exist, or you do not have the required permissions to view this…\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"image-wrap\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/images/404.jpg\"],[11,\"alt\",\"Page not found\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/not-found.hbs"
    }
  });
  _exports.default = _default;
});