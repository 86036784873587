define("tmp-for-all/components/modal-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hjVzSXz8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\"],[[23,[\"toggleModal\"]],true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"modal-header__heading\"],[9],[1,[21,\"headingText\"],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"modal-header__close-btn\"],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"cross\",\"20px\",\"25px\",\"icon--black\"]]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[23,[\"toggleModal\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/modal-wrapper/template.hbs"
    }
  });
  _exports.default = _default;
});