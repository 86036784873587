define("tmp-for-all/helpers/save-button-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.saveButtonText = saveButtonText;
  var get = Ember.get;
  function saveButtonText(params) {
    var model = params[0];
    var createText = params[1] || 'Create';
    var updateText = params[2] || 'Save';
    return get(model, 'isNew') ? createText : updateText;
  }
  var _default = Ember.Helper.helper(saveButtonText);
  _exports.default = _default;
});