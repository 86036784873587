define("tmp-for-all/components/shared/auditor-selector/component", ["exports", "tmp-for-all/constants", "ember-cli-pagination/remote/route-mixin"], function (_exports, _constants, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend(_routeMixin.default, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    classNames: ['client-details'],
    groupLabel: 'Client Details',
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var jurisdiction = get(this, 'worksite.jurisdiction');
      var jId = get(jurisdiction, 'id');
      set(this, 'contact.jurisdiction', jurisdiction);
      this.ajax.getAuditors(jId).then(function (allAuditors) {
        _this.store.query('tmp-user', {
          filter: {
            where: {
              id: {
                inq: allAuditors.data.map(function (a) {
                  return a.id;
                })
              }
            }
          }
        }).then(function (tmpUsers) {
          set(_this, 'auditors', tmpUsers);
        });
      });
    },
    toolTip: Ember.String.htmlSafe(_constants.TOOLTIP_MESSAGES.WORKSITE_OWNERSHIP.AUDITOR)
  });
  _exports.default = _default;
});