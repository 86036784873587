define("tmp-for-all/models/tmp", ["exports", "ember-data", "tmp-for-all/models/tmp-base", "tmp-for-all/constants", "tmp-for-all/utils/html-comment-helpers", "tmp-for-all/utils/animations"], function (_exports, _emberData, _tmpBase, _constants, _htmlCommentHelpers, _animations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    RSVP = _Ember.RSVP,
    get = _Ember.get,
    $ = _Ember.$;
  var _default = _tmpBase.default.extend({
    declineComment: attr('string'),
    type: _constants.TMP_TYPES.ACCEPTED,
    acceptanceConditions: hasMany('acceptance-condition', {
      async: true
    }),
    customConditions: hasMany('custom-condition'),
    owner: belongsTo('tmp-user', {
      async: true
    }),
    ajax: Ember.inject.service(),
    decline: function decline(commentText) {
      var _this = this;
      this.set('isDeclining', true);
      if (!commentText || !(0, _htmlCommentHelpers.hasHtmlComment)(commentText)) {
        this.set('isDeclining', false);
        (0, _animations.jiggleALittleBit)($('.t-decline-textarea'));
        return RSVP.reject(new Error('Please enter a declined comment before clicking confirm'));
      }
      this.set('declineComment', commentText);
      return this.get('ajax').tmpAction(this, 'decline').catch(function () {
        return RSVP.reject(new Error('TMP could not be declined, please try again'));
      }).finally(function () {
        _this.get('history').reload();
        _this.set('isDeclining', false);
      });
    },
    createDraft: function createDraft() {
      var _this2 = this;
      this.set('isCreatingDraft', true);
      return this.get('ajax').tmpAction(this, 'draft').then(function () {
        var promises = [];
        var tmp = _this2.store.peekRecord('tmp-draft', get(_this2, 'id'));
        if (tmp) {
          promises.push(get(tmp, 'layouts').reload());
          promises.push(tmp.hasMany('history').reload());
        }
        return RSVP.all(promises);
      }).catch(function () {
        return RSVP.reject(new Error('TMP draft could not be created, please try again'));
      }).finally(function () {
        return _this2.set('isCreatingDraft', false);
      });
    }
  });
  _exports.default = _default;
});