define("tmp-for-all/components/planning-map/detail-popup/nothing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+NSWL49I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"popup-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"popup-header\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"popup-header-heading\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Detail not found!\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"popup-tabs-wrap\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"popup-tab\"],[9],[0,\"\\n      No impacts found at this location. The works may\\n      have been recently cancelled.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/planning-map/detail-popup/nothing/template.hbs"
    }
  });
  _exports.default = _default;
});