define("tmp-for-all/components/shared/form/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rOPWZKGi",
    "block": "{\"symbols\":[\"fileData\",\"index\"],\"statements\":[[7,\"input\"],[11,\"class\",\"file-picker\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"didSelectFiles\"],null]],[12,\"accept\",[21,\"acceptFilter\"]],[11,\"multiple\",\"\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"pendingFiles\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"file-list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"pendingFiles\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"shared/form/file-upload/item\",null,[[\"fileData\",\"removeFile\",\"index\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"didRemoveFile\",[22,1,[]]],null],[22,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/file-upload/template.hbs"
    }
  });
  _exports.default = _default;
});