define("tmp-for-all/components/project-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    worksiteModels: computed('worksites.[]', function () {
      var worksites = get(this, 'worksites');
      return worksites.map(function (ws) {
        return ws.getWorksite();
      });
    })
  });
  _exports.default = _default;
});