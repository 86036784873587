define("tmp-for-all/components/tmp-view/assign-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QbvYWn97",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if-permitted\",[\"claimSubmittedTmp\"],[[\"model\"],[[23,[\"tmp\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"element-wrap inline-label\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"class\",\"tagName\",\"selected\",\"onchange\",\"options\"],[\"t-primary-rca-assignee-selector\",\"div\",[23,[\"tmpOwner\"]],[27,\"action\",[[22,0,[]],\"selectRCAUser\"],null],[23,[\"rcaUsers\"]]]],{\"statements\":[[0,\"      \"],[1,[22,1,[\"fullname\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"margin-btm-20\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"tmp\",\"owner\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"stack-on-mobile margin-right-15\"],[9],[1,[23,[\"tmp\",\"owner\",\"fullname\"]],false],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"stack-on-mobile margin-right-15\"],[9],[1,[23,[\"tmp\",\"owner\",\"email\"]],false],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"stack-on-mobile margin-right-15\"],[9],[1,[23,[\"tmp\",\"owner\",\"mobile\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[0,\"No RCA Assignee\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/tmp-view/assign-user/template.hbs"
    }
  });
  _exports.default = _default;
});