define("tmp-for-all/routes/nzta-detours/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return this.session.currentUser
      // To allow direct navigation to the route we must
      // wait for related domain relationship to resolve.
      .then(function (user) {
        return user.getSelectedDomain().then(function () {
          return user;
        });
      }).then(function (user) {
        return _this.authorization.checkAllowableNavigation(_this, 'nztaDetourAdmin', user.domain);
      }).catch(function () {
        return _this.replaceWith('index');
      });
    },
    model: function model() {
      return Ember.RSVP.hash({
        features: [],
        nocAreas: this.store.findAll('noc-meta'),
        activeDetourSet: null
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (controller.detourNumber) {
        this.ajax.getNztaDetourByDetourNumber(controller.detourNumber).then(function (result) {
          set(model, 'activeDetourSet', result);
        }).catch(function (err) {
          console.error('could not fetch by detournumber...', err);
        });
      }
      Ember.tryInvoke(controller, 'afterSetup');
    },
    actions: {
      addClosure: function addClosure() {
        return this.store.createRecord('nzta-detour', {
          detourPart: 0,
          editing: true
        });
      },
      createDetour: function createDetour(detour) {
        return this.store.createRecord('nzta-detour', detour);
      },
      getDetourSetByCoords: function getDetourSetByCoords(latlng, zoom) {
        return this.ajax.getNztaDetourByCoords(latlng, zoom);
      },
      getDetourSetForModel: function getDetourSetForModel(model) {
        return this.ajax.getNztaDetourByDetourNumber(model.detourNumber);
      }
    }
  });
  _exports.default = _default;
});