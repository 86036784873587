define("tmp-for-all/routes/tmp/layouts/create/map", ["exports", "tmp-for-all/mixins/routes/layout-map-route-mixin", "tmp-for-all/constants"], function (_exports, _layoutMapRouteMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_layoutMapRouteMixin.default, {
    templateName: 'tmp/layouts/edit/map',
    returnRoute: 'tmp.layouts.create.details',
    returnDetourRoute: 'tmp.layouts.create.detour',
    siteSpecificRoutePermission: 'editAcceptedLayout',
    tmpType: _constants.TMP_TYPES.ACCEPTED
  });
  _exports.default = _default;
});