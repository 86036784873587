define("tmp-for-all/templates/tmp/layouts/view/deployments/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ucrh55np",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\"],[true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-orange big-heading\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Schedule Deployment\"],[10],[0,\"\\n\"],[4,\"link-to\",[\"tmp.view\"],[[\"class\",\"replace\"],[\"float-right\",true]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"cross\",\"20px\",\"25px\",\"icon--black\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[1,[27,\"deployment-form\",null,[[\"tmp\",\"tmpLayout\",\"deployment\",\"saveDeployment\",\"cancelDeployment\",\"deleteDeployment\",\"saveDeploymentSeries\",\"stmsSearch\"],[[23,[\"model\",\"tmp\"]],[23,[\"model\",\"layout\"]],[23,[\"model\",\"deployment\"]],[27,\"route-action\",[\"saveDeployment\"],null],[27,\"route-action\",[\"cancelDeployment\"],null],[27,\"route-action\",[\"deleteDeployment\"],null],[27,\"route-action\",[\"saveDeploymentSeries\"],null],[27,\"route-action\",[\"stmsSearch\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmp/layouts/view/deployments/create.hbs"
    }
  });
  _exports.default = _default;
});