define("tmp-for-all/mixins/routes/layout-detour-readonly-mixin", ["exports", "tmp-for-all/utils/route-helpers", "tmp-for-all/mixins/routes/get-tooltip-context-mixin", "tmp-for-all/constants", "ember-copy"], function (_exports, _routeHelpers, _getTooltipContextMixin, _constants, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    setProperties = Ember.setProperties;
  var _default = Ember.Mixin.create(_getTooltipContextMixin.default, {
    ajax: Ember.inject.service(),
    model: function model() {
      var layout = this.modelFor((0, _routeHelpers.getParentRouteName)(get(this, 'routeName')));
      var detours = get(layout, 'detours');
      var worksite = this.modelFor((0, _routeHelpers.getParentRouteName)((0, _routeHelpers.getParentRouteName)(get(this, 'routeName')))).get('latestWorksite');
      return Ember.RSVP.hash({
        layout: layout,
        worksite: worksite,
        detours: detours
      });
    },
    afterModel: function afterModel(model) {
      var searchQuery = (0, _emberCopy.copy)(_constants.DEFAULT_PLANNINGMAP_SEARCH_QUERY, true);
      searchQuery.dateRange = {
        from: get(model.layout, 'startDate').toISOString(),
        to: get(model.layout, 'endDate').toISOString()
      };
      set(this, 'searchQuery', searchQuery);
    },
    actions: {
      noop: function noop() {
        console.log('noop');
      },
      close: function close() {
        this.replaceWith(get(this, 'returnRoute'));
      },
      getWorksiteTooltipContext: function getWorksiteTooltipContext(clash) {
        this.worksiteTooltipContext(clash.properties).then(function (context) {
          return setProperties(clash, context);
        });
      },
      updateClashes: function updateClashes(boundingBox) {
        var searchQuery = get(this, 'searchQuery');
        searchQuery.boundingBox = boundingBox;
        set(this, 'searchQuery', searchQuery);
        return get(this, 'ajax').getPlanningMapFeatures(searchQuery).then(function (results) {
          return results.data;
        });
      },
      getDetail: function getDetail(latLng, zoom) {
        var detailQuery = R.merge(get(this, 'searchQuery'), {
          position: {
            latLng: latLng,
            zoom: zoom
          }
        });
        delete detailQuery.boundingBox;
        return get(this, 'ajax').getPlanningMapFeatureDetail(detailQuery);
      }
    }
  });
  _exports.default = _default;
});