define("tmp-for-all/controllers/worksites/search", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin", "tmp-for-all/mixins/controllers/list-order-controller-mixin", "tmp-for-all/utils/search-filter", "tmp-for-all/utils/worksite-filters"], function (_exports, _listPaginationControllerMixin, _listOrderControllerMixin, _searchFilter, _worksiteFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
    computed = Ember.computed;
  var SearchController = Ember.Controller.extend(_listPaginationControllerMixin.default, _listOrderControllerMixin.default, {
    queryParams: R.concat(['status', 'freetext', 'viewMode', 'mapStyle'], _searchFilter.default.getFilterQueryParams(_worksiteFilters.default)),
    queryFilter: computed.alias('model.queryFilter'),
    status: null,
    freetext: null,
    viewMode: null,
    mapStyle: null,
    order: null,
    actions: {
      // pass the controller to the component for updating the query params
      updateFilterParams: function updateFilterParams(func, params) {
        set(this, 'page', 1);
        func(this, params);
      },
      setSearchText: function setSearchText(value) {
        set(this, 'page', 1);
        set(this, 'freetext', value);
      },
      setStatus: function setStatus(status) {
        status = status.value;
        set(this, 'status', status);
        set(this, 'page', 1);
      },
      setViewMode: function setViewMode(mode) {
        set(this, 'viewMode', mode);
      },
      setMapStyle: function setMapStyle(style) {
        set(this, 'mapStyle', style);
      },
      showOnMap: function showOnMap(geoJson) {
        set(this, 'worksiteFocus', geoJson);
      },
      showAllLocations: function showAllLocations() {
        set(this, 'worksiteFocus', null);
      },
      pageClicked: function pageClicked(page) {
        set(this, 'worksiteFocus', null);
        this._super(page);
      }
    }
  });
  // I wanted to make this functionality through a mixin, but this will not work
  // due to the way that the Ember object lifecycle works.  When we create a generic
  // mixin we do not have access to the filterTypes. We need to reOpen the controller
  // then set the query params to 'null' so they correctly get un-set when that
  // filterType has been removed
  SearchController.reopen(_searchFilter.default.getParamsAssignments(_worksiteFilters.default));
  var _default = SearchController;
  _exports.default = _default;
});