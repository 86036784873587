define("tmp-for-all/services/processing-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP;
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    getProcessingTimeResults: function getProcessingTimeResults(model, params) {
      var and = [];
      var filter = {
        where: {
          and: and
        }
      };
      if (params.fromDate) and.push({
        processedAt: {
          gte: params.fromDate
        }
      });
      if (params.toDate) and.push({
        processedAt: {
          lte: params.toDate
        }
      });
      if (params.processingTime) and.push({
        processingTime: {
          lte: params.processingTime
        }
      });
      if (params.processedById) and.push({
        processedById: params.processedById
      });
      return RSVP.hash({
        results: get(this, 'store').query(model, {
          filter: filter
        }),
        selectedUser: params.processedById ? get(this, 'store').findRecord('tmp-user', params.processedById) : null,
        selectedMonthIndex: params.selectedMonthIndex,
        queryFilter: filter
      });
    }
  });
  _exports.default = _default;
});