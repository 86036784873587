define("tmp-for-all/models/cost", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    value: attr('number'),
    startsAt: attr('date'),
    endsAt: attr('date'),
    fee: belongsTo('fee', {
      async: true
    })
  });
  _exports.default = _default;
});