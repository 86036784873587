define("tmp-for-all/components/if-feature-enabled/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IfFeatureEnabledComponent = Ember.Component.extend({
    tagName: '',
    authorization: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    isEnabled: Ember.computed('flagName', 'domainType', 'domainId', function () {
      var flag = Ember.get(this, 'flagName');
      var domainType = Ember.get(this, 'domainType');
      var domainId = Ember.get(this, 'domainId');
      return _emberData.default.PromiseObject.create({
        promise: this.ajax.featureFlagEnabledFor(flag, domainType, domainId)
      });
    })
  });
  IfFeatureEnabledComponent.reopenClass({
    positionalParams: ['flagName']
  });
  var _default = IfFeatureEnabledComponent;
  _exports.default = _default;
});