define("tmp-for-all/helpers/format-meters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatMeters = formatMeters;
  function formatMeters(params) {
    var meters = params[0] || 0;
    return (meters / 1000).toFixed(3);
  }
  var _default = Ember.Helper.helper(formatMeters);
  _exports.default = _default;
});