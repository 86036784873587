define("tmp-for-all/models/permission", ["exports", "ember-data", "tmp-for-all/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;
  var permission = _emberData.default.Model.extend({
    name: attr('string'),
    displayName: attr('string'),
    isRole: attr('boolean'),
    children: hasMany('permission', {
      inverse: null
    }),
    domainType: attr('string')
  });
  permission.reopenClass({
    roleFilter: {
      filter: {
        where: {
          isRole: true,
          name: {
            neq: _constants.PENDING_PERMISSION.name
          }
        }
      }
    },
    companyRoleFilter: {
      filter: {
        where: {
          isRole: true,
          name: {
            neq: _constants.PENDING_PERMISSION.name
          },
          domainType: 'company'
        }
      }
    },
    jurisdictionRoleFilter: {
      filter: {
        where: {
          isRole: true,
          name: {
            neq: _constants.PENDING_PERMISSION.name
          },
          domainType: 'jurisdiction'
        }
      }
    },
    unscopedRoleFilter: {
      // Ember will stringify null incorrectly
      filter: "{\"where\":{\"domainType\":null,\"isRole\":true,\"name\":{\"neq\":\"".concat(_constants.PENDING_PERMISSION.name, "\"}}}")
    }
  });
  var _default = permission;
  _exports.default = _default;
});