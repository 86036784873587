define("tmp-for-all/utils/planning-map-side-panel-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.structure = _exports.state = void 0;
  var structure = {
    searchKey: 'status',
    children: [{
      label: 'Accepted',
      searchKey: 'accepted',
      sectionHeader: true,
      children: [{
        label: 'Worksites',
        searchKey: 'worksite',
        childrenAsArray: true,
        children: [{
          label: 'Excavation',
          searchKey: 'excavation'
        }, {
          label: 'Non-excavation',
          searchKey: 'non-excavation'
        }, {
          label: 'Event',
          searchKey: 'event'
        }]
      }, {
        label: 'Impacts',
        searchKey: 'impact',
        childrenAsArray: true,
        children: [{
          label: 'Closed',
          searchKey: 'closed'
        }, {
          label: 'Restricted',
          searchKey: 'restricted'
        }, {
          label: 'Open',
          searchKey: 'open'
          //   }, {
          //     'label': 'Footpath',
          //     'searchKey': 'footpath'
        }]
      }, {
        label: 'Detours',
        searchKey: 'detour'
      }]
    }, {
      label: 'Submitted',
      searchKey: 'submitted',
      sectionHeader: true,
      children: [{
        label: 'Worksites',
        searchKey: 'worksite',
        childrenAsArray: true,
        children: [{
          label: 'Excavation',
          searchKey: 'excavation'
        }, {
          label: 'Non-excavation',
          searchKey: 'non-excavation'
        }, {
          label: 'Event',
          searchKey: 'event'
        }]
      }, {
        label: 'Impacts',
        searchKey: 'impact',
        childrenAsArray: true,
        children: [{
          label: 'Closed',
          searchKey: 'closed'
        }, {
          label: 'Restricted',
          searchKey: 'restricted'
        }, {
          label: 'Open',
          searchKey: 'open'
          //   }, {
          //     'label': 'Footpath',
          //     'searchKey': 'footpath'
        }]
      }, {
        label: 'Detours',
        searchKey: 'detour'
      }]
    }, {
      label: 'Published',
      searchKey: 'published',
      sectionHeader: true,
      children: [{
        label: 'Worksites',
        searchKey: 'worksite',
        childrenAsArray: true,
        children: [{
          label: 'Excavation',
          searchKey: 'excavation'
        }, {
          label: 'Non-excavation',
          searchKey: 'non-excavation'
        }, {
          label: 'Event',
          searchKey: 'event'
        }]
      }]
    }, {
      label: 'Work In Warranty',
      searchKey: 'warranty',
      sectionHeader: true,
      children: [{
        label: 'Worksites',
        searchKey: 'worksite',
        childrenAsArray: true,
        children: [{
          label: 'Excavation',
          searchKey: 'excavation'
        }, {
          label: 'Non-excavation',
          searchKey: 'non-excavation'
        }, {
          label: 'Event',
          searchKey: 'event'
        }]
      }, {
        label: 'Impacts',
        searchKey: 'impact',
        childrenAsArray: true,
        children: [{
          label: 'Closed',
          searchKey: 'closed'
        }, {
          label: 'Restricted',
          searchKey: 'restricted'
        }, {
          label: 'Open',
          searchKey: 'open'
          // }, {
          //   'label': 'Footpath',
          //   'searchKey': 'footpath'
        }]
      }]
    }]
  };
  _exports.structure = structure;
  var state = {
    status: {
      accepted: {
        worksite: {
          excavation: true,
          'non-excavation': true,
          event: true
        },
        impact: {
          closed: true,
          restricted: true,
          open: true
        },
        detour: true
      }
    }
  };
  _exports.state = state;
});