define("tmp-for-all/components/maps/map-control-panel/rca/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['t-rca-tab'],
    actions: {
      updateQuery: function updateQuery(labels, values) {
        get(this, 'updateSearchQuery')({
          primaryRCA: values
        });
      }
    }
  });
  _exports.default = _default;
});