define("tmp-for-all/components/worksite-view/tmps/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    classNames: ['margin-btm-50'],
    tmpSubmissions: computed.alias('worksite.tmpSubmissions'),
    tmpDrafts: computed.alias('worksite.tmpDrafts'),
    tmps: computed.alias('worksite.tmps'),
    numberOfTMPs: computed('tmpRevisionsGroupedById', function () {
      return Object.keys(get(this, 'tmpRevisionsGroupedById')).length;
    }),
    boxTitle: computed('numberOfTMPs', function () {
      var numberOfTmps = get(this, 'numberOfTMPs');
      return "TMPs (".concat(numberOfTmps, ")");
    }),
    tmpRevisionsGroupedById: computed('tmpSubmissions', 'tmpDrafts.@each.isDeleted', 'tmps', function () {
      var tmpItems = get(this, 'tmpSubmissions').toArray().concat(get(this, 'tmpDrafts').toArray(), get(this, 'tmps').toArray());

      // Are there any invalid items
      if (tmpItems.find(function (tmpItem) {
        return !tmpItem;
      })) {
        Raygun.send(new Error("Null value found in a worksite's tmp relationships. tmpDrafts: ".concat(get(this, 'tmpDrafts').toArray(), ", tmpSubmissions: ").concat(get(this, 'tmpSubmissions').toArray(), ", tmps: ").concat(get(this, 'tmps').toArray())));
      }

      // Remove any falsy values from the array
      tmpItems = tmpItems.filter(function (tmpItem) {
        return !!tmpItem;
      });
      var groupById = R.groupBy(function (tmpItem) {
        return get(tmpItem, 'tmpCode');
      });
      var sortByStatus = R.map(R.sort(function (a, b) {
        // rules, sort into the following order
        // draft -> submission -> Accepted -> Approved -> Declined
        var scores = {
          draft: 2,
          submission: 1,
          accepted: 0
        };
        return scores[get(b, 'type')] - scores[get(a, 'type')];
      }));
      return R.pipe(groupById, sortByStatus)(tmpItems);
    })
  });
  _exports.default = _default;
});