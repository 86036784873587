define("tmp-for-all/components/worksite-view/contacts/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['margin-btm-50'],
    session: Ember.inject.service(),
    isAuditor: computed('session', function () {
      if (!get(this, 'session.currentUser')) {
        return false;
      }
      return _emberData.default.PromiseObject.create({
        promise: get(this, 'session.currentUser').then(function (user) {
          return get(user, 'isAuditor');
        })
      });
    }),
    showAuditorButton: computed('worksite.contacts', function () {
      var _this = this;
      if (!get(this, 'session.currentUser')) {
        return false;
      }
      return _emberData.default.PromiseObject.create({
        promise: get(this, 'session.currentUser').then(function (user) {
          return Promise.all([get(_this, 'worksite.hasAuditor'), get(user, 'isAuditor'), get(user, 'selectedDomain'), get(user, 'selectedDomainId'), get(_this, 'worksite.jurisdiction.id')]).then(function (results) {
            var correctDomain = results[2] === 'jurisdiction';
            var correctDomainId = parseInt(results[3]) === parseInt(results[4]);
            return !results[0] && results[1] && correctDomain && correctDomainId;
          });
        })
      });
    }),
    actions: {
      addAuditor: function addAuditor() {
        var _this2 = this;
        get(this, 'session.currentUser').then(function (user) {
          return get(_this2, 'addAuditor')(user);
        });
      }
    }
  });
  _exports.default = _default;
});