define("tmp-for-all/components/search-results/tmps/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;
  var _default = Ember.Component.extend({
    authManager: inject.service('session')
  });
  _exports.default = _default;
});