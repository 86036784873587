define("tmp-for-all/components/shared/toggle-text-length/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aLFQBe8l",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"gt\",[[23,[\"fullTextLength\"]],[23,[\"croppedTextCharLength\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[21,\"croppedToggleText\"],false],[4,\"unless\",[[23,[\"isExpanded\"]]],null,{\"statements\":[[0,\"…\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"toggle-text-length-trigger link-style\"],[9],[0,\"\\n    \"],[1,[21,\"toggleTriggerText\"],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"isExpanded\"]]],null],[27,\"not\",[[23,[\"isExpanded\"]]],null]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"textToToggle\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/toggle-text-length/template.hbs"
    }
  });
  _exports.default = _default;
});