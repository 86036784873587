define("tmp-for-all/models/condition-group-to-condition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    condition: belongsTo('condition', {
      async: true
    }),
    conditionGroup: belongsTo('condition-group', {
      async: true
    })
  });
  _exports.default = _default;
});