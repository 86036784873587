define("tmp-for-all/components/search-parameters/projects/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zJYqb5rF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row row--large-gutters\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-12 col-sm-6 col-md-4\"],[9],[0,\"\\n\"],[4,\"form-field/wrapper\",null,[[\"label\",\"inputId\"],[\"Keyword(s)\",\"search-field-keywords\"]],{\"statements\":[[0,\"      \"],[1,[27,\"one-way-input\",[[23,[\"freetextValue\"]]],[[\"id\",\"classNames\",\"placeholder\",\"update\"],[\"search-field-keywords\",\"form-field__input\",\"Search by project title or summary of work...\",[27,\"perform\",[[23,[\"updateFreetextTask\"]]],null]]]],false],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"form-field__clear-input\"],[11,\"role\",\"button\"],[11,\"title\",\"Clear search text\"],[9],[0,\"\\n        \"],[1,[27,\"svg-icon\",null,[[\"type\"],[\"cross\"]]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"clearInputValue\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-parameters/projects/template.hbs"
    }
  });
  _exports.default = _default;
});