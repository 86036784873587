define("tmp-for-all/components/shared/form/text-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Rb2FWovj",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"for\",[21,\"inputID\"]],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"toolTipText\"]]],null,{\"statements\":[[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"        \"],[1,[21,\"toolTipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[1,[27,\"textarea\",null,[[\"name\",\"value\",\"required\",\"placeholder\",\"id\",\"class\",\"disabled\",\"maxlength\",\"rows\"],[[23,[\"name\"]],[23,[\"value\"]],[23,[\"required\"]],[23,[\"placeholder\"]],[23,[\"inputID\"]],[23,[\"class\"]],[23,[\"disabled\"]],[23,[\"maxlength\"]],[23,[\"rows\"]]]]],false],[0,\"\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"error\"],[9],[1,[22,1,[\"message\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/text-area/template.hbs"
    }
  });
  _exports.default = _default;
});