define("tmp-for-all/templates/tmp-submission/layouts/edit/detour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LBYmnGa9",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"maps/detour-control\",null,[[\"worksite\",\"tmpLayout\",\"detours\",\"updateClashes\",\"getDetail\",\"saveDetours\",\"onCancel\",\"createNewDetour\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"layout\"]],[23,[\"model\",\"detours\"]],[27,\"route-action\",[\"updateClashes\"],null],[27,\"route-action\",[\"getDetail\"],null],[27,\"route-action\",[\"saveDetours\"],null],[27,\"route-action\",[\"returnToEdit\"],null],[27,\"route-action\",[\"createNewDetour\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmp-submission/layouts/edit/detour.hbs"
    }
  });
  _exports.default = _default;
});