define("tmp-for-all/components/shared/conditions-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    classNames: ['add-conditions'],
    showArchived: computed(function () {
      return false;
    }),
    // determine the condition type based on the first item in the list.
    createConditionLink: computed('conditionType', function () {
      return "conditions.".concat(get(this, 'conditionType'), ".create");
    }),
    color: computed('conditionType', function () {
      var type = get(this, 'conditionType');
      switch (type) {
        case 'tmp':
          return 'orange';
        case 'worksite':
          return 'blue';
        default:
          return 'green';
      }
    }),
    actions: {
      toggleArchiveFilter: function toggleArchiveFilter(val) {
        set(this, 'showArchived', val);
      }
    }
  });
  _exports.default = _default;
});