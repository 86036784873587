define("tmp-for-all/components/search-parameters/selected-filters/selected-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "X6XhsuAl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"pill-box\",null,[[\"classNames\",\"handleDeleteClick\"],[\"t-selected-filter\",[27,\"action\",[[22,0,[]],[23,[\"removeFilter\"]],[23,[\"filter\"]],[23,[\"index\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[23,[\"filter\",\"label\"]],false],[1,[27,\"if\",[[23,[\"filterValue\",\"length\"]],\":\"],null],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"t-selected-filter-value\"],[9],[1,[21,\"filterValue\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-parameters/selected-filters/selected-filter/template.hbs"
    }
  });
  _exports.default = _default;
});