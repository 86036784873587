define("tmp-for-all/utils/tmp-filters", ["exports", "tmp-for-all/utils/search-filter", "tmp-for-all/constants"], function (_exports, _searchFilter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchFilters = _exports.processingQueueFilters = void 0;
  var makeSingleDateFilter = _searchFilter.default.makeSingleDateFilter,
    makeDiscreteList = _searchFilter.default.makeDiscreteList,
    makeTextFilter = _searchFilter.default.makeTextFilter,
    makeBooleanFilter = _searchFilter.default.makeBooleanFilter,
    makeOptionsFilter = _searchFilter.default.makeOptionsFilter;

  // TODO: in a seperate story, these fields are not yet available on the tmp/project models
  // - Project Reference

  var worksiteApplicationTypeOptions = Object.keys(_constants.WORKSITE_APPLICATION_TYPES).map(function (key) {
    return {
      name: _constants.WORKSITE_APPLICATION_TYPES[key],
      value: key
    };
  });
  var worksiteTypeOptions = Object.keys(_constants.WORKSITE_TYPES).map(function (key) {
    return {
      name: _constants.WORKSITE_TYPES[key],
      value: key
    };
  });
  var trafficManagementLevelOptions = Object.keys(_constants.TRAFFIC_MANAGEMENT_LEVELS_SHORT).map(function (key) {
    return {
      name: _constants.TRAFFIC_MANAGEMENT_LEVELS_SHORT[key],
      value: key
    };
  });
  var layoutTrafficImpactOptions = _constants.LAYOUT_TRAFFIC_IMPACTS.map(function (lti) {
    return {
      name: lti,
      value: lti
    };
  });
  var searchFilters = [makeSingleDateFilter('Basic', 'Layout min date', 'layoutMinDate'), makeSingleDateFilter('Basic', 'Layout max date', 'layoutMaxDate'), makeSingleDateFilter('Basic', 'Worksite start date', 'worksiteStartDate'), makeSingleDateFilter('Basic', 'Worksite end date', 'worksiteEndDate'), makeSingleDateFilter('Submitted', 'Submission date', 'submittedAt'), makeDiscreteList('Project', 'Project name', 'project', 'projectId'), makeDiscreteList('Basic', 'Client', 'company', 'companyId'), makeTextFilter('Worksite', 'Worksite reference', '01ND', 'worksiteReference'), makeTextFilter('Worksite', 'Worksite name', 'SH.01 Bader Dr', 'worksiteName'), makeDiscreteList('RCA', 'Primary RCA', 'jurisdiction', 'jurisdictionId'), makeDiscreteList('RCA', 'Primary RCA assignee', 'tmpUser', 'ownerId'), makeDiscreteList('RCA', 'Current secondary RCA', 'jurisdiction', 'currentSecondaryRcaId'), makeDiscreteList('RCA', 'Secondary RCA', 'jurisdiction', 'secondaryRcaId'), makeDiscreteList('RCA', 'Secondary RCA assignee', 'tmpUser', 'currentSecondaryRcaAssigneeId'), makeOptionsFilter('Worksite', 'Type of application', worksiteApplicationTypeOptions, 'applicationType'),
  // makeTextFilter('Basic', 'Project Reference', '01ND', 'projectReference') // e.b enabled in a later story
  function () {
    var filter = makeBooleanFilter('Worksite', 'Created from Global Permit Layout', 'globalPermitLayoutId', true);
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.globalPermitLayoutId) {
        whereFilter.and.push({
          globalPermitLayoutId: {
            neq: 'null'
          }
        });
      }
      delete params.globalPermitLayoutId;
    };
    return filter;
  }(), makeOptionsFilter('Worksite', 'Work type', worksiteTypeOptions, 'worksiteType'), makeOptionsFilter('Worksite', 'Road level', trafficManagementLevelOptions, 'trafficManagementLevel'), makeBooleanFilter('Worksite', 'Layout full closure', 'isFullClosureRequested', true), makeBooleanFilter('Basic', 'Layout should be advertised', 'isAdvertised', true), function () {
    var filter = makeOptionsFilter('Basic', 'Layout traffic impact', layoutTrafficImpactOptions, 'trafficImpact');
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.trafficImpact) {
        var parts = params.trafficImpact.split('|');
        whereFilter.and.push({
          or: parts.map(function (val) {
            return {
              trafficImpact: {
                like: "%".concat(val, "%")
              }
            };
          })
        });
      }
      delete params.trafficImpact;
    };
    return filter;
  }(), makeBooleanFilter('Basic', 'SCATs notification required', 'isScatsNotificationRequired', true)];
  _exports.searchFilters = searchFilters;
  var processingQueueFilters = [makeBooleanFilter('Basic', 'Single jurisdiction approval', 'isSingleRcaApproval', true), makeBooleanFilter('Basic', 'Multiple jurisdiction approvals', 'isCrossRcaApproval', true)];
  _exports.processingQueueFilters = processingQueueFilters;
});