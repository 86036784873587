define("tmp-for-all/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.UnprotectedRoute.extend({
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!get(this, 'session.isAuthenticated')) {
        return this.transitionTo('login');
      }
      return this.get('authorization').load();
    }
  });
  _exports.default = _default;
});