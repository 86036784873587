define("tmp-for-all/mixins/routes/conditions-group-base", ["exports", "tmp-for-all/utils/action-description", "tmp-for-all/constants"], function (_exports, _actionDescription, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;
  var _default = Ember.Mixin.create({
    authManager: Ember.inject.service('session'),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    getActionDescription: _actionDescription.default,
    // resolves condition groups and their conditions based on a condition type property
    // current supported values are tmp and worksite
    conditionType: '',
    model: function model() {
      var _this = this;
      return get(this, 'authManager.currentUser').then(function (user) {
        if (get(user, 'selectedDomain') !== _constants.DOMAIN_TYPES.JURISDICTION) {
          get(_this, 'flashMessages').danger('Must be operating under an RCA');
          return _this.transitionTo('index');
        }
        return RSVP.hash({
          conditionGroups: _this.store.query('condition-group', {
            filter: {
              where: {
                and: [{
                  conditionType: get(_this, 'conditionType')
                }, {
                  jurisdictionId: get(user, 'selectedDomainId')
                }]
              },
              include: 'conditions'
            }
          }),
          // handed down from this route for child routes to determine condition group type
          conditionType: get(_this, 'conditionType')
        });
      });
    },
    saveConditionGroup: function saveConditionGroup(group, action) {
      var _this2 = this;
      get(this, 'authManager.currentUser').then(function (user) {
        // User must belong to a jurisdiction to be able to create a condition group
        var domain = get(user, 'domain');
        if (domain) return RSVP.Promise.resolve(domain);
        // create under the AMA jurisdiction if the user is admin
        return get(_this2, 'ajax').getJurisdictionByName('AMA');
      }).then(function (domain) {
        set(group, 'jurisdiction', domain);
        return group.save();
      }).then(function () {
        get(_this2, 'flashMessages').success("Condition Group has been ".concat(_this2.getActionDescription(action)[0], " successfully."));
        _this2.refresh();
        _this2.transitionTo("condition-groups.".concat(get(_this2, 'conditionType'), ".list"));
      }).catch(function (errors) {
        get(_this2, 'flashMessages').danger("There was a problem ".concat(_this2.getActionDescription(action)[1], " this Condition Group"));
      });
    },
    actions: {
      // putting condition-group actions on top level route allows actions to bubble up and avoids code repeition
      saveConditionGroup: function saveConditionGroup(group, action) {
        this.saveConditionGroup(group, action);
      },
      toggleArchiveState: function toggleArchiveState(group) {
        var action = group.toggleProperty('isArchived') ? 'archived' : 'unarchived';
        this.saveConditionGroup(group, action);
      }
    }
  });
  _exports.default = _default;
});