define("tmp-for-all/routes/worksite-fees/update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(params) {
      var jurisdiction = params.jurisdiction;
      var date = params.date;
      if (!jurisdiction || !date) {
        this.replaceWith('/admin/permissions');
        return;
      }
      get(this, 'ajax').updateWorksiteFees(jurisdiction, date);
      this.replaceWith('/');
    }
  });
  _exports.default = _default;
});