define("tmp-for-all/components/shared/form/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Rs0sxqiS",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"type\",\"checked\",\"disabled\",\"id\"],[\"checkbox\",[23,[\"checked\"]],[23,[\"disabled\"]],[23,[\"inputID\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"for\",[28,[[21,\"inputID\"]]]],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"toolTipText\"]]],null,{\"statements\":[[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"        \"],[1,[21,\"toolTipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/check-box/template.hbs"
    }
  });
  _exports.default = _default;
});