define("tmp-for-all/mixins/routes/add-comment-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Mixin.create({
    actions: {
      addComment: function addComment(commentText, model, files) {
        var _this = this;
        if (files.length === 0) files = null;
        return model.addComment(commentText, files).then(function () {
          return get(_this, 'flashMessages').success('Comment has been recorded.');
        }).catch(function () {
          return get(_this, 'flashMessages').danger('There was a problem saving your comment.');
        });
      }
    }
  });
  _exports.default = _default;
});