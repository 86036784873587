define("tmp-for-all/helpers/detour-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.detourDescription = detourDescription;
  function detourDescription(params) {
    var steps = params[0] || false;
    if (!steps || !steps.length) return '';
    return steps.map(function (s) {
      return s.text;
    }).join(', ');
  }
  var _default = Ember.Helper.helper(detourDescription);
  _exports.default = _default;
});