define("tmp-for-all/components/shared/add-conditions/group/condition/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Y3lLNH82",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"shared/form/check-box\",null,[[\"checked\",\"disabled\",\"label\",\"update\",\"useButtonStyle\",\"class\"],[[23,[\"isChecked\"]],[23,[\"isDisabled\"]],[23,[\"labelText\"]],[27,\"action\",[[22,0,[]],[23,[\"onChange\"]],[23,[\"condition\"]]],null],true,[23,[\"classString\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/add-conditions/group/condition/template.hbs"
    }
  });
  _exports.default = _default;
});