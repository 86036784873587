define("tmp-for-all/components/shared/expand-contract-box/component", ["exports", "tmp-for-all/mixins/components/expand-contract-mixin"], function (_exports, _expandContractMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_expandContractMixin.default, {
    classNames: ['info-box', 'expand-outer'],
    classNameBindings: ['showBaseline'],
    minAnimationSpeed: 400,
    initialHeight: 100,
    // Don't allow expand/contract to be disabled regardless of height difference:
    dontDisable: true
  });
  _exports.default = _default;
});