define("tmp-for-all/models/worksite-draft", ["exports", "ember-data", "tmp-for-all/models/worksite-base", "tmp-for-all/constants", "tmp-for-all/utils/animations", "tmp-for-all/utils/html-comment-helpers"], function (_exports, _emberData, _worksiteBase, _constants, _animations, _htmlCommentHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP,
    computed = Ember.computed,
    $ = Ember.$;
  var attr = _emberData.default.attr;
  var _default = _worksiteBase.default.extend({
    isPublished: attr('boolean'),
    ajax: Ember.inject.service(),
    status: computed('isPublished', function () {
      return get(this, 'isPublished') ? 'Published Draft' : 'Draft';
    }),
    type: _constants.WORKSITE_MODEL_TYPES.DRAFT,
    submitComment: attr('string', {
      defaultValue: ''
    }),
    addEmptyWorkItem: function addEmptyWorkItem() {
      this.store.createRecord('work-item', {
        worksiteDraft: this
      });
    },
    submit: function submit() {
      var _this = this;
      if (get(this, 'isNotInitialRevision') && (!get(this, 'submitComment') || !(0, _htmlCommentHelpers.hasHtmlComment)(get(this, 'submitComment')))) {
        (0, _animations.jiggleALittleBit)($('.t-comment-textarea'));
        return RSVP.reject(new Error('Comment cannot be blank.'));
      }
      set(this, 'isSubmitting', true);
      set(this, 'submitComment', (0, _htmlCommentHelpers.sanitizeHtmlComment)(get(this, 'submitComment')));
      return get(this, 'ajax').worksiteAction(this, 'submit').then(function () {
        var submission = _this.store.peekRecord('worksite-submission', _this.id);
        if (submission) {
          return get(_this, 'store').unloadRecord(submission);
        }
      }).catch(function (err) {
        err.message = 'Worksite could not be submitted, please try again';
        return RSVP.reject(err);
      }).finally(function () {
        set(_this, 'isSubmitting', false);
        set(_this, 'submitComment', '');
      });
    },
    getFeeEstimate: function getFeeEstimate() {
      var _this2 = this;
      var id = get(this, 'id');
      return get(this, 'ajax').getWorksiteDraftEstimateFees(id).then(function (fees) {
        return set(_this2, 'estimatedFees', fees);
      });
    },
    // Re-test whenever the TMPs are modified.
    canDeletePromise: computed('id', 'tmps', 'tmpSubmissions', 'tmpDrafts', function () {
      var id = get(this, 'id');
      if (get(this, 'isDeleted')) {
        return _emberData.default.PromiseObject.create({
          promise: RSVP.resolve(false)
        });
      }
      var promise = get(this, 'ajax').request("worksite-drafts/".concat(id, "/can-delete"));
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }).readOnly(),
    // Uses promise reject instead of resolving false to provide the error messaging
    canSubmitWorksite: function canSubmitWorksite() {
      return get(this, 'jurisdiction').then(function (jurisdiction) {
        if (get(jurisdiction, 'denySubmitWorksites')) {
          return RSVP.reject(new Error('Worksites for the selected RCA cannot be submitted using MyWorksites, please contact the RCA for further information.'));
        }
        return RSVP.resolve(true);
      });
    }
  });
  _exports.default = _default;
});