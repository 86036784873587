define("tmp-for-all/components/shared/json-or-primitive-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var capitalise = function capitalise(str) {
    return "".concat(str.charAt(0).toUpperCase()).concat(str.slice(1));
  };
  var _default = Ember.Component.extend({
    isJson: computed('value', function () {
      try {
        JSON.parse(this.get('value'));
        return true;
      } catch (_unused) {
        return false;
      }
    }),
    parsedValue: computed('value', function () {
      var obj = JSON.parse(this.get('value'));
      return Object.keys(obj).map(function (key) {
        return "".concat(capitalise(key), ": ").concat(obj[key]);
      }).join(', ');
    })
  });
  _exports.default = _default;
});