define("tmp-for-all/routes/worksite/edit/contacts/new-company", ["exports", "tmp-for-all/mixins/routes/modals/new-company-route"], function (_exports, _newCompanyRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_newCompanyRoute.NewCompanyModalMixin, {
    saveActionName: 'selectNewCompany'
  });
  _exports.default = _default;
});