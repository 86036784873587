define("tmp-for-all/authenticators/token", ["exports", "tmp-for-all/authenticators/loopback"], function (_exports, _loopback) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var unHashToken = function unHashToken(tokenString) {
    return JSON.parse(decodeURIComponent(window.atob(tokenString)));
  };

  /**
   * This authenticator is used to get the actual token for a
   * user that logged in via external authentication
   */
  var _default = _loopback.default.extend({
    authenticate: function authenticate(tokenString) {
      try {
        var token = unHashToken(tokenString);
        if (!token.id || !token.userId) throw new Error('Invalid token object');
        return Promise.resolve(token);
      } catch (e) {
        return Promise.reject(e);
      }
    }
  });
  _exports.default = _default;
});