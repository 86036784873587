define("tmp-for-all/components/condition-groups-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mOk4+I2w",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[7,\"div\"],[11,\"class\",\"deprecated-grid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"deprecated-col-1-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"element-group-wrap\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"element-group-label\"],[9],[0,\"Archived Groups\"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"element-wrap\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"checked\"],[\"checkbox\",\"show-archived-groups\",[23,[\"showArchived\"]]]]],false],[0,\"\\n        \"],[7,\"label\"],[11,\"for\",\"show-archived-groups\"],[9],[0,\"Show Archived Groups\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n  \"],[7,\"table\"],[12,\"class\",[28,[\"table \",[21,\"color\"]]]],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Group Name\"],[10],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Enabled by Default\"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"links-column\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredGroups\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"condition-groups-list/item\",null,[[\"group\",\"conditionType\"],[[22,1,[]],[23,[\"conditionType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"shared/fixed-actions-bar\",null,null,{\"statements\":[[4,\"link-to\",[[23,[\"createConditionGroupLink\"]]],[[\"class\"],[\"primary-action\"]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"plus\"]]],false],[0,\" Add condition group\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/condition-groups-list/template.hbs"
    }
  });
  _exports.default = _default;
});