define("tmp-for-all/components/project-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var isUtility = get(this, 'project.company.isUtility') === undefined ? true : get(this, 'project.company.isUtility');
      var isGovernmentAgency = get(this, 'project.company.isGovernmentAgency') === undefined ? false : get(this, 'project.company.isGovernmentAgency');
      set(this, 'showUtilityCompanies', isUtility);
      set(this, 'showGovernmentAgencies', isGovernmentAgency);
    },
    classNames: ['project-form'],
    companyError: computed.alias('project.errors.companyCreatedFor'),
    canDelete: computed('project.isNew', 'project.worksites.length', 'project.worksiteSubmissions.length', 'project.worksiteDrafts.length', function () {
      return !get(this, 'project.isNew') && !get(this, 'project.worksites.length') && !get(this, 'project.worksiteSubmissions.length') && !get(this, 'project.worksiteDrafts.length');
    }),
    actions: {
      updateFilter: function updateFilter(type) {
        this.set('project.company', null);
        switch (type) {
          case 'company':
            this.set('showUtilityCompanies', false);
            this.set('showGovernmentAgencies', false);
            break;
          case 'utility':
            this.set('showUtilityCompanies', true);
            this.set('showGovernmentAgencies', false);
            break;
          case 'agency':
            this.set('showUtilityCompanies', false);
            this.set('showGovernmentAgencies', true);
            break;
        }
      },
      searchCompanies: function searchCompanies(q) {
        var isUtility = get(this, 'showUtilityCompanies');
        var isGovernmentAgency = get(this, 'showGovernmentAgencies');
        return get(this, 'searchCompanies')(q, {
          isUtility: isUtility,
          isGovernmentAgency: isGovernmentAgency
        });
      },
      deleteProject: function deleteProject() {
        if (get(this, 'deleteProject')) get(this, 'deleteProject')();
      }
    }
  });
  _exports.default = _default;
});