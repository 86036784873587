define("tmp-for-all/components/reports/worksite-fee-results/item/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    feeStatus: computed('result.feeStatus', function () {
      return _constants.WORKSITE_FEE_STATUSES[get(this, 'result.feeStatus')];
    }),
    actions: {
      checkResult: function checkResult(value) {
        var result = get(this, 'result');
        get(this, 'didCheckResult')(result, value);
      }
    }
  });
  _exports.default = _default;
});