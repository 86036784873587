define("tmp-for-all/routes/companies/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set,
    setProperties = Ember.setProperties;
  var COMPANY_ADMIN_PERMISSION_ID = 25;
  var _default = Ember.Route.extend({
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: null,
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (user) {
        set(_this, 'currentUser', user);
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'createCompany').catch(function () {
          return _this.replaceWith('index');
        });
      });
    },
    model: function model() {
      var _this2 = this;
      var currentUser = get(this, 'currentUser');
      var isSuperUser = !get(currentUser, 'selectedDomain') && !get(currentUser, 'selectedDomainId');
      var rcas = isSuperUser ? this.store.findAll('jurisdiction') : get(currentUser, 'jurisdictions');
      var company = this.store.createRecord('company');
      var customerNumbers = rcas.then(function (rcas) {
        return rcas.map(function (jurisdiction) {
          return _this2.store.createRecord('customer-number', {
            jurisdiction: jurisdiction,
            company: company
          });
        });
      });
      return RSVP.hash({
        company: company,
        customerNumbers: customerNumbers,
        user: this.store.createRecord('tmp-user')
      });
    },
    saveCompanyAdmin: function saveCompanyAdmin(user, company) {
      var _this3 = this;
      if (!user || !user.email) return RSVP.resolve();
      var newUser = get(user, 'isNew');
      var selectedDomain = 'company';
      var selectedDomainId = company.id;

      // new users: set selected domain and id and add meta to send invite email
      if (newUser) {
        setProperties(user, {
          selectedDomain: selectedDomain,
          selectedDomainId: selectedDomainId
        });
        user.set('meta', {
          creationType: 'inviteCompanyAdmin'
        });
      }
      var savePromise = newUser ? user.save() : RSVP.resolve();
      return savePromise.then(function () {
        return RSVP.hash({
          permissionAssignments: get(user, 'permissionAssignments'),
          permission: _this3.store.findRecord('permission', COMPANY_ADMIN_PERMISSION_ID)
        });
      }).then(function (_ref) {
        var permissionAssignments = _ref.permissionAssignments,
          permission = _ref.permission;
        var assignment = permissionAssignments.find(function (pa) {
          return get(pa, 'domainType') === selectedDomain && get(pa, 'domainId') === selectedDomainId && get(pa, 'permissionId') === COMPANY_ADMIN_PERMISSION_ID;
        });
        return assignment || _this3.store.createRecord('permission-assignment', {
          user: user,
          domainType: selectedDomain,
          domainId: selectedDomainId,
          permission: permission,
          meta: {
            needsOrgAdditionNotification: !newUser
          }
        }).save();
      });
    },
    actions: {
      saveCompany: function saveCompany(company, customerNumbers, companyAdmin) {
        var _this4 = this;
        return company.save().then(function (savedCompany) {
          return RSVP.all([_this4.saveCompanyAdmin(companyAdmin, savedCompany)].concat(customerNumbers.map(function (cn) {
            return cn.save();
          })));
        }).then(function () {
          get(_this4, 'flashMessages').success('Organisation has been created successfully.');
          _this4.transitionTo('companies.list');
        }).catch(function () {
          get(_this4, 'flashMessages').danger('There was a problem creating this Organisation');
        });
      },
      willTransition: function willTransition() {
        var _this5 = this;
        var company = this.get('controller.model.company');
        var customerNumbers = get(this, 'controller.model.customerNumbers');
        return RSVP.all(customerNumbers.map(function (cn) {
          return cn.destroyOrRollback();
        })).then(function () {
          return set(_this5, 'controller.model.customerNumbers', null);
        }).then(function () {
          if (company) company.destroyOrRollback();
        });
      }
    }
  });
  _exports.default = _default;
});