define("tmp-for-all/helpers/permission/edit-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.editContactsCheck = editContactsCheck;
  function editContactsCheck(worksiteType) {
    return {
      'worksite-draft': 'editDraftWorksiteContacts',
      'worksite-submission': 'editSubmittedWorksiteContacts',
      worksite: 'editSubmittedWorksiteContacts'
    }[R.head(worksiteType)];
  }
  var _default = Ember.Helper.helper(editContactsCheck);
  _exports.default = _default;
});