define("tmp-for-all/components/search-results/organisations/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "J8/fApMT",
    "block": "{\"symbols\":[\"company\"],\"statements\":[[4,\"if\",[[23,[\"companies\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n    \"],[7,\"table\"],[11,\"class\",\"table\"],[9],[0,\"\\n      \"],[7,\"thead\"],[9],[0,\"\\n        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"th\"],[11,\"class\",\"no-wrap\"],[9],[0,\"Name\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Address\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Website\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isSuperUser\"]]],null,{\"statements\":[[0,\"            \"],[7,\"th\"],[9],[0,\"Customer number\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"th\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"uniqueCompanies\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"search-results/organisations/list/item\",null,[[\"company\",\"showCustomerNumbers\"],[[22,1,[]],[23,[\"isSuperUser\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"text-14 text-grey-dark\"],[9],[0,\"No results returned for current search criteria. Please refine your search and try again.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/organisations/list/template.hbs"
    }
  });
  _exports.default = _default;
});