define("tmp-for-all/models/worksite-comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    createdAt: attr('date'),
    createdBy: attr(),
    text: attr('string'),
    worksite: belongsTo('worksite-base', {
      async: true,
      polymorphic: true,
      inverse: 'worksiteComments'
    }),
    // User's organisation who logged the comment
    jurisdiction: belongsTo('jurisdiction', {
      readOnly: true
    }),
    company: belongsTo('company', {
      readOnly: true
    })
  });
  _exports.default = _default;
});