define("tmp-for-all/classes/form-object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
    get = Ember.get;

  /**
   * An object that can be used instead of a proper model (e.g. worksite) instance when
   * a page has a form with no associated model
   */
  var FormObject = Ember.Object.extend({
    init: function init() {
      set(this, 'errors', new _emberData.default.Errors());
    },
    /**
     * Use this method to set errors after a failed ajax request.
     *
     * @param {Object} ajaxError a JQuery ajaxError object
     */
    setErrors: function setErrors(ajaxError) {
      var newErrors = new _emberData.default.Errors();
      set(this, 'errors', newErrors);
      var incomingErrors = get(ajaxError, 'errors') || get(ajaxError, 'responseJSON.errors') || [];
      incomingErrors.forEach(function (error) {
        var field = R.last(R.split('/', get(error, 'source.pointer') || 'default'));
        newErrors.add(field, get(error, 'detail'));
      });
    }
  });
  FormObject.toString = function () {
    return 'FormObject';
  };
  var _default = FormObject;
  _exports.default = _default;
});