define("tmp-for-all/components/search-results/worksites/results-table/item/claim-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WUyotIeH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"worksite-claim links-column\"],[11,\"data-th\",\"Claim\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isSubmission\"]]],null,{\"statements\":[[4,\"unless\",[[27,\"eq\",[[23,[\"searchResult\",\"ownerId\"]],[23,[\"user\",\"id\"]]],null]],null,{\"statements\":[[4,\"if-permitted\",[\"claimSubmittedWorksite\"],[[\"model\"],[[23,[\"user\",\"domain\"]]]],{\"statements\":[[4,\"if-permitted\",[\"claimSubmittedWorksite\"],[[\"model\"],[[23,[\"worksiteModel\"]]]],{\"statements\":[[4,\"unless\",[[23,[\"searchResult\",\"isDeclined\"]]],null,{\"statements\":[[0,\"              \"],[7,\"button\"],[12,\"disabled\",[23,[\"worksite\",\"isSaving\"]]],[12,\"class\",[28,[\"tertiary-action \",[27,\"if\",[[23,[\"worksite\",\"isSaving\"]],\"tertiary-action--disabled\"],null],\" t-claim-button\"]]],[9],[0,\"\\n                Claim\\n              \"],[3,\"action\",[[22,0,[]],\"onClaim\",[23,[\"worksiteModel\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/worksites/results-table/item/claim-column/template.hbs"
    }
  });
  _exports.default = _default;
});