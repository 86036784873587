define("tmp-for-all/templates/planning-map/region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gzncr7Qk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"planning-map\",null,[[\"center\",\"zoom\",\"bounds\",\"data\",\"sidePanelStructure\",\"searchQuery\",\"updateSearchQuery\",\"getDetail\",\"query\",\"rcaCollection\",\"isLoadingData\",\"setSearchQuery\"],[[23,[\"model\",\"map\",\"center\"]],[23,[\"model\",\"map\",\"zoom\"]],null,[23,[\"model\",\"data\"]],[23,[\"structure\"]],[23,[\"searchQuery\"]],[27,\"action\",[[22,0,[]],\"updateSearchQuery\"],null],[27,\"action\",[[22,0,[]],\"getDetail\"],null],[23,[\"query\"]],[23,[\"model\",\"rcaCollection\"]],[23,[\"model\",\"isLoadingData\"]],[27,\"action\",[[22,0,[]],\"setSearchQuery\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/planning-map/region.hbs"
    }
  });
  _exports.default = _default;
});