define("tmp-for-all/templates/companies/edit/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9Pn8+OUv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Edit Organisation\"]],{\"statements\":[[0,\"  \"],[1,[27,\"companies-form\",null,[[\"company\",\"imCustomerNumbers\",\"currentUser\",\"saveCompany\",\"toggleArchiveState\",\"addCustomerNumber\"],[[23,[\"model\",\"company\"]],[23,[\"model\",\"customerNumbers\"]],[23,[\"model\",\"currentUser\"]],[27,\"route-action\",[\"saveCompany\"],null],[27,\"route-action\",[\"toggleArchiveState\"],null],[27,\"route-action\",[\"addCustomerNumber\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/companies/edit/index.hbs"
    }
  });
  _exports.default = _default;
});