define("tmp-for-all/components/search-results/worksites/results-table/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    // Additional 'isVisible' property set for each object on init below
    columns: [Ember.Object.create({
      key: 'ID',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.ID,
      sortName: 'worksiteId'
    }), Ember.Object.create({
      key: 'VERSION',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.VERSION
    }), Ember.Object.create({
      key: 'NAME',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.NAME,
      sortName: 'name'
    }), Ember.Object.create({
      key: 'REFERENCE',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.REFERENCE,
      sortName: 'reference'
    }), Ember.Object.create({
      key: 'PROJECT',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.PROJECT,
      sortName: 'projectName'
    }), Ember.Object.create({
      key: 'CLIENT',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.CLIENT,
      sortName: 'clientName'
    }), Ember.Object.create({
      key: 'APPLICANT',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.APPLICANT,
      sortName: 'applicantOrganisation'
    }), Ember.Object.create({
      key: 'STARTDATE',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.STARTDATE,
      sortName: 'startDate'
    }), Ember.Object.create({
      key: 'ENDDATE',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.ENDDATE,
      sortName: 'endDate'
    }), Ember.Object.create({
      key: 'SUBMITTED',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.SUBMITTED,
      sortName: 'submittedAt'
    }), Ember.Object.create({
      key: 'DAYSAGO',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.DAYSAGO,
      sortName: 'submittedAt'
    }), Ember.Object.create({
      key: 'STATUS',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.STATUS
    }), Ember.Object.create({
      key: 'TMPS',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.TMPS
    }), Ember.Object.create({
      key: 'WORK_STATUS',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.WORK_STATUS,
      sortName: 'workStatus'
    }), Ember.Object.create({
      key: 'WORKSTART',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.WORKSTART,
      sortName: 'workStartDate'
    }), Ember.Object.create({
      key: 'WORKSTARTLOGGEDAT',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.WORKSTARTLOGGEDAT,
      sortName: 'workStartDateLoggedAt'
    }), Ember.Object.create({
      key: 'WORKCOMPLETION',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.WORKCOMPLETION,
      sortName: 'workCompletionDate'
    }), Ember.Object.create({
      key: 'WORKCOMPLETIONLOGGEDAT',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.WORKCOMPLETIONLOGGEDAT,
      sortName: 'workCompletionDateLoggedAt'
    }), Ember.Object.create({
      key: 'WARRANTYCOMPLETE',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.WARRANTYCOMPLETE,
      sortName: 'warrantyCompleteDate'
    }), Ember.Object.create({
      key: 'ASSIGNEDTO',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.ASSIGNEDTO
    }), Ember.Object.create({
      key: 'JURISDICTIONAREANAME',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.JURISDICTIONAREANAME
    }), Ember.Object.create({
      key: 'WORKSITETYPE',
      columnName: _constants.WORKSITE_TABLE_COLUMNS.WORKSITETYPE,
      sortName: 'worksiteType'
    })],
    orderByName: null,
    orderByDirection: null,
    init: function init() {
      this._super.apply(this, arguments);
      // Set visible columns to match page default (set in controller):
      this.revertToDefaultColumns();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (get(this, 'order')) {
        var orderProps = get(this, 'order').split(' ');
        set(this, 'orderByName', orderProps[0]);
        set(this, 'orderByDirection', orderProps[1]);
      }
    },
    toggleText: 'Show all columns',
    // Set visible columns to page default (defaults set in controller)
    revertToDefaultColumns: function revertToDefaultColumns() {
      var columns = get(this, 'columns') || [];
      var columnsToHide = get(this, 'columnsExcludedByDefault') || [];
      set(this, 'columns', columns.map(function (column) {
        var isVisible = true;
        if (columnsToHide && columnsToHide.includes(column.columnName)) isVisible = false;
        set(column, 'isVisible', isVisible);
        return column;
      }));
    },
    actions: {
      toggleColumn: function toggleColumn(column) {
        column.toggleProperty('isVisible');
        // If all columns are now shown, change toggle all link to read "Revert to default":
        var allColumnsAreVisible = get(this, 'columns').every(function (column) {
          return get(column, 'isVisible');
        });
        if (allColumnsAreVisible) {
          set(this, 'toggleText', 'Revert to default');
        }
      },
      toggleAllColumns: function toggleAllColumns() {
        var columns = get(this, 'columns');
        var allColumnsAreVisible = columns.every(function (column) {
          return get(column, 'isVisible');
        });
        // If all columns are already shown, action reverts visible columns back to page default instead:
        if (allColumnsAreVisible) {
          this.revertToDefaultColumns();
          set(this, 'toggleText', 'Show all columns');
          // Otherwise, update all columns to be visible:
        } else {
          set(this, 'columns', columns.map(function (column) {
            set(column, 'isVisible', true);
            return column;
          }));
          set(this, 'toggleText', 'Revert to default');
        }
      }
    }
  });
  _exports.default = _default;
});