define("tmp-for-all/components/worksite/documentation-form/documentation-item/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tooltipMessage: computed('doc', function () {
      var _this = this;
      return _constants.TOOLTIP_BLOCK_MESSAGES.WORKSITE_DOCUMENTATION.TYPES.find(function (item) {
        return get(item, 'type') === get(_this, 'doc.type');
      });
    })
  });
  _exports.default = _default;
});