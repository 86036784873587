define("tmp-for-all/components/password/reset-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    getProperties = Ember.getProperties,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    classNames: ['password-reset-wrap'],
    ajax: Ember.inject.service(),
    passwordInputIconColor: computed('password', function () {
      return get(this, 'password') ? 'blue' : 'grey';
    }),
    repeatPasswordInputIconColor: computed('confirm', function () {
      return get(this, 'confirm') ? 'blue' : 'grey';
    }),
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;
        var _getProperties = getProperties(this, ['confirm', 'password', 'accessToken']),
          confirm = _getProperties.confirm,
          password = _getProperties.password,
          accessToken = _getProperties.accessToken;
        if (password !== confirm) {
          set(this, 'message', 'Passwords don\'t match. Please try again.');
        } else {
          get(this, 'ajax').userAction(null, 'process-password-reset', {
            password: password
          }, accessToken).then(function () {
            return set(_this, 'success', true);
          }).catch(function () {
            return set(_this, 'message', 'There was a problem resetting your password.');
          });
        }
      }
    }
  });
  _exports.default = _default;
});