define("tmp-for-all/components/shared/toggle-text-length/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    classNames: ['toggle-text-length'],
    isExpanded: false,
    croppedTextCharLength: 50,
    fullText: computed('textToToggle', function () {
      return get(this, 'textToToggle').replace(/(<([^>]+)>)/ig, '');
    }),
    fullTextLength: computed('croppedToggleText', function () {
      return get(this, 'fullText').length;
    }),
    croppedToggleText: computed('isExpanded', function () {
      if (get(this, 'isExpanded')) {
        return get(this, 'fullText');
      } else {
        return get(this, 'fullText').substr(0, get(this, 'croppedTextCharLength'));
      }
    }),
    toggleTriggerText: computed('isExpanded', function () {
      return get(this, 'isExpanded') ? 'show less' : 'show more';
    })
  });
  _exports.default = _default;
});