define("tmp-for-all/mixins/submit-panel-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // Set submit panel element (expandable grey panel at bottom of page):
      var submitPanel = (0, _jquery.default)('.submit-options');
      set(this, 'submitPanel', submitPanel);
      // Set content area wrapping main element so we can adjust the padding when needed:
      var mainEl = (0, _jquery.default)('#main');
      set(this, 'mainElement', mainEl);
    },
    // Revert main element padding-bottom back to default when navigating away from page:
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var mainElement = get(this, 'mainElement');
      if (mainElement) mainElement.removeClass('submit-panel-open');
      // Remove listener to stop animation on manual scroll:
      (0, _jquery.default)('html, body').off('scroll mousedown wheel DOMMouseScroll mousewheel touchmove');
    },
    actions: {
      // Smooth scroll page to submit panel when opened:
      scrollToSubmitPanel: function scrollToSubmitPanel() {
        var submitPanel = get(this, 'submitPanel');
        if (!submitPanel.length) return;
        this.send('toggleMainPaddingBottom', true);
        var elementX = submitPanel.offset().top;
        this.send('scrollToPosition', elementX);
      },
      // Animate smooth scroll of page to supplied x coord
      scrollToPosition: function scrollToPosition(pageX) {
        var pageEl = (0, _jquery.default)('html, body');

        // Stop animation on page element when scrolling manually:
        pageEl.on('scroll mousedown wheel DOMMouseScroll mousewheel touchmove', function () {
          pageEl.stop();
        });

        // Animate page scroll to target element, remove above listener on complete:
        pageEl.animate({
          scrollTop: pageX + 'px'
        }, 800, function () {
          pageEl.off('scroll mousedown wheel DOMMouseScroll mousewheel touchmove');
        });
      },
      // Remove padding bottom on main element when submit panel is open:
      toggleMainPaddingBottom: function toggleMainPaddingBottom(isSubmitPanelOpen) {
        var mainElement = get(this, 'mainElement');
        if (!mainElement) return;
        if (isSubmitPanelOpen) {
          mainElement.addClass('submit-panel-open');
        } else {
          mainElement.removeClass('submit-panel-open');
        }
      }
    }
  });
  _exports.default = _default;
});