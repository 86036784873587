define("tmp-for-all/mixins/routes/view-my-worksites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    authorization: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'session.currentUser');
      }).then(function (user) {
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'submitWorksite', {
          modelName: get(user, 'selectedDomain'),
          modelId: get(user, 'selectedDomainId')
        });
      }).catch(function () {
        return _this.router.replaceWith('index');
      });
    }
  });
  _exports.default = _default;
});