define("tmp-for-all/templates/modals/add-new-contact-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "99u+KHXm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\"],[true]],{\"statements\":[[0,\"  \"],[1,[27,\"shared/add-new-contact\",null,[[\"select\",\"cancel\"],[[27,\"route-action\",[\"select\"],null],[27,\"route-action\",[\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/modals/add-new-contact-modal.hbs"
    }
  });
  _exports.default = _default;
});