define("tmp-for-all/components/search-parameters/filters/filter-controls/options-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var filter = get(this, 'filter');
      var updateFilterParams = get(this, 'updateFilterParams');
      if (updateFilterParams && filter) {
        updateFilterParams(function (controller) {
          var options = filter.getOptions(controller);
          set(_this, 'options', options);
          options.then(function (options) {
            return filter.getInitialSelection(controller, options, get(_this, 'index'));
          }).then(function (selection) {
            if (selection) set(_this, 'selectedOption', selection);
          });
        });
      }
    },
    isDisabled: computed('selectedOption', function () {
      return !get(this, 'selectedOption.name') || !get(this, 'selectedOption.name').length;
    }),
    actions: {
      selectOption: function selectOption(option) {
        set(this, 'selectedOption', option);
      },
      saveFilter: function saveFilter() {
        var _this2 = this;
        var updateFilterParams = get(this, 'updateFilterParams');
        var filter = get(this, 'filter');
        if (updateFilterParams && filter) {
          var index = get(this, 'addFilter')(filter);
          updateFilterParams(function (controller) {
            filter.setOption(controller, get(_this2, 'selectedOption').value, index);
          });

          // Exit modal
          get(this, 'toggleModal')();
        }
      }
    }
  });
  _exports.default = _default;
});