define("tmp-for-all/components/shared/work-date-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    date: null,
    minDate: computed(function () {
      return null;
    }),
    maxDate: computed(function () {
      return new Date();
    }),
    disabledDateSelector: computed(function () {
      return true;
    }),
    actions: {
      selectToday: function selectToday() {
        set(this, 'disabledDateSelector', true);
        get(this, 'selectDate')(moment().endOf('day').format());
      },
      selectYesterday: function selectYesterday() {
        set(this, 'disabledDateSelector', true);
        get(this, 'selectDate')(moment().subtract(1, 'days').endOf('day').format());
      },
      selectCustom: function selectCustom() {
        set(this, 'disabledDateSelector', false);
      },
      userPickDate: function userPickDate(date) {
        get(this, 'selectDate')(moment(date).endOf('day').format());
      }
    }
  });
  _exports.default = _default;
});