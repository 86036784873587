define("tmp-for-all/components/content-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-container'],
    classNameBindings: ['isFullWidth:content-container--listing-page', 'isPlanningMapPage:content-container--planning-map-page']
  });
  _exports.default = _default;
});