define("tmp-for-all/components/shared/threaded-history/item/component", ["exports", "tmp-for-all/utils/diff-utils"], function (_exports, _diffUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    isCommentHTML: computed('history.comment', function () {
      var commentText = get(this, 'history.comment');
      var htmlRegex = /<\/[a-z][\s\S]*>/i;
      return htmlRegex.test(commentText);
    }),
    hasOrganisationSuffix: computed('history.description', function () {
      var desc = get(this, 'history.description');
      return desc && desc.endsWith(')');
    }),
    organisationName: computed('history.organisationName', 'hasOrganisationSuffix', function () {
      var orgName = get(this, 'history.organisationName');
      return orgName && !get(this, 'hasOrganisationSuffix') ? "(".concat(orgName, ")") : '';
    }),
    rawDiffContent: computed('history', function () {
      return get(this, 'history.diff');
    }),
    diffContent: computed('rawDiffContent', function () {
      var gotDiff = get(this, 'hasDiff');
      if (gotDiff) {
        var rdc = get(this, 'rawDiffContent');
        return (0, _diffUtils.filteredDiffContent)(rdc);
      }
    }),
    diffEmpty: computed('diffContent', function () {
      var theDiff = get(this, 'rawDiffContent');
      return !theDiff || Object.keys(theDiff).length === 0;
    }),
    hasDiff: computed('history.diff', function () {
      return !!get(this, 'history.diff');
    }),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});