define("tmp-for-all/components/companies-form/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var UTILITY = 'utility';
  var COMPANY = 'company';
  var PRIVATE = 'private';
  var GOVT = 'government';
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    postalAddressType: _constants.ADDRESS_TYPES.POSTAL,
    physicalAddressType: _constants.ADDRESS_TYPES.PHYSICAL,
    archiveButtonText: computed('company.isArchived', function () {
      return this.get('company.isArchived') ? 'Unarchive' : 'Archive';
    }),
    didInsertElement: function didInsertElement() {
      this.$('#idCompanyName').focus();
    },
    isRcaUser: computed.equal('currentUser.selectedDomain', 'jurisdiction'),
    rcaId: computed('currentUser.selectedDomainId', function () {
      return get(this, 'isRcaUser') ? String(get(this, 'currentUser.selectedDomainId')) : null;
    }),
    customerNumberRcas: Ember.computed.mapBy('customerNumbers', 'jurisdiction'),
    customerNumberRcaIds: Ember.computed.mapBy('customerNumberRcas', 'id'),
    isSuperUser: computed('currentUser.selectedDomain', 'currentUser.selectedDomainId', function () {
      var currentUser = get(this, 'currentUser');
      if (!currentUser) return;
      return !get(currentUser, 'selectedDomain') && !get(currentUser, 'selectedDomainId');
    }),
    canAddCustomerNumber: computed('customerNumberRcaIds', 'rcaId', function () {
      return get(this, 'isSuperUser') || !get(this, 'customerNumberRcaIds').includes(get(this, 'rcaId'));
    }),
    companyTypes: computed('company.isUtility', 'company.isGovernmentAgency', 'company.isPrivateIndividual', function () {
      var nameKey = "companyFilter-".concat(get(this, 'elementId'));
      var isUtil = get(this, 'company.isUtility');
      var isGovAgency = get(this, 'company.isGovernmentAgency');
      var isPrivate = get(this, 'company.isPrivateIndividual');
      var options = [{
        name: nameKey,
        value: PRIVATE,
        label: 'Private Individual',
        checked: isPrivate
      }, {
        name: nameKey,
        value: COMPANY,
        label: 'Organisation',
        checked: !isUtil && !isGovAgency && !isPrivate
      }, {
        name: nameKey,
        value: GOVT,
        label: 'Government Agency',
        checked: isGovAgency
      }, {
        name: nameKey,
        value: UTILITY,
        label: 'Utility',
        checked: isUtil
      }];
      return options;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var imCustomerNumbers = get(this, 'imCustomerNumbers');
      if (imCustomerNumbers) {
        var customerNumbers = imCustomerNumbers.map(function (cn) {
          return cn;
        });
        set(this, 'customerNumbers', customerNumbers);
      }
    },
    actions: {
      updateCompanyType: function updateCompanyType(type) {
        set(this, 'company.isUtility', type === UTILITY);
        set(this, 'company.isGovernmentAgency', type === GOVT);
        set(this, 'company.isPrivateIndividual', type === PRIVATE);
      }
    }
  });
  _exports.default = _default;
});