define("tmp-for-all/components/layout-time-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    isEmpty = Ember.isEmpty;
  var DAY_START_HOUR = 6;

  /**
   * timeToMoment
   * Given an existing moment and a time string 'HH:mm' returns a new moment
   * with the given time and the date correct in relation to DAY_START_HOUR and
   * the initial date.
   * @param  {Moment} momentDate
   * @param  {String} timeString
   * @return {Moment}
   */
  function timeToMoment(momentDate, timeString) {
    var hours = timeString.slice(0, 2);
    var minutes = timeString.slice(3);
    var newMoment = momentDate.freeze().hours(hours).minutes(minutes);
    if (parseInt(hours, 10) < DAY_START_HOUR) {
      newMoment = newMoment.add(1, 'day');
    }
    return newMoment;
  }
  var _default = Ember.Component.extend({
    // These can be empty or used to initialize selections
    preparationIn: '',
    trafficControlIn: '',
    trafficControlOut: '',
    siteCleared: '',
    // Errors
    preparationInErrors: '',
    trafficControlInErrors: '',
    trafficControlOutErrors: '',
    siteClearedErrors: '',
    // These values must be passed in to calculate restrictions
    isContinuous: '',
    startDate: '',
    endDate: '',
    // Actions called when values set
    didSelectPreparationIn: null,
    didSelectTrafficControlIn: null,
    didSelectTrafficControlOut: null,
    didSelectSiteCleared: null,
    // Set these when restricting to a deployment to within a layouts range
    layoutPreparationIn: null,
    layoutTrafficControlIn: null,
    layoutTrafficControlOut: null,
    layoutSiteCleared: null,
    layoutStartDate: null,
    layoutEndDate: null,
    allTimes: computed(function () {
      var times = [];
      for (var i = 0; i < 24; i++) {
        var hour = ((i + DAY_START_HOUR) % 24).toString();
        hour = hour.length > 1 ? hour : '0' + hour;
        times.push(hour + ':00');
        times.push(hour + ':15');
        times.push(hour + ':30');
        times.push(hour + ':45');
      }
      return times;
    }),
    timesOfDayStart: computed('allTimes', 'siteCleared', 'isContinuous', 'startDate', 'endDate', 'layoutTrafficControlIn', 'layoutStartDate', function () {
      var allTimes = this.get('allTimes');
      var siteCleared = this.get('siteCleared');
      var isContinuous = this.get('isContinuous');
      var startDate = moment(this.get('startDate'));
      var endDate = moment(this.get('endDate'));
      var layoutTrafficControlIn = this.get('layoutTrafficControlIn');
      var restrictedTimes = layoutTrafficControlIn ? allTimes.slice(allTimes.indexOf(layoutTrafficControlIn), -1) : allTimes;
      var layoutStartDate = this.get('layoutStartDate');
      if (isContinuous && endDate.diff(startDate, 'days') > 0) {
        // No restrictions when *within* start and end date
        if (layoutStartDate && startDate.diff(moment(layoutStartDate), 'days') > 0) {
          return allTimes;
        } else {
          // On start date - use layout restrictions
          return restrictedTimes;
        }
      } else {
        // Not continuous - use layout and end time restrictions
        return restrictedTimes.slice(0, restrictedTimes.indexOf(siteCleared));
      }
    }),
    timesOfDayEnd: computed('allTimes', 'trafficControlIn', 'isContinuous', 'startDate', 'endDate', 'layoutSiteCleared', 'layoutEndDate', function () {
      var allTimes = this.get('allTimes');
      var trafficControlIn = this.get('trafficControlIn');
      var isContinuous = this.get('isContinuous');
      var startDate = moment(this.get('startDate'));
      var endDate = moment(this.get('endDate'));
      var layoutSiteCleared = this.get('layoutSiteCleared');
      var restrictedTimes = layoutSiteCleared ? allTimes.slice(0, allTimes.indexOf(layoutSiteCleared) + 1) : allTimes;
      var layoutEndDate = this.get('layoutEndDate');
      if (isContinuous && endDate.diff(startDate, 'days') > 0) {
        // No restrictions when *within* start and end date
        if (layoutEndDate && moment(layoutEndDate).diff(endDate, 'days') > 0) {
          return allTimes;
        } else {
          // On end date - use layout restrictions
          return restrictedTimes;
        }
      } else {
        // Not continuous - or same day continuous, use layout and start time restrictions
        return restrictedTimes.slice(restrictedTimes.indexOf(trafficControlIn));
      }
    }),
    relativeTimesStart: computed('trafficControlIn', 'allTimes', 'layoutPreparationIn', function () {
      var trafficControlIn = this.get('trafficControlIn');
      var allTimes = this.get('allTimes');
      var layoutPreparationIn = this.get('layoutPreparationIn');
      // If trafficControlIn is not set return no times
      if (isEmpty(trafficControlIn)) {
        return [];
      }
      var startIndex = allTimes.indexOf(layoutPreparationIn) >= 0 ? allTimes.indexOf(layoutPreparationIn) : 0;
      var endIndex = allTimes.indexOf(trafficControlIn);
      // Times must be after layoutPreparationIn and before trafficControlIn
      var allowedVariances = allTimes.slice(startIndex, endIndex);
      // Return up to 12 hours before trafficControlIn
      if (allowedVariances.length > 48) {
        allowedVariances = allowedVariances.slice(-48);
      }
      return allowedVariances;
    }),
    relativeTimesEnd: computed('isContinuous', 'trafficControlIn', 'siteCleared', 'startDate', 'endDate', 'layoutTrafficControlOut', function () {
      var isContinuous = this.get('isContinuous');
      var trafficControlIn = this.get('trafficControlIn');
      var siteCleared = this.get('siteCleared');
      var startDate = moment(this.get('startDate'));
      var layoutTrafficControlOut = get(this, 'layoutTrafficControlOut');
      // Non continuous layouts have the same start and end date
      // e.g. times are PER DAY not covering entire period
      var endDate = isContinuous ? moment(this.get('endDate')) : startDate.clone();
      // If trafficControlIn and siteCleared are not set return no times
      if (isEmpty(trafficControlIn) && isEmpty(siteCleared)) {
        return [];
      }
      // Run backwards in 1/4 hour steps from the end time until we either have
      // 12 hours or we reach the limit (start time)
      // This solves the annoying edge cases of
      // 1 - A continuous layout end time being at the start of a day should
      //   allow relative times in the previous day
      // 2 - A continuous layout with an end time at the start of the day should
      //   not allow relative times to be set before the layout start time
      var endMoment = timeToMoment(endDate, siteCleared);
      // Use the value of tcOut from the layout to retrict deployment
      var layoutTcOutMoment;
      if (!isEmpty(layoutTrafficControlOut)) {
        layoutTcOutMoment = timeToMoment(endDate, layoutTrafficControlOut);
        // Subtract an hour so that this bound is inclusive
        layoutTcOutMoment = layoutTcOutMoment.subtract(1, 'hour');
      }
      var startMoment = timeToMoment(startDate, trafficControlIn);

      // Use the maximum of the deployment traffic control in, and the layout
      // traffic control out as bounds
      if (layoutTcOutMoment) {
        startMoment = moment.max(startMoment, layoutTcOutMoment);
      }
      var allowedVariances = [];
      // If in a Layout the Start Time (time we cannot select a value before) is
      // the value of Traffic Control In
      // If in a deployment the Start Time is the closer time of the Traffic Control
      // In time and the Traffic Control Out that was selected when the layout was
      // created.
      // 30 mins in diff ensures times do not go past the Start Time, even if
      // the Start Time or End Time are not on the same half hour
      while (allowedVariances.length < 48 && endMoment.diff(startMoment, 'minutes') >= 30) {
        endMoment = endMoment.subtract(15, 'minutes');
        allowedVariances.push(endMoment.format('HH:mm'));
      }
      allowedVariances = allowedVariances.reverse();
      return allowedVariances;
    }),
    actions: {
      onTrafficControlIn: function onTrafficControlIn(selection) {
        var _this = this;
        get(this, 'didSelectTrafficControlIn')(selection);
        // Clear invalid preparationIn
        // Needs to be run with .later or relativeTimesStart is not updated
        Ember.run.later(function () {
          var relativeTimesStart = get(_this, 'relativeTimesStart');
          var preparationIn = get(_this, 'preparationIn');
          if (!isEmpty(preparationIn) && relativeTimesStart.indexOf(preparationIn) < 0) {
            get(_this, 'didSelectPreparationIn')();
          }
        });
      },
      onSiteCleared: function onSiteCleared(selection) {
        var _this2 = this;
        get(this, 'didSelectSiteCleared')(selection);
        // Clear invalid trafficControlOut
        // Needs to be run with .later or relativeTimesEnd is not updated
        Ember.run.later(function () {
          var relativeTimesEnd = get(_this2, 'relativeTimesEnd');
          var trafficControlOut = get(_this2, 'trafficControlOut');
          if (!isEmpty(trafficControlOut) && relativeTimesEnd.indexOf(trafficControlOut) < 0) {
            get(_this2, 'didSelectTrafficControlOut')();
          }
        });
      }
    }
  });
  _exports.default = _default;
});