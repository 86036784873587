define("tmp-for-all/adapters/application", ["exports", "ember-data", "tmp-for-all/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.apiHost,
    namespace: _environment.default.apiNamespace,
    session: Ember.inject.service(),
    authorize: function authorize(xhr) {
      var authToken = this.session.data.authenticated.id;
      if (Ember.isPresent(authToken)) {
        xhr.setRequestHeader('Authorization', authToken);
      }
    },
    /**
     * A note for future code archaeologists:
     *
     * We used to override ensureResponseAuthorized() here, because the backend was
     * returning 401s when it really meant 403. That behaviour is fixed in the
     * backend, so the override is now removed. See SUPMWS-399 for details.
     */
    /**
      * This override is necessary in order for an activating user to delete themself by making an authenticated request from a
      * technically unauthenticated route.  Although, they are somewhat authenticated by the verification token passed
      * in the query string.
      */
    ajaxOptions: function ajaxOptions() {
      var _this = this;
      var hash = this._super.apply(this, arguments);
      var beforeSend = hash.beforeSend;
      if (this.tempToken) {
        hash.beforeSend = function (xhr) {
          xhr.setRequestHeader('VerificationToken', _this.tempToken);
          if (beforeSend) beforeSend(xhr);
        };
      }
      return hash;
    },
    /**
     * In place for future use as headersForRequest is not yet used apparently, but will be once
     * ajaxOptions is removed...it is currently deprecated.
     * See node_modules/ember-simple-auth/addon/mixins/data-adapter-mixin.js#headersForRequest for more information.
     * This override is necessary in order for an activating user to delete themself by making an authenticated request from a
     * technically unauthenticated route.  Although, they are somewhat authenticated by the verification token passed
     * in the query string.
     */
    headersForRequest: function headersForRequest() {
      var headers = this._super.apply(this, arguments) || {};
      if (this.tempToken) {
        headers.VerificationToken = this.tempToken;
        this.tempToken = null;
      }
      return headers;
    },
    /**
     * This override is necessary in order for an activating user to delete themself by making an authenticated request from a
     * technically unauthenticated route.  Although, they are somewhat authenticated by the verification token passed
     * in the query string.
     * Called by the store when a record is deleted.
     */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.verificationToken) this.tempToken = snapshot.adapterOptions.verificationToken;
      return this._super.apply(this, arguments);
    },
    /**
     * Extends findRecord to allow the caller to optionally add a prefetch
     * query parameter to the server.
     *
     * The server can use this to prefetch a predefined set of related models.
     */
    findRecord: function findRecord(store, type, id, snapshot) {
      if (get(snapshot, 'adapterOptions.prefetch')) {
        var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
        var query = {
          prefetch: get(snapshot.adapterOptions, 'prefetch')
        };
        return this.ajax(url, 'GET', {
          data: query
        }).catch(function (err) {
          var errors = get(err, 'errors');
          // a standard error thrown on route will have no errors array
          if (!errors) return console.error(err);
          if (errors.length && (errors[0].status === 403 || errors[0].status === 404)) {
            window.location.replace('/not-found');
          } else if (errors.length && errors[0].status === 401) {
            window.location.replace('/login');
          } else {
            console.error(errors);
          }
        });
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
  _exports.default = _default;
});