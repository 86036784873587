define("tmp-for-all/components/loading-overlay/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['loading-overlay'],
    showOverlay: false,
    classNameBindings: ['showOverlay:loading-overlay--show']
  });
  _exports.default = _default;
});