define("tmp-for-all/components/tmp-view/layouts/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eEkOGKzv",
    "block": "{\"symbols\":[\"layout\"],\"statements\":[[4,\"each\",[[23,[\"layouts\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"tmp-view/layouts/list/item\",null,[[\"tmpLayout\",\"tmp\",\"editLayout\",\"changeDeployment\",\"createSiteSpecificWorksite\"],[[22,1,[]],[23,[\"tmp\"]],[27,\"route-action\",[\"editLayout\"],null],[23,[\"changeDeployment\"]],[27,\"route-action\",[\"createSiteSpecificWorksite\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/tmp-view/layouts/list/template.hbs"
    }
  });
  _exports.default = _default;
});