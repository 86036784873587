define("tmp-for-all/components/admin/edit-holidays/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'minHolidayDate', new Date());
    },
    allHolidays: computed('holidays.[]', 'newHolidays.[]', function () {
      return get(this, 'holidays').toArray().concat(get(this, 'newHolidays'));
    }),
    holidaySorting: ['date'],
    orderedHolidays: computed.sort('allHolidays', 'holidaySorting')
  });
  _exports.default = _default;
});