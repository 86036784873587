define("tmp-for-all/components/shared/threaded-history/item/files/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    totalFilesDeleted: computed('history.files', function () {
      if (!get(this, 'history.files') || !Array.isArray(get(this, 'history.files'))) return 0;
      return get(this, 'history.files').filter(function (file) {
        return file.fileRemoved;
      }).length;
    }),
    areAllFilesDeleted: computed('history.files', 'totalFilesDeleted', function () {
      return get(this, 'history.files.length') && get(this, 'history.files.length') === get(this, 'totalFilesDeleted');
    })
  });
  _exports.default = _default;
});