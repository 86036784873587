define("tmp-for-all/routes/worksites/create/wizard/contacts/new-company", ["exports", "tmp-for-all/mixins/routes/modals/new-company-route"], function (_exports, _newCompanyRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend(_newCompanyRoute.NewCompanyModalMixin, {
    actions: {
      save: function save(company) {
        var _this = this;
        company.save().then(function (company) {
          _this.send('selectNewCompany', company);
          get(_this, 'flashMessages').success('A new company has been created.');
          _this.replaceWith(get(_this, 'parentRouteName'));
        }).catch(function () {
          get(_this, 'flashMessages').danger('There was an error saving the new company.');
        });
      }
    }
  });
  _exports.default = _default;
});