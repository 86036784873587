define("tmp-for-all/components/maps/draw-polygon/select-layer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JsCufJQZ",
    "block": "{\"symbols\":[\"clash\",\"clash\",\"layer\"],\"statements\":[[4,\"if\",[[23,[\"isActive\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"geometry\"]]],null,{\"statements\":[[4,\"geojson-layer\",null,[[\"geoJSON\",\"style\",\"clickable\",\"color\",\"stroke\",\"opacity\",\"weight\",\"onClick\"],[[22,2,[\"geometry\"]],[23,[\"clashStyle\"]],true,[23,[\"color\"]],[23,[\"stroke\"]],[23,[\"opacity\"]],[23,[\"weight\"]],[27,\"route-action\",[\"getWorksiteTooltipContext\",[22,2,[]]],null]]],{\"statements\":[[4,\"popup-layer\",null,null,{\"statements\":[[0,\"        \"],[1,[27,\"maps/draw-polygon/select-layer/worksite-popup\",null,[[\"name\",\"worksiteId\",\"startDate\",\"endDate\",\"company\",\"worksite\",\"contacts\",\"workItems\"],[[22,2,[\"properties\",\"name\"]],[22,2,[\"properties\",\"reference\"]],[22,2,[\"properties\",\"startdate\"]],[22,2,[\"properties\",\"enddate\"]],[22,2,[\"properties\",\"companyname\"]],[22,2,[\"worksite\"]],[22,2,[\"contacts\"]],[22,2,[\"workItems\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3]},null]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[23,[\"geometry\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"geojson-layer\",null,[[\"geoJSON\",\"style\",\"clickable\",\"color\",\"stroke\",\"opacity\",\"weight\"],[[22,1,[\"geometry\"]],[23,[\"clashStyle\"]],false,[23,[\"color\"]],[23,[\"stroke\"]],[23,[\"opacity\"]],[23,[\"weight\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/draw-polygon/select-layer/template.hbs"
    }
  });
  _exports.default = _default;
});