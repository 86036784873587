define("tmp-for-all/components/shared/add-custom-conditions/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;
  var _default = Ember.Component.extend({
    classNames: ['custom-conditions'],
    isAddingComment: false,
    newConditionText: '',
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'newConditionText', '');
    },
    filteredConditions: computed('parent.customConditions.[]', 'filterId', function () {
      var filterId = get(this, 'filterId');
      return _emberData.default.PromiseArray.create({
        promise: RSVP.filter(get(this, 'parent.customConditions').toArray(), function (condition) {
          return get(condition, 'jurisdiction').then(function (jurisdiction) {
            return get(jurisdiction, 'id') === filterId;
          });
        })
      });
    }),
    actions: {
      addCondition: function addCondition() {
        set(this, 'isAddingCondition', true);
      },
      exitAddingCondition: function exitAddingCondition() {
        set(this, 'isAddingCondition', false);
        set(this, 'newConditionText', '');
      },
      removeCondition: function removeCondition(parent, condition) {
        get(this, 'removeCustomCondition')(parent, condition);
      },
      saveCondition: function saveCondition(parent, rcaId) {
        var text = get(this, 'newConditionText');
        get(this, 'saveCustomCondition')(parent, rcaId, text);
        set(this, 'isAddingCondition', false);
        set(this, 'newConditionText', '');
      }
    }
  });
  _exports.default = _default;
});