define("tmp-for-all/components/maps/static-map-worksite/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    imageUrl: computed('worksite.id', 'worksite.type', 'width', 'height', function () {
      var worksiteId = get(this, 'worksite.id');
      var worksiteType = get(this, 'worksite.type');
      var width = get(this, 'width');
      var height = get(this, 'height');
      var authToken = get(this, 'session.data.authenticated.id');
      // Append timestamp to force Ember to reload the image
      var timestamp = Date.now();
      if (!worksiteId || !width || !height || !authToken) {
        return '';
      } else {
        return "".concat(_environment.default.apiHost, "/api/").concat(worksiteType, "s/").concat(worksiteId, "/geo-json-image.png?width=").concat(width, "&height=").concat(height, "&access_token=").concat(authToken, "&t=").concat(timestamp);
      }
    })
  });
  _exports.default = _default;
});