define("tmp-for-all/mixins/routes/edit-worksite/documentation-route-mixin", ["exports", "tmp-for-all/mixins/routes/edit-worksite/base-mixin", "tmp-for-all/utils/animations"], function (_exports, _baseMixin, _animations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    RSVP = Ember.RSVP,
    $ = Ember.$;

  /**
   * This mixin extends the base worksite-wizard route mixin, adding stuff for
   * the documentation page.
   *
   * This mixin should only be used in routes where the user edits documentation for a worksite
   */
  var _default = Ember.Mixin.create(_baseMixin.default, {
    relationsAreDirty: computed.alias('worksite.documentationBlocksIsDirty'),
    save: function save() {
      var worksite = get(this, 'worksite');
      return worksite.save().then(function () {
        return worksite.saveDocumentationBlocks();
      });
    },
    saveAndTransition: function saveAndTransition() {
      if (get(this, 'worksite.hasDirtyFilesForUpload')) {
        (0, _animations.jiggleALittleBit)($('.invalid-file'));
        get(this, 'flashMessages').danger('Some of the attached files are not allowed.');
        return;
      }
      return this._super.apply(this, arguments);
    },
    cleanup: function cleanup() {
      var worksite = get(this, 'worksite');
      worksite.destroyOrRollback();
      return worksite.reloadRelations(['documentationBlocks']).then(function () {
        return RSVP.all(get(worksite, 'documentationBlocks').map(function (block) {
          block.resetUploadList();
          block.reloadRelations(['files']);
        }));
      });
    },
    actions: {
      addDocBlock: function addDocBlock(type, location) {
        var newBlock = this.store.createRecord('documentation-block', {
          type: type
        });
        if (location === 'TOP') {
          return get(this, 'worksite.documentationBlocks').unshiftObject(newBlock);
        }
        get(this, 'worksite.documentationBlocks').pushObject(newBlock);
      },
      deleteDocBlock: function deleteDocBlock(block) {
        block.deleteRecord();
      },
      restoreDocBlock: function restoreDocBlock(block) {
        block.rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});