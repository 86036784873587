define("tmp-for-all/utils/raygun-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = catchRaygunErrors;
  function catchRaygunErrors(pageInfo, err) {
    if (!Ember.testing) {
      if (err.payload) {
        err.payload.errors.forEach(function (error) {
          if (error.status !== '401') {
            Raygun.send(pageInfo + ' Failed with Status ' + error.status);
          }
        });
      } else {
        Raygun.send('Backend not responding ' + err.stack);
      }
    }
  }
});