define("tmp-for-all/models/tmp-comment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    createdAt: attr('date'),
    createdBy: attr(),
    text: attr('string'),
    tmp: belongsTo('tmp-base', {
      async: true,
      polymorphic: true,
      inverse: 'tmpComments'
    })
  });
  _exports.default = _default;
});