define("tmp-for-all/components/worksite-view/component", ["exports", "ember-data", "tmp-for-all/mixins/submit-panel-mixin", "tmp-for-all/constants", "tmp-for-all/config/environment", "tmp-for-all/utils/html-comment-helpers", "jquery"], function (_exports, _emberData, _submitPanelMixin, _constants, _environment, _htmlCommentHelpers, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set,
    getProperties = Ember.getProperties,
    RSVP = Ember.RSVP,
    setProperties = Ember.setProperties,
    observer = Ember.observer;
  var _default = Ember.Component.extend(_submitPanelMixin.default, {
    classNames: ['worksite-view'],
    ajax: Ember.inject.service(),
    modal: Ember.inject.service(),
    session: Ember.inject.service(),
    worksiteMeta: computed.alias('worksite.worksiteMeta'),
    declineBoxOpen: false,
    declineIsInFlight: computed.bool('worksite.isDeclining'),
    declinedComment: '',
    reviewBoxOpen: false,
    issueAccessPermitBoxOpen: false,
    isNotAccepted: computed.not('worksite.isAccepted'),
    isAwaitingAcceptance: computed.and('worksite.isSubmittedAndNotDeclined', 'isNotAccepted'),
    statusColour: computed('worksite.status', function () {
      var status = get(this, 'worksite.status').toUpperCase();
      return _constants.WORKSITE_STATUS_COLOURS[status] || _constants.WORKSITE_STATUS_COLOURS.DEFAULT;
    }),
    statusHelpText: computed('worksite.status', function () {
      var mapping = {
        'Published Draft': '- All users can see this draft',
        Draft: '- Only your organisation can see this draft'
      };
      return mapping[get(this, 'worksite.status')] || '';
    }),
    submitOptionsOpen: computed('declineBoxOpen', 'reviewBoxOpen', 'issueAccessPermitBoxOpen', function () {
      return get(this, 'declineBoxOpen') || get(this, 'reviewBoxOpen') || get(this, 'issueAccessPermitBoxOpen') ? 'opened' : '';
    }),
    conditionsSectionOpen: false,
    jurisdiction: computed.alias('worksite.jurisdiction'),
    customConditions: computed('worksite.{customConditions,jurisdiction.id}', function () {
      var filterId = get(this, 'worksite.jurisdiction.id');
      return _emberData.default.PromiseArray.create({
        promise: RSVP.filter(get(this, 'worksite.customConditions').toArray(), function (condition) {
          return get(condition, 'jurisdiction').then(function (jurisdiction) {
            return get(jurisdiction, 'id') === filterId;
          });
        })
      });
    }),
    hasConditions: computed.or('selectedConditions.length', 'customConditions.length'),
    outstandingNotices: computed.filter('notices', function (notice) {
      var _getProperties = getProperties(notice, ['noticeTypeIsResolvable', 'isResolved']),
        noticeTypeIsResolvable = _getProperties.noticeTypeIsResolvable,
        isResolved = _getProperties.isResolved;
      return !isResolved && noticeTypeIsResolvable;
    }),
    allowStopWork: computed('worksiteMeta.status', 'worksite.isDeclined', function () {
      if (get(this, 'worksite.isDeclined')) return false;
      return R.contains(get(this, 'worksiteMeta.status'), [_constants.WORKSITE_META_STATUS_KEYS.STARTED, _constants.WORKSITE_META_STATUS_KEYS.STOPPED, _constants.WORKSITE_META_STATUS_KEYS.NON_CONFORMANCE]);
    }),
    allowNonConformance: computed.alias('allowStopWork'),
    pdfLink: computed('worksite.id', function () {
      var id = get(this, 'worksite.id');
      var token = get(this, 'session.data.authenticated.id');
      return "".concat(_environment.default.apiPath, "/worksites/").concat(id, "/pdf-access-permission?access_token=").concat(token);
    }),
    pdfExtrasLink: computed('worksite.id', function () {
      var id = get(this, 'worksite.id');
      var token = get(this, 'session.data.authenticated.id');
      return "".concat(_environment.default.apiPath, "/worksites/").concat(id, "/pdf-access-permission?includeExtras=true&access_token=").concat(token);
    }),
    acceptFeesTermsConditions: false,
    purchaseOrderFeeAcknowledged: false,
    isSubmitting: computed.alias('worksite.isSubmitting'),
    noAcceptance: computed.not('acceptFeesTermsConditions'),
    disableSubmitBtn: computed.and('noAcceptance', 'isSubmitting'),
    purchaseOrderRequired: computed('worksite', function () {
      var jurisdictionIsCCC = get(this, 'worksite.jurisdiction.name') === _constants.JURISDICTION_NAMES.CCC || get(this, 'worksite.jurisdiction.name') === _constants.JURISDICTION_NAMES.CCCERC;
      var purchaseOrder = get(this, 'worksite.purchaseOrder');
      if (jurisdictionIsCCC && !purchaseOrder) {
        return true;
      } else {
        return false;
      }
    }),
    declineHappened: observer('worksite.hasDeclined', function () {
      setProperties(this, {
        declinedComment: '',
        declineBoxOpen: false,
        reviewBoxOpen: false,
        issueAccessPermitBoxOpen: false
      });
    }),
    warnLegacyData: function warnLegacyData(success) {
      var _this = this;
      this.set('modal.options', {
        title: 'Legacy Data Warning',
        text: 'This worksite extent contains legacy data. In order to make updates, the current extent will be cleared and will need to be redrawn. Do you wish to edit the extent?',
        confirmButton: 'Edit Extent',
        confirmAction: function confirmAction() {
          get(_this, 'modal').toggle();
          success();
        }
      });
      get(this, 'modal').toggle();
    },
    continueSubmit: function continueSubmit(worksite) {
      var _this2 = this;
      if (get(this, 'purchaseOrderRequired') && !get(this, 'purchaseOrderFeeAcknowledged')) {
        get(this, 'togglePurchaseOrderFeeModal')();
        set(worksite, 'isSubmitting', true);
      } else if (get(this, 'acceptFeesTermsConditions')) {
        this.submitWorksite(worksite);
      } else {
        worksite.getFeeEstimate().then(function () {
          get(_this2, 'toggleSubmissionModal')();
          set(worksite, 'isSubmitting', true);
        });
      }
    },
    actions: {
      updateFeesTermsConditions: function updateFeesTermsConditions(value) {
        set(this, 'acceptFeesTermsConditions', value);
      },
      submitWorksite: function submitWorksite(worksite) {
        var _this3 = this;
        if (typeof worksite.canSubmitWorksite === 'function') {
          return worksite.canSubmitWorksite().then(function () {
            return _this3.continueSubmit(worksite);
          }).catch(function (err) {
            return get(_this3, 'flashMessages').danger(err.message, {
              timeout: 5000
            });
          });
        }
        this.continueSubmit(worksite);
      },
      cancelPurchaseOrderModal: function cancelPurchaseOrderModal() {
        get(this, 'togglePurchaseOrderFeeModal')();
        setProperties(this, {
          isSubmitting: false,
          acceptFeesTermsConditions: false
        });
      },
      acceptPurchaseOrderFee: function acceptPurchaseOrderFee(worksite) {
        get(this, 'togglePurchaseOrderFeeModal')();
        set(this, 'purchaseOrderFeeAcknowledged', true);
        this.continueSubmit(worksite);
      },
      cancelSubmission: function cancelSubmission() {
        if (get(this, 'displayPurchaseOrderFeeModal')) {
          get(this, 'togglePurchaseOrderFeeModal')();
        } else {
          get(this, 'toggleSubmissionModal')();
        }
        setProperties(this, {
          isSubmitting: false,
          acceptFeesTermsConditions: false
        });
      },
      saveWorksiteConditions: function saveWorksiteConditions(worksite, selectedConditions, rcaId) {
        get(this, 'saveWorksiteConditions')(worksite, selectedConditions, rcaId);
        // Close conditions section and position top of page at start of conditions section:
        set(this, 'conditionsSectionOpen', false);
        (0, _jquery.default)('body, html').animate({
          scrollTop: this.$().find('#conditions-wrap').offset().top - 50
        }, 0);
      },
      openDeclineBox: function openDeclineBox() {
        set(this, 'reviewBoxOpen', false);
        set(this, 'issueAccessPermitBoxOpen', false);
        set(this, 'declineBoxOpen', true);
        this.send('scrollToSubmitPanel');
      },
      openReviewBox: function openReviewBox() {
        set(this, 'declineBoxOpen', false);
        set(this, 'issueAccessPermitBoxOpen', false);
        set(this, 'reviewBoxOpen', true);
        this.send('scrollToSubmitPanel');
      },
      openIssueAccessPermitBox: function openIssueAccessPermitBox() {
        set(this, 'reviewBoxOpen', false);
        set(this, 'declineBoxOpen', false);
        set(this, 'issueAccessPermitBoxOpen', true);
        this.send('scrollToSubmitPanel');
      },
      closeActionBox: function closeActionBox() {
        set(this, 'declineBoxOpen', false);
        set(this, 'reviewBoxOpen', false);
        set(this, 'issueAccessPermitBoxOpen', false);
        this.send('toggleMainPaddingBottom', false);
      },
      declineWorksite: function declineWorksite(worksite) {
        set(this, 'declinedComment', (0, _htmlCommentHelpers.sanitizeHtmlComment)(get(this, 'declinedComment')));
        this.declineWorksite(worksite, this.declinedComment);
      },
      reviewSubmitted: function reviewSubmitted(historyDescription, comment) {
        var _this4 = this;
        var _getProperties2 = getProperties(this, ['reviewWorksite', 'worksite']),
          reviewWorksite = _getProperties2.reviewWorksite,
          worksite = _getProperties2.worksite;
        reviewWorksite(worksite, historyDescription, comment).then(function () {
          set(_this4, 'reviewBoxOpen', false);
          _this4.send('toggleMainPaddingBottom', false);
        });
      },
      issueAccessPermitSubmitted: function issueAccessPermitSubmitted(rcaId, historyDescription, comment) {
        var _this5 = this;
        comment = (0, _htmlCommentHelpers.sanitizeHtmlComment)(comment);
        var _getProperties3 = getProperties(this, ['issueAccessPermit', 'worksite', 'selectedConditions']),
          issueAccessPermit = _getProperties3.issueAccessPermit,
          worksite = _getProperties3.worksite,
          selectedConditions = _getProperties3.selectedConditions;
        issueAccessPermit(worksite, historyDescription, comment, selectedConditions, rcaId).then(function () {
          set(_this5, 'issueAccessPermitBoxOpen', false);
          _this5.send('toggleMainPaddingBottom', false);
        });
      },
      editExtent: function editExtent(worksite) {
        var _this6 = this;
        var worksiteLocation = get(worksite, 'location');
        if (worksiteLocation && get(worksiteLocation, 'type') !== 'Polygon') {
          this.warnLegacyData(function () {
            get(_this6, 'onEditExtentClicked')(worksite);
          });
        } else {
          get(this, 'onEditExtentClicked')(worksite);
        }
      },
      refreshHistory: function refreshHistory() {
        var history = get(this, 'worksite.history');
        history.reload();
      }
    }
  });
  _exports.default = _default;
});