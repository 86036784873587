define("tmp-for-all/routes/company-admin/edit-users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Route.extend({
    users: computed.alias('model'),
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'session.currentUser');
      }).then(function (user) {
        set(_this, 'currentUser', user);
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'editUsers', {
          modelName: get(user, 'selectedDomain'),
          modelId: get(user, 'selectedDomainId')
        });
      }).catch(function () {
        return _this.router.replaceWith('index');
      });
    },
    model: function model() {
      var user = get(this, 'currentUser');
      return this.store.findRecord(get(user, 'selectedDomain'), get(user, 'selectedDomainId')).then(function (domain) {
        return get(domain, 'tmpUsers');
      });
    }
  });
  _exports.default = _default;
});