define("tmp-for-all/components/maps/detour-control/component", ["exports", "tmp-for-all/constants", "laneify", "tmp-for-all/utils/detour-functions", "tmp-for-all/utils/map-functions"], function (_exports, _constants, _laneify, _detourFunctions, _mapFunctions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    setProperties = Ember.setProperties,
    run = Ember.run;
  var emptyFeatureCollection = {
    type: 'FeatureCollection',
    features: []
  };
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNames: ['map-overlay'],
    isLayersPanelVisible: true,
    isFixedPanelVisible: true,
    showWorksites: true,
    showImpacts: true,
    showClosedImpacts: true,
    showReducedImpacts: true,
    showOpenImpacts: true,
    showDetours: true,
    worksiteLayer: [],
    closedLayoutLayer: [],
    reducedLayoutLayer: [],
    openLayoutLayer: [],
    detourLayer: [],
    focuser: null,
    impacts: computed.readOnly('tmpLayout.impacts'),
    focus: computed('worksite.location', {
      get: function get(_key) {
        var focuser = _get(this, 'focuser');
        if (focuser) return focuser;
        return _get(this, 'worksite.location.coordinates');
      },
      set: function set(_key, value) {
        this.set('focuser', value);
        return value;
      }
    }),
    defaultMapSettings: computed('intl.locale', function () {
      return _constants.MAP_SETTINGS.DEFAULT[_get(this, 'intl.locale')[0].toUpperCase()];
    }),
    zoom: computed('focus', 'defaultMapSettings', function () {
      return _get(this, 'focus') ? 18 : _get(this, 'defaultMapSettings').ZOOM;
    }),
    // Don't need to set the center manually when adding the detour layer, as the map handles this automatically
    center: computed('focus', 'defaultMapSettings', function () {
      var focus = _get(this, 'focus');
      var centerofpolygon = [];
      if (focus && !(typeof focus === 'undefined')) {
        centerofpolygon = _get(this, 'focus').lat ? focus : (0, _mapFunctions.getCenterofPolygon)(focus);
      }
      return centerofpolygon.length > 0 ? [centerofpolygon[0].lat, centerofpolygon[0].lng] : [_get(this, 'defaultMapSettings').CENTER.LAT, _get(this, 'defaultMapSettings').CENTER.LNG];
    }),
    firstDetour: computed.alias('tmpLayout.firstDetour'),
    secondDetour: computed.alias('tmpLayout.secondDetour'),
    loadingClashData: false,
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var address = _get(this, 'worksite.address');
      if (!_get(this, 'worksite.location.coordinates')) {
        _get(this, 'ajax').getWorksiteAddressCoordinates(address).then(function (coordinates) {
          set(_this, 'focus', coordinates);
        });
      }
      if (!_get(this, 'worksite.location') || _get(this, 'worksite.applicationType') === 'GLOBALPERMIT') set(this, 'zoom', 9);

      // If a layout start or end date is not set yet, we cannot query for nearby worksites/impacts/detours
      var canRetrieveWorksitesInVicinity = _get(this, 'tmpLayout.startDate') && _get(this, 'tmpLayout.endDate');
      setProperties(this, {
        impactStart: null,
        impactEnd: null,
        lrmHelpExpanded: true,
        worksLayerEnabled: false,
        showVicinityWarning: !canRetrieveWorksitesInVicinity,
        moreThanOneDetourCheck: _get(this, 'secondDetour') && _get(this, 'secondDetour.distance'),
        detourSameAsAboveCheck: false,
        detourSameAsAboveNoCheck: _get(this, 'secondDetour') && _get(this, 'secondDetour.distance'),
        selectedTileComponentName: 'mapbox-streets-tile-layer',
        worksiteClashes: emptyFeatureCollection
      });
      (0, _detourFunctions.hideLayersPanelForMobile)(this, 'isLayersPanelVisible');
    },
    loadFirstDetourControl: function loadFirstDetourControl() {
      var map = _get(this, 'leafletMap');
      var startEnd = _get(this, 'impactStartEnd');
      var control = (0, _detourFunctions.generateRoutingControl)(_get(this, 'readOnly'), map, 'firstDetour', this.routesFoundCallback.bind(this), startEnd, _get(this, 'firstDetour'));
      set(this, 'firstLeafletDetourControl', control);
    },
    removeDetourControl: function removeDetourControl(controlName) {
      _get(this, controlName).remove();
      set(this, controlName, null);
    },
    removeDetour: function removeDetour(detour) {
      if (detour) {
        detour.deleteRecord();
        if (_get(detour, 'ordinal') === 1) {
          _get(this, 'firstLeafletDetourControl').remove();
          if (_get(this, 'secondDetour') && !_get(this, 'secondDetour.isDeleted')) {
            set(this, 'secondDetour.ordinal', 1); // is now the firstDetour
            this.removeDetourControl('firstLeafletDetourControl');
            this.removeDetourControl('secondLeafletDetourControl');
            this.loadFirstDetourControl();
          }
        } else if (_get(this, 'secondLeafletDetourControl')) {
          this.removeDetourControl('secondLeafletDetourControl');
        }
        set(this, 'moreThanOneDetourCheck', false);
      }
    },
    ensureDetour: function ensureDetour(ordinal) {
      var currentDetour = _get(this, 'detours').find(function (d) {
        return _get(d, 'ordinal') === ordinal && !_get(d, 'isDeleted');
      });
      if (!currentDetour) {
        var newDetour = _get(this, 'createNewDetour')(this.tmpLayout, ordinal);
        _get(this, 'detours').pushObject(newDetour);
      }
    },
    // Takes the "routesfound" event information given by OSRM via leaflet routing machine and sets the route (location coordinates),
    //  waypoints, text version of the route steps, total distance for the detour, and total estimated time for the detour.
    routesFoundCallback: function routesFoundCallback(e, detourModel) {
      set(this, detourModel + '.location', (0, _detourFunctions.geoJsonFromCoordinates)(e.routes[0].coordinates));
      set(this, detourModel + '.waypoints', e.routes[0].waypoints.map(function (w) {
        return w.latLng;
      }));
      set(this, detourModel + '.steps', (0, _detourFunctions.removeWaypointArrivals)(e.routes[0].instructions));
      set(this, detourModel + '.distance', Math.round(e.routes[0].summary.totalDistance));
      set(this, detourModel + '.time', Math.round(e.routes[0].summary.totalTime));
    },
    setClashStyle: function setClashStyle(clash) {
      return Object.assign((0, _detourFunctions.clashOptions)(clash.type, clash.impacttype), clash);
    },
    filterFeatures: function filterFeatures(layerName, features, impactType) {
      var filterName = layerName.replace(/Layer/, '');
      return impactType ? features.filter(function (f) {
        return f.impacttype === impactType;
      }) : features.filter(function (f) {
        return f.type === filterName;
      });
    },
    addWorkLayer: function addWorkLayer(layerName, impactType) {
      this.removeWorksLayer(layerName);
      var layerFeatures = this.filterFeatures(layerName, _get(this, 'worksFeatures'), impactType);
      set(this, layerName, layerFeatures);
      if (layerName === _constants.WORKS_LAYERS.DETOURS) this.addDetourMarkers(_get(this, 'worksFeatures'));
    },
    updateVicinityObjects: function updateVicinityObjects() {
      var _this2 = this;
      // If we're showing the vicinity warning (meaning layout start/end dates have not been set) or the checkbox is not checked don't do anything
      if (_get(this, 'showVicinityWarning') || !_get(this, 'worksLayerEnabled')) return;
      if (_get(this, 'detourMarkerLayer')) _get(this, 'leafletMap').addLayer(_get(this, 'detourMarkerLayer'));
      var map = _get(this, 'leafletMap');
      var boundingBox = (0, _mapFunctions.getBoundingBox)(map);
      set(this, 'mapBounds', boundingBox);
      var worksiteId = _get(this, 'worksite.id');
      var layoutId = _get(this, 'tmpLayout.id');
      set(this, 'loadingClashData', true);
      _get(this, 'updateClashes')(boundingBox).then(function (data) {
        data = data.filter(function (c) {
          return c.modelid !== worksiteId && c.modeltype === 'worksite' || c.modelid !== layoutId && c.modeltype === 'layout' || c.modeltype === 'detour';
        }).map(_this2.setClashStyle);
        if (!_get(_this2, 'isDestroying') && !_get(_this2, 'isDestroyed')) {
          set(_this2, 'worksFeatures', data);
          if (_get(_this2, 'showWorksites')) _this2.addWorkLayer(_constants.WORKS_LAYERS.WORKSITES);
          if (_get(_this2, 'showImpacts') && _get(_this2, 'showClosedImpacts')) _this2.addWorkLayer(_constants.WORKS_LAYERS.CLOSED_IMPACTS, 'closed');
          if (_get(_this2, 'showImpacts') && _get(_this2, 'showReducedImpacts')) _this2.addWorkLayer(_constants.WORKS_LAYERS.REDUCED_IMPACTS, 'restricted');
          if (_get(_this2, 'showImpacts') && _get(_this2, 'showOpenImpacts')) _this2.addWorkLayer(_constants.WORKS_LAYERS.OPEN_IMPACTS, 'open');
          if (_get(_this2, 'showDetours')) _this2.addWorkLayer(_constants.WORKS_LAYERS.DETOURS);
        }
      }).finally(function () {
        if (!_get(_this2, 'isDestroying') && !_get(_this2, 'isDestroyed')) set(_this2, 'loadingClashData', false);
      });
    },
    addDetourMarkers: function addDetourMarkers(data) {
      var detourMarkers = (0, _detourFunctions.generateDetourMarkers)(data);
      var detourMarkerLayer = L.geoJson(detourMarkers, (0, _detourFunctions.detourMarkerOptions)());
      set(this, _constants.WORKS_LAYERS.DETOUR_MARKERS, detourMarkerLayer);
      _get(this, 'leafletMap').addLayer(detourMarkerLayer);
    },
    removeWorksLayer: function removeWorksLayer(layerName) {
      if (layerName === _constants.WORKS_LAYERS.DETOURS && _get(this, _constants.WORKS_LAYERS.DETOUR_MARKERS)) _get(this, 'leafletMap').removeLayer(_get(this, _constants.WORKS_LAYERS.DETOUR_MARKERS));
      set(this, layerName, []);
    },
    addSecondDetour: function addSecondDetour() {
      var detour2 = _get(this, 'secondDetour');
      if (detour2 && _get(detour2, 'distance')) {
        var startEnd = _get(this, 'impactStartEnd');
        var control2 = (0, _detourFunctions.generateRoutingControl)(_get(this, 'readOnly'), _get(this, 'leafletMap'), 'secondDetour', this.routesFoundCallback.bind(this), startEnd, detour2);
        set(this, 'secondLeafletDetourControl', control2);
      }
    },
    focusFirstDetour: function focusFirstDetour() {
      if (_get(this, 'firstLeafletDetourControl')._line) {
        var bounds = _get(this, 'firstLeafletDetourControl')._line.getBounds();
        _get(this, 'leafletMap').fitBounds(bounds);
      }
    },
    toggleWorks: function toggleWorks(checked, property, workLayerName, impactType) {
      this.toggleProperty(property);
      if (checked) {
        this.addWorkLayer(workLayerName, impactType);
      } else {
        this.removeWorksLayer(workLayerName);
      }
    },
    actions: {
      mapMoved: function mapMoved(e) {
        var zoom = e.target.getZoom();
        set(this, 'zoom', zoom);

        // If we're zoomed in far enough and we are outside of the current map bounds
        if (zoom >= _constants.MAP_SETTINGS.ZOOM_LEVEL_CUTOVER && _get(this, 'mapBounds') && !(0, _mapFunctions.isWithinCurrentBounds)(_get(this, 'mapBounds'), _get(this, 'leafletMap'))) {
          run.debounce(this, this.updateVicinityObjects, 500);
        }
      },
      setTileComponentName: function setTileComponentName(tileComponentName) {
        set(this, 'selectedTileComponentName', tileComponentName);
      },
      mapLoaded: function mapLoaded(e) {
        var _this3 = this;
        var map = e.target;
        set(this, 'leafletMap', map);
        var attribution = L.control.attribution({
          position: 'bottomleft'
        });
        map.addControl(attribution);

        // Only one of the impacts will have the first detour covering itself
        _get(this, 'impacts').forEach(function (impact) {
          var road = impact.features[0];
          var lanes = _laneify.default.split(road, {
            laneOffset: (0, _detourFunctions.getLaneOffset)(road)
          });
          var layer = L.geoJson(lanes, {
            style: (0, _detourFunctions.getStyleFor)(impact.properties.impact)
          });
          set(_this3, 'impactStart', L.latLng(lanes.features[0].geometry.coordinates[0].slice().reverse()));
          set(_this3, 'impactEnd', L.latLng(lanes.features[0].geometry.coordinates[lanes.features[0].geometry.coordinates.length - 1].slice().reverse()));
          map.addLayer(layer);
        });
        var startEnd = [_get(this, 'impactStart'), _get(this, 'impactEnd')];
        set(this, 'impactStartEnd', startEnd);
        this.ensureDetour(1);
        this.loadFirstDetourControl();
        this.addSecondDetour();
        this.updateVicinityObjects();
        run.debounce(this, this.focusFirstDetour, 500);
      },
      moreThanOneDetourChange: function moreThanOneDetourChange(moreThanOne) {
        if (!moreThanOne) {
          this.removeDetour(_get(this, 'secondDetour'));
          _get(this, 'leafletMap').off('click');
        } else {
          this.ensureDetour(2);
        }
        set(this, 'detourSameAsAboveCheck', false);
        set(this, 'detourSameAsAboveNoCheck', false);
      },
      detourSameAsAboveChange: function detourSameAsAboveChange(same) {
        if (!_get(this, 'secondLeafletDetourControl')) {
          var map = _get(this, 'leafletMap');
          var lc2 = null;
          if (same) {
            var lc1 = _get(this, 'firstLeafletDetourControl');
            lc2 = (0, _detourFunctions.generateRoutingControl)(_get(this, 'readOnly'), map, 'secondDetour', this.routesFoundCallback.bind(this), []);
            var waypoints = lc1.getWaypoints();
            lc2.setWaypoints(waypoints.reverse());
          } else {
            var startEnd = _get(this, 'impactStartEnd');
            lc2 = (0, _detourFunctions.generateRoutingControl)(_get(this, 'readOnly'), map, 'secondDetour', this.routesFoundCallback.bind(this), startEnd.reverse());
          }
          set(this, 'secondLeafletDetourControl', lc2);
        }
      },
      done: function done() {
        _get(this, 'saveDetours')();
      },
      reset: function reset() {
        var map = _get(this, 'leafletMap');
        if (_get(this, 'firstLeafletDetourControl')) {
          map.removeControl(_get(this, 'firstLeafletDetourControl'));
        }
        if (_get(this, 'secondLeafletDetourControl')) {
          map.removeControl(_get(this, 'secondLeafletDetourControl'));
        }
        set(this, 'moreThanOneDetourCheck', false);
        set(this, 'detourSameAsAboveCheck', false);
        set(this, 'detourSameAsAboveNoCheck', false);
        _get(this, 'tmpLayout.detours').forEach(function (d) {
          if (d) d.rollbackAttributes();
        });
        _get(this, 'leafletMap').off('click');
        _get(this, 'onCancel')();
      },
      determineSelectedItems: function determineSelectedItems(e) {
        var _this4 = this;
        var currentZoom = _get(this, 'leafletMap').getZoom();
        set(this, 'detailModels', false);
        set(this, 'loadingModels', true);
        _get(this, 'getDetail')([e.latlng.lat, e.latlng.lng], currentZoom).then(function (models) {
          set(_this4, 'detailModels', models);
        });
      },
      deleteDetour: function deleteDetour(detour) {
        this.removeDetour(detour);
      },
      addFirstDetour: function addFirstDetour() {
        this.ensureDetour(1);
        this.loadFirstDetourControl();
      },
      worksInVicinityCheckChanged: function worksInVicinityCheckChanged(checked) {
        set(this, 'worksLayerEnabled', checked);
        if (checked) this.updateVicinityObjects();else {
          this.removeWorksLayer(_constants.WORKS_LAYERS.WORKSITES);
          this.removeWorksLayer(_constants.WORKS_LAYERS.CLOSED_IMPACTS);
          this.removeWorksLayer(_constants.WORKS_LAYERS.REDUCED_IMPACTS);
          this.removeWorksLayer(_constants.WORKS_LAYERS.OPEN_IMPACTS);
          this.removeWorksLayer(_constants.WORKS_LAYERS.DETOURS);
        }
      },
      toggleImpacts: function toggleImpacts(checked) {
        this.toggleProperty('showImpacts');
        if (checked) {
          if (_get(this, 'showClosedImpacts')) this.addWorkLayer(_constants.WORKS_LAYERS.CLOSED_IMPACTS, 'closed');
          if (_get(this, 'showReducedImpacts')) this.addWorkLayer(_constants.WORKS_LAYERS.REDUCED_IMPACTS, 'restricted');
          if (_get(this, 'showOpenImpacts')) this.addWorkLayer(_constants.WORKS_LAYERS.OPEN_IMPACTS, 'open');
        } else {
          this.removeWorksLayer(_constants.WORKS_LAYERS.CLOSED_IMPACTS);
          this.removeWorksLayer(_constants.WORKS_LAYERS.REDUCED_IMPACTS);
          this.removeWorksLayer(_constants.WORKS_LAYERS.OPEN_IMPACTS);
        }
      },
      toggleWorksites: function toggleWorksites(checked) {
        this.toggleWorks(checked, 'showWorksites', _constants.WORKS_LAYERS.WORKSITES);
      },
      toggleClosedImpacts: function toggleClosedImpacts(checked) {
        this.toggleWorks(checked, 'showClosedImpacts', _constants.WORKS_LAYERS.CLOSED_IMPACTS, 'closed');
      },
      toggleReducedImpacts: function toggleReducedImpacts(checked) {
        this.toggleWorks(checked, 'showReducedImpacts', _constants.WORKS_LAYERS.REDUCED_IMPACTS, 'restricted');
      },
      toggleOpenImpacts: function toggleOpenImpacts(checked) {
        this.toggleWorks(checked, 'showOpenImpacts', _constants.WORKS_LAYERS.OPEN_IMPACTS, 'open');
      },
      toggleDetours: function toggleDetours(checked) {
        this.toggleWorks(checked, 'showDetours', _constants.WORKS_LAYERS.DETOURS);
      }
    }
  });
  _exports.default = _default;
});