define("tmp-for-all/routes/worksite-submission/edit/contacts", ["exports", "tmp-for-all/mixins/routes/edit-worksite/contacts-route-mixin"], function (_exports, _contactsRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_contactsRouteMixin.default, {
    nextStep: 'worksite-submission',
    finishedTarget: 'worksite-submission'
  });
  _exports.default = _default;
});