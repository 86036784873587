define("tmp-for-all/routes/companies/list", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/constants"], function (_exports, _routeMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service(),
    currentUser: null,
    currentJurisdictionId: null,
    isSuperUser: false,
    queryParams: {
      page: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      showArchivedResults: {
        refreshModel: true
      },
      relatedPartiesFilter: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (user) {
        set(_this, 'currentUser', user);
        set(_this, 'currentJurisdictionId', user.selectedDomain === 'jurisdiction' && user.selectedDomainId);
        set(_this, 'isSuperUser', user.isSuperUser);
      });
    },
    model: function model(params) {
      var jurisdictionId = get(this, 'currentJurisdictionId');
      var criteria = [];
      if (params.search) {
        criteria.push({
          name: {
            ilike: "%".concat(params.search, "%")
          }
        });
      }
      if (params.showArchivedResults) {
        // If showArchived is selected then we will show all results
        // whether archived or not
      } else {
        // If it is not selected then we want to query it isArchived=false
        criteria.push({
          isArchived: false
        });
      }
      if (!!jurisdictionId && params.relatedPartiesFilter && params.relatedPartiesFilter === _constants.RELATED_PARTIES_ONLY) {
        criteria.push({
          jurisdictionId: jurisdictionId
        });
        criteria.push({
          isRelatedParty: true
        });
      } else if (!!jurisdictionId && params.relatedPartiesFilter && params.relatedPartiesFilter === _constants.UNRELATED_PARTIES_ONLY) {
        criteria.push({
          jurisdictionId: jurisdictionId
        });
        criteria.push({
          isRelatedParty: false
        });
      }
      if (criteria.length > 1) {
        params.filter = {
          where: {
            and: criteria
          }
        };
      } else if (criteria.length === 1) {
        params.filter = {
          where: criteria[0]
        };
      } else {
        params.filter = {};
      }
      params.filter = JSON.stringify(params.filter);
      var results = this.findPaged('company-search-result', params);
      return results;
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});