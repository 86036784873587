define("tmp-for-all/models/worksite-history", ["exports", "ember-data", "tmp-for-all/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    worksiteId: attr('number'),
    worksiteNumber: attr('number'),
    majorRevisionNumber: attr('number'),
    minorRevisionNumber: attr('number'),
    eventType: attr('string'),
    description: attr('string'),
    comment: attr('string'),
    createdAt: attr('date'),
    public: attr('boolean'),
    files: attr(),
    organisationName: attr('string'),
    diff: attr(),
    // User's organisation who logged the notice or comment that became the history item
    jurisdiction: belongsTo('jurisdiction', {
      readOnly: true
    }),
    company: belongsTo('company', {
      readOnly: true
    }),
    number: computed.alias('worksiteNumber'),
    revisionNumber: computed('majorRevisionNumber', 'minorRevisionNumber', function () {
      var major = get(this, 'majorRevisionNumber') || '0';
      var minor = get(this, 'minorRevisionNumber') || '0';
      return "".concat(major, ".").concat(minor);
    }),
    IS_DECLINED: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.DECLINED),
    IS_ACCEPTED: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.ACCEPTED),
    IS_SUBMITTED: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.SUBMITTED),
    IS_COMMENTED: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.COMMENTED),
    IS_AUDITORCOMMENTED: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.AUDITORCOMMENTED),
    IS_UPDATED: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.UPDATED),
    IS_REVIEWED: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.REVIEWED),
    IS_START_WORK: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.START_WORK),
    IS_COMPLETE_WORK: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.COMPLETE_WORK),
    IS_AUTOMATED_COMPLETE_WORK: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.AUTOMATED_COMPLETE_WORK),
    IS_WARRANTY_COMPLETE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.WARRANTY_COMPLETE),
    IS_MODIFY_WARRANTY_DATE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.MODIFY_WARRANTY_DATE),
    IS_UPLOAD: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.UPLOAD),
    IS_NON_CONFORMANCE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.NON_CONFORMANCE),
    IS_RESOLVE_NON_CONFORMANCE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.RESOLVE_NON_CONFORMANCE),
    IS_ACCEPT_COMPLETE_WORK_NOTICE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.ACCEPT_COMPLETE_WORK_NOTICE),
    IS_REJECT_COMPLETE_WORK_NOTICE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.REJECT_COMPLETE_WORK_NOTICE),
    IS_ISSUE_COMPLETE_MAINTENANCE_NOTICE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.ISSUE_COMPLETE_MAINTENANCE_NOTICE),
    IS_STOP_WORK: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.STOP_WORK),
    IS_RESOLVE_STOP_WORK_NOTICE: computed.equal('eventType', _constants.HISTORY_EVENT_TYPES.RESOLVE_STOP_WORK_NOTICE),
    isNoticeCategory: computed('eventType', function () {
      return R.contains(get(this, 'eventType'), [_constants.HISTORY_EVENT_TYPES.COMPLETE_WORK, _constants.HISTORY_EVENT_TYPES.AUTOMATED_COMPLETE_WORK, _constants.HISTORY_EVENT_TYPES.ACCEPT_COMPLETE_WORK_NOTICE, _constants.HISTORY_EVENT_TYPES.REJECT_COMPLETE_WORK_NOTICE, _constants.HISTORY_EVENT_TYPES.ISSUE_START_WORK_INFRINGEMENT_NOTICE, _constants.HISTORY_EVENT_TYPES.ISSUE_COMPLETE_WORK_INFRINGEMENT_NOTICE, _constants.HISTORY_EVENT_TYPES.ACCEPT_START_WORK_INFRINGEMENT_NOTICE, _constants.HISTORY_EVENT_TYPES.ACCEPT_COMPLETE_WORK_INFRINGEMENT_NOTICE, _constants.HISTORY_EVENT_TYPES.ACCEPT_COMPLETE_MAINTENANCE_NOTICE, _constants.HISTORY_EVENT_TYPES.REJECT_COMPLETE_MAINTENANCE_NOTICE, _constants.HISTORY_EVENT_TYPES.ISSUE_COMPLETE_MAINTENANCE_NOTICE, _constants.HISTORY_EVENT_TYPES.ISSUE_AUTOMATIC_COMPLETE_MAINTENANCE_NOTICE, _constants.HISTORY_EVENT_TYPES.MODIFY_WARRANTY_DATE, _constants.HISTORY_EVENT_TYPES.NON_CONFORMANCE, _constants.HISTORY_EVENT_TYPES.RESOLVE_NON_CONFORMANCE, _constants.HISTORY_EVENT_TYPES.STOP_WORK, _constants.HISTORY_EVENT_TYPES.RESOLVE_STOP_WORK_NOTICE, _constants.HISTORY_EVENT_TYPES.START_WORK_NOTICE_OVERDUE, _constants.HISTORY_EVENT_TYPES.COMPLETE_WORK_DATE_UPCOMING, _constants.HISTORY_EVENT_TYPES.COMPLETE_WORK_NOTICE_OVERDUE]);
    }),
    isCommentCategory: computed('eventType', function () {
      return R.contains(get(this, 'eventType'), [_constants.HISTORY_EVENT_TYPES.COMMENTED]);
    }),
    isAuditorCommentCategory: computed('eventType', function () {
      return R.contains(get(this, 'eventType'), [_constants.HISTORY_EVENT_TYPES.AUDITORCOMMENTED]);
    }),
    isFileCategory: computed('eventType', function () {
      return R.contains(get(this, 'eventType'), [_constants.HISTORY_EVENT_TYPES.UPLOAD]);
    }),
    isRevision: computed('eventType', function () {
      return R.contains(get(this, 'eventType'), [_constants.HISTORY_EVENT_TYPES.SUBMITTED]);
    }),
    category: computed('isNoticeCategory', 'isCommentCategory', 'isFileCategory', function () {
      if (get(this, 'isNoticeCategory')) return _constants.HISTORY_ITEM_CATEGORIES.NOTICE;
      if (get(this, 'isCommentCategory')) return _constants.HISTORY_ITEM_CATEGORIES.COMMENT;
      if (get(this, 'isFileCategory')) return _constants.HISTORY_ITEM_CATEGORIES.FILE;
      if (get(this, 'isAuditorCommentCategory')) return _constants.HISTORY_ITEM_CATEGORIES.AUDITORCOMMENT;
      if (get(this, 'isRevision')) return _constants.HISTORY_ITEM_CATEGORIES.REVISION;
      return _constants.HISTORY_ITEM_CATEGORIES.EVENT;
    })
  });
  _exports.default = _default;
});