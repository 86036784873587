define("tmp-for-all/components/nzta-detour/part/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    detourId: Ember.computed('this.draft', 'this.published', function () {
      var d = this.published || this.draft;
      return "".concat(d.detourNumber, "-").concat(d.detourPart);
    }),
    hasPublished: Ember.computed.bool('published'),
    actions: {
      editPart: function editPart() {
        if (!this.draft) {
          this.set('draft', this.createDraft(this.published));
        }
        this.editSection(this.draft);
      }
    }
  });
  _exports.default = _default;
});