define("tmp-for-all/services/route-navigator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var isInstance = function isInstance(subject) {
    return Ember.typeOf(subject) === 'instance';
  };
  var isRoute = function isRoute(subject) {
    /* eslint-disable no-prototype-builtins */
    return isInstance(subject) && subject.hasOwnProperty('routeName');
  };
  var isModel = function isModel(subject) {
    return isInstance(subject) && subject.constructor.isModel;
  };
  var isObject = function isObject(subject) {
    return Ember.typeOf(subject) === 'object';
  };
  var assert = function assert(condition, msg) {
    if (!condition) throw new Error(msg);
  };
  var wayPoint;
  var _default = Ember.Service.extend({
    setPreviousRoute: function setPreviousRoute(route) {
      set(this, 'history', route);
    },
    getLastRoute: function getLastRoute() {
      return get(this, 'history');
    },
    setWayPoint: function setWayPoint(route, context) {
      assert(isRoute(route), 'First parameter of setWayPoint() should be an instance of Ember.Route');
      assert(!context || isModel(context) || isObject(context), 'Second parameter of setWayPoint() should be either an object or instance of Ember.Model');
      wayPoint = {
        routeName: get(route, 'routeName'),
        applyQueryParams: false
      };
      if (context) {
        wayPoint.applyQueryParams = get(context, 'applyQueryParams') || false;
        wayPoint.context = get(context, 'id');
      } else {
        wayPoint.context = null;
      }
      return wayPoint;
    },
    getWayPoint: function getWayPoint() {
      return wayPoint;
    },
    returnToWayPoint: function returnToWayPoint(route) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      assert(isRoute(route), 'First parameter of returnToWayPoint() should be an instance of Ember.Route');
      assert(isObject(options), 'Second parameter of returnToWayPoint() should be an object');
      assert(options.defaultRouteName, 'Please specify a defaultRouteName to transitionTo if no waypoint has been set.');
      var transitionArgs = [];
      if (!wayPoint) {
        transitionArgs.push(options.defaultRouteName);
        if (options.defaultContext) transitionArgs.push(options.defaultContext);
        if (options.defaultQueryParams) transitionArgs.push(options.defaultQueryParams);
      } else {
        transitionArgs.push(wayPoint.routeName);
        if (wayPoint.context) transitionArgs.push(wayPoint.context);
        if (wayPoint.applyQueryParams && options.queryParams) {
          transitionArgs.push({
            queryParams: options.queryParams
          });
        }
      }
      return route.transitionTo.apply(route, transitionArgs);
    }
  });
  _exports.default = _default;
});