define("tmp-for-all/controllers/reports/tmp-processing-time", ["exports", "tmp-for-all/mixins/controllers/processing-time-report"], function (_exports, _processingTimeReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // The TMP processing time controller is an exact duplicate of the Worksite Processing time controller, so just extend
  // to create a new instance
  var _default = Ember.Controller.extend(_processingTimeReport.default);
  _exports.default = _default;
});