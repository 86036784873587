define("tmp-for-all/models/jurisdiction-area", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    name: attr('string'),
    area: attr('string'),
    createdAt: attr('string'),
    updatedAt: attr('string'),
    jurisdiction: belongsTo('jurisdiction'),
    worksites: hasMany('worksite'),
    worksiteSubmissions: hasMany('worksite-submission'),
    worksiteDrafts: hasMany('worksite-draft')
  });
  _exports.default = _default;
});