define("tmp-for-all/-ember-preferences-internal", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    return {
      namespace: _environment.default.modulePrefix
    };
  }
});