define("tmp-for-all/components/shared/form/currency-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set,
    getWithDefault = Ember.getWithDefault;
  var _default = Ember.Component.extend({
    classNames: ['currency-field'],
    inputID: computed('elementId', function () {
      return "input-".concat(get(this, 'elementId'));
    }),
    value: null,
    formattedValue: computed('value', function () {
      return (get(this, 'value') / 100).toFixed(2);
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    stepWithDefault: computed('step', function () {
      return getWithDefault(this, 'step', 0.01);
    }),
    actions: {
      updateValue: function updateValue(newValue) {
        set(this, 'value', newValue * 100);
      }
    }
  });
  _exports.default = _default;
});