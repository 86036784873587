define("tmp-for-all/components/worksite/documentation-form/documentation-selector/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    docsHelp: _constants.TOOLTIP_BLOCK_MESSAGES.WORKSITE_DOCUMENTATION,
    docsHelpIsExpanded: false,
    actions: {
      toggleDocsHelp: function toggleDocsHelp() {
        this.toggleProperty('docsHelpIsExpanded');
      }
    }
  });
  _exports.default = _default;
});