define("tmp-for-all/components/worksite/modify-warranty/warranty-end-date-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Dn9XR2tM",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"shared/form/radio-button\",null,[[\"name\",\"value\",\"label\",\"checked\",\"class\",\"onChange\"],[\"dateselect\",\"twoyears\",\"Default 2 years\",true,\"button-style-radio\",[27,\"action\",[[22,0,[]],\"selectTwoYears\"],null]]]],false],[0,\"\\n\"],[1,[27,\"shared/form/radio-button\",null,[[\"name\",\"value\",\"label\",\"class\",\"onChange\"],[\"dateselect\",\"custom\",\"Specific date:\",\"button-style-radio\",[27,\"action\",[[22,0,[]],\"selectCustom\"],null]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"element-wrap inline-date-picker\"],[9],[0,\"\\n  \"],[1,[27,\"pikaday-input\",null,[[\"value\",\"disabled\",\"minDate\",\"onSelection\"],[[23,[\"date\"]],[23,[\"disabledDateSelector\"]],[23,[\"minDate\"]],[27,\"action\",[[22,0,[]],\"userPickDate\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[27,\"shared/form/radio-button\",null,[[\"name\",\"value\",\"label\",\"class\",\"onChange\"],[\"dateselect\",\"nowarranty\",\"No Warranty Needed\",\"button-style-radio\",[27,\"action\",[[22,0,[]],\"selectNoWarranty\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite/modify-warranty/warranty-end-date-selector/template.hbs"
    }
  });
  _exports.default = _default;
});