define("tmp-for-all/models/custom-condition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    text: attr('string'),
    jurisdiction: belongsTo('jurisdiction'),
    worksite: belongsTo('worksite'),
    worksiteSubmission: belongsTo('worksite-submission'),
    tmp: belongsTo('tmp'),
    tmpSubmission: belongsTo('tmp-submission')
  });
  _exports.default = _default;
});