define("tmp-for-all/templates/worksite/work/modify-warranty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0uUZpQ6q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\"],[true]],{\"statements\":[[0,\"  \"],[1,[27,\"worksite/modify-warranty\",null,[[\"worksite\",\"warrantyEndDate\",\"workCompletionDate\",\"selectDate\",\"save\",\"cancel\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"warrantyEndDate\"]],[23,[\"model\",\"workCompletionDate\"]],[27,\"route-action\",[\"selectDate\"],null],[27,\"route-action\",[\"save\"],null],[27,\"route-action\",[\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite/work/modify-warranty.hbs"
    }
  });
  _exports.default = _default;
});