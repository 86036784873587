define("tmp-for-all/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _default = _emberData.default.Model.extend({
    name: attr('string'),
    reference: attr('string'),
    location: attr('string'),
    summary: attr('string'),
    company: belongsTo('company', {
      async: true
    }),
    jurisdiction: belongsTo('jurisdiction', {
      async: true
    }),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    worksites: hasMany('worksite', {
      async: true
    }),
    worksiteSubmissions: hasMany('worksite-submission', {
      async: true
    }),
    worksiteDrafts: hasMany('worksite-draft', {
      async: true
    })
  });
  _exports.default = _default;
});