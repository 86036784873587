define("tmp-for-all/mixins/routes/edit-doc-block-permission-check", ["exports", "tmp-for-all/helpers/permission/edit-documentation"], function (_exports, _editDocumentation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Mixin.create({
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      var worksite = this.modelFor(get(this, 'parentRoute'));
      var permission = (0, _editDocumentation.editDocumentationCheck)([get(worksite, 'type')]);
      return get(this, 'authorization').checkAllowableNavigation(this, permission, worksite).catch(function () {
        return _this.replaceWith('index');
      });
    }
  });
  _exports.default = _default;
});