define("tmp-for-all/components/home-page/component", ["exports", "tmp-for-all/constants", "tmp-for-all/config/environment"], function (_exports, _constants, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    applyPrefix: _environment.default.mwsApplyPrefix,
    searchPrefix: _environment.default.mwsSearchPrefix,
    authManager: Ember.inject.service('session'),
    currentUser: computed.alias('authManager.currentUser'),
    hasRole: computed('currentUser.roles.@each', function () {
      var roles = get(this, 'currentUser.roles');
      // Check that roles exists and that pendingPermission is not the only role
      return roles && !(roles.includes(_constants.PENDING_PERMISSION.name) && roles.length === 1);
    }),
    companyId: computed.alias('currentUser.selectedDomainId'),
    supportEmail: _environment.default.supportEmail,
    isSuperUser: computed.alias('authManager.currentUser.isSuperUser'),
    authToken: computed.alias('authManager.data.authenticated.id'),
    userIsJurisdictionDomain: computed.equal('currentUser.selectedDomain', 'jurisdiction'),
    userIsCompanyDomain: computed.equal('currentUser.selectedDomain', 'company'),
    isCompanyAdmin: computed('currentUser.roles.@each', function () {
      var roles = get(this, 'currentUser.roles');
      // Check that roles exists and that pendingPermission is not the only role
      return roles && !(roles.includes(_constants.COMPANY_ADMIN_PERMISSION.name) && roles.length === 1);
    }),
    canAccessSotb: undefined,
    canAccessApplyBeta: undefined,
    newQueue: undefined,
    adminUsers: [],
    pendingUsers: [],
    linkToMwsApply: computed('currentUser', function () {
      return "".concat(get(this, 'applyPrefix'), "/create/").concat(get(this, 'authToken'));
    }),
    linkToMwsSob: computed('currentUser', function () {
      return "".concat(get(this, 'applyPrefix'), "/skip-on-berm/").concat(get(this, 'authToken'));
    }),
    linkToMwsEvent: computed('currentUser', function () {
      return "".concat(get(this, 'applyPrefix'), "/event/").concat(get(this, 'authToken'));
    }),
    linkToNewWorksitesQueue: computed('currentUser', 'currentUser.selectedDomain', function () {
      return "".concat(get(this, 'searchPrefix'), "/worksites-queue/").concat(get(this, 'authToken'));
    }),
    linkToNewTmpsQueue: computed('currentUser', 'currentUser.selectedDomain', function () {
      return "".concat(get(this, 'searchPrefix'), "/tmps-queue/").concat(get(this, 'authToken'));
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var domainType = get(this, 'currentUser.selectedDomain');
      var isCompanyAdmin = get(this, 'isCompanyAdmin');
      var promises = [get(this, 'currentUser.navToSkipOnTheBermEnabled'), get(this, 'currentUser.navToNewApplyWorkflowEnabled'), get(this, 'currentUser.newQueueEnabled')];
      Promise.all(promises).then(function (responses) {
        var _responses = _slicedToArray(responses, 3),
          skip = _responses[0],
          beta = _responses[1],
          newQueue = _responses[2];
        set(_this, 'canAccessSotb', !!skip);
        set(_this, 'canAccessApplyBeta', !!beta);
        set(_this, 'newQueue', !!newQueue);
        if (domainType === 'company' && isCompanyAdmin) {
          _this.ajax.getPendingUsers(get(_this, 'currentUser.selectedDomainId')).then(function (pendingUsers) {
            return set(_this, 'pendingUsers', pendingUsers);
          });
        }

        // If the user has no role yet, get their admins
        if (!get(_this, 'hasRole')) {
          _this.ajax.getAdminUsers({
            id: get(_this, 'currentUser.selectedDomainId')
          }).then(function (adminUsers) {
            return set(_this, 'adminUsers', adminUsers);
          });
        }
      });
    }
  });
  _exports.default = _default;
});