define("tmp-for-all/components/tmp-view/conditions-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hrb266Fy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,[\"isOpen\"]],[23,[\"userCanEdit\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/tmp-view/conditions-wrapper/template.hbs"
    }
  });
  _exports.default = _default;
});