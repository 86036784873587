define("tmp-for-all/components/maps/detour-control/works-layers/works-layer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/40UbgY4",
    "block": "{\"symbols\":[\"poly\"],\"statements\":[[4,\"each\",[[23,[\"works\"]]],null,{\"statements\":[[4,\"geojson-layer\",null,[[\"geoJSON\",\"color\",\"stroke\",\"opacity\",\"weight\",\"fill\",\"fillOpacity\",\"onClick\"],[[22,1,[\"geometry\"]],[22,1,[\"color\"]],[22,1,[\"stroke\"]],[22,1,[\"opacity\"]],[22,1,[\"weight\"]],[22,1,[\"fill\"]],[22,1,[\"fillOpacity\"]],[27,\"action\",[[22,0,[]],[23,[\"determineSelectedItems\"]]],null]]],{\"statements\":[[4,\"leaflet-overrides/popup-layer\",null,null,{\"statements\":[[0,\"      \"],[1,[27,\"planning-map/detail-popup\",null,[[\"detailModels\",\"type\"],[[23,[\"detailModels\"]],[22,1,[\"type\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/detour-control/works-layers/works-layer/template.hbs"
    }
  });
  _exports.default = _default;
});