define("tmp-for-all/utils/deployment-filters", ["exports", "tmp-for-all/utils/search-filter", "tmp-for-all/constants"], function (_exports, _searchFilter, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchFilters = void 0;
  var makeDiscreteList = _searchFilter.default.makeDiscreteList,
    makeOptionsFilter = _searchFilter.default.makeOptionsFilter;
  var options = Object.keys(_constants.DEPLOYMENT_STATUSES).map(function (key) {
    return {
      name: _constants.DEPLOYMENT_STATUSES[key],
      value: key
    };
  });
  var searchFilters = [makeOptionsFilter('Deployment', 'Status', options, 'status'), makeDiscreteList('RCA', 'Primary RCA', 'jurisdiction', 'jurisdictionId'), makeDiscreteList('RCA', 'Secondary RCA', 'jurisdiction', 'firstSecondaryRcaId'), makeDiscreteList('Organisation', 'Applicant company', 'company', 'applicantId'), makeDiscreteList('STMS', 'On-site STMS', 'tmpUser', 'stmsUserId')];
  _exports.searchFilters = searchFilters;
});