define("tmp-for-all/mixins/controllers/list-pagination-controller-mixin", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Mixin.create({
    queryParams: ['page', 'perPage', 'jurisdictionAreaId'],
    jurisdictionAreaId: null,
    perPage: _environment.default.itemsPerPage,
    page: 1,
    actions: {
      pageClicked: function pageClicked(page) {
        this.replaceRoute({
          page: page
        });
        // set(this, 'page', page)
      },
      setJurisdictionArea: function setJurisdictionArea(jurisdictionArea) {
        set(this, 'jurisdictionAreaId', get(jurisdictionArea, 'id'));
      }
    }
  });
  _exports.default = _default;
});