define("tmp-for-all/components/condition-groups-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    showArchived: computed(function () {
      return false;
    }),
    filteredGroups: computed('showArchived', 'groups', function () {
      var groups = this.get('groups');
      return this.get('showArchived') ? groups : R.reject(function (g) {
        return g.get('isArchived');
      }, groups);
    }),
    createConditionGroupLink: computed('conditionType', function () {
      return "condition-groups.".concat(get(this, 'conditionType'), ".create");
    }),
    color: computed('conditionType', function () {
      var type = get(this, 'conditionType');
      switch (type) {
        case 'tmp':
          return 'orange';
        case 'worksite':
          return 'blue';
        default:
          return 'green';
      }
    }),
    actions: {
      toggleArchiveFilter: function toggleArchiveFilter(val) {
        this.set('showArchived', val);
      }
    }
  });
  _exports.default = _default;
});