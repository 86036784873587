define("tmp-for-all/helpers/time-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.timeRange = timeRange;
  function timeRange(params) {
    return "From: ".concat(params[0], " To: ").concat(params[1]);
  }
  var _default = Ember.Helper.helper(timeRange);
  _exports.default = _default;
});