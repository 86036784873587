define("tmp-for-all/components/worksite-view/outstanding-notices/notice-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7iDe8l2x",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"no-wrap\"],[9],[7,\"strong\"],[9],[1,[27,\"notice-type\",[[23,[\"notice\",\"noticeType\"]]],null],false],[10],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"no-wrap\"],[9],[1,[27,\"format-date\",[[23,[\"notice\",\"statedIssueDate\"]]],null],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"no-wrap\"],[9],[1,[27,\"format-date\",[[23,[\"notice\",\"resolveDeadline\"]]],null],false],[10],[0,\"\\n\"],[7,\"td\"],[9],[1,[23,[\"notice\",\"issueComment\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"links-column\"],[9],[0,\"\\n\"],[4,\"if-permitted\",[\"resolveNotice\"],[[\"model\"],[[23,[\"notice\"]]]],{\"statements\":[[4,\"if\",[[23,[\"notice\",\"isRejectable\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[11,\"class\",\"tertiary-action tertiary-action--listed-right\"],[9],[1,[21,\"rejectButtonText\"],false],[3,\"action\",[[22,0,[]],[23,[\"rejectNotice\"]],[23,[\"notice\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\"],[11,\"class\",\"tertiary-action\"],[9],[1,[21,\"clearButtonText\"],false],[3,\"action\",[[22,0,[]],[23,[\"clearNotice\"]],[23,[\"notice\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/outstanding-notices/notice-row/template.hbs"
    }
  });
  _exports.default = _default;
});