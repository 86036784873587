define("tmp-for-all/components/shared/transfer-worksite-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    transferCompany: null,
    transferUser: null,
    transferMessage: '',
    transferIncludeTmps: true,
    transferHaveContactedApplicant: false,
    disableTransferButton: computed('transferHaveContactedApplicant', 'transferCompany', 'transferUser', function () {
      return !get(this, 'transferHaveContactedApplicant') || !get(this, 'transferCompany') || !get(this, 'transferUser');
    }),
    actions: {
      toggleTransferIncludeTmps: function toggleTransferIncludeTmps() {
        this.toggleProperty('transferIncludeTmps');
      },
      toggleTransferHaveContactedApplicant: function toggleTransferHaveContactedApplicant() {
        this.toggleProperty('transferHaveContactedApplicant');
      },
      selectTransferCompany: function selectTransferCompany(company) {
        set(this, 'transferCompany', company);
      },
      selectTransferUser: function selectTransferUser(user) {
        set(this, 'transferUser', user);
      },
      closeModal: function closeModal() {
        get(this, 'toggleTransferModal')();
      },
      saveTransfer: function saveTransfer() {
        var _this = this;
        var model = get(this, 'worksite.type');
        var id = get(this, 'worksite.id');
        var url = "/".concat(model, "s/").concat(id, "/applicant-transfer");
        var reqBody = {
          message: get(this, 'transferMessage'),
          tmpUserId: get(this, 'transferUser.id'),
          companyId: get(this, 'transferCompany.id'),
          syncTmps: get(this, 'transferIncludeTmps')
        };
        return get(this, 'ajax').authSafePost(url, {
          data: reqBody
        }).catch(function () {
          return get(_this, 'flashMessages').error('An error occurred when transferring this worksite');
        }).then(function () {
          get(_this, 'flashMessages').success('This worksite has now been transferred');
          _this.get('router').transitionTo('/');
        });
      }
    }
  });
  _exports.default = _default;
});