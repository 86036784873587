define("tmp-for-all/routes/reports/processing-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Only tmp-processing-time specific functionality should reside here
  var _default = Ember.Route.extend({
    redirect: function redirect() {
      this.transitionTo('reports.worksite-processing-time');
    }
  });
  _exports.default = _default;
});