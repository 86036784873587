define("tmp-for-all/utils/action-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getActionDescription;
  function getActionDescription(action) {
    // returns a set of user friendly strings to describe actions performed in the User Interface
    return {
      edit: ['edited', 'editing'],
      create: ['created', 'creating'],
      delete: ['deleted', 'deleting'],
      archived: ['archived', 'archiving'],
      unarchived: ['un-archived', 'un-archiving']
    }[action];
  }
});