define("tmp-for-all/components/maps/mapbox-streets-tile-layer/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mapboxUrl = _environment.default.mapboxStreetsUrl.replace('{mapboxBaseUrl}', _environment.default.mapboxBaseUrl).replace('{mapboxToken}', _environment.default.mapboxToken);
  var _default = Ember.Component.extend({
    url: mapboxUrl,
    attribution: _environment.default.mapboxAttribution
  });
  _exports.default = _default;
});