define("tmp-for-all/utils/nzta-detour-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.extraTimeOptions = void 0;
  _exports.getExtraTimeLabelForNumber = getExtraTimeLabelForNumber;
  _exports.stateHighwayNumbers = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var extraTimeOptionsMapper = [{
    label: '0-10 mins',
    range: [-Infinity, 10]
  }, {
    label: '10-20 mins',
    range: [10, 20]
  }, {
    label: '20-40 mins',
    range: [20, 40]
  }, {
    label: '40-60 mins',
    range: [40, 60]
  }, {
    label: '60-90 mins',
    range: [60, 90]
  }, {
    label: '90-150 mins',
    range: [90, 150]
  }, {
    label: '>150 mins',
    range: [150, Infinity]
  }];
  var extraTimeOptions = extraTimeOptionsMapper.map(function (v) {
    return v.label;
  });

  // given an length of time as a number, return the appropriate time option
  _exports.extraTimeOptions = extraTimeOptions;
  function getExtraTimeLabelForNumber(timeNumber) {
    return extraTimeOptionsMapper.find(function (_ref) {
      var _ref$range = _slicedToArray(_ref.range, 2),
        lower = _ref$range[0],
        upper = _ref$range[1];
      return lower <= timeNumber && timeNumber <= upper;
    }).label;
  }
  var stateHighwayNumbers = [null, '002', '003', '004', '005', '006', '007', '008', '010', '011', '012', '014', '015', '016', '018', '01B', '01H', '01J', '01K', '01N', '01S', '01T', '020', '021', '022', '023', '024', '025', '026', '027', '028', '029', '030', '031', '032', '033', '034', '035', '036', '037', '038', '039', '03A', '041', '043', '044', '045', '046', '047', '048', '049', '050', '051', '053', '054', '056', '057', '058', '060', '062', '063', '065', '067', '069', '06A', '071', '073', '074', '075', '076', '077', '078', '079', '07A', '080', '082', '083', '084', '085', '086', '087', '088', '08A', '08B', '090', '093', '094', '095', '096', '097', '098', '099', '20A', '20B', '25A', '29A', '30A', '51A', '67A', '74A', '74M'].map(function (sh) {
    return {
      name: sh || 'None',
      value: sh
    };
  });
  _exports.stateHighwayNumbers = stateHighwayNumbers;
});