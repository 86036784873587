define("tmp-for-all/routes/worksites/create/wizard/extent", ["exports", "tmp-for-all/mixins/routes/edit-worksite/extent-route-mixin"], function (_exports, _extentRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Route.extend(_extentRouteMixin.default, {
    ajax: Ember.inject.service(),
    nextStep: 'worksites.create.wizard.work-items',
    finishedTarget: 'worksite-draft',
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      if (!get(model, 'location') && get(model, 'address')) {
        var address = get(model, 'address');
        return get(this, 'ajax').getWorksiteAddressCoordinates(address).then(function (coordinates) {
          set(model, 'mapFocus', coordinates);
        });
      }
    }
  });
  _exports.default = _default;
});