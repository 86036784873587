define("tmp-for-all/routes/tmp-submission/layouts/create/detour", ["exports", "tmp-for-all/mixins/routes/layout-detour-route-mixin", "tmp-for-all/constants"], function (_exports, _layoutDetourRouteMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_layoutDetourRouteMixin.default, {
    templateName: 'tmp-submission/layouts/edit/detour',
    returnRoute: 'tmp-submission.layouts.create.details',
    siteSpecificRoutePermission: 'editSubmittedLayout',
    tmpType: _constants.TMP_TYPES.SUBMISSION
  });
  _exports.default = _default;
});