define("tmp-for-all/components/shared/add-new-company/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      if (get(this, 'intl.locale')[0] !== _constants.LOCALES.EN_NZ) set(this, 'registerChoice', 'NOT_NZBN');
    },
    registerChoices: computed('intl.locale', function () {
      var choices = [{
        name: 'private individual',
        value: 'PRIVATE_INDIVIDUAL',
        label: 'Private individual',
        checked: false
      }, {
        name: 'other thing..',
        value: 'NOT_NZBN',
        label: 'Other organisation e.g. trust, overseas company, government entity',
        checked: get(this, 'intl.locale')[0] !== _constants.LOCALES.EN_NZ
      }];
      if (get(this, 'intl.locale')[0] === _constants.LOCALES.EN_NZ) {
        choices.unshift({
          name: 'registered company',
          value: 'NZBN',
          label: 'NZBN Registered Company',
          checked: true
        });
      }
      return choices;
    }),
    actions: {
      registeredCompanySelected: function registeredCompanySelected(company) {
        set(this, 'company.nzbn', company.nzbn);
        set(this, 'company.name', company.name);
        set(this, 'company.physicalAddress', company.physicalAddress);
      },
      updateCompanyType: function updateCompanyType(type) {
        set(this, 'registerChoice', type);
        set(this, 'company.isPrivateIndividual', type === 'PRIVATE_INDIVIDUAL');
      },
      setCompanyContact: function setCompanyContact(name) {
        // If adding a private individual, set the company contact to be
        // the person's name.
        var privateIndividual = get(this, 'company.isPrivateIndividual');
        if (privateIndividual) {
          set(this, 'company.contact', name);
        }
      }
    }
  });
  _exports.default = _default;
});