define("tmp-for-all/components/form-field/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "p3feB2Xz",
    "block": "{\"symbols\":[\"option\",\"option\"],\"statements\":[[4,\"if\",[[23,[\"search\"]]],null,{\"statements\":[[4,\"power-select\",null,[[\"search\",\"selected\",\"disabled\",\"placeholder\",\"onchange\"],[[27,\"action\",[[22,0,[]],[23,[\"search\"]]],null],[23,[\"value\"]],[23,[\"disabled\"]],[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],[23,[\"didChange\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[22,2,[\"name\"]],[22,2,[\"name\"]],[22,2,[]]],null],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"power-select\",null,[[\"selected\",\"searchEnabled\",\"disabled\",\"placeholder\",\"onchange\",\"options\"],[[23,[\"value\"]],false,[23,[\"disabled\"]],[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],[23,[\"didChange\"]]],null],[23,[\"options\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"if\",[[22,1,[\"name\"]],[22,1,[\"name\"]],[22,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/form-field/select/template.hbs"
    }
  });
  _exports.default = _default;
});