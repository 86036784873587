define("tmp-for-all/routes/activate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP;

  // TODO is this unused? Can it be removed?
  var _default = Ember.UnprotectedRoute.extend({
    ajax: Ember.inject.service(),
    model: function model(params) {
      var _this = this;
      var currentCompany = get(this, 'currentCompany');
      return get(this, 'ajax').userAction(null, 'retrieve-from-token', params.token).then(function (user) {
        return RSVP.hash({
          user: user,
          assignedCompanies: get(_this, 'ajax').getCompanies(user.id),
          assignedJurisdictions: get(_this, 'ajax').getJurisdictions(user.id),
          currentCompany: currentCompany,
          token: params.token,
          disabled: false
        });
      });
    },
    afterModel: function afterModel(model) {
      model.hasAssignedCompany = model.assignedCompanies.length > 0;
      model.hasAssignedJurisdiction = model.assignedJurisdictions.length > 0;
    },
    actions: {
      selectNewCompany: function selectNewCompany(company) {
        set(this, 'currentCompany', company);
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});