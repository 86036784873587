define("tmp-for-all/templates/projects/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qe/Edi0f",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"isFullWidth\",\"isListingPage\"],[true,true]],{\"statements\":[[0,\"\\n  \"],[4,\"listing-page-heading\",null,null,{\"statements\":[[0,\"Search Projects\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"search-parameters\",null,[[\"disableFilters\",\"freetext\",\"searchType\",\"setSearchText\"],[true,[23,[\"searchText\"]],\"projects\",[27,\"action\",[[22,0,[]],\"searchUpdated\"],null]]]],false],[0,\"\\n\\n  \"],[1,[27,\"search-results\",null,[[\"handlePaginationClick\",\"results\",\"searchType\"],[[27,\"action\",[[22,0,[]],\"pageClicked\"],null],[23,[\"model\"]],\"projects\"]]],false],[0,\"\\n\\n\"],[4,\"shared/fixed-actions-bar\",null,null,{\"statements\":[[4,\"link-to\",[\"projects.create\"],[[\"class\"],[\"primary-action\"]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"plus\"]]],false],[0,\" Add project\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/projects/list.hbs"
    }
  });
  _exports.default = _default;
});