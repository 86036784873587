define("tmp-for-all/models/tmp-draft", ["exports", "ember-data", "tmp-for-all/models/tmp-base", "tmp-for-all/constants", "tmp-for-all/utils/animations", "tmp-for-all/utils/html-comment-helpers"], function (_exports, _emberData, _tmpBase, _constants, _animations, _htmlCommentHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    $ = Ember.$;
  var attr = _emberData.default.attr;
  var _default = _tmpBase.default.extend({
    ajax: Ember.inject.service(),
    type: _constants.TMP_TYPES.DRAFT,
    submitComment: attr('string', {
      defaultValue: ''
    }),
    submitValidation: function submitValidation() {
      if (!get(this, 'filesForUpload').validate()) {
        return 'Some of the supporting files are not allowed.';
      }
      if (!get(this, 'layouts.length') && !get(this, 'isGenericTmp')) {
        return 'TMP must have at least 1 Layout for submission.';
      }
      if (get(this, 'isGenericTmp') && !get(this, 'filesForUpload.length') && !get(this, 'files.length')) {
        return 'You must attach file(s) with your specified methodology.';
      }
      return this.validatePrivateFiles();
    },
    submit: function submit() {
      var _this = this;
      if (get(this, 'isNotInitialRevision') && (!get(this, 'submitComment') || !(0, _htmlCommentHelpers.hasHtmlComment)(get(this, 'submitComment')))) {
        (0, _animations.jiggleALittleBit)($('.t-comment-textarea'));
        return Ember.RSVP.reject(new Error('Comment cannot be blank.'));
      }
      var error = this.submitValidation();
      if (error) return Ember.RSVP.reject(error);
      set(this, 'submitComment', (0, _htmlCommentHelpers.sanitizeHtmlComment)(get(this, 'submitComment')));

      // Upload the files first.  If they don't succeed then we don't want to continue processing the submission
      // Also, we upload on the tmp-draft, which won't exist after submission

      var saveFilesOrNoop = get(this, 'isGenericTmp') ? this.saveFiles() : Ember.RSVP.resolve();
      return saveFilesOrNoop.catch(function () {
        return Ember.RSVP.reject(new Error('Failed to upload files'));
      }).then(function () {
        // Indent, because we don't want the the failed files to trigger the catch in this chain
        return _this.get('ajax').tmpAction(_this, 'submit').then(function () {
          return _this.store.peekRecord('tmp-submission', _this.id);
        }).then(function (submission) {
          return Ember.RSVP.Promise.all([get(submission, 'acceptanceConditions').reload(), get(submission, 'history').reload(), get(submission, 'layouts').reload(), get(submission, 'files').reload()]);
        }).then(function () {
          return get(_this, 'latestWorksite.tmpSubmissions').reload();
        }).then(function () {
          return _this.unloadRecord();
        }).catch(function () {
          return Ember.RSVP.reject(new Error('TMP could not be submitted, please try again'));
        });
      }).finally(function () {
        _this.set('isSubmitting', false);
      });
    }
  });
  _exports.default = _default;
});