define("tmp-for-all/components/shared/object-diff/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['t-fee-line-item'],
    /**
     * In some contexts we reach this code after the keys of the diff content
     * object have been capitalised, in some cases before. So we need to look
     * for both 'revisionNumber' and 'RevisionNumber' when using that field
     * to get the revisions for headers. Ick!
     */
    revisions: computed('diffContent', function () {
      var dc = get(this, 'diffContent');
      var revisions = [];
      if (dc) {
        var _dc$revisionNumber;
        revisions = (_dc$revisionNumber = dc.revisionNumber) !== null && _dc$revisionNumber !== void 0 ? _dc$revisionNumber : dc.RevisionNumber;
      }
      return revisions;
    }),
    /**
     * The revision number gets removed from the diff content so it does not
     * appear on its own row. Looking for both names as above.
     */
    displayDiffs: computed('diffContent', function () {
      var dc = get(this, 'diffContent');
      if (dc) {
        delete dc.RevisionNumber;
        delete dc.revisionNumber;
      }
      return dc;
    })
  });
  _exports.default = _default;
});