define("tmp-for-all/components/shared/show-hide-box/component", ["exports", "tmp-for-all/mixins/components/show-hide-mixin"], function (_exports, _showHideMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend(_showHideMixin.default, {
    classNames: ['show-hide-box'],
    classNameBindings: ['showHideElementIsVisible:is-visible'],
    color: 'green',
    // default colour if none passed in
    buttonText: computed('title', 'btnText', function () {
      return get(this, 'btnText') || get(this, 'title');
    })
  });
  _exports.default = _default;
});