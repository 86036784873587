define("tmp-for-all/components/shared/simple-expand-contract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mTUuSNSI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"expandable-content\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[4,\"unless\",[[23,[\"disableExpand\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[\"simple-expand-contract-trigger unstyle-btn text-\",[21,\"linkColor\"]]]],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"Show \"],[1,[21,\"expandTriggerText\"],false],[0,\" …\"],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"toggleHeight\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/simple-expand-contract/template.hbs"
    }
  });
  _exports.default = _default;
});