define("tmp-for-all/routes/worksites/drafts", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/constants"], function (_exports, _routeMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend(_routeMixin.default, {
    authManager: Ember.inject.service('session'),
    authorization: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: {
      order: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'authManager.currentUser');
      }).then(function (user) {
        var domainType = get(user, 'selectedDomain');
        var domainId = get(user, 'selectedDomainId');
        var isSuperUser = get(user, 'isSuperUser');
        var canCreateDraftWorksiteForDomain = domainType && domainId && _this.authorization.resolvePermission('createDraftWorksite', domainType, [domainId]);
        if (isSuperUser || canCreateDraftWorksiteForDomain) {
          return true;
        }
        _this.replaceWith('index');
      }).catch(function () {
        return _this.router.replaceWith('index');
      });
    },
    model: function model(params) {
      var _this2 = this;
      var order = params.order ? params.order : 'createdAt DESC';
      delete params.order;
      return get(this, 'authManager.currentUser').then(function (user) {
        return {
          order: order,
          where: {
            type: _constants.WORKSITE_MODEL_TYPES.DRAFT,
            applicantId: get(user, 'selectedDomainId')
          }
        };
      }).then(function (whereQuery) {
        return _this2.findPaged('worksite-search-result', R.merge(params, {
          filter: whereQuery
        }));
      }).catch(function (err) {
        Raygun.send(new Error(err));
        _this2.replaceWith('index');
      });
    }
  });
  _exports.default = _default;
});