define("tmp-for-all/models/worksite-fee", ["exports", "ember-data", "tmp-for-all/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    computed = _Ember.computed,
    get = _Ember.get;
  var _default = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),
    itemCode: attr('string'),
    costPerUnit: attr('number'),
    unit: attr('string'),
    name: attr('string'),
    description: attr('string'),
    accountingCode: attr('string'),
    quantity: attr('number'),
    markedAsEstimatedAt: attr('date'),
    markedAsToBeCheckedAt: attr('date'),
    markedAsReadyToInvoiceAt: attr('date'),
    markedAsInvoicedAt: attr('date'),
    markedAsApprovedAt: attr('date'),
    isInvoicePriorToApplicationApproval: attr('boolean'),
    isAuto: attr('boolean', {
      defaultValue: false
    }),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    fee: belongsTo('fee', {
      async: true
    }),
    cost: belongsTo('cost', {
      async: true
    }),
    createdBy: belongsTo('tmp-user', {
      async: true
    }),
    worksite: belongsTo('worksite-base', {
      async: true,
      polymorphic: true,
      inverse: 'worksiteFees'
    }),
    jurisdiction: belongsTo('jurisdiction', {
      async: true
    }),
    currentStatus: null,
    displayStatus: computed.or('currentStatus', 'initialStatus'),
    initialStatus: computed('markedAsToBeCheckedAt', 'markedAsReadyToInvoiceAt', 'markedAsInvoicedAt', function () {
      if (get(this, 'markedAsInvoicedAt')) {
        return _constants.WORKSITE_FEE_STATUSES.INVOICED;
      }
      if (get(this, 'markedAsReadyToInvoiceAt')) {
        return _constants.WORKSITE_FEE_STATUSES.READY_TO_INVOICE;
      }
      if (get(this, 'markedAsToBeCheckedAt')) {
        return _constants.WORKSITE_FEE_STATUSES.TO_BE_CHECKED;
      }
      return _constants.WORKSITE_FEE_STATUSES.ESTIMATED;
    }),
    price: computed('quantity', 'costPerUnit', function () {
      var quantity = get(this, 'quantity');
      var costPerUnit = get(this, 'costPerUnit');
      if (!quantity || !costPerUnit) return 0;
      if (typeof costPerUnit === 'string') costPerUnit = costPerUnit.replace('.', '');
      return quantity * costPerUnit;
    }),
    changeStatus: function changeStatus(status) {
      var statuses = Object.keys(_constants.WORKSITE_FEE_STATUSES).map(function (key) {
        return _constants.WORKSITE_FEE_STATUSES[key];
      });
      if (statuses.indexOf(status) === -1) {
        var msg = "Expected 'status' to be one of ".concat(statuses.join(', '), ". Instead got ").concat(status);
        return Promise.reject(new Error(msg));
      }
      var endpoint;
      switch (status) {
        case _constants.WORKSITE_FEE_STATUSES.ESTIMATED:
          endpoint = _constants.WORKSITE_FEE_ENDPOINTS.ESTIMATED;
          break;
        case _constants.WORKSITE_FEE_STATUSES.TO_BE_CHECKED:
          endpoint = _constants.WORKSITE_FEE_ENDPOINTS.TO_BE_CHECKED;
          break;
        case _constants.WORKSITE_FEE_STATUSES.READY_TO_INVOICE:
          endpoint = _constants.WORKSITE_FEE_ENDPOINTS.READY_TO_INVOICE;
          break;
        case _constants.WORKSITE_FEE_STATUSES.INVOICED:
          endpoint = _constants.WORKSITE_FEE_ENDPOINTS.INVOICED;
          break;
      }
      return get(this, 'ajax').changeWorksiteFeeStatus(this.id, endpoint);
    }
  });
  _exports.default = _default;
});