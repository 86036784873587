define("tmp-for-all/components/shared/customer-number-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    isRcaUser: computed.equal('currentUser.selectedDomain', 'jurisdiction'),
    rcaId: computed('currentUser.selectedDomainId', function () {
      return get(this, 'isRcaUser') ? String(get(this, 'currentUser.selectedDomainId')) : null;
    }),
    isSuperUser: computed('currentUser.selectedDomain', 'currentUser.selectedDomainId', function () {
      var currentUser = get(this, 'authManager.currentUser');
      if (!currentUser) return;
      return !get(currentUser, 'selectedDomain') && !get(currentUser, 'selectedDomainId');
    }),
    company: computed('customerNumber', function () {
      return get(this, 'customerNumber.company');
    }),
    jurisdiction: computed('customerNumber', function () {
      return get(this, 'customerNumber.jurisdiction');
    }),
    companyName: computed('company', function () {
      var n = get(this, 'company.name');
      return n;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});