define("tmp-for-all/components/shared/form/radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jJ37VEaS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"name\",[21,\"name\"]],[12,\"value\",[21,\"value\"]],[12,\"checked\",[21,\"isChecked\"]],[12,\"disabled\",[21,\"isDisabled\"]],[12,\"id\",[21,\"inputID\"]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n\"],[7,\"label\"],[12,\"for\",[28,[[21,\"inputID\"]]]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[1,[27,\"shared/error-messages/inline\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/form/radio-button/template.hbs"
    }
  });
  _exports.default = _default;
});