define("tmp-for-all/templates/activate/activate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TDVbcfXx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Activate account\"]],{\"statements\":[[0,\"  \"],[1,[27,\"user-form\",null,[[\"user\",\"currentCompany\",\"hasAssignedCompany\",\"hasAssignedJurisdiction\",\"assignedCompanies\",\"assignedJurisdictions\",\"saveUser\",\"disabled\",\"isSuperUser\",\"activatingUser\",\"selectNewCompany\",\"searchCompanies\",\"addNewCompany\",\"deleteUser\"],[[23,[\"model\",\"user\"]],[23,[\"model\",\"currentCompany\"]],[23,[\"model\",\"hasAssignedCompany\"]],[23,[\"model\",\"hasAssignedJurisdiction\"]],[23,[\"model\",\"assignedCompanies\"]],[23,[\"model\",\"assignedJurisdictions\"]],[27,\"route-action\",[\"activateUser\"],null],[23,[\"model\",\"disabled\"]],false,true,[27,\"route-action\",[\"selectNewCompany\"],null],[27,\"route-action\",[\"searchCompanies\"],null],[27,\"route-action\",[\"addNewCompany\"],null],[27,\"route-action\",[\"deleteUser\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/activate/activate.hbs"
    }
  });
  _exports.default = _default;
});