define("tmp-for-all/templates/activate-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pKO0SkmP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Activation Failed\"]],{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"home-notification\"],[9],[0,\"Unable to find a pending user matching this token. Please retry the link in your invitation email or contact your company admin if the problem persists.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/activate-error.hbs"
    }
  });
  _exports.default = _default;
});