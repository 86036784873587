define("tmp-for-all/components/worksite-view/tmps/tmp/revision/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var PromiseObject = _emberData.default.PromiseObject;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['standard-row'],
    classNameBindings: ['tmp.isSelected:expanded'],
    computedStatusPromise: computed('tmp.secondaryJurisdictions', function () {
      return PromiseObject.create({
        promise: get(this, 'tmp.secondaryJurisdictions').then(function (secondaries) {
          if (get(secondaries, 'firstObject.acceptedAt')) {
            return 'Accepted by Secondary';
          }
          return 'Accepted by Primary';
        })
      });
    }),
    computedStatus: computed('computedStatusPromise.content', function () {
      if (get(this, 'tmp.isDeclined')) return get(this, 'tmp.status');
      return get(this, 'computedStatusPromise.content');
    }),
    categories: computed('tmp.layouts', function () {
      var categories = R.uniq(get(this, 'tmp.layouts').map(function (l) {
        return l.get('category');
      }));
      return categories.join(', ');
    }),
    startDate: computed('tmp.layouts', function () {
      var startDates = R.uniq(get(this, 'tmp.layouts').map(function (l) {
        return l.get('startDate');
      }));
      var sortedDates = this.get('sortByDate')(startDates);
      return R.head(sortedDates);
    }),
    endDate: computed('tmp.layouts', function () {
      var endDates = R.uniq(get(this, 'tmp.layouts').map(function (l) {
        return l.get('endDate');
      }));
      var sortedDates = this.get('sortByDate')(endDates);
      return R.last(sortedDates);
    }),
    sortByDate: function sortByDate(dates) {
      return R.sort(function (d1, d2) {
        var m1 = moment(d1);
        var m2 = moment(d2);
        if (m1.isSame(m2)) return 0;
        if (m1.isBefore(m2)) return -1;
        return 1;
      }, dates);
    },
    tmpLink: computed('tmp.type', function () {
      var type = get(this, 'tmp.type');
      switch (type) {
        case 'tmp':
          return 'tmp';
        case 'tmp-submission':
          return 'tmp-submission';
        case 'tmp-draft':
          return 'tmp-draft';
      }
    }),
    canCreateRevision: computed('tmp.type', function () {
      return get(this, 'tmp.type') !== 'tmp-draft';
    }),
    canDeleteDraft: computed('tmp.isNew', 'tmp.type', function () {
      return get(this, 'tmp.type') === 'tmp-draft' && !get(this, 'tmp.isNew');
    }),
    hasLayouts: computed.notEmpty('tmp.layouts'),
    actions: {
      // When row is clicked ("selected"), update selected row property on parent:
      toggleMap: function toggleMap() {
        var tmp = get(this, 'tmp');
        // Deselect this tmp row if already selected:
        if (tmp.isSelected) this.get('updateSelectedRow')(null);
        // Make this tmp row selected:
        else this.get('updateSelectedRow')(tmp);
      }
    }
  });
  _exports.default = _default;
});