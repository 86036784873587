define("tmp-for-all/mixins/routes/edit-worksite/extent-route-mixin", ["exports", "tmp-for-all/mixins/routes/edit-worksite/base-mixin", "tmp-for-all/mixins/routes/get-tooltip-context-mixin"], function (_exports, _baseMixin, _getTooltipContextMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    setProperties = Ember.setProperties;
  var _default = Ember.Mixin.create(_baseMixin.default, _getTooltipContextMixin.default, {
    actions: {
      updateIsFullClosureRequested: function updateIsFullClosureRequested(value) {
        var worksite = get(this, 'worksite');
        set(worksite, 'isFullClosureRequested', value);
      },
      updateTrafficManagementLevel: function updateTrafficManagementLevel(selectedTrafficManagementLevel) {
        var worksite = get(this, 'worksite');
        set(worksite, 'trafficManagementLevel', selectedTrafficManagementLevel.value);
        set(worksite, 'manualRoadLevel', true);
      },
      removeManualRoadLevel: function removeManualRoadLevel() {
        set(this, 'worksite.manualRoadLevel', false);
      },
      getWorksiteTooltipContext: function getWorksiteTooltipContext(clash) {
        this.worksiteTooltipContext(clash.properties).then(function (context) {
          return setProperties(clash, context);
        });
      },
      updateRoadType: function updateRoadType(roadType) {
        var worksite = get(this, 'worksite');
        set(worksite, 'roadType', roadType.value);
        if (roadType !== 'MOTW') {
          set(worksite, 'motorwayWorkType', null);
        }
      },
      updateMotorwayWorkType: function updateMotorwayWorkType(workType) {
        var worksite = get(this, 'worksite');
        set(worksite, 'motorwayWorkType', workType);
      }
    }
  });
  _exports.default = _default;
});