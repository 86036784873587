define("tmp-for-all/routes/projects/edit", ["exports", "tmp-for-all/mixins/company-search"], function (_exports, _companySearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Route.extend(_companySearch.default, {
    model: function model(params) {
      return this.store.findRecord('project', params.project_id);
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('projects.view', get(this, 'controller.model.id'));
      },
      updateCompany: function updateCompany(company) {
        set(this, 'controller.model.company', company);
      },
      saveProject: function saveProject() {
        var _this = this;
        get(this, 'controller.model').save().then(function () {
          get(_this, 'flashMessages').success('Project edit was successful');
          _this.transitionTo('projects.view', get(_this, 'controller.model.id'));
        }).catch(function () {
          get(_this, 'flashMessages').danger('There was a problem saving this Project');
        });
      },
      deleteProject: function deleteProject() {
        var _this2 = this;
        get(this, 'controller.model').destroyRecord().then(function () {
          get(_this2, 'flashMessages').success('Project was successfully deleted');
          _this2.transitionTo('projects.list');
        }).catch(function () {
          get(_this2, 'flashMessages').danger('There was a problem deleting this Project');
        });
      }
    }
  });
  _exports.default = _default;
});