define("tmp-for-all/instance-initializers/ember-cli-raygun", ["exports", "tmp-for-all/config/environment", "ember-cli-raygun/initializers/ember-cli-raygun"], function (_exports, _environment, _emberCliRaygun) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initializeWithConfig = initializeWithConfig;
  function initializeWithConfig(passedConfig) {
    return (0, _emberCliRaygun.default)(passedConfig);
  }
  var _default = {
    name: 'ember-cli-raygun',
    initialize: function initialize() {
      return (0, _emberCliRaygun.default)(_environment.default);
    }
  };
  _exports.default = _default;
});