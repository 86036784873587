define("tmp-for-all/components/planning-map/panel-filters/filter-category/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MHQKTtQS",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[4,\"panel-section\",null,[[\"panelSectionHeading\"],[[23,[\"structure\",\"label\"]]]],{\"statements\":[[4,\"each\",[[23,[\"structure\",\"children\"]]],null,{\"statements\":[[4,\"panel-section/panel-sub-section\",null,[[\"canToggle\",\"showContent\"],[[22,1,[\"children\",\"length\"]],false]],{\"statements\":[[0,\"      \"],[1,[27,\"planning-map/panel-filters/filter-control\",null,[[\"structure\",\"state\",\"updateState\"],[[22,1,[]],[27,\"get-state\",[[23,[\"state\"]],[22,1,[\"searchKey\"]]],null],[27,\"action\",[[22,0,[]],\"updateState\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/planning-map/panel-filters/filter-category/template.hbs"
    }
  });
  _exports.default = _default;
});