define("tmp-for-all/components/shared/clash-resolution-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    clashMessage: '',
    actions: {
      closeModal: function closeModal() {
        get(this, 'toggleClashModal')();
      },
      saveClash: function saveClash() {
        // return get(this, 'ajax')
        return;
      }
    }
  });
  _exports.default = _default;
});