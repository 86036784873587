define("tmp-for-all/components/search-parameters/component", ["exports", "tmp-for-all/utils/worksite-filters"], function (_exports, _worksiteFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;

  // [filterTypes] -> [{groupName, options: [filterTypes]}]
  var getGroupedModel = R.pipe(R.groupBy(R.prop('group')), R.toPairs, R.map(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      k = _ref2[0],
      value = _ref2[1];
    return {
      groupName: k,
      options: value
    };
  }));
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    store: Ember.inject.service(),
    filterOptions: [],
    selectedFilters: [],
    filterTypes: _worksiteFilters.default,
    currentUser: computed.alias('authManager.currentUser'),
    savedSearchLocation: computed('savedSearch', function () {
      var savedSearch = this.get('savedSearch');
      if (savedSearch) {
        return savedSearch;
      }
      return '';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      get(this, 'currentUser').then(function (user) {
        var updateFilterParams = get(_this, 'updateFilterParams');
        var filterTypes = get(_this, 'filterTypes');
        var savedSearch = get(_this, 'savedSearchLocation');
        get(user, 'savedSearchEnabled').then(function (restoreSavedSearch) {
          if (filterTypes && updateFilterParams) {
            set(_this, 'filterOptions', getGroupedModel(filterTypes));
            updateFilterParams(function (controller) {
              var savedSearchPrefs = get(user, savedSearch);
              if (savedSearchPrefs && restoreSavedSearch) {
                Object.keys(savedSearchPrefs).forEach(function (sspKey) {
                  if (sspKey in controller && !['status', 'freetext'].includes(sspKey)) {
                    set(controller, sspKey, savedSearchPrefs[sspKey]);
                  }
                });
              }
              // filters that return true on init are our selected filters from query params
              var activeFilters = filterTypes.filter(function (f) {
                return f.init(controller);
              });
              set(_this, 'selectedFilters', activeFilters);
            });
          }
        });
      });
    },
    actions: {
      removeFilter: function removeFilter(filter, index) {
        var _this2 = this;
        set(filter, 'disabled', false);
        var savedSearch = get(this, 'savedSearchLocation');
        var updateFilterParams = get(this, 'updateFilterParams');
        get(this, 'currentUser').then(function (user) {
          var savedSearchPrefs = get(user, savedSearch);
          if (savedSearchPrefs) {
            filter.queryParams.forEach(function (o) {
              return delete savedSearchPrefs[o];
            });
            set(user, savedSearch, savedSearchPrefs);
          }
          get(_this2, 'selectedFilters').removeAt(get(_this2, 'selectedFilters').indexOf(filter));
        });
        updateFilterParams(filter.onRemove, index);
      },
      addFilter: function addFilter(filter) {
        if (filter) {
          set(this, 'selectedFilter', null);
          var index = filter.addFilter();
          if (get(this, 'selectedFilters').indexOf(filter) === -1) {
            var filters = [filter].concat(get(this, 'selectedFilters'));
            set(this, 'selectedFilters', filters);
          }
          return index;
        }
      }
    }
  });
  _exports.default = _default;
});