define("tmp-for-all/mixins/routes/processing-time-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    processingTime: Ember.inject.service(),
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      processedById: {
        refreshModel: true
      },
      fromDate: {
        refreshModel: true
      },
      toDate: {
        refreshModel: true
      },
      processingTime: {
        refreshModel: true
      },
      selectedMonthIndex: {
        refreshModel: true
      }
    }
  });
  _exports.default = _default;
});