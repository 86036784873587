define("tmp-for-all/routes/tmp-submission/layouts/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend({
    model: function model() {
      var _this = this;
      var unsavedLayout = get(this, 'store').peekAll('layout').find(function (layout) {
        return !layout.id;
      });
      if (unsavedLayout) return unsavedLayout;
      var tmpSubmission = this.modelFor('tmp-submission');
      return tmpSubmission.get('latestWorksite').then(function (worksite) {
        return _this.store.createRecord('layout', {
          tmpSubmission: tmpSubmission,
          daysOfWeek: [0, 1, 2, 3, 4],
          startDate: get(worksite, 'startDate'),
          endDate: get(worksite, 'endDate')
        });
      });
    }
  });
  _exports.default = _default;
});