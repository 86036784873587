define("tmp-for-all/transforms/boolean-nullable", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.BooleanTransform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === null) {
        return null;
      }
      return this._super(serialized);
    },
    serialize: function serialize(deserialized) {
      if (deserialized === null) {
        return null;
      }
      return this._super(deserialized);
    }
  });
  _exports.default = _default;
});