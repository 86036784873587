define("tmp-for-all/components/admin-users-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qi2qKdGL",
    "block": "{\"symbols\":[\"admin\"],\"statements\":[[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"table\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Name\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Email\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Phone\"],[10],[0,\"\\n        \"],[7,\"th\"],[9],[0,\"Mobile\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"adminUsers\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"preferredName\"]],false],[0,\" \"],[1,[22,1,[\"lastName\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[7,\"a\"],[12,\"href\",[28,[\"mailto:\",[22,1,[\"email\"]]]]],[9],[1,[22,1,[\"email\"]],false],[10],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"phone\"]],false],[10],[0,\"\\n          \"],[7,\"td\"],[9],[1,[22,1,[\"mobile\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/admin-users-list/template.hbs"
    }
  });
  _exports.default = _default;
});