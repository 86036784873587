define("tmp-for-all/templates/tmp-submission/layouts/edit/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o2zeYRpD",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"maps/lane-selector\",null,[[\"worksite\",\"tmpLayout\",\"clashes\",\"onCancel\",\"onUpdateImpacts\",\"updateClashes\",\"onError\",\"onClickClashFeature\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"layout\"]],[23,[\"model\",\"clashes\"]],[27,\"route-action\",[\"returnToEdit\"],null],[27,\"route-action\",[\"updateImpacts\"],null],[27,\"route-action\",[\"updateClashes\"],null],[27,\"route-action\",[\"displayErrorMessage\"],null],[27,\"route-action\",[\"getTooltipTemplate\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmp-submission/layouts/edit/map.hbs"
    }
  });
  _exports.default = _default;
});