define("tmp-for-all/templates/tmp-submission/layouts/edit/details/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AAnelL8Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"layouts-form\",null,[[\"tmpLayout\",\"tmp\",\"saveLayout\",\"cancelLayout\",\"addNewUser\",\"stmsSearch\"],[[23,[\"model\",\"layout\"]],[23,[\"model\",\"tmp\"]],[27,\"route-action\",[\"saveLayout\"],null],[27,\"route-action\",[\"cancelLayout\"],null],[27,\"route-action\",[\"addNewUser\"],null],[27,\"route-action\",[\"stmsSearch\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmp-submission/layouts/edit/details/index.hbs"
    }
  });
  _exports.default = _default;
});