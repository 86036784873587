define("tmp-for-all/routes/application", ["exports", "tmp-for-all/mixins/return-path-mixin", "tmp-for-all/mixins/authorization-route-mixin", "tmp-for-all/config/environment"], function (_exports, _returnPathMixin, _authorizationRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.UnprotectedRoute.extend(_authorizationRouteMixin.default, _returnPathMixin.default, {
    intl: Ember.inject.service(),
    metrics: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (_environment.default.googleTagManagerKey) {
        get(this, 'metrics').activateAdapters([{
          name: 'GoogleTagManager',
          environments: ['production', 'test', 'development'],
          config: {
            id: _environment.default.googleTagManagerKey
          }
        }]);
      }
      return get(this, 'intl').setLocale(_environment.default.intl.defaultLocale);
    },
    actions: {
      error: function error(err) {
        // jsonapi error will have an errors array
        var errors = get(err, 'errors');
        // a standard error thrown on route will have no errors array
        if (!errors) return console.error(err);
        if (errors.length && errors[0].status === 401) {
          this.transitionTo('not-found');
        } else {
          console.error(errors);
        }
      }
    }
  });
  _exports.default = _default;
});