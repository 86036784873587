define("tmp-for-all/components/nzta-detour/map/component", ["exports", "tmp-for-all/config/environment", "tmp-for-all/utils/nzta-detour-helper", "@turf/bbox", "tmp-for-all/constants"], function (_exports, _environment, _nztaDetourHelper, _bbox3, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function detourPartsToLeafletBounds(geoms) {
    var allfeatures = geoms.map(function (m) {
      return m.geojson || m.geojsonNztaProvider;
    }).map(function (f) {
      if (f.type === 'Feature') {
        return f;
      } else {
        return {
          type: 'Feature',
          geometry: f,
          properties: {}
        };
      }
    });
    var _bbox = (0, _bbox3.default)({
        type: 'FeatureCollection',
        features: allfeatures
      }),
      _bbox2 = _slicedToArray(_bbox, 4),
      x1 = _bbox2[0],
      y1 = _bbox2[1],
      x2 = _bbox2[2],
      y2 = _bbox2[3];
    return [[y1, x1], [y2, x2]];
  }
  function chooseDetourIcon(index, total) {
    if (index === 0) {
      return L.icon({
        iconUrl: '/assets/images/impact-editor/marker-a.png',
        iconSize: [35, 42],
        iconAnchor: [16, 42]
      });
    } else if (index === total - 1) {
      return L.icon({
        iconUrl: '/assets/images/impact-editor/marker-b.png',
        iconSize: [35, 42],
        iconAnchor: [16, 42]
      });
    } else {
      return L.icon({
        iconUrl: '/assets/images/impact-editor/marker-icon-clear.png',
        iconSize: [16, 16],
        iconAnchor: [8, 8]
      });
    }
  }
  function geoJsonFromCoordinates(coordinates) {
    if (!coordinates) return;
    return {
      type: 'LineString',
      coordinates: coordinates.map(function (loc) {
        return [loc.lng, loc.lat];
      }),
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326'
        }
      }
    };
  }
  function waypointCoords(waypoints) {
    return waypoints.map(function (wp) {
      return wp.latLng;
    });
  }
  function getClosureEnds(closure) {
    var ends = [];
    if (closure.waypoints) {
      ends = [closure.waypoints[0], closure.waypoints[closure.waypoints.length - 1]];
    } else if (closure.geojson && closure.coordinates && closure.coordinates.length) {
      ends = [closure.geojson.coordinates[0], closure.geojson.coordinates[closure.geojson.coordinates.length - 1]];
    }
    return ends;
  }
  function formatTime(seconds) {
    return (seconds / 60).toFixed(2);
  }
  function formatDistance(meters) {
    return (meters / 1000).toFixed(2);
  }
  var _default = Ember.Component.extend({
    modal: Ember.inject.service(),
    ajax: Ember.inject.service(),
    detours: Ember.computed.filterBy('unfilteredDetours', 'isPublished', true),
    draftDetours: Ember.computed.filterBy('unfilteredDetours', 'isPublished', false),
    detourParts: Ember.computed('unfilteredDetours.[]', function () {
      var parts = {};
      this.unfilteredDetours.forEach(function (detour) {
        if (!parts[detour.detourPart]) parts[detour.detourPart] = {};
        var key = detour.isPublished ? 'published' : 'draft';
        parts[detour.detourPart][key] = detour;
      });
      return parts;
    }),
    unfilteredDetours: [],
    selectedClosure: null,
    selectedClosureDraft: null,
    center: [-41.27780646738182, 174.7265625],
    zoom: 6,
    selectedTileComponentName: 'mapbox-streets-tile-layer',
    markerIndex: null,
    showErrorMessage: function showErrorMessage(message, error) {
      console.error(message, error);
      if (error && (error.code === 401 || Ember.get(error, 'errors.0.status') === 401)) {
        message = "".concat(message, ". Please refresh or double check you are still logged in.");
      }
      this.flashMessages.danger(message);
    },
    featureStyle: function featureStyle(feature) {
      var colors = {
        closure: {
          draft: '#00c5cd',
          published: '#0053f4'
        },
        detour: {
          draft: '#208020',
          published: '#2eb467'
        }
      };
      var type = feature.properties.detourPart === 0 ? 'closure' : 'detour';
      var state = feature.properties.isPublished ? 'published' : 'draft';
      var style = {
        color: colors[type][state]
      };
      if (state === 'draft') style.dashArray = '5 10';
      return style;
    },
    // Configures an initialzes the routing control that talks to OSRM
    // provide a function that will be called when new routes come back from OSRM
    setupControl: function setupControl(fn) {
      this.markerIndex = 0;
      var control = new L.Routing.Control({
        serviceUrl: _environment.default.osrmServiceUrl,
        waypointMode: 'snap',
        waypoints: [],
        showAlternatives: false,
        fitSelectedRoutes: false,
        lineOptions: {
          addWaypoints: true,
          styles: [
          /**
           * First line used to increase hit area when adding
           * a waypoint to the route.
           */
          {
            color: 'transparent',
            weight: 20
          }, {
            color: '#2B00FF',
            opacity: 0.4,
            weight: 8
          }]
        },
        plan: new L.Routing.Plan([], {
          createMarker: function createMarker(i, wp, n) {
            var icon = chooseDetourIcon(i, n);
            if (icon) {
              return L.marker(wp.latLng, {
                draggable: true,
                icon: icon
              });
            }
            return null;
          }
        })
      });
      control.on('routesfound', fn);
      control.addTo(this.map);
      return control;
    },
    modelToEdit: Ember.computed('addingClosure', 'addingDetour', 'editingClosure', 'editingDetour', function () {
      if (this.addingClosure || this.editingClosure) return this.selectedClosureDraft;else if (this.addingDetour) return this.newDetour;else if (this.editingDetour) return this.detourToEdit;
    }),
    newDetour: Ember.computed.alias('draftDetours.lastObject'),
    updateSection: function updateSection(route) {
      if (this.supressUpdate) {
        this.set('supressUpdate', false);
        return;
      }
      var model = this.modelToEdit;
      var associatedClosure = this.selectedClosure || this.selectedClosureDraft;
      var closureJourneyTime = associatedClosure.journeyTime || 0;
      var closureJourneyLength = associatedClosure.journeyLength || 0;
      if (!model) return;
      var journeyTime = formatTime(route.summary.totalTime);
      var extraJourneyTime = (0, _nztaDetourHelper.getExtraTimeLabelForNumber)(journeyTime - closureJourneyTime);
      var journeyLength = formatDistance(route.summary.totalDistance);
      var extraJourneyLength = (journeyLength - closureJourneyLength).toFixed(2);
      model.setProperties({
        geojson: geoJsonFromCoordinates(route.coordinates),
        waypoints: waypointCoords(this.control.getWaypoints()),
        detourName: model.detourName || '',
        startLocation: model.startLocation || '',
        endLocation: model.endLocation || '',
        journeyTime: journeyTime,
        journeyLength: journeyLength,
        extraJourneyTime: extraJourneyTime,
        extraJourneyLength: extraJourneyLength
      });
    },
    editMode: Ember.computed.or('addingClosure', 'editingClosure', 'editDetourMode'),
    selectMode: Ember.computed.not('editMode'),
    editDetourMode: Ember.computed.or('addingDetour', 'editingDetour'),
    editSection: function editSection(model) {
      if (model.detourPart === 0) {
        this.set('editingClosure', true);
      } else {
        this.setProperties({
          editingDetour: true,
          detourToEdit: model
        });
      }
      if (Array.isArray(model.waypoints)) {
        this.control.setWaypoints(model.waypoints);
        this.set('markerIndex', model.waypoints.length);
      }
      model.set('editing', true);
    },
    saveSection: function saveSection(model) {
      var _this = this;
      this.set('showOverlay', true);
      var file = this.fileToUpload;
      return model.save().then(function (savedModel) {
        if (!file) return savedModel;
        var url = "".concat(_environment.default.apiHost, "/api/nzta-detours/").concat(savedModel.id, "/upload-file");
        return _this.ajax.uploadFile(url, file).then(function () {
          return savedModel;
        });
      }).then(function (savedModel) {
        // update features without waiting
        _this.updateFeatures();
        _this.setProperties({
          fileToUpload: null,
          showOverlay: false,
          markerIndex: 0,
          addingDetour: false,
          addingClosure: false,
          editingClosure: false,
          editingDetour: false
        });
        model.set('editing', false);
        _this.control.setWaypoints([]);
        return savedModel;
      }).catch(function (e) {
        _this.showErrorMessage('Save failed', e);
        _this.set('showOverlay', false);
      });
    },
    setCurrentSelection: function setCurrentSelection(detourSet) {
      var _this2 = this;
      this.set('unfilteredDetours', []);
      detourSet.forEach(function (detour) {
        if (!isNaN(parseFloat(Ember.get(detour, 'extraJourneyLength')))) {
          detour.set('extraJourneyLength', parseFloat(Ember.get(detour, 'extraJourneyLength')).toFixed(2));
        }
        if (detour.detourPart === 0 && detour.isPublished) {
          _this2.set('selectedClosure', detour);
        } else if (detour.detourPart === 0 && !detour.isPublished) {
          _this2.set('selectedClosureDraft', detour);
        } else {
          _this2.unfilteredDetours.addObject(detour);
        }
      });
    },
    notEditMode: Ember.computed.not('editMode'),
    notAddingDetour: Ember.computed.not('addingDetour'),
    notEditingDetour: Ember.computed.not('editDetourMode'),
    hasSavedClosure: Ember.computed.or('selectedClosure.id', 'selectedClosureDraft.id'),
    noOfficialDetours: Ember.computed.or('selectedClosure.noOfficialDetour', 'selectedClosureDraft.noOfficialDetour'),
    allowOfficialDetours: Ember.computed.not('noOfficialDetours'),
    showAddDetourButton: Ember.computed.and('hasSavedClosure', 'notAddingDetour', 'notEditingDetour', 'allowOfficialDetours', 'notEditMode'),
    isLayersPanelVisible: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.routeLoadedDetourSet) {
        this.setCurrentSelection(this.routeLoadedDetourSet);
        this.map.fitBounds(detourPartsToLeafletBounds(this.routeLoadedDetourSet));
      }
    },
    actions: {
      mapLoaded: function mapLoaded(e) {
        var _this3 = this;
        this.set('map', e.target);
        this.control = this.setupControl(function (e) {
          _this3.updateSection(e.routes[0]);
        });
        this.map.setMinZoom(_constants.DEFAULT_MIN_ZOOM);
        this.map.setMaxBounds(_constants.DEFAULT_DEFAULT_NZ_BOUNDS);
      },
      mapClick: function mapClick(e) {
        var _this4 = this;
        if (this.supressClick) {
          this.set('supressClick', false);
          return;
        }
        var clickPosition = e.latlng;
        if (this.editMode) {
          var waypoints = this.control.getWaypoints();
          if (this.markerIndex < 2) {
            waypoints[this.markerIndex] = clickPosition;
            this.control.setWaypoints(waypoints);
          } else {
            this.control.spliceWaypoints(waypoints.length - 1, 0, clickPosition);
          }
          this.markerIndex++;
        } else {
          this.unfilteredDetours.forEach(function (detour) {
            detour.set('editing', false);
            detour.set('highlight', false);
          });
          var zoomLevel = this.map.getZoom();
          this.setProperties({
            selectedClosure: null,
            selectedClosureDraft: null,
            unfilteredDetours: [],
            selectedNocArea: null
          });
          this.getDetourSetByCoords(clickPosition, zoomLevel).then(function (detourSet) {
            if (!detourSet) return;
            _this4.setCurrentSelection(detourSet);
          });
        }
      },
      removeLast: function removeLast() {
        this.control.spliceWaypoints(this.control.getWaypoints().length - 2, 1);
        this.markerIndex--;
      },
      editSection: function editSection(model) {
        this.editSection(model);
      },
      cancelEdit: function cancelEdit(model) {
        model.set('editing', false);
        if (!model.id) {
          if (model.detourPart > 0) {
            this.unfilteredDetours.removeObject(model);
          }
          if (this.selectedClosureDraft === model) {
            this.set('selectedClosureDraft', null);
            this.set('selectedNocArea', null);
          }
          model.destroyRecord();
        } else {
          model.rollbackAttributes();
        }
        this.setProperties({
          fileToUpload: null,
          markerIndex: 0,
          addingDetour: false,
          editingDetour: false,
          addingClosure: false,
          editingClosure: false
        });
        this.control.setWaypoints([]);
      },
      createDraft: function createDraft(published) {
        var duplicate = published.toJSON();
        duplicate.isPublished = false;
        duplicate.id = null;
        var draft = this.createDetour(duplicate);
        if (draft.detourPart === 0) {
          this.set('selectedClosureDraft', draft);
        } else {
          var old = this.unfilteredDetours.find(function (item) {
            var samePart = item.detourPart === draft.detourPart;
            return samePart && !item.isPublished;
          });
          this.unfilteredDetours.removeObject(old);
          this.unfilteredDetours.addObject(draft);
          this.editSection(draft);
        }
        return draft;
      },
      saveSection: function saveSection(model, publish) {
        var _this5 = this;
        return this.saveSection(model).catch(function (e) {
          _this5.showErrorMessage('Save failed', e);
          _this5.set('showOverlay', false);
        });
      },
      publishSection: function publishSection(model, willOverwrite) {
        var _this6 = this;
        var type = model.detourPart === 0 ? 'Closure' : 'Detour';
        var dialogText = "Are you sure you want to publish this ".concat(type, "?");
        if (willOverwrite) {
          dialogText += " This will overwrite the existing published ".concat(type, ".");
        }
        this.set('modal.options', {
          title: "Publish ".concat(type, "?"),
          text: dialogText,
          confirmButton: 'Publish',
          confirmAction: function confirmAction() {
            model.set('isPublished', true);
            _this6.saveSection(model).then(_this6.getDetourSetForModel)
            // need to use arrow function to maintain scope
            .then(function (detourSet) {
              return _this6.setCurrentSelection(detourSet);
            }).then(function () {
              if (model.detourPart === 0) _this6.set('selectedClosureDraft', null);
            }).then(function () {
              _this6.modal.toggle();
              _this6.flashMessages.success("".concat(type, " was successfully published"));
            }).catch(function (e) {
              _this6.modal.toggle();
              _this6.showErrorMessage("There was a problem publishing this ".concat(type), e);
            });
          }
        });
        this.modal.toggle();
      },
      deleteDetour: function deleteDetour(detour) {
        var _this7 = this;
        this.set('modal.options', {
          title: 'Delete Detour?',
          text: 'Are you sure you want to delete this Detour?',
          confirmButton: 'Delete',
          confirmAction: function confirmAction() {
            _this7.unfilteredDetours.removeObject(detour);
            detour.destroyRecord().then(function () {
              _this7.setProperties({
                markerIndex: 0,
                addingDetour: false,
                editingDetour: false
              });
              _this7.control.setWaypoints([]);
              _this7.updateFeatures();
            }).then(function () {
              _this7.modal.toggle();
              _this7.flashMessages.success('Detour was deleted successfully');
            }).catch(function (e) {
              _this7.showErrorMessage('There was a problem deleting this Detour', e);
            });
          }
        });
        this.modal.toggle();
      },
      addNewClosure: function addNewClosure() {
        this.set('addingClosure', true);
        this.set('selectedClosureDraft', this.addClosure());
        this.set('selectedNocArea', null);
      },
      deleteClosure: function deleteClosure(closure, onlyClosureVersion) {
        var _this8 = this;
        var text = 'Are you sure you want to delete this Closure?';
        if (onlyClosureVersion && this.unfilteredDetours.length) {
          text += ' The Detours associated with this Closure will also be deleted.';
        } else {
          if (closure.isPublished) {
            text += ' Please note: Any published detours associated with this closure will be hidden on the public site until you publish the draft version.';
          }
        }
        this.set('modal.options', {
          title: 'Delete Closure?',
          text: text,
          confirmButton: 'Delete',
          confirmAction: function confirmAction() {
            closure.destroyRecord().then(function () {
              _this8.setProperties({
                selectedClosure: null,
                selectedClosureDraft: null,
                addingClosure: false,
                editingClosure: false,
                unfilteredDetours: []
              });
              _this8.updateFeatures();
            }).then(function () {
              _this8.modal.toggle();
              _this8.flashMessages.success('The closure was successfully deleted');
            }).catch(function (e) {
              _this8.showErrorMessage('There was a problem deleting this Closure', e);
            });
          }
        });
        this.modal.toggle();
      },
      addDetour: function addDetour() {
        var closure = this.selectedClosure || this.selectedClosureDraft;
        var maxDetourPart = this.detours.reduce(function (max, d) {
          return Math.max(max, d.detourPart);
        }, 1);
        var maxDraftDetourPart = this.draftDetours.reduce(function (max, d) {
          return Math.max(max, d.detourPart);
        }, 1);
        var detourPart = Math.max(maxDetourPart, maxDraftDetourPart) + 1;
        var detourModel = this.createDetour({
          detourPart: detourPart,
          detourName: '',
          detourNumber: closure.detourNumber,
          nocArea: closure.nocArea
        });
        var ends = getClosureEnds(closure);
        this.control.setWaypoints(ends);
        this.unfilteredDetours.addObject(detourModel);
        detourModel.set('editing', true);
        this.setProperties({
          addingDetour: true,
          markerIndex: ends.length
        });
      },
      detourClick: function detourClick(detour, e) {
        if (this.editMode) return;
        this.set('supressClick', true);
        this.unfilteredDetours.forEach(function (detour) {
          detour.set('editing', false);
          detour.set('highlight', false);
        });
        if (detour.isPublished) {
          detour.set('highlight', true);
        } else {
          this.editSection(detour);
        }
      },
      setNocArea: function setNocArea(noc) {
        this.set('selectedNocArea', noc);
        this.selectedClosureDraft.set('nocArea', noc.displayName);
      },
      toggleLeftPanel: function toggleLeftPanel() {
        this.toggleProperty('isLayersPanelVisible');
      },
      filterClick: function filterClick(e) {
        this.set("filter.".concat(e.target.name), e.target.checked);
        this.updateFeatures();
      },
      didSelectFile: function didSelectFile(e) {
        this.set('fileToUpload', e.target.files[0]);
      }
    }
  });
  _exports.default = _default;
});