define("tmp-for-all/controllers/planning-map/region", ["exports", "tmp-for-all/utils/planning-map-side-panel-config", "tmp-for-all/constants", "ember-copy"], function (_exports, _planningMapSidePanelConfig, _constants, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var computed = Ember.computed,
    set = Ember.set,
    get = Ember.get;
  var twoWeeksFromNow = new Date();
  twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
  var defaultQuery = {
    primaryRCA: [],
    dateRange: {
      from: moment(new Date()).startOf('day').toISOString(),
      to: moment(twoWeeksFromNow).startOf('day').toISOString()
    },
    freetext: '',
    status: {
      accepted: {
        worksite: {
          include: ['excavation', 'non-excavation', 'event']
        },
        impact: {
          include: ['closed', 'restricted', 'open']
        },
        detour: true
      }
    }
  };
  var _default = Ember.Controller.extend({
    region: null,
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: computed.alias('session.currentUser'),
    saveQueryEnabled: computed('currentUser', function () {
      return this.ajax.featureFlagEnabled('save_map_preferences');
    }),
    // Note: Can't check for savedQueryEnabled in afterSetup as page loads before promise completes causing errors
    afterSetup: function afterSetup() {
      var _this = this;
      get(this, 'currentUser').then(function (user) {
        var savedSearchQuery = get(user, 'savedMapPreferences');
        if (savedSearchQuery) {
          set(_this, 'searchQuery', savedSearchQuery);
        } else {
          var searchQuery = (0, _emberCopy.copy)(defaultQuery, true);
          searchQuery.primaryRCA = _constants.REGIONS[get(_this, 'region')].defaultRCAs;
          set(_this, 'searchQuery', searchQuery);
        }
      });
    },
    structure: _planningMapSidePanelConfig.structure,
    state: _planningMapSidePanelConfig.state,
    actions: {
      updateSearchQuery: function updateSearchQuery(updatedPortion) {
        var _this2 = this;
        Promise.all([get(this, 'currentUser'), get(this, 'saveQueryEnabled')]).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
            user = _ref2[0],
            saveQueryEnabled = _ref2[1];
          set(_this2, 'model.isLoadingData', true);
          var newQuery = R.merge(get(_this2, 'searchQuery'), updatedPortion);
          // Note: BoundingBox column is irrelevant to search criteria as Querying the entire region every-time.
          delete newQuery.boundingBox;
          Object.keys(newQuery).forEach(function (key) {
            if (newQuery[key] == null) delete newQuery[key];
          });
          if (saveQueryEnabled) set(user, 'savedMapPreferences', newQuery);
          set(_this2, 'searchQuery', newQuery);
          get(_this2, 'ajax').getPlanningMapFeatures(get(_this2, 'searchQuery')).then(function (results) {
            set(_this2, 'model.data', results.data);
          }).catch(function (e) {
            Raygun.send(e);
            get(_this2, 'flashMessages').danger('There was a problem loading the map data - please try again.');
            set(_this2, 'model.isLoadingData', false);
          }).finally(function () {
            return set(_this2, 'model.isLoadingData', false);
          });
        });
      },
      getDetail: function getDetail(latLng, zoom) {
        var detailQuery = R.merge(get(this, 'searchQuery'), {
          position: {
            latLng: latLng,
            zoom: zoom
          }
        });

        // We're asking for items under a specific point, no point in sending a bounding box
        // Note: if performance lags, we may look to change this
        delete detailQuery.boundingBox;
        return get(this, 'ajax').getPlanningMapFeatureDetail(detailQuery);
      },
      setSearchQuery: function setSearchQuery(query) {
        set(this, 'searchQuery', query);
      }
    }
  });
  _exports.default = _default;
});