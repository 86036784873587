define("tmp-for-all/components/worksite/documentation-form/documentation-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dIGsqIf3",
    "block": "{\"symbols\":[\"typeMessage\",\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"type-selector align-right margin-btm-20 clearfix\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"onchange\",\"selected\",\"searchEnabled\",\"placeholder\",\"searchField\",\"tagName\",\"class\",\"options\"],[[23,[\"updateType\"]],[23,[\"selected\"]],true,\"Select Documentation Type...\",\"displayName\",\"div\",\"item-selector-select align-left\",[23,[\"availableOptions\"]]]],{\"statements\":[[0,\"    \"],[1,[22,2,[\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[7,\"span\"],[11,\"class\",\"tooltip-icon match-form-element\"],[9],[0,\"?\"],[3,\"action\",[[22,0,[]],\"toggleDocsHelp\"]],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"secondary-action \",[27,\"if\",[[23,[\"disableAddBtn\"]],\"secondary-action--disabled\"],null],\" float-right t-add-doc-block\"]]],[12,\"disabled\",[21,\"disableAddBtn\"]],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"secondary-action__icon-left\",\"plus\"]]],false],[0,\" Add documentation item\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"addDocBlock\"]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"shared/inline-help-block\",null,[[\"manualToggle\"],[[23,[\"docsHelpIsExpanded\"]]]],{\"statements\":[[0,\"  \"],[1,[23,[\"docsHelp\",\"message\"]],true],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"docsHelp\",\"TYPES\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,1,[\"message\"]],true],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite/documentation-form/documentation-selector/template.hbs"
    }
  });
  _exports.default = _default;
});