define("tmp-for-all/components/tmp-view/details/assign-user/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    observer = Ember.observer,
    set = Ember.set;
  var _default = Ember.Component.extend({
    setSelectedOwner: function setSelectedOwner() {
      var _this = this;
      var owner = get(this, 'tmp.owner');
      var rcaUsers = get(this, 'rcaUsers') || [];
      if (owner) {
        owner.then(function (user) {
          if (!user && rcaUsers.length) {
            return set(_this, 'tmpOwner', rcaUsers[0]);
          } else {
            return set(_this, 'tmpOwner', user);
          }
        });
      }
    },
    onSetOwner: observer('tmp.owner', function () {
      this.setSelectedOwner();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this.setSelectedOwner();
    },
    actions: {
      selectRCAUser: function selectRCAUser(user) {
        get(this, 'assignTmp')(user, get(this, 'tmp'));
      },
      userMatcher: function userMatcher(user, term) {
        var name = "".concat(get(user, 'preferredName'), " ").concat(get(user, 'lastName'));
        return name.toLowerCase().indexOf(term.toLowerCase());
      }
    }
  });
  _exports.default = _default;
});