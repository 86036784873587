define("tmp-for-all/templates/worksite/notice/resolve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6wyhN7g6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\"],[true]],{\"statements\":[[0,\"  \"],[1,[27,\"worksite/resolve-notice\",null,[[\"notice\",\"resolveComment\",\"statedResolveDate\",\"errors\",\"save\",\"cancel\"],[[23,[\"model\",\"notice\"]],[23,[\"model\",\"resolveComment\"]],[23,[\"model\",\"statedResolveDate\"]],[23,[\"model\",\"errors\"]],[27,\"route-action\",[\"save\"],null],[27,\"route-action\",[\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite/notice/resolve.hbs"
    }
  });
  _exports.default = _default;
});