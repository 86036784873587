define("tmp-for-all/models/permission-assignment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    domainType: attr('string'),
    domainId: attr('number'),
    user: belongsTo('tmp-user'),
    permission: belongsTo('permission'),
    isValidated: attr('boolean')
  });
  _exports.default = _default;
});