define("tmp-for-all/components/worksite-view/display-fees/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ytufES1s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"t-fee-name\"],[9],[7,\"strong\"],[9],[1,[23,[\"fee\",\"name\"]],false],[10],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"t-fee-status\"],[9],[1,[23,[\"fee\",\"displayStatus\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"t-fee-date-added\"],[9],[1,[27,\"if\",[[23,[\"isEstimate\"]],\"-\",[27,\"format-date\",[[23,[\"fee\",\"createdAt\"]],\"DD/MM/YYYY\"],null]],null],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"t-fee-quantity\"],[9],[1,[23,[\"fee\",\"quantity\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"t-fee-unit-cost\"],[9],[1,[27,\"format-currency\",[[23,[\"fee\",\"costPerUnit\"]]],null],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"t-fee-line-total\"],[9],[1,[27,\"format-currency\",[[23,[\"fee\",\"price\"]]],null],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/display-fees/item/template.hbs"
    }
  });
  _exports.default = _default;
});