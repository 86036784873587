define("tmp-for-all/components/worksite/contacts-form/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    selectedOptions: Ember.computed.mapBy('worksite.contacts', 'type'),
    isSuperUser: computed.alias('authManager.currentUser.isSuperUser'),
    isRcaUser: computed.equal('authManager.currentUser.selectedDomain', 'jurisdiction'),
    userRcaId: computed.alias('authManager.currentUser.selectedDomainId'),
    worksiteRcaId: computed.alias('worksite.jurisdiction.id'),
    canAddAuditor: computed('isRcaUser', 'worksiteRcaId', 'userRcaId', function () {
      // An RCA user for the same RCA as the worksite can add an auditor
      // (As long as they are currently 'operating under' the right RCA)
      // Make sure we are comparing strings & strings - in case one of the IDs
      // is a number
      return get(this, 'isRcaUser') && get(this, 'worksiteRcaId').toString() === get(this, 'userRcaId').toString();
    }),
    availableOptions: computed('selectedOptions', function () {
      var _this = this;
      return Object.keys(_constants.WORKSITE_CONTACT_TYPES).filter(function (key) {
        if (key === 'AUDITOR') {
          return get(_this, 'isSuperUser') || get(_this, 'canAddAuditor');
        }
        if (key === 'TRAFFIC' || key === 'PARTY') return true;
        return !R.contains(key, get(_this, 'selectedOptions'));
      }).map(function (value) {
        return {
          value: value,
          displayName: _constants.WORKSITE_CONTACT_TYPES[value]
        };
      });
    }),
    selected: null,
    disableAddBtn: computed.not('selected'),
    disableSaveBtn: computed.alias('worksite.isSubmitting'),
    transferModalShown: false,
    actions: {
      toggleTransferModal: function toggleTransferModal() {
        this.toggleProperty('transferModalShown');
      },
      updateType: function updateType(selection) {
        set(this, 'selected', selection);
      },
      addContact: function addContact() {
        this.addContact(this.selected.value);
        set(this, 'selected', null);
      },
      addContactTop: function addContactTop() {
        this.addContact(this.selected.value, 'TOP');
        set(this, 'selected', null);
      }
    }
  });
  _exports.default = _default;
});