define("tmp-for-all/mixins/controllers/processing-time-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    setProperties = Ember.setProperties;
  var now = moment();
  var defaultMonth = now.clone().subtract(1, 'month');
  var defaultFromDate = defaultMonth.startOf('month').startOf('day').toISOString();
  var defaultToDate = defaultMonth.endOf('month').endOf('day').toISOString();
  var _default = Ember.Mixin.create({
    queryParams: ['processedById', 'fromDate', 'toDate', 'processingTime', 'selectedMonthIndex'],
    selectedUser: {
      id: null,
      fullname: 'All Users'
    },
    processedById: null,
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    processingTime: null,
    actions: {
      userDidChange: function userDidChange(user) {
        set(this, 'selectedUser', user);
        set(this, 'processedById', get(user, 'id'));
      },
      processingTimeDidChange: function processingTimeDidChange(day) {
        set(this, 'selectedProcessingTime', day);
        set(this, 'processingTime', day.val);
      },
      fromDateDidChange: function fromDateDidChange(date) {
        var newDate = date ? moment(date).startOf('day').toISOString() : null;
        set(this, 'selectedMonthIndex', null);
        set(this, 'fromDate', newDate);
      },
      toDateDidChange: function toDateDidChange(date) {
        var newDate = date ? moment(date).endOf('day').toISOString() : null;
        set(this, 'selectedMonthIndex', null);
        set(this, 'toDate', newDate);
      },
      monthDidChange: function monthDidChange(month) {
        var selectedMonth = now.clone().subtract(month.index, 'month');
        setProperties(this, {
          selectedMonthIndex: month.index,
          fromDate: selectedMonth.startOf('month').startOf('day').toISOString(),
          toDate: selectedMonth.endOf('month').endOf('day').toISOString()
        });
      },
      resetFilter: function resetFilter() {
        var _this = this;
        get(this, 'queryParams').forEach(function (param) {
          if (param === 'fromDate') {
            set(_this, 'fromDate', defaultFromDate);
          } else if (param === 'toDate') {
            set(_this, 'toDate', defaultToDate);
          } else if (param === 'selectedMonthIndex') {
            set(_this, 'selectedMonthIndex', 1);
          } else {
            set(_this, param, null);
          }
        });
      }
    }
  });
  _exports.default = _default;
});