define("tmp-for-all/models/deployment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    isEmpty = Ember.isEmpty;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var DAY_START_HOUR = 6;
  var _default = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),
    layout: belongsTo('layout', {
      async: false
    }),
    startDate: attr('date'),
    endDate: attr('date'),
    startDateTime: computed('startDate', 'trafficControlIn', 'preparationIn', function () {
      // Calculate start date and time
      var startDate = moment(get(this, 'startDate')).freeze();
      // Use preparationIn if it is set, otherwise use trafficControlIn
      var startTime = get(this, 'preparationIn');
      if (isEmpty(startTime)) {
        startTime = get(this, 'trafficControlIn');
      }
      var startTimeSplit = startTime.split(':');
      var startHour = startTimeSplit[0];
      // If the time is an hour smaller than DAY_START_HOUR it refers to the following morning
      if (startHour < DAY_START_HOUR) {
        startDate = startDate.add(1, 'days');
      }
      var startMinute = startTimeSplit[1];
      startDate = startDate.hour(startHour).minute(startMinute);
      return startDate;
    }),
    endDateTime: computed('endDate', 'siteCleared', function () {
      // Calculate end date and time
      var endDate = moment(get(this, 'endDate')).freeze();
      var endTimeSplit = get(this, 'siteCleared').split(':');
      var endHour = endTimeSplit[0];
      // If the time is an hour smaller than DAY_START_HOUR it refers to the following morning
      if (endHour < DAY_START_HOUR) {
        endDate = endDate.add(1, 'days');
      }
      var endMinute = endTimeSplit[1];
      return endDate.hour(endHour).minute(endMinute);
    }),
    preparationIn: attr('string'),
    trafficControlIn: attr('string'),
    trafficControlOut: attr('string'),
    siteCleared: attr('string'),
    duration: computed('startDateTime', 'endDateTime', function () {
      var startDateTime = get(this, 'startDateTime');
      var endDateTime = get(this, 'endDateTime');

      // Calculate absolute duration of deployment
      var durationTime = moment.duration(endDateTime.diff(startDateTime));
      // Format string
      var minutes = durationTime.minutes() > 0 ? ", ".concat(durationTime.minutes(), " minutes") : '';
      if (durationTime.days() > 0) {
        return "".concat(durationTime.days(), " days, ").concat(durationTime.hours(), " hours").concat(minutes);
      } else {
        return "".concat(durationTime.hours(), " hours").concat(minutes);
      }
    }),
    stmsUser: belongsTo('tmp-user', {
      async: true
    }),
    notes: attr('string'),
    status: attr('string', {
      defaultValue: 'SCHEDULED'
    }),
    changeState: function changeState(action) {
      return get(this, 'ajax').deploymentAction(get(this, 'id'), action);
    }
  });
  _exports.default = _default;
});