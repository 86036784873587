define("tmp-for-all/components/worksite/details-form/time-of-day/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o0KJ+8Gu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"deprecated-col-1-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"element-wrap\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      Start time \"],[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10],[0,\"\\n\"],[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"        \"],[1,[21,\"toolTipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[1,[27,\"shared/form/select-box\",null,[[\"options\",\"value\",\"didChange\",\"errors\"],[[23,[\"availableTimes\"]],[23,[\"start\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"start\"]]],null]],null],[23,[\"errors\",\"timeOfDayStart\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"deprecated-col-1-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"element-wrap\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"End time \"],[7,\"span\"],[11,\"class\",\"text-red\"],[9],[0,\"*\"],[10],[10],[0,\"\\n    \"],[1,[27,\"shared/form/select-box\",null,[[\"options\",\"value\",\"didChange\",\"errors\"],[[23,[\"availableTimes\"]],[23,[\"end\"]],[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"end\"]]],null]],null],[23,[\"errors\",\"timeOfDayEnd\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite/details-form/time-of-day/template.hbs"
    }
  });
  _exports.default = _default;
});