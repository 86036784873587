define("tmp-for-all/components/shared/add-conditions/group/condition/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    tagName: '',
    classString: computed('condition', function () {
      var condition = get(this, 'condition');
      var classes = ['condition'];
      if (condition) {
        classes.push('condition-' + get(condition, 'id'));
      }
      return classes.join(' ');
    }),
    labelText: computed('conditionNumber', 'condition.text', function () {
      var conditionNumber = get(this, 'conditionNumber');
      if (conditionNumber !== null && typeof conditionNumber !== 'undefined') {
        return "".concat(conditionNumber, ". ").concat(get(this, 'condition.text'));
      } else {
        return get(this, 'condition.text');
      }
    })
  });
  _exports.default = _default;
});