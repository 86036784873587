define("tmp-for-all/models/worksite-search-result", ["exports", "ember-data", "tmp-for-all/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _Ember = Ember,
    computed = _Ember.computed,
    get = _Ember.get;
  var _default = _emberData.default.Model.extend({
    worksiteId: attr('number'),
    worksiteCode: attr('string'),
    majorRevisionNumber: attr('number'),
    minorRevisionNumber: attr('number'),
    type: attr('string'),
    worksiteType: attr('string'),
    name: attr('string'),
    reference: attr('string'),
    location: attr(),
    jurisdictionAreaName: attr('string'),
    projectId: attr('number'),
    projectName: attr('string'),
    clientName: attr('string'),
    clientId: attr('number'),
    applicantId: attr('number'),
    applicantOrganisation: attr('string'),
    startDate: attr('date'),
    endDate: attr('date'),
    submittedAt: attr('date'),
    declinedAt: attr('date'),
    isDeclined: attr('boolean'),
    tmpCount: attr('number'),
    workStatus: attr('string'),
    workStartDate: attr('date', {
      defaultValue: ''
    }),
    workStartDateLoggedAt: attr('date', {
      defaultValue: ''
    }),
    workCompletionDate: attr('date'),
    workCompletionDateLoggedAt: attr('date'),
    warrantyCompleteDate: attr('date'),
    rank: attr('number'),
    // We don't return relationships on the worksite-search-result, so we deal with this relation manually
    ownerId: attr('string'),
    assignee: attr('string'),
    applicationType: attr('string'),
    clientIsUtility: attr('boolean'),
    workCategory: attr('string'),
    workItemTypes: attr('string'),
    status: computed(function () {
      if (get(this, 'isDeclined') === true) return 'DECLINED';
      var type = get(this, 'type');
      var typeTranslations = R.invertObj(_constants.WORKSITE_MODEL_TYPES);
      return typeTranslations[type];
    }),
    getWorksite: function getWorksite() {
      var type = get(this, 'type');
      var worksiteId = get(this, 'worksiteId');
      if (R.isNil(type) || R.isNil(worksiteId)) {
        return R.reject('type or worksiteId is null, can\'t resolve worksite');
      }
      return this.store.findRecord(get(this, 'type'), worksiteId);
    }
  });
  _exports.default = _default;
});