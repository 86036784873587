define("tmp-for-all/components/search-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DVSTBz1Q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-box\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"concat\",[\"search-results/\",[23,[\"searchType\"]]],null]],[[\"allowClaim\",\"changeDeployment\",\"claim\",\"columnsExcludedByDefault\",\"createDraft\",\"handlePaginationClick\",\"heading\",\"isProcessingQueue\",\"isSTMS\",\"mapStyle\",\"setMapStyle\",\"order\",\"queryFilter\",\"showFilters\",\"results\",\"setOrder\",\"setViewMode\",\"showAllLocations\",\"showArchived\",\"showOnMap\",\"user\",\"userDomainId\",\"viewMode\",\"worksiteFocus\",\"setMapStyle\",\"becomeUser\",\"showDeclinedCheckbox\",\"hideDeclinedWorksites\"],[[23,[\"allowClaim\"]],[23,[\"changeDeployment\"]],[23,[\"claim\"]],[23,[\"columnsExcludedByDefault\"]],[23,[\"createDraft\"]],[23,[\"handlePaginationClick\"]],[23,[\"heading\"]],[23,[\"isProcessingQueue\"]],[23,[\"isSTMS\"]],[23,[\"mapStyle\"]],[23,[\"setMapStyle\"]],[23,[\"order\"]],[23,[\"queryFilter\"]],[23,[\"showFilters\"]],[23,[\"results\"]],[23,[\"setOrder\"]],[23,[\"setViewMode\"]],[23,[\"showAllLocations\"]],[23,[\"showArchived\"]],[23,[\"showOnMap\"]],[23,[\"user\"]],[23,[\"userDomainId\"]],[23,[\"viewMode\"]],[23,[\"worksiteFocus\"]],[23,[\"setMapStyle\"]],[23,[\"becomeUser\"]],[23,[\"showDeclinedCheckbox\"]],[23,[\"hideDeclinedWorksites\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/template.hbs"
    }
  });
  _exports.default = _default;
});