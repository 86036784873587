define("tmp-for-all/components/shared/no-worksite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H/S/HC+d",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"heading-block underline-coloured-blue has-tab-btn\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"\\n    Worksite/Project Information\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"cone\",\"28px\",\"22px\",\"icon--in-heading icon--blue\"]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"tab-btn unstyle-btn\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\"],[\"chevron-down\",\"20px\",\"20px\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"deprecated-grid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"deprecated-col-3-3\"],[9],[0,\"\\n    Worksite not yet published.\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/no-worksite/template.hbs"
    }
  });
  _exports.default = _default;
});