define("tmp-for-all/components/user-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    showArchived: false,
    inputID: computed('elementId', function () {
      return "input-".concat(this.get('elementId'));
    })
  });
  _exports.default = _default;
});