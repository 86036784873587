define("tmp-for-all/components/form-field/wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['form-field'],
    classNameBindings: ['displayLabelInline:form-field--inline-label', 'missingLabel:form-field--no-label'],
    missingLabel: computed('label', function () {
      return get(this, 'label') === undefined;
    })
  });
  _exports.default = _default;
});