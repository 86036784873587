define("tmp-for-all/templates/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ND/u9FHo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Edit User\"]],{\"statements\":[[0,\"  \"],[1,[27,\"user-form\",null,[[\"user\",\"companyRoles\",\"jurisdictions\",\"jurisdictionRoles\",\"unscopedRoles\",\"currentUser\",\"isSuperUser\",\"saveUser\",\"toggleArchiveState\",\"addPermissionAssignment\",\"deletePermissionAssignment\",\"updatePermissionAssignment\",\"hasAssignedCompany\",\"hasAssignedJurisdiction\",\"searchCompanies\",\"assignments\",\"resendInvite\",\"becomeUser\"],[[23,[\"model\",\"user\"]],[23,[\"model\",\"companyRoles\"]],[23,[\"model\",\"jurisdictions\"]],[23,[\"model\",\"jurisdictionRoles\"]],[23,[\"model\",\"unscopedRoles\"]],[23,[\"model\",\"currentUser\"]],[23,[\"model\",\"isSuperUser\"]],[27,\"route-action\",[\"saveUser\"],null],[27,\"route-action\",[\"toggleArchiveState\"],null],[27,\"route-action\",[\"addPermissionAssignment\"],null],[27,\"route-action\",[\"deletePermissionAssignment\"],null],[27,\"route-action\",[\"updatePermissionAssignment\"],null],true,true,[27,\"route-action\",[\"searchCompanies\"],null],[23,[\"model\",\"assignments\"]],[27,\"route-action\",[\"resendInvite\"],null],[27,\"route-action\",[\"becomeUser\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/users/edit.hbs"
    }
  });
  _exports.default = _default;
});