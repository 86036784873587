define("tmp-for-all/components/shared/project-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dnJucEMr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"Project Reference\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"project-name\"],[9],[4,\"link-to\",[\"projects.view\",[23,[\"project\",\"id\"]]],null,{\"statements\":[[1,[23,[\"project\",\"name\"]],false]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\"Summary of Work\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"project-summary\"],[9],[1,[23,[\"project\",\"summary\"]],false],[10],[0,\"\\n\"],[7,\"h3\"],[9],[0,\"Client Name\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"project-company\"],[9],[1,[23,[\"project\",\"company\",\"name\"]],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/project-info/template.hbs"
    }
  });
  _exports.default = _default;
});