define("tmp-for-all/components/shared/array-or-primitive-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jScwV52s",
    "block": "{\"symbols\":[\"val\"],\"statements\":[[4,\"if\",[[23,[\"isArray\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[1,[27,\"shared/json-or-primitive-display\",null,[[\"value\"],[[22,1,[]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"displayValue\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/array-or-primitive-display/template.hbs"
    }
  });
  _exports.default = _default;
});