define("tmp-for-all/components/shared/app-alerts/app-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+b6+0Iz9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"alert-banner alert-banner-\",[23,[\"alert\",\"type\"]]]]],[9],[0,\"\\n  \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[[23,[\"iconName\"]],\"20px\",\"20px\",\"alert-banner-icon icon--white\"]]],false],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"alert-banner-text\"],[9],[0,\"\\n    \"],[7,\"strong\"],[9],[1,[23,[\"alert\",\"name\"]],false],[10],[0,\" -\\n    \"],[1,[23,[\"alert\",\"description\"]],false],[0,\".\\n\"],[4,\"if\",[[23,[\"alert\",\"link\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"isExternalLink\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"rel\",\"noopener\"],[12,\"href\",[23,[\"alert\",\"link\"]]],[11,\"target\",\"_blank\"],[9],[1,[21,\"linkText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[4,\"link-to\",[[23,[\"alert\",\"link\"]]],[[\"target\"],[\"_blank\"]],{\"statements\":[[1,[21,\"linkText\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"alert-banner-close\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\"],[\"cross\",\"14px\",\"14px\"]]],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"dismissAlert\"]],[23,[\"alert\"]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/app-alerts/app-alert/template.hbs"
    }
  });
  _exports.default = _default;
});