define("tmp-for-all/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var A = Ember.A;
  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return A(serialized);
    },
    serialize: function serialize(deserialized) {
      return deserialized ? deserialized.toArray() : null;
    }
  });
  _exports.default = _default;
});