define("tmp-for-all/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5kT3Gj62",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"login-page\",null,[[\"authenticated\"],[\"authenticated\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/login.hbs"
    }
  });
  _exports.default = _default;
});