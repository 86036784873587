define("tmp-for-all/mixins/routes/conditions-group-edit-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    RSVP = Ember.RSVP;
  var _default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    conditionGroup: computed.alias('controller.model'),
    dataIsDirty: computed.alias('conditionGroup.hasChanges'),
    openModal: function openModal() {
      var _this = this;
      set(this, 'modal.options', {
        title: 'Discard Changes?',
        text: 'You have made unsaved changes to this condition group',
        confirmButton: 'Discard',
        confirmAction: function confirmAction() {
          RSVP.resolve(_this.cleanup()).then(function () {
            _this.get('modal').toggle();
            _this.transitionTo('condition-groups.worksite.list');
          });
        }
      });
      get(this, 'modal').toggle();
    },
    cleanup: function cleanup() {
      return get(this, 'conditionGroup').destroyOrRollback();
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (get(this, 'dataIsDirty')) {
          transition.abort();
          this.openModal(get(this, 'conditionGroup'));
        }
      }
    }
  });
  _exports.default = _default;
});