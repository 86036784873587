define("tmp-for-all/mixins/components/check-existing-user-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    isEmpty = Ember.isEmpty;
  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    disabled: computed.alias('searching'),
    isExistingUser: computed.not('contact.isNew'),
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'askForEmail', true);
      set(this, 'searching', false);
    },
    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (isEmpty(email) || !re.test(email)) {
        get(this, 'flashMessages').danger('Contact email is invalid');
        return false;
      }
      return true;
    },
    actions: {
      searchForEmail: function searchForEmail(email) {
        var _this = this;
        if (!this.validateEmail(email)) {
          return false;
        }
        var cachedUser = get(this, 'store').peekAll('tmp-user').find(function (user) {
          return user.id && get(user, 'email') === email;
        });
        if (cachedUser) {
          set(this, 'contact', cachedUser);
          set(this, 'askForEmail', false);
        } else {
          set(this, 'searching', true);
          get(this, 'store').query('tmp-user', {
            filter: {
              where: {
                email: email
              }
            }
          }).then(function (users) {
            var currentUser = get(_this, 'contact');
            if (isEmpty(users)) {
              // If we have an existing user provided by the route, then set the email here and leave
              if (currentUser && get(currentUser, 'isNew')) {
                set(currentUser, 'email', email);
                return;
              }
              var newContact = get(_this, 'store').createRecord('tmp-user', {
                email: email
              });
              set(_this, 'contact', newContact);
            } else {
              set(_this, 'contact', get(users, 'firstObject'));
            }
          }).finally(function () {
            if (get(_this, 'isDestroying') || get(_this, 'isDestroyed')) return;
            set(_this, 'searching', false);
            set(_this, 'askForEmail', false);
          });
        }
      }
    }
  });
  _exports.default = _default;
});