define("tmp-for-all/utils/diff-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filteredDiffContent = filteredDiffContent;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function capitalise(str) {
    return "".concat(str.charAt(0).toUpperCase()).concat(str.slice(1));
  }
  function formatValue(value, fieldName) {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    // TODO what other date fields could show up here?
    if (['startDate', 'endDate'].includes(fieldName)) {
      return moment(Date.parse(value)).format('DD/MM/YYYY');
    }
    return value;
  }
  function empty(v) {
    return v === null || v === undefined || Array.isArray(v) && v.length === 0;
  }
  function filteredDiffContent(rawDiff) {
    // Older diffs did not have revisionNumbers. This should be able to come out
    // of the code once UAT2 is updated with prod data, because there should be
    // no diffs at all therefore there can't be diffs of the old format.
    if (!rawDiff.revisionNumber) {
      return {};
    }
    var _Object$keys = Object.keys(rawDiff.revisionNumber),
      _Object$keys2 = _slicedToArray(_Object$keys, 2),
      revision1 = _Object$keys2[0],
      revision2 = _Object$keys2[1];
    var out = {};
    R.toPairsIn(rawDiff).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        propName = _ref2[0],
        vals = _ref2[1];
      // If there are keys for the 2 revision numbers then this is not nested
      if (vals[revision1] && vals[revision2]) {
        // pair[0] is the property name, pair[1] is the object with revision values
        var valPairs = R.toPairs(vals).filter(function (v) {
          return !R.isEmpty(v) && !R.isNil(v);
        });
        if (valPairs.length > 1) {
          var v1 = vals[revision1];
          var v2 = vals[revision2];

          // If one of the values is null/undefined, and the other is false,
          // then we won't call that a change
          if (v1 || v2) {
            out[capitalise(propName)] = [formatValue(v1, propName), formatValue(v2, propName)];
          }
        }
      } else {
        var rawNested = vals;
        R.toPairsIn(rawNested).forEach(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
            nestedPropName = _ref4[0],
            nestedVals = _ref4[1];
          if (nestedVals && (!empty(nestedVals[revision1]) || !empty(nestedVals[revision2]))) {
            var _v = nestedVals[revision1];
            var _v2 = nestedVals[revision2];

            // If one of the values is null/undefined, and the other is falsey,
            // then we won't call that a change
            if (_v || _v2) {
              out[capitalise("".concat(propName, ": ").concat(nestedPropName))] = [formatValue(_v, nestedPropName), formatValue(_v2, nestedPropName)];
            }
          }
        });
      }
    });
    return out;
  }
});