define("tmp-for-all/routes/condition-groups/worksite/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('condition-group', {
        conditionType: 'worksite'
      });
    },
    actions: {
      willTransition: function willTransition() {
        var conditionGroup = this.get('controller.model');
        if (conditionGroup) {
          conditionGroup.destroyOrRollback();
        }
      }
    }
  });
  _exports.default = _default;
});