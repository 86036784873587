define("tmp-for-all/utils/conditions-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSortedSelectedConditions = getSortedSelectedConditions;
  _exports.refreshAcceptanceConditions = refreshAcceptanceConditions;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP,
    compare = Ember.compare;
  function getSortedSelectedConditions(eObject, conditionsAttribute) {
    return get(eObject, conditionsAttribute).then(function (acceptanceConditions) {
      return RSVP.all(acceptanceConditions.map(function (acceptanceCondition) {
        return get(acceptanceCondition, 'condition');
      }).toArray().sort(function (a, b) {
        return compare(parseInt(get(a, 'id'), 10), parseInt(get(b, 'id'), 10)); // Necessary because Ember's default sorting is a string sort, so you have issues like [2, 1, 10] => [1, 10, 2]
      }));
    });
  }

  function refreshAcceptanceConditions(eObject, parentType, store) {
    return store.query('acceptance-condition', {
      filter: {
        where: {
          parentId: get(eObject, 'id'),
          parentType: parentType
        }
      }
    }, {
      reload: true
    }).then(function (acs) {
      return set(eObject, 'acceptanceConditions', acs);
    });
  }
});