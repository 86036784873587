define("tmp-for-all/components/search-results/worksites/map/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    observer = Ember.observer,
    set = Ember.set;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    bounds: computed('boundsForAll', 'boundsForSingle', function () {
      return get(this, 'boundsForSingle') || get(this, 'boundsForAll');
    }),
    boundsForSingle: computed('worksiteFocus', function () {
      var focus = get(this, 'worksiteFocus');
      return !focus ? null : L.geoJson(focus).getBounds();
    }),
    boundsForAll: computed('worksiteLocationsFiltered', function () {
      if (!get(this, 'worksiteLocationsFiltered').length) {
        var viewport = _constants.MAP_SETTINGS.DEFAULT[get(this, 'intl.locale')[0].toUpperCase()].VIEWPORT;
        return L.latLngBounds(viewport.SOUTHWEST, viewport.NORTHEAST);
      }
      return L.geoJson(get(this, 'worksiteLocationsFiltered')).getBounds();
    }),
    selectedMapStyle: computed('mapStyle', function () {
      return get(this, 'mapStyle') || _constants.MAP_TILE_OPTIONS.MAPBOX_STREET;
    }),
    mapboxStreetsLayer: computed.equal('selectedMapStyle', _constants.MAP_TILE_OPTIONS.MAPBOX_STREET),
    mapboxSatelliteLayer: computed.equal('selectedMapStyle', _constants.MAP_TILE_OPTIONS.MAPBOX_SATELLITE),
    linzAerialLayer: computed.equal('selectedMapStyle', _constants.MAP_TILE_OPTIONS.LINZ_AERIAL),
    tileLayerOptions: computed('mapboxStreetsLayer', 'mapboxSatelliteLayer', 'linzAerialLayer', 'intl.locale', function () {
      var layerOptions = [{
        name: 'tileLayer',
        value: _constants.MAP_TILE_OPTIONS.MAPBOX_STREET,
        label: 'Mapbox Streets',
        checked: get(this, 'mapboxStreetsLayer')
      }, {
        name: 'tileLayer',
        value: _constants.MAP_TILE_OPTIONS.MAPBOX_SATELLITE,
        label: 'Mapbox Satellite',
        checked: get(this, 'mapboxSatelliteLayer')
      }];
      if (get(this, 'intl.locale')[0] === _constants.LOCALES.EN_NZ) {
        layerOptions.push({
          name: 'tileLayer',
          value: _constants.MAP_TILE_OPTIONS.LINZ_AERIAL,
          label: 'LINZ Aerial',
          checked: get(this, 'linzAerialLayer')
        });
      }
      return layerOptions;
    }),
    mapInstance: null,
    viewModeObserver: observer('viewMode', function () {
      // refresh map when container size changes
      get(this, 'mapInstance').invalidateSize();
    }),
    worksiteLocations: computed.mapBy('worksites', 'location'),
    worksiteLocationsFiltered: computed.filter('worksiteLocations', function (location) {
      return location;
    }),
    actions: {
      mapDidLoad: function mapDidLoad(event) {
        var map = event.target;
        set(this, 'mapInstance', event.target);
        var attribution = L.control.attribution({
          position: 'bottomleft'
        });
        map.addControl(attribution);
      }
    }
  });
  _exports.default = _default;
});