define("tmp-for-all/templates/tmps/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mCf1fD/Y",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Create TMP\"]],{\"statements\":[[0,\"  \"],[1,[27,\"tmp-create\",null,[[\"tmp\",\"createTMP\",\"searchProjects\",\"searchCompanies\"],[[23,[\"model\"]],[27,\"route-action\",[\"createTMP\"],null],[27,\"route-action\",[\"searchProjects\"],null],[27,\"route-action\",[\"searchCompanies\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmps/create.hbs"
    }
  });
  _exports.default = _default;
});