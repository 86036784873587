define("tmp-for-all/components/search-results/user-search-results/list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZTI23kM7",
    "block": "{\"symbols\":[\"org\"],\"statements\":[[7,\"td\"],[11,\"class\",\"user-name\"],[9],[0,\"\\n  \"],[7,\"strong\"],[9],[1,[23,[\"user\",\"fullName\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"user\",\"isArchived\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-grey-dark text-12\"],[9],[0,\"(archived)\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"td\"],[9],[1,[23,[\"user\",\"email\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"company-name\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"user\",\"organisationNamesAndPermissions\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"],[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"      \"],[1,[22,1,[\"permissions\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"stms-number\"],[9],[1,[23,[\"user\",\"stmsNumber\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"links-column\"],[9],[4,\"link-to\",[\"users.edit\",[23,[\"user\",\"id\"]]],null,{\"statements\":[[0,\"Edit\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"if\",[[27,\"await\",[[27,\"permitted\",[\"admin\"],[[\"model\"],[[23,[\"authManager\",\"currentUser\",\"domain\"]]]]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"td\"],[11,\"class\",\"links-column\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"primary-action\"],[9],[0,\"\\n      \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-right\",\"user\"]]],false],[0,\" Switch to\\n    \"],[3,\"action\",[[22,0,[]],[23,[\"becomeUser\"]],[23,[\"user\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/user-search-results/list/item/template.hbs"
    }
  });
  _exports.default = _default;
});