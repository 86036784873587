define("tmp-for-all/components/shared/time-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NPnqPHZo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"submitted\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"message\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  -\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/time-label/template.hbs"
    }
  });
  _exports.default = _default;
});