define("tmp-for-all/routes/planning-map/select-region", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (get(this, 'intl.locale')[0] === _constants.LOCALES.EN_AU) {
        this.transitionTo('planning-map.region', _constants.REGIONS_KEYS.whittlesea);
      }
    },
    model: function model(params) {
      return _constants.REGIONS;
    },
    actions: {
      transitionToRegion: function transitionToRegion(regionName) {
        this.transitionTo('planning-map.region', regionName);
      }
    }
  });
  _exports.default = _default;
});