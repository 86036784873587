define("tmp-for-all/routes/worksite-submission/edit/documentation", ["exports", "tmp-for-all/mixins/routes/edit-worksite/documentation-route-mixin", "tmp-for-all/mixins/routes/edit-doc-block-permission-check"], function (_exports, _documentationRouteMixin, _editDocBlockPermissionCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_editDocBlockPermissionCheck.default, _documentationRouteMixin.default, {
    nextStep: 'worksite-submission.edit.contacts',
    finishedTarget: 'worksite-submission',
    parentRoute: 'worksite-submission.edit'
  });
  _exports.default = _default;
});