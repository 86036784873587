define("tmp-for-all/mixins/routes/modals/new-contact-route", ["exports", "tmp-for-all/mixins/routes/modals/modal-route-base"], function (_exports, _modalRouteBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.NewContactModalMixin = void 0;
  var get = Ember.get;

  /**
   * Mixin to be used with all create new contact modal routes.
   *
   * Extends modal-route-base mixin
   *
   * Must be used with a parent route that provides a saveNewUser action
   */
  var NewContactModalMixin = Ember.Mixin.create(_modalRouteBase.default, {
    templateName: 'modals/add-new-contact-modal',
    actions: {
      select: function select(contact) {
        this.send('saveNewUser', {
          contact: contact,
          replaceWithRoute: get(this, 'parentRouteName')
        });
      }
    }
  });
  _exports.NewContactModalMixin = NewContactModalMixin;
  var _default = Ember.Route.extend(NewContactModalMixin);
  _exports.default = _default;
});