define("tmp-for-all/components/shared/read-only-conditions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3gPWsTtX",
    "block": "{\"symbols\":[\"condition\",\"custom-index\",\"group\",\"index\"],\"statements\":[[4,\"each\",[[23,[\"amendedGroups\"]]],null,{\"statements\":[[4,\"if\",[[22,3,[\"hasConditions\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"shared/read-only-conditions/group\",null,[[\"startNumber\",\"selectedConditions\",\"group\"],[[27,\"start-position-filtered\",[[23,[\"amendedGroups\"]],[22,4,[]],[23,[\"selectedConditions\"]]],null],[23,[\"selectedConditions\"]],[22,3,[\"group\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[3,4]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"filteredConditions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"static-conditions-group panel bg-grey-light margin-btm-10\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"group-label\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"group-name\"],[9],[0,\"Custom Conditions\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredConditions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"static-condition\"],[9],[7,\"strong\"],[9],[1,[27,\"add\",[[22,2,[]],1,[23,[\"selectedConditions\",\"length\"]]],null],false],[0,\". \"],[10],[1,[22,1,[\"text\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/read-only-conditions/template.hbs"
    }
  });
  _exports.default = _default;
});