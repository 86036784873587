define("tmp-for-all/components/search-results/projects/list/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YybG73wQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"project-title\"],[9],[0,\"\\n  \"],[4,\"link-to\",[\"projects.view\",[23,[\"project\",\"id\"]]],null,{\"statements\":[[7,\"strong\"],[9],[1,[21,\"projectTitle\"],false],[10]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"project-summary\"],[11,\"data-th\",\"Summary\"],[9],[1,[23,[\"project\",\"summary\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"project-company\"],[11,\"data-th\",\"Company\"],[9],[1,[21,\"companyName\"],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/projects/list/item/template.hbs"
    }
  });
  _exports.default = _default;
});