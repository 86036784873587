define("tmp-for-all/models/company", ["exports", "ember-data", "tmp-for-all/models/domain"], function (_exports, _emberData, _domain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    hasMany = _emberData.default.hasMany;
  var _Ember = Ember,
    inject = _Ember.inject,
    get = _Ember.get;
  var _default = _domain.default.extend({
    ajax: inject.service(),
    contact: attr('string'),
    isArchived: attr('boolean'),
    name: attr('string'),
    phone: attr('string'),
    physicalAddress: attr('string'),
    postalAddress: attr('string'),
    url: attr('string'),
    nzbn: attr('string'),
    isUtility: attr('boolean'),
    isGovernmentAgency: attr('boolean'),
    isPrivateIndividual: attr('boolean'),
    projects: hasMany('project', {
      async: true
    }),
    tmpUsers: hasMany('tmp-user', {
      async: true
    }),
    adminTeam: hasMany('tmp-user', {
      async: true
    }),
    tmps: hasMany('tmp', {
      async: true,
      inverse: 'company'
    }),
    tmpDrafts: hasMany('tmp-draft', {
      async: true,
      inverse: 'company'
    }),
    tmpSubmissions: hasMany('tmp-submission', {
      async: true,
      inverse: 'company'
    }),
    mergedCompanies: hasMany('mergedCompany', {
      async: true
    }),
    modelName: Ember.computed.alias('constructor.modelName'),
    testMerge: function testMerge(companyToConsume) {
      return get(this, 'ajax').testMergeCompany(get(this, 'id'), get(companyToConsume, 'id'));
    },
    mergeConsume: function mergeConsume(companyToConsume) {
      return get(this, 'ajax').mergeCompanies(get(this, 'id'), get(companyToConsume, 'id'));
    }
  });
  _exports.default = _default;
});