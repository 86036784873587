define("tmp-for-all/components/planning-map/detail-popup/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lBsR3C1h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"popup-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"popup-header\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"popup-header-heading\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"popup-heading-icon popup-heading-icon--loading\"],[9],[0,\"\\n        \"],[1,[27,\"svg-icon\",null,[[\"type\",\"height\",\"width\",\"classNames\"],[\"loading\",\"16px\",\"16px\",\"icon--align-top\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Loading…\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"popup-tab-select clearfix\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"popup-tab-btn thirds\"],[9],[0,\"Overview\"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"popup-tab-btn thirds\"],[9],[0,\"Impacts\"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"popup-tab-btn thirds\"],[9],[0,\"Detours\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"popup-tabs-wrap\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"popup-tab\"],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/planning-map/detail-popup/loading/template.hbs"
    }
  });
  _exports.default = _default;
});