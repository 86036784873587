define("tmp-for-all/templates/worksite/edit/ownership/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mDIxeAwV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Worksite Ownership\"]],{\"statements\":[[0,\"  \"],[1,[27,\"worksite/ownership-form\",null,[[\"model\",\"selectJurisdiction\",\"selectApplicantCompany\",\"selectClientCompany\",\"selectClientContact\",\"selectProject\",\"addNewCompany\",\"addNewUser\",\"addNewProject\",\"searchCompanies\",\"next\",\"saveProgress\",\"saveAndReturn\",\"setUpdateTmps\"],[[23,[\"model\"]],[27,\"route-action\",[\"selectJurisdiction\"],null],[27,\"route-action\",[\"selectApplicantCompany\"],null],[27,\"route-action\",[\"selectClientCompany\"],null],[27,\"route-action\",[\"selectClientContact\"],null],[27,\"route-action\",[\"selectProject\"],null],[27,\"route-action\",[\"addNewCompany\"],null],[27,\"route-action\",[\"addNewUser\"],null],[27,\"route-action\",[\"addNewProject\"],null],[27,\"route-action\",[\"searchCompanies\"],null],[27,\"route-action\",[\"next\"],null],[27,\"route-action\",[\"saveProgress\"],null],[27,\"route-action\",[\"saveAndReturn\"],null],[27,\"route-action\",[\"setUpdateTmps\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite/edit/ownership/index.hbs"
    }
  });
  _exports.default = _default;
});