define("tmp-for-all/components/maps/address-finder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oIo9qMCe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"control-panel-field-label\"],[9],[1,[21,\"labelText\"],false],[10],[0,\"\\n\"],[1,[27,\"autocomplete-input\",null,[[\"classNames\",\"name\",\"results\",\"updateTerm\",\"selectResult\",\"resultName\",\"resultValue\"],[\"control-panel-field-input\",\"address\",[23,[\"results\"]],[27,\"action\",[[22,0,[]],[23,[\"updateTerm\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"selectResult\"]]],null],\"address\",\"address\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/address-finder/template.hbs"
    }
  });
  _exports.default = _default;
});