define("tmp-for-all/routes/tmps/drafts", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/mixins/routes/tmp-search-route-mixin", "tmp-for-all/constants"], function (_exports, _routeMixin, _tmpSearchRouteMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    RSVP = Ember.RSVP,
    get = Ember.get;
  var _default = Ember.Route.extend(_routeMixin.default, _tmpSearchRouteMixin.default, {
    queryFilter: computed.alias('model.queryFilter'),
    tmps: computed.alias('model.tmps'),
    authManager: Ember.inject.service('session'),
    queryParams: {
      page: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (user) {
        return get(user, 'selectedDomain');
      }).then(function (domain) {
        if (domain === 'jurisdiction') {
          get(_this, 'flashMessages').danger('Must be operating under a company');
          return _this.transitionTo('index');
        }
        return true;
      });
    },
    model: function model(params) {
      var _this2 = this;
      return get(this, 'authManager.currentUser').then(function (user) {
        // company_id is a special param for a serverside join
        params.company_id = get(user, 'selectedDomainId');
        return {
          order: params.order ? params.order : 'createdAt DESC',
          where: {
            type: _constants.TMP_TYPES.DRAFT
          }
        };
      }).then(function (filter) {
        return RSVP.hash({
          tmps: _this2.findPaged('tmp-search-result', R.merge(params, {
            filter: filter
          })),
          queryFilter: filter
        });
      }).catch(function (err) {
        Raygun.send(new Error(err));
        _this2.replaceWith('index');
      });
    }
  });
  _exports.default = _default;
});