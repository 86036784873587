define("tmp-for-all/components/shared/add-new-contact/component", ["exports", "tmp-for-all/mixins/components/check-existing-user-mixin"], function (_exports, _checkExistingUserMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
    get = Ember.get;
  var _default = Ember.Component.extend(_checkExistingUserMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'contact', null);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var contact = get(this, 'contact');
      if (contact) {
        contact.destroyOrRollback();
      }
    }
  });
  _exports.default = _default;
});