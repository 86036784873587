define("tmp-for-all/components/shared/project-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /*
      Tag omitted to ensure flexibility in regards to being able to slot this info
      into multiple different layouts. Any layout styling should be applied to a
      wrapper class outside of this component if needed.
    */
    tagName: ''
  });
  _exports.default = _default;
});