define("tmp-for-all/components/shared/form/select-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['element-wrap'],
    inputID: computed('elementId', function () {
      return "input-".concat(this.get('elementId'));
    }),
    placeholderOption: computed(function () {
      return true;
    }),
    cssClasses: computed('class', function () {
      return 'ember-power-select ' + get(this, 'class');
    })
  });
  _exports.default = _default;
});