define("tmp-for-all/templates/worksites/create/wizard/documentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4sU0yU/m",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Worksite Documentation\"]],{\"statements\":[[0,\"  \"],[1,[27,\"worksite/documentation-form\",null,[[\"worksite\",\"inWizard\",\"addDocBlock\",\"deleteDocBlock\",\"restoreDocBlock\",\"next\",\"finish\"],[[23,[\"model\"]],true,[27,\"route-action\",[\"addDocBlock\"],null],[27,\"route-action\",[\"deleteDocBlock\"],null],[27,\"route-action\",[\"restoreDocBlock\"],null],[27,\"route-action\",[\"next\"],null],[27,\"route-action\",[\"finish\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksites/create/wizard/documentation.hbs"
    }
  });
  _exports.default = _default;
});