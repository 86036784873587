define("tmp-for-all/controllers/deployments/search", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin", "tmp-for-all/mixins/controllers/list-order-controller-mixin", "tmp-for-all/utils/search-filter", "tmp-for-all/utils/deployment-filters"], function (_exports, _listPaginationControllerMixin, _listOrderControllerMixin, _searchFilter, _deploymentFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var searchFilters = _deploymentFilters.default.searchFilters;
  var _Ember = Ember,
    set = _Ember.set,
    computed = _Ember.computed;
  var SearchController = Ember.Controller.extend(_listPaginationControllerMixin.default, _listOrderControllerMixin.default, {
    filterTypes: searchFilters,
    queryParams: R.concat(_searchFilter.default.getFilterQueryParams(searchFilters), ['status', 'freetext', 'fromDate', 'toDate', 'stmsUserId', 'showCompleted']),
    queryFilter: computed.alias('model.queryFilter'),
    status: null,
    worksiteReference: null,
    freetext: null,
    fromDate: null,
    toDate: null,
    showCompleted: false,
    days: [{
      value: -1,
      name: ''
    }, {
      value: 0,
      name: 'Today'
    }, {
      value: 1,
      name: 'Tomorrow'
    }],
    actions: {
      // pass the controller to the component for updating the query params
      updateFilterParams: function updateFilterParams(func, params) {
        set(this, 'page', 1);
        func(this, params);
      },
      setFreetext: function setFreetext(freetext) {
        set(this, 'page', 1);
        set(this, 'freetext', freetext);
      },
      setStatus: function setStatus(status) {
        set(this, 'status', status);
        set(this, 'page', 1);
      },
      dayDidChange: function dayDidChange(day) {
        set(this, 'selectedDay', day);
        if (day.value === -1) {
          set(this, 'fromDate', null);
          set(this, 'toDate', null);
        } else {
          var from = moment().startOf('day').add(day.value, 'days');
          var to = moment().startOf('day').add(day.value + 1, 'days');
          // NOTE: moment().toISOString() always converts to UTC
          set(this, 'fromDate', from.toISOString());
          set(this, 'toDate', to.toISOString());
        }
        set(this, 'page', 1);
      },
      fromDateDidChange: function fromDateDidChange(date) {
        var newDate = date ? moment(date).startOf('day').toISOString() : null;
        set(this, 'selectedDay', null);
        set(this, 'fromDate', newDate);
        set(this, 'page', 1);
      },
      toDateDidChange: function toDateDidChange(date) {
        var newDate = date ? date.toISOString() : null;
        set(this, 'selectedDay', null);
        set(this, 'toDate', newDate);
        set(this, 'page', 1);
      },
      showCompletedDidChange: function showCompletedDidChange(checked) {
        set(this, 'showCompleted', checked);
      }
    }
  });
  SearchController.reopen(_searchFilter.default.getParamsAssignments(searchFilters));
  var _default = SearchController;
  _exports.default = _default;
});