define("tmp-for-all/models/deployment-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var get = Ember.get;
  var _default = Model.extend({
    ajax: Ember.inject.service(),
    tmpId: attr('number'),
    tmpCode: attr('string'),
    startDate: attr('date'),
    endDate: attr('date'),
    jurisdictionId: attr('number'),
    firstSecondaryRcaId: attr('number'),
    worksiteName: attr('string'),
    preparationIn: attr('string'),
    actualSignIn: attr('date'),
    trafficControlIn: attr('string'),
    trafficControlOut: attr('string'),
    siteCleared: attr('string'),
    actualSiteCleared: attr('date'),
    layoutId: attr('number'),
    layoutCode: attr('string'),
    layoutCategory: attr('string'),
    layoutDescription: attr('string'),
    contractor: attr('string'),
    stmsName: attr('string'),
    stmsMobile: attr('string'),
    stmsUserId: attr('number'),
    status: attr('string'),
    firstDetourSteps: attr('array'),
    firstDetourDistance: attr('number'),
    firstDetourTravelTime: attr('number'),
    secondDetourSteps: attr('array'),
    secondDetourDistance: attr('number'),
    secondDetourTravelTime: attr('number'),
    changeState: function changeState(action) {
      return get(this, 'ajax').deploymentAction(get(this, 'id'), action);
    }
  });
  _exports.default = _default;
});