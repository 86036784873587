define("tmp-for-all/components/shared/sortable-column-heading/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: 'th',
    classNameBindings: ['sortable:sortable-column-heading', 'selected', 'ascending:ascending:descending'],
    sortName: computed.alias('heading.sortName'),
    sortable: computed.bool('sortName'),
    selected: computed('orderByName', 'sortName', function () {
      return get(this, 'orderByName') === get(this, 'sortName');
    }),
    isAscending: computed.equal('orderByDirection', 'ASC'),
    ascending: computed.and('isAscending', 'selected'),
    actions: {
      updateSort: function updateSort() {
        var newDirection = get(this, 'ascending') ? 'DESC' : 'ASC';
        var orderStatement = "".concat(get(this, 'sortName'), " ").concat(newDirection);
        get(this, 'updateOrder')(orderStatement);
      }
    }
  });
  _exports.default = _default;
});