define("tmp-for-all/components/shared/app-alerts/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    observer = Ember.observer;
  var _default = Ember.Component.extend({
    classNames: ['alert-banners'],
    alertService: Ember.inject.service('alerts'),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    isAuthenticated: computed.readOnly('session.isAuthenticated'),
    start: Ember.on('init', observer('isAuthenticated', function () {
      var _this = this;
      if (get(this, 'isAuthenticated')) {
        get(this, 'alertService').start(function (alerts) {
          Ember.run(function () {
            set(_this, 'alerts', alerts);
          });
        });
      }
    })),
    actions: {
      dismissAlert: function dismissAlert(alert) {
        var _this2 = this;
        get(this, 'session.currentUser').then(function (currentUser) {
          var viewedAlert = get(_this2, 'store').createRecord('viewed-alert', {
            tmpUser: currentUser,
            alert: alert
          });
          return viewedAlert.save().then(function () {
            get(_this2, 'alerts').removeObject(alert);
          });
        }).catch(function () {
          get(_this2, 'flashMessages').danger('Error: unable to remove alert at this time, please try again later');
        });
      }
    }
  });
  _exports.default = _default;
});