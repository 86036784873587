define("tmp-for-all/models/feature-flag", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _default = _emberData.default.Model.extend({
    flagName: attr('string'),
    isEnabled: attr('boolean'),
    domainType: attr('string'),
    domainId: attr('number')
  });
  _exports.default = _default;
});