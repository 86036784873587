define("tmp-for-all/components/worksite/details-form/time-of-day/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var convertHourNumberToTimes = function convertHourNumberToTimes(hourNum) {
    var minutes = ['00', '30'];
    var hour = hourNum < 10 ? '0' + String(hourNum) : String(hourNum);
    return minutes.map(function (min) {
      return "".concat(hour, ":").concat(min);
    });
  };
  var _default = Ember.Component.extend({
    availableTimes: function () {
      return R.pipe(R.range, R.map(convertHourNumberToTimes), R.flatten, R.concat(['']))(0, 24);
    }()
  });
  _exports.default = _default;
});