define("tmp-for-all/mixins/controllers/list-jurisdiction-area-controller-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.Mixin.create({
    queryParams: ['jurisdictionAreaName'],
    jurisdictionAreaName: null,
    actions: {
      setJurisdictionArea: function setJurisdictionArea(jurisdictionArea) {
        var jurisdictionAreaName = jurisdictionArea ? get(jurisdictionArea, 'name') : null;
        set(this, 'jurisdictionAreaName', jurisdictionAreaName);
      }
    }
  });
  _exports.default = _default;
});