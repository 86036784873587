define("tmp-for-all/utils/detour-functions", ["exports", "tmp-for-all/config/environment", "tmp-for-all/constants"], function (_exports, _environment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.clashOptions = clashOptions;
  _exports.detourMarkerOptions = detourMarkerOptions;
  _exports.generateDetourMarkers = generateDetourMarkers;
  _exports.generateRoutingControl = generateRoutingControl;
  _exports.geoJsonFromCoordinates = geoJsonFromCoordinates;
  _exports.getLaneOffset = getLaneOffset;
  _exports.getStyleFor = getStyleFor;
  _exports.hideLayersPanelForMobile = hideLayersPanelForMobile;
  _exports.removeWaypointArrivals = removeWaypointArrivals;
  var get = Ember.get,
    set = Ember.set,
    $ = Ember.$;
  function getLaneOffset(road) {
    return metresToDegrees(getLaneMetresOffset(road));
  }
  function getLaneMetresOffset(road) {
    if (road.properties.oneway) {
      return 0;
    }
    var laneWidth = 3.5;
    var lanes = road.properties.lanes || 2;
    var lanesPerSide = lanes / 2;
    return laneWidth * lanesPerSide / 2;
  }
  function metresToDegrees(metres) {
    var circumference = 40040000; // Approximate globe circumference
    var metresPerDegree = circumference / 360;
    var oneMetreInDegrees = 1 / metresPerDegree;
    return oneMetreInDegrees * metres;
  }

  // Functionality pulled out of roady.js for use in detours
  function getStyleFor(impact) {
    var defaultStyleConfig = {
      open: {
        color: '#00BD00',
        // green
        lineCap: 'square'
      },
      reduced: {
        color: '#FF7000' // orange
      },

      closed: {
        color: '#000000' // black
      },

      directionPriority: {
        color: '#1C3585' // blue
      },

      stopGo: {
        color: '#961408' // red
      }
    };

    function getImpactStyles(impact) {
      var styles = {};
      var config = defaultStyleConfig;
      if (impact.laneDrop) {
        if (impact.laneDrop.lanes) {
          Object.keys(impact.laneDrop.lanes).forEach(function (direction) {
            var lanes = impact.laneDrop.lanes[direction];
            var fullClosure = !hasImpactType(lanes, 'open') && !hasImpactType(lanes, 'reduced');
            if (fullClosure) {
              styles[direction] = config.closed;
            } else if (hasImpactType(lanes, 'reduced') || hasImpactType(lanes, 'closed')) {
              styles[direction] = config.reduced;
            } else {
              styles[direction] = config.open;
            }
          });
        }
      } else {
        var impactType = Object.keys(impact)[0];
        styles.against = styles.with = config[impactType];
      }
      return styles;
    }
    function hasImpactType(array, impactType) {
      return array.indexOf(impactType) >= 0;
    }
    function getBaseStyle() {
      return Object.assign({}, defaultStyleConfig.open);
    }
    var styles = getImpactStyles(impact);
    var styleFn = function styleFn(feature) {
      var direction = feature.properties.direction || 'with';
      return Object.assign(getBaseStyle(), styles[direction]);
    };
    return styleFn;
  }

  /**
   * Generates a leaflet-routing-machine control for use on a layout.  This is what allows automatic calculation of routes
   * and route information when dragging waypoints for a given detour.
   *
   * @param {boolean} isReadonly - Used to determine whether to make the detour waypoints addable and draggable
   * @param {Leaflet} map - Instance of leaflet used for display
   * @param {string} detourModelName - Used as a parameter for the routesFoundCallback function parameter
   * @param {function} routesFoundCallback - Bound function to call when the routesfound event fires
   * @param {array} initialWayPoints - Array of initial waypoints to use when generating the routing control.  This should be an array of two leaflet latLng objects marking the start and end points.
   * @param {Detour} existingModel - Existing detour model to use for the initial waypoints if one exists already
   * @returns {L.Routing.control}
   */
  function generateRoutingControl(isReadonly, map, detourModelName, routesFoundCallback, initialWayPoints, existingModel) {
    function chooseDetourIcon(index, total) {
      if (index === 0) {
        return L.icon({
          iconUrl: '/assets/images/impact-editor/marker-a.png',
          iconSize: [35, 42],
          iconAnchor: [16, 42]
        });
      } else if (index === total - 1) {
        return L.icon({
          iconUrl: '/assets/images/impact-editor/marker-b.png',
          iconSize: [35, 42],
          iconAnchor: [16, 42]
        });
      } else {
        return L.icon({
          iconUrl: '/assets/images/impact-editor/marker-icon-clear.png',
          iconSize: [16, 16],
          iconAnchor: [8, 8]
        });
      }
    }

    // Can be used to adjust the position of markers on the map pixel by pixel
    function adjustLatLngPosition(map, latLng) {
      var point = map.latLngToContainerPoint(latLng);
      var newPoint = L.point([point.x, point.y]);
      return map.containerPointToLatLng(newPoint);
    }
    function detourModelLatLngs(detour) {
      if (detour) {
        var waypoints = get(detour, 'waypoints');
        if (waypoints && waypoints.length) {
          var mapped = waypoints.map(function (loc) {
            return L.latLng([loc.lat, loc.lng]);
          });
          return mapped;
        }
      }
      return null;
    }
    var existingLatLngs = detourModelLatLngs(existingModel);
    var control = L.Routing.control({
      serviceUrl: _environment.default.osrmServiceUrl,
      waypointMode: 'snap',
      showAlternatives: false,
      fitSelectedRoutes: false,
      lineOptions: {
        addWaypoints: !isReadonly,
        styles: [
        /**
         * First line used to increase hit area when adding
         * a waypoint to the route.
         */
        {
          color: 'transparent',
          weight: 20
        }, {
          color: '#2B00FF',
          opacity: 0.8,
          weight: 10
        }]
      },
      plan: L.Routing.plan(existingLatLngs || initialWayPoints || [], {
        /**
         * Setting these to transparent until we can get
         * routeWhileDragging option working a bit quicker.
         */
        dragStyles: [{
          color: 'transparent',
          opacity: 0.8,
          weight: 4
        }, {
          color: 'transparent',
          opacity: 0.8,
          weight: 2,
          dashArray: '5, 5'
        }],
        draggableWaypoints: !isReadonly,
        createMarker: function createMarker(i, wp, n) {
          var icon = chooseDetourIcon(i, n);
          if (icon) {
            var adjustedLatLng = adjustLatLngPosition(map, wp.latLng);
            return L.marker(adjustedLatLng, {
              draggable: !isReadonly,
              icon: icon
            });
          }
          return null;
        }
      })
    }).on('routesfound', function (e) {
      routesFoundCallback(e, detourModelName);
    }).addTo(map);
    return control;
  }
  function hideLayersPanelForMobile(component, layerVisibleVar) {
    if ($(window).width() < 768) {
      set(component, layerVisibleVar, false);
    }
  }
  function geoJsonFromCoordinates(coordinates) {
    if (!coordinates) return;
    return {
      type: 'LineString',
      coordinates: coordinates.map(function (loc) {
        return [loc.lng, loc.lat];
      })
    };
  }

  /**
   * Remove intermediary waypoint marker steps like "You have arrived at your 1st destination" to reduce detour description clutter
   */
  function removeWaypointArrivals(steps) {
    if (!steps || !steps.length) return steps;
    return steps.filter(function (s, i) {
      return /^(?!You\shave\sarrived).+$/g.test(s.text) || i === steps.length - 1;
    });
  }
  function generateDetourMarkers(data) {
    var detourMarkers = data.filter(function (d) {
      return d.type === 'detour';
    }).map(function (d) {
      var markers = [d.geometry.geometry.coordinates[0], d.geometry.geometry.coordinates[d.geometry.geometry.coordinates.length - 1]];
      return markers.map(function (marker, i) {
        return {
          type: 'Point',
          coordinates: marker,
          properties: {
            iconPath: "/assets/images/impact-editor/marker-".concat(i === 0 ? 'a' : 'b', "-grey.png")
          }
        };
      });
    });
    return [].concat.apply([], detourMarkers); // flatten
  }

  function detourMarkerOptions() {
    return {
      pointToLayer: function pointToLayer(geoJson, latlng) {
        return L.marker(latlng, {
          zIndexOffset: -5,
          icon: L.icon({
            iconUrl: geoJson.properties.iconPath,
            iconSize: [25, 30],
            iconAnchor: [12, 30]
          })
        });
      }
    };
  }
  function clashOptions(type, impacttype) {
    return {
      stroke: true,
      opacity: type === 'worksite' ? 0.3 : 0.8,
      fill: type === 'worksite',
      fillOpacity: 0.1,
      weight: 2,
      color: _constants.MAP_OBJECT_COLORS[impacttype] || _constants.MAP_OBJECT_COLORS[type]
    };
  }
});