define("tmp-for-all/components/app-nav/user-entity-selection/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0B0B41jA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"user-role-link t-entity-text\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"user-role-text\"],[9],[1,[21,\"text\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isSelected\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"tick\",\"10px\",\"10px\",\"t-checked-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[3,\"action\",[[22,0,[]],\"selectEntity\",[23,[\"id\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/app-nav/user-entity-selection/template.hbs"
    }
  });
  _exports.default = _default;
});