define("tmp-for-all/controllers/reports/billing", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin"], function (_exports, _listPaginationControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global moment */
  var _default = Ember.Controller.extend(_listPaginationControllerMixin.default, {
    ajax: Ember.inject.service(),
    queryParams: ['companyId', 'fromDate', 'toDate', 'showOnly', 'carStatus', 'relatedPartiesOnly', 'feeIsBillable', 'minFeeValue'],
    companyId: null,
    fromDate: moment().startOf('day').toISOString(),
    toDate: moment().endOf('day').toISOString(),
    showOnly: null,
    results: Ember.computed.alias('model.results'),
    selectedBillingItems: Ember.computed.filterBy('results', 'isSelected', true),
    selectedBillingItemIds: Ember.computed.mapBy('selectedBillingItems', 'id'),
    carStatus: null,
    allFeesSelected: false,
    relatedPartiesOnly: false,
    missingCustomerNumbersOnly: false,
    feeIsBillable: false,
    minFeeValue: 0,
    actions: {
      carStatusDidChange: function carStatusDidChange(carStatus) {
        Ember.set(this, 'model.carStatus', carStatus);
        Ember.set(this, 'carStatus', carStatus ? carStatus.key : null);
        Ember.set(this, 'page', 1);
        Ember.set(this, 'allFeesSelected', false);
      },
      showOnlyDidChange: function showOnlyDidChange(showOnly) {
        Ember.set(this, 'model.showOnly', showOnly);
        Ember.set(this, 'showOnly', showOnly ? showOnly.key : null);
        Ember.set(this, 'page', 1);
        Ember.set(this, 'allFeesSelected', false);
      },
      companyDidChange: function companyDidChange(company) {
        Ember.set(this, 'model.company', company);
        Ember.set(this, 'companyId', company ? company.id : null);
        Ember.set(this, 'page', 1);
        Ember.set(this, 'allFeesSelected', false);
      },
      fromDateDidChange: function fromDateDidChange(date) {
        var newDate = date ? moment(date).startOf('day').toISOString() : moment();
        Ember.set(this, 'fromDate', newDate);
        Ember.set(this, 'page', 1);
        Ember.set(this, 'allFeesSelected', false);
      },
      toDateDidChange: function toDateDidChange(date) {
        var newDate = date ? moment(date).endOf('day').toISOString() : moment();
        Ember.set(this, 'toDate', newDate);
        Ember.set(this, 'page', 1);
        Ember.set(this, 'allFeesSelected', false);
      },
      relatedPartiesOnlyDidChange: function relatedPartiesOnlyDidChange(bool) {
        Ember.set(this, 'relatedPartiesOnly', bool);
      },
      missingCustomerNumbersOnlyDidChange: function missingCustomerNumbersOnlyDidChange(bool) {
        Ember.set(this, 'missingCustomerNumbersOnly', bool);
      },
      feeIsBillableDidChange: function feeIsBillableDidChange(bool) {
        Ember.set(this, 'feeIsBillable', bool);
      },
      resetFilter: function resetFilter() {
        var _this = this;
        Ember.setProperties(this, {
          company: null,
          rca: null,
          fromDate: moment().startOf('day').toISOString(),
          toDate: moment().endOf('day').toISOString(),
          showOnly: null,
          allFeesSelected: false,
          minFeeValue: 0
        });
        Ember.get(this, 'queryParams').forEach(function (param) {
          if (param === 'perPage') return;
          if (param === 'page') return Ember.set(_this, 'page', 1);
          if (param === 'fromDate') return Ember.set(_this, 'fromDate', moment().startOf('day').toISOString());
          if (param === 'toDate') return Ember.set(_this, 'toDate', moment().endOf('day').toISOString());
          if (param === 'relatedPartiesOnly') return Ember.set(_this, 'relatedPartiesOnly', false);
          if (param === 'feeIsBillable') return Ember.set(_this, 'feeIsBillable', false);
          if (param === 'minFeeValue') return Ember.set(_this, 'minFeeValue', 0);
          return Ember.set(_this, param, null);
        });
      },
      selectAllBillingItems: function selectAllBillingItems(isSelected) {
        Ember.get(this, 'results').setEach('isSelected', isSelected);
      },
      markSelectedAsInvoiced: function markSelectedAsInvoiced(isInvoiced) {
        var _this2 = this;
        var worksiteFeeIds = Ember.get(this, 'selectedBillingItemIds');
        var queryFilter = Ember.get(this, 'model.queryFilter');
        var filter = {
          where: {
            id: {
              inq: worksiteFeeIds
            }
          }
        };
        if (Ember.get(this, 'allFeesSelected')) {
          filter = queryFilter;
        }
        filter = encodeURIComponent(JSON.stringify(filter));
        return Ember.get(this, 'ajax').updateWorksiteFeesStatus(filter, isInvoiced).then(function () {
          return _this2.store.unloadAll('worksite-fee');
        }).then(function () {
          return _this2.send('refreshModel');
        }).then(function () {
          var msg = "Fees successfully marked as '".concat(isInvoiced ? 'Invoiced' : 'Not Invoiced', "'");
          Ember.get(_this2, 'flashMessages').success(msg);
        }).catch(function () {
          var msg = "There was a problem marking fees as '".concat(isInvoiced ? 'Invoiced' : 'Not Invoiced', "'");
          Ember.get(_this2, 'flashMessages').danger(msg);
        });
      },
      deleteWorksiteFees: function deleteWorksiteFees() {
        var _this3 = this;
        var worksiteFeeIds = Ember.get(this, 'selectedBillingItemIds');
        var queryFilter = Ember.get(this, 'model.queryFilter');
        var filter = {
          where: {
            id: {
              inq: worksiteFeeIds
            }
          }
        };
        if (Ember.get(this, 'allFeesSelected')) {
          filter = queryFilter;
        }
        filter = encodeURIComponent(JSON.stringify(filter));
        return Ember.get(this, 'ajax').deleteWorksiteFees(filter).then(function () {
          return _this3.store.unloadAll('worksite-fee');
        }).then(function () {
          return _this3.send('refreshModel');
        }).then(function () {
          Ember.get(_this3, 'flashMessages').success('Fees successfully marked as \'Deleted\'');
        }).catch(function () {
          Ember.get(_this3, 'flashMessages').danger('There was a problem marking fees as \'Deleted\'');
        });
      },
      selectFee: function selectFee(fee, value) {
        if (!value) {
          Ember.set(this, 'allFeesSelected', false);
        }
        Ember.set(fee, 'isSelected', value);
      },
      selectAllFees: function selectAllFees() {
        Ember.set(this, 'allFeesSelected', true);
      },
      unselectAllFees: function unselectAllFees() {
        Ember.set(this, 'allFeesSelected', false);
      }
    }
  });
  _exports.default = _default;
});