define("tmp-for-all/routes/login", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var sessionStore = _constants.BrowserSession.sessionStore,
    returnPathKey = _constants.BrowserSession.returnPathKey;
  var _default = Ember.UnprotectedRoute.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!get(this, 'session.isAuthenticated') && sessionStore) {
        var attemptedTransition = get(this, 'session.attemptedTransition');
        if (attemptedTransition) {
          sessionStore.setItem(returnPathKey, attemptedTransition.intent.url);
        }
      }
    }
  });
  _exports.default = _default;
});