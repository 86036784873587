define("tmp-for-all/components/condition-group-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    archiveButtonText: computed('group.isArchived', function () {
      return this.get('group.isArchived') ? 'Unarchive' : 'Archive';
    }),
    actions: {
      toggleArchiveState: function toggleArchiveState() {
        this.toggleProperty('group.isArchived');
        this.saveConditionGroup(this.group, this.actionName);
      }
    }
  });
  _exports.default = _default;
});