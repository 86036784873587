define("tmp-for-all/components/modal-wrapper/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      closeModal: function closeModal() {
        this.onClose();
      }
    }
  });
  _exports.default = _default;
});