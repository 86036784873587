define("tmp-for-all/models/worksite-fee-search-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _emberData.default.Model.extend({
    worksiteNumber: attr('number'),
    worksiteName: attr('string'),
    worksiteAddress: attr('string'),
    worksiteReference: attr('string'),
    feeId: attr('number'),
    feeCreatedAt: attr('date'),
    feeItemCode: attr('string'),
    feeName: attr('string'),
    feeDescription: attr('string'),
    feeValue: attr('number'),
    feeStatus: attr('string'),
    feeBillable: attr('string'),
    companyId: attr('number'),
    companyName: attr('string'),
    customerNumber: attr('string'),
    jurisdictionId: attr('number'),
    jurisdictionAreaName: attr('string'),
    worksiteType: attr('string'),
    carStatus: attr('string'),
    worksiteAssigneeId: attr('number'),
    feeMarkedAsToBeCheckedAt: attr('date'),
    feeMarkedAsReadyToInvoiceAt: attr('date'),
    feeMarkedAsInvoicedAt: attr('date'),
    isInvoicePriorToApplicationApproval: attr('boolean'),
    isRelatedParty: attr('boolean'),
    editFeePath: Ember.computed('worksiteType', function () {
      var type = Ember.String.dasherize(Ember.get(this, 'worksiteType'));
      // if the type is draft, that means it's a revision since true drafts cannot have fees yet and should be linked to the accepted worksite fees
      return type === 'worksite-draft' ? 'worksite.edit.fees' : "".concat(type, ".edit.fees");
    }),
    worksitePath: Ember.computed('worksiteType', function () {
      var type = Ember.String.dasherize(Ember.get(this, 'worksiteType'));
      // if the type is draft, that means it's a revision since true drafts cannot have fees yet and should be linked to the accepted worksite fees
      return type === 'worksite-draft' ? 'worksite' : type;
    }),
    vehicleCrossingPresent: attr('boolean')
  });
  _exports.default = _default;
});