define("tmp-for-all/components/worksite-view/display-fees/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['margin-btm-50', 'fee-display-section'],
    feeTotal: computed('fees.[]', 'fees.@each.price', function () {
      var lineTotals = get(this, 'fees').map(function (fee) {
        return get(fee, 'price');
      });
      return lineTotals.length ? lineTotals.reduce(function (a, b) {
        return a + b;
      }) : ' - ';
    }),
    hasWorksiteFees: computed.notEmpty('fees')
  });
  _exports.default = _default;
});