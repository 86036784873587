define("tmp-for-all/templates/company-admin/invite-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lIgg70FC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Invite User\"]],{\"statements\":[[0,\"  \"],[1,[27,\"invite-user\",null,[[\"user\",\"inviteUser\",\"searchCompanies\",\"selectedDomain\",\"selectedDomainId\",\"companies\",\"jurisdictions\",\"roles\",\"isSuperUser\",\"addRole\"],[[23,[\"model\",\"user\"]],[27,\"route-action\",[\"saveUser\"],null],[27,\"route-action\",[\"searchCompanies\"],null],[23,[\"model\",\"selectedDomain\"]],[23,[\"model\",\"selectedDomainId\"]],[23,[\"model\",\"companies\"]],[23,[\"model\",\"jurisdictions\"]],[23,[\"model\",\"roles\"]],[23,[\"model\",\"isSuperUser\"]],[27,\"route-action\",[\"addRole\"],null]]]],false],[0,\"\\n  \"],[1,[21,\"message\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/company-admin/invite-user.hbs"
    }
  });
  _exports.default = _default;
});