define("tmp-for-all/components/search-results/organisations/list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    authManager: Ember.inject.service('session'),
    showArchived: false,
    inputID: computed('elementId', function () {
      return "input-".concat(this.get('elementId'));
    }),
    isSuperUser: computed.alias('authManager.currentUser.isSuperUser'),
    isRcaUser: computed.equal('authManager.currentUser.selectedDomain', 'jurisdiction'),
    userJurisdictionId: computed.alias('authManager.currentUser.selectedDomainId'),
    uniqueCompanies: computed('companies', function () {
      var results = get(this, 'companies').uniqBy('id');
      return results;
    })
  });
  _exports.default = _default;
});