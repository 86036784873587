define("tmp-for-all/helpers/document-block-name", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.documentBlockName = documentBlockName;
  function documentBlockName(params) {
    return _constants.DOC_TYPES[params[0]];
  }
  var _default = Ember.Helper.helper(documentBlockName);
  _exports.default = _default;
});