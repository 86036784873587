define("tmp-for-all/services/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    show: false,
    toggle: function toggle() {
      this.toggleProperty('show');
    }
  });
  _exports.default = _default;
});