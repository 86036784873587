define("tmp-for-all/routes/users/list", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend(_routeMixin.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      },
      showArchived: {
        refreshModel: true
      },
      orgType: {
        refreshModel: true
      }
    },
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization').checkAllowableNavigation(this, 'admin').catch(function () {
        return _this.replaceWith('index');
      });
    },
    model: function model(params) {
      params.filter = {
        where: {},
        order: 'fullname asc'
      };
      if (params.search) {
        params.filter.iLikeOr = {
          fullName: "%".concat(params.search, "%"),
          email: "%".concat(params.search, "%"),
          companyNamesAndPermissions: "%".concat(params.search, "%"),
          jurisdictionNamesAndPermissions: "%".concat(params.search, "%")
        };
        delete params.search;
      }
      if (!params.showArchived || params.showArchived === 'false') {
        if (!params.filter.where.and) {
          params.filter.where.and = [];
        }
        params.filter.where.and = [{
          isArchived: false
        }];
      }
      this.addOrgTypeFilter(params, params.filter.where);
      delete params.showArchived;
      params.perPage = 20; // overriding the environment config itemsPerPage value
      return this.findPaged('user-search-result', params);
    },
    addOrgTypeFilter: function addOrgTypeFilter(params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.orgType) {
        switch (params.orgType) {
          case 'organisation':
            whereFilter.and.push({
              hasPlainOrganisation: true
            });
            break;
          case 'utility':
            whereFilter.and.push({
              hasUtilityOrganisation: true
            });
            break;
          case 'private-individual':
            whereFilter.and.push({
              hasPrivateIndividualOrganisation: true
            });
            break;
          case 'government-agency':
            whereFilter.and.push({
              hasGovernmentAgencyOrganisation: true
            });
            break;
          case 'jurisdiction':
            whereFilter.and.push({
              hasJurisdiction: true
            });
            break;
        }
      }
      delete params.orgType;
    },
    actions: {
      becomeUser: function becomeUser(user) {
        this.get('session').authenticate('authenticator:superuser', user.id).then(function () {
          window.location.reload();
        });
      }
    }
  });
  _exports.default = _default;
});