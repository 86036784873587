define("tmp-for-all/controllers/tmps/drafts", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin", "tmp-for-all/mixins/controllers/list-order-controller-mixin"], function (_exports, _listPaginationControllerMixin, _listOrderControllerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set;
  var _default = Ember.Controller.extend(_listPaginationControllerMixin.default, _listOrderControllerMixin.default, {
    queryParams: ['worksiteReference'],
    worksiteReference: null,
    actions: {
      setWorksiteReference: function setWorksiteReference(worksiteReference) {
        set(this, 'worksiteReference', worksiteReference);
        set(this, 'page', 1);
      }
    }
  });
  _exports.default = _default;
});