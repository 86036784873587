define("tmp-for-all/mixins/routes/layout-map-readonly-mixin", ["exports", "tmp-for-all/constants", "tmp-for-all/utils/route-helpers", "tmp-for-all/mixins/routes/get-tooltip-context-mixin", "ember-copy"], function (_exports, _constants, _routeHelpers, _getTooltipContextMixin, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;
  var _default = Ember.Mixin.create(_getTooltipContextMixin.default, {
    ajax: Ember.inject.service(),
    model: function model() {
      var parentRouteName = (0, _routeHelpers.getParentRouteName)(get(this, 'routeName'));
      var isSingleLayout = parentRouteName.includes('layout');
      var layout, worksite;
      if (isSingleLayout) {
        layout = this.modelFor(parentRouteName);
        worksite = this.modelFor((0, _routeHelpers.getParentRouteName)(parentRouteName)).get('latestWorksite');
      } else {
        layout = this.modelFor(parentRouteName).get('layouts');
        worksite = this.modelFor(parentRouteName).get('latestWorksite');
      }
      return RSVP.hash({
        worksite: worksite,
        layout: layout
      });
    },
    afterModel: function afterModel(model) {
      var searchQuery = (0, _emberCopy.copy)(_constants.DEFAULT_PLANNINGMAP_SEARCH_QUERY, true);
      var startDate = get(model.layout, 'startDate') || get(model.worksite, 'startDate');
      var endDate = get(model.layout, 'endDate') || get(model.worksite, 'endDate');
      if (startDate && endDate) {
        searchQuery.dateRange = {
          from: startDate.toISOString(),
          to: endDate.toISOString()
        };
      }
      set(this, 'searchQuery', searchQuery);
    },
    actions: {
      noop: function noop() {
        console.log('noop');
      },
      close: function close() {
        var returnRoute = get(this, 'returnRoute');
        if (returnRoute) {
          this.replaceWith(returnRoute);
        } else {
          var worksite = get(this, 'controller.model.worksite');
          var worksiteType = get(worksite, 'type');
          var route = "".concat(worksiteType, ".view");
          this.transitionTo(route, worksite);
        }
      },
      error: function error(err) {
        console.error(err);
      },
      updateClashes: function updateClashes(boundingBox) {
        var searchQuery = get(this, 'searchQuery') || {};
        searchQuery.boundingBox = boundingBox;
        set(this, 'searchQuery', searchQuery);
        return get(this, 'ajax').getPlanningMapFeatures(searchQuery).then(function (results) {
          return results.data;
        });
      }
    }
  });
  _exports.default = _default;
});