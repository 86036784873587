define("tmp-for-all/components/maps/linz-aerial-tile-layer/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    url: _environment.default.linzMapUrl.replace('{token}', _environment.default.linzToken)
  });
  _exports.default = _default;
});