define("tmp-for-all/routes/activate/activate", ["exports", "tmp-for-all/mixins/company-search"], function (_exports, _companySearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _default = Ember.UnprotectedRoute.extend(_companySearch.default, {
    ajax: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      set(this, 'controller.displayExitModal', false);
    },
    actions: {
      addNewCompany: function addNewCompany() {
        this.replaceWith('activate.new-company');
      },
      activateUser: function activateUser(user, isPrivateIndividual, physicalAddress, postalAddress, company) {
        var _this = this;
        set(this, 'controller.model.disabled', true);
        user.activateUser(get(this, 'controller.model.token'), isPrivateIndividual, physicalAddress, postalAddress, company).then(function () {
          get(_this, 'flashMessages').success('User activated!  Now log in');
          Ember.run.later(function () {
            // Delete password from client so ember does not try to set it again
            set(user, 'password', null);
            set(_this, 'controller.model.disabled', false);
            _this.transitionTo('login');
            // Fix issues with not transitioning past login screen, displaying 'Contact your Company Admin etc'
            Ember.run.later(function () {
              /* eslint-disable no-self-assign */
              window.location = window.location;
            }, 100);
          }, 2000);
        }).catch(function (ajaxError) {
          set(_this, 'controller.model.disabled', false);
          // Because not a regular save, need to hand parse the errors
          get(ajaxError, 'errors').forEach(function (error) {
            if (get(error, 'status') !== 422) return;
            var field = R.last(get(error, 'source.pointer').split('/'));
            user.send('becomeDirty');
            get(user, 'errors').add(field, get(error, 'detail'));
          });
          get(_this, 'flashMessages').danger('There was a problem activating');
        });
      },
      toggleExitModal: function toggleExitModal() {
        get(this, 'controller').toggleProperty('displayExitModal');
      },
      deleteUser: function deleteUser(user) {
        var _this2 = this;
        set(this, 'controller.model.disabled', true);
        // See client/app/adapters/application.js for what's going on with this verificationToken
        var verificationToken = get(this, 'controller.model.token');
        return get(this, 'ajax').deletePermissionAssignments(user, verificationToken).then(function () {
          return user.destroyRecord({
            adapterOptions: {
              verificationToken: get(_this2, 'controller.model.token')
            }
          });
        }).then(function () {
          get(_this2, 'flashMessages').success('User deleted!');
          set(_this2, 'controller.model.disabled', false);
          _this2.transitionTo('login');
        }).catch(function () {
          set(_this2, 'controller.model.disabled', false);
          get(_this2, 'flashMessages').danger('There was a problem deleting');
        });
      }
    }
  });
  _exports.default = _default;
});