define("tmp-for-all/templates/worksites/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lxc8eVOu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"isFullWidth\",\"isListingPage\"],[true,true]],{\"statements\":[[0,\"\\n  \"],[4,\"listing-page-heading\",null,null,{\"statements\":[[0,\"My Accepted Worksites\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"search-results\",null,[[\"columnsExcludedByDefault\",\"handlePaginationClick\",\"order\",\"results\",\"searchType\",\"showFilters\",\"setOrder\"],[[23,[\"columnsExcludedByDefault\"]],[27,\"action\",[[22,0,[]],\"pageClicked\"],null],[23,[\"order\"]],[23,[\"model\"]],\"worksites\",false,[27,\"action\",[[22,0,[]],\"setOrder\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksites/list.hbs"
    }
  });
  _exports.default = _default;
});