define("tmp-for-all/components/terms-and-conditions-acceptance/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    supportEmail: _environment.default.supportEmail,
    termsOfUseUrl: _environment.default.termsOfUseUrl,
    privacyPolicyUrl: _environment.default.privacyPolicyUrl,
    disableAccept: computed('user.isSaving', 'user.termsAndConditionsAccepted', function () {
      return get(this, 'user.isSaving') || !get(this, 'user.termsAndConditionsAccepted');
    }),
    termsAndCondBool: computed('user.termsAndConditionsAccepted', function () {
      return !!get(this, 'user.termsAndConditionsAccepted');
    }),
    termsAndPrivacyAgreeLabel: computed('termsOfUseUrl', 'privacyPolicyUrl', function () {
      return "I accept the <a target=\"_blank\" href=\"".concat(get(this, 'termsOfUseUrl'), "\">Terms of Use</a> and <a target=\"_blank\" href=\"").concat(get(this, 'privacyPolicyUrl'), "\">Privacy Policy</a>").htmlSafe();
    }),
    actions: {
      updateTsAndCs: function updateTsAndCs(checked) {
        set(this, 'user.termsAndConditionsAccepted', checked ? new Date() : null);
      },
      saveUser: function saveUser() {
        return get(this, 'saveAction')(get(this, 'user'));
      }
    }
  });
  _exports.default = _default;
});