define("tmp-for-all/routes/projects/view", ["exports", "ember-cli-pagination/remote/route-mixin", "tmp-for-all/mixins/routes/delete-draft-worksite"], function (_exports, _routeMixin, _deleteDraftWorksite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP;
  var _default = Ember.Route.extend(_routeMixin.default, _deleteDraftWorksite.default, {
    model: function model(params) {
      var projectId = params.project_id;
      var wsFilter = {
        page: params.page,
        perPage: 10,
        filter: {
          where: {
            projectId: projectId
          }
        }
      };
      return RSVP.hash({
        project: this.store.findRecord('project', projectId),
        worksites: this.findPaged('worksite-search-result', wsFilter)
      });
    },
    postDeleteDraftWorksiteHook: function postDeleteDraftWorksiteHook() {
      // Refresh the list of worksites.
      this.refresh();
    }
  });
  _exports.default = _default;
});