define("tmp-for-all/mixins/routes/edit-worksite/contacts-route-mixin", ["exports", "tmp-for-all/mixins/routes/edit-worksite/base-mixin", "tmp-for-all/utils/route-helpers", "tmp-for-all/helpers/permission/edit-contacts", "tmp-for-all/constants"], function (_exports, _baseMixin, _routeHelpers, _editContacts, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Mixin.create(_baseMixin.default, {
    session: Ember.inject.service(),
    authorization: Ember.inject.service(),
    currentContact: null,
    worksite: computed.alias('controller.model.worksite').readOnly(),
    contacts: computed.alias('controller.model.contacts').readOnly(),
    clientCompany: computed.alias('controller.model.clientCompany').readOnly(),
    applicantCompany: computed.alias('controller.model.applicantCompany').readOnly(),
    companies: computed.alias('controller.model.companies').readOnly(),
    currentUser: computed.alias('controller.model.currentUser').readOnly(),
    relationsAreDirty: computed.alias('worksite.contactsIsDirty'),
    model: function model() {
      var _this = this;
      var worksite = this._super.apply(this, arguments);
      var permission = (0, _editContacts.editContactsCheck)([get(worksite, 'type')]);
      return get(this, 'authorization').checkAllowableNavigation(this, permission, worksite).catch(function () {
        return _this.replaceWith('index');
      }).then(function () {
        return RSVP.hash({
          worksite: worksite,
          contacts: get(worksite, 'contacts'),
          clientCompany: get(worksite, 'clientCompany'),
          applicantCompany: get(worksite, 'company'),
          currentUser: get(_this, 'session.currentUser'),
          contactRole: _this.store.query('permission', {
            filter: {
              where: {
                name: 'contact',
                isRole: true
              }
            }
          }).then(function (permissions) {
            return get(permissions, 'firstObject');
          })
        });
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;
      return RSVP.resolve(this._super.apply(this, arguments)).then(function () {
        return _this2.bulkCreateContactSnapshot(get(model, 'contacts'));
      }).then(function () {
        return _this2.initRequiredContacts(model);
      });
    },
    initRequiredContacts: function initRequiredContacts(model) {
      var _this3 = this;
      var requiredContacts = ['PRINCIPAL', 'APPLICANT', 'PAYER'];
      var contacts = get(model, 'contacts');
      return RSVP.all(requiredContacts.map(function (type) {
        var existing = contacts.findBy('type', type);
        return existing || _this3._addContact(type, '', model);
      }));
    },
    save: function save() {
      var _this4 = this;
      var worksite = get(this, 'worksite');
      return worksite.save().then(function () {
        return worksite.saveContacts();
      }).then(function () {
        return get(worksite, 'contacts');
      }).then(function (contacts) {
        return _this4.bulkCreateContactSnapshot(contacts);
      });
    },
    cleanup: function cleanup() {
      var worksite = get(this, 'worksite');
      worksite.destroyOrRollback();
      return worksite.reloadRelations(['contacts']);
    },
    bulkCreateContactSnapshot: function bulkCreateContactSnapshot(contacts) {
      var _this5 = this;
      return RSVP.all((contacts || []).map(function (contact) {
        return _this5.createContactSnapshot(contact);
      }));
    },
    createContactSnapshot: function createContactSnapshot(contact) {
      return contact.snapshotBelongsTo(['company', 'user']);
    },
    pushContact: function pushContact(worksite, newContact, location) {
      this.createContactSnapshot(newContact);
      var contacts = get(worksite, 'contacts');
      return location === 'TOP' ? contacts.unshiftObject(newContact) : contacts.pushObject(newContact);
    },
    _addContact: function _addContact(type, location, model) {
      var newContact = this.store.createRecord('worksite-contact', {
        type: type
      });
      if (type === 'APPLICANT') {
        set(newContact, 'company', get(model, 'applicantCompany'));
        set(newContact, 'user', get(model, 'currentUser'));
      } else if (type === 'PRINCIPAL') {
        set(newContact, 'company', get(model, 'clientCompany'));
      }
      return this.pushContact(get(model, 'worksite'), newContact, location);
    },
    actions: {
      addNewCompany: function addNewCompany(contact) {
        set(this, 'currentContact', contact);
        this.replaceWith((0, _routeHelpers.getChildRouteName)(get(this, 'routeName'), 'new-company'));
      },
      addNewUser: function addNewUser(contact) {
        set(this, 'currentContact', contact);
        this.replaceWith((0, _routeHelpers.getChildRouteName)(get(this, 'routeName'), 'new-contact'));
      },
      saveNewUser: function saveNewUser(_ref) {
        var _this6 = this;
        var contact = _ref.contact,
          replaceWithRoute = _ref.replaceWithRoute;
        var currentContact = get(this, 'currentContact');
        contact.set('meta', {
          creationType: 'addContact',
          worksiteCode: get(this, 'worksite.worksiteCode'),
          contactType: _constants.WORKSITE_CONTACT_TYPES[currentContact.get('type')]
        });
        (contact.get('isNew') ? contact.save() : Promise.resolve()).then(function () {
          set(currentContact, 'user', contact);
          return get(contact, 'permissionAssignments');
        }).then(R.find(function (assignment) {
          return get(assignment, 'domainType') === 'company' && get(assignment, 'domainId') === get(currentContact, 'company.id');
        })).then(function (assignment) {
          return assignment || _this6.store.createRecord('permission-assignment', {
            user: contact,
            domainType: 'company',
            domainId: get(currentContact, 'company.id'),
            permission: get(_this6, 'controller.model.contactRole')
          }).save();
        }).then(function () {
          return get(currentContact, 'company.tmpUsers').reload();
        }).then(function () {
          _this6.replaceWith(replaceWithRoute);
        }).then(function () {
          return get(_this6, 'flashMessages').success('A new contact has been created.');
        }).catch(function () {
          return get(_this6, 'flashMessages').danger('There was an error saving the new contact.');
        });
      },
      selectNewCompany: function selectNewCompany(company) {
        var currentContact = get(this, 'currentContact');
        set(currentContact, 'company', company);
        set(currentContact, 'user', null);
        set(this, 'currentContact', null);
      },
      addContact: function addContact(type, location) {
        this._addContact(type, location, get(this, 'controller.model'));
      },
      deleteContact: function deleteContact(contact) {
        contact.deleteRecord();
      },
      restoreContact: function restoreContact(contact) {
        contact.rollbackBelongsTo();
        contact.rollbackAttributes();
      },
      selectCompany: function selectCompany(contact, company) {
        set(contact, 'company', company);
        set(contact, 'user', null);
      },
      selectUser: function selectUser(contact, user) {
        set(contact, 'user', user);
      },
      willTransition: function willTransition(transition) {
        var worksite = get(this, 'worksite');
        var transitioningToModal = (0, _routeHelpers.routeIsDescendant)(get(this, 'routeName'), transition.targetName);
        if (transitioningToModal) return;
        if (get(this, 'dataIsDirty')) {
          transition.abort();
          this.openModal(worksite, transition);
        }
      }
    }
  });
  _exports.default = _default;
});