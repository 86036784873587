define("tmp-for-all/models/notice", ["exports", "ember-data", "tmp-for-all/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
    computed = _Ember.computed,
    get = _Ember.get;
  var _default = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),
    noticeType: attr('string'),
    status: attr('string'),
    worksite: belongsTo('worksite'),
    // issue fields
    issuedAt: attr('date'),
    issuedBy: belongsTo('tmp-user', {
      async: true
    }),
    issueComment: attr('string'),
    statedIssueDate: attr('date'),
    resolveDeadline: attr('date'),
    // resolve fields
    statedResolveDate: attr('date'),
    resolvedBy: belongsTo('tmp-user'),
    resolvedAt: attr('date'),
    resolveComment: attr('string'),
    // User's organisation who logged the notice
    jurisdiction: belongsTo('jurisdiction', {
      readOnly: true
    }),
    company: belongsTo('company', {
      readOnly: true
    }),
    // computed
    isResolved: computed.bool('resolvedAt'),
    isNotResolved: computed.not('isResolved'),
    /**
     * Only some notice types may be resolved.
     */
    noticeTypeIsResolvable: computed('noticeType', function () {
      return R.contains(get(this, 'noticeType'), [_constants.WORKSITE_NOTICE_TYPE_KEYS.STOP_WORK, _constants.WORKSITE_NOTICE_TYPE_KEYS.NON_CONFORMANCE, _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK, _constants.WORKSITE_NOTICE_TYPE_KEYS.WARRANTY_COMPLETE, _constants.WORKSITE_NOTICE_TYPE_KEYS.START_WORK_INFRINGEMENT, _constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK_INFRINGEMENT]);
    }),
    isRejectable: computed('noticeType', 'status', function () {
      var allowedStatus = get(this, 'status') === _constants.NOTICE_STATUS_KEYS.ISSUED;
      var allowedTypes = [_constants.WORKSITE_NOTICE_TYPE_KEYS.COMPLETE_WORK, _constants.WORKSITE_NOTICE_TYPE_KEYS.WARRANTY_COMPLETE];
      return allowedStatus && allowedTypes.indexOf(get(this, 'noticeType')) !== -1;
    }),
    resolve: function resolve() {
      var _get;
      return (_get = get(this, 'ajax')).noticeAction.apply(_get, [this, 'resolve'].concat(Array.prototype.slice.call(arguments)));
    },
    reject: function reject() {
      var _get2;
      return (_get2 = get(this, 'ajax')).noticeAction.apply(_get2, [this, 'reject'].concat(Array.prototype.slice.call(arguments)));
    }
  });
  _exports.default = _default;
});