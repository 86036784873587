define("tmp-for-all/components/maps/static-map-worksite/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sZLixVZa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"imageUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[21,\"imageUrl\"]],[11,\"alt\",\"Worksite Preview\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/static-map-worksite/template.hbs"
    }
  });
  _exports.default = _default;
});