define("tmp-for-all/templates/admin/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "31b/m9BD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Permissions\"]],{\"statements\":[[0,\"  \"],[1,[27,\"permissions-tree\",null,[[\"permission\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Update Worksite Fees\"]],{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[0,\"See below to update old worksite fee using the currently flagged fee processors\"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"b\"],[9],[0,\"Variables:\"],[10],[10],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"b\"],[9],[0,\"BASE_URL\"],[10],[0,\": The site you want to update. [http://localhost:4200/, https://uat1.myworksites.co.nz/, https://uat2.myworksites.co.nz/, https://manage.myworksites.co.nz]\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"b\"],[9],[0,\"JURISDICTION_ID\"],[10],[0,\": The jurisdiction of the worksites you wish to update\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"b\"],[9],[0,\"DATE:\"],[10],[0,\" Cutoff for worksite creation date (format YYYY/MM/DD)\"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"Copy the URL below into your browser replacing the required values\"],[10],[0,\"\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n  \"],[7,\"p\"],[9],[7,\"b\"],[9],[0,\"{BASE_URL}\"],[10],[0,\"/worksite-fees/update?jurisdiction=\"],[7,\"b\"],[9],[0,\"{JURISDICTION_ID}\"],[10],[0,\"&date=\"],[7,\"b\"],[9],[0,\"{DATE}\"],[10],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/admin/permissions.hbs"
    }
  });
  _exports.default = _default;
});