define("tmp-for-all/routes/companies/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set;
  var _Ember = Ember,
    RSVP = _Ember.RSVP;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    authManager: Ember.inject.service('session'),
    currentUser: null,
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (user) {
        set(_this, 'currentUser', user);
        var isSuperUser = !get(user, 'selectedDomain') && !get(user, 'selectedDomainId');
        set(_this, 'isSuperUser', isSuperUser);
      });
    },
    model: function model(params) {
      var currentUser = get(this, 'currentUser');
      var jurisdictionId = get(currentUser, 'selectedDomainId');
      var companyIdFilter = {
        filter: {
          where: {
            companyId: params.company_id
          }
        }
      };
      var customerNumberFilter;
      if (get(this, 'isSuperUser')) {
        customerNumberFilter = {
          filter: {
            where: {
              companyId: params.company_id
            }
          }
        };
      } else {
        customerNumberFilter = {
          filter: {
            where: {
              and: [{
                companyId: params.company_id
              }, {
                jurisdictionId: jurisdictionId
              }]
            }
          }
        };
      }
      return RSVP.hash({
        company: this.store.findRecord('company', params.company_id),
        customerNumbers: this.store.query('customer-number', customerNumberFilter),
        mergedCompanies: this.store.query('merged-company', companyIdFilter)
      });
    }
  });
  _exports.default = _default;
});