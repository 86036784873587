define("tmp-for-all/models/tmp-search-result", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var attr = _emberData.default.attr;
  var _default = _emberData.default.Model.extend({
    // modelId and modelName will override domain lookups for permissions
    modelId: computed.alias('tmpId'),
    modelName: computed.alias('type'),
    tmpId: attr('number'),
    tmpCode: attr('string'),
    majorRevisionNumber: attr('number'),
    minorRevisionNumber: attr('number'),
    type: attr('string'),
    worksiteId: attr('number'),
    worksiteCode: attr('string'),
    worksiteName: attr('string'),
    layoutMinDate: attr('date'),
    layoutMaxDate: attr('date'),
    layoutCount: attr('number'),
    projectId: attr('number'),
    projectName: attr('string'),
    companyId: attr('number'),
    jurisdictionId: attr('number'),
    ownerId: attr('number'),
    ownerFullname: attr('string'),
    worksiteReference: attr('string'),
    worksiteStartDate: attr('date'),
    worksiteEndDate: attr('date'),
    isGenericTmp: attr('boolean'),
    isDeclined: attr('boolean'),
    createdAt: attr('date'),
    submittedAt: attr('date'),
    acceptedAt: attr('date'),
    rank: attr('number'),
    status: computed('type', 'isDeclined', function () {
      var type = get(this, 'type');
      var isDeclined = get(this, 'isDeclined');
      if (isDeclined) return 'Declined';
      switch (type) {
        case 'tmp-draft':
          return 'Draft';
        case 'tmp-submission':
          return 'Submitted';
        case 'tmp':
          return 'Accepted';
      }
    }),
    isDraft: computed.equal('type', 'tmp-draft'),
    isSubmitted: computed.not('isDraft'),
    isCrossRcaApproval: attr('boolean'),
    currentSecondaryRcaId: attr('number'),
    currentSecondaryRcaAssigneeId: attr('number'),
    currentSecondaryRcaAssigneeName: attr('string'),
    inApproval: computed.and('isCrossRcaApproval', 'currentSecondaryRcaId'),
    description: attr('string')
  });
  _exports.default = _default;
});