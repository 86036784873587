define("tmp-for-all/components/worksite-view/review-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set,
    isEmpty = Ember.isEmpty,
    getProperties = Ember.getProperties;
  var _default = Ember.Component.extend({
    options: computed(function () {
      return [{
        name: 'isApproval',
        value: true,
        label: 'Approve',
        checked: true
      }, {
        name: 'isApproval',
        value: false,
        label: 'Report Issue'
      }];
    }),
    isApproval: true,
    isOnBehalfOf: false,
    notIsOnBehalfOf: computed.not('isOnBehalfOf'),
    onBehalfOf: '',
    notes: '',
    canSubmit: computed('isOnBehalfOf', 'onBehalfOf', 'notes', function () {
      var _getProperties = getProperties(this, ['isOnBehalfOf', 'onBehalfOf', 'notes']),
        isOnBehalfOf = _getProperties.isOnBehalfOf,
        onBehalfOf = _getProperties.onBehalfOf,
        notes = _getProperties.notes;
      if (isEmpty(notes)) return false;
      if (!isOnBehalfOf) return true;
      return !isEmpty(onBehalfOf);
    }),
    submitButtonDisabled: computed.not('canSubmit'),
    historyDescription: computed('isApproval', 'isOnBehalfOf', 'onBehalfOf', function () {
      var action = get(this, 'isApproval') ? 'Approved' : 'Problem Noted';
      if (get(this, 'isOnBehalfOf')) {
        return "".concat(action, " on behalf of ").concat(get(this, 'onBehalfOf'));
      } else {
        return action;
      }
    }),
    actions: {
      setReviewType: function setReviewType(isApproval) {
        set(this, 'isApproval', isApproval);
      },
      createReview: function createReview() {
        get(this, 'onSubmit')(get(this, 'historyDescription'), get(this, 'notes'));
      }
    }
  });
  _exports.default = _default;
});