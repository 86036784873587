define("tmp-for-all/components/maps/map-control-panel/date-range/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    set = Ember.set,
    get = Ember.get;
  var viewingDateFormat = 'D MMM YYYY';
  var _default = Ember.Component.extend({
    classNames: ['t-date-range-tab'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      // Create a new reference for our local object
      set(this, 'dateRangeStart', new Date(get(this, 'dateRange.from')));
      set(this, 'dateRangeEnd', new Date(get(this, 'dateRange.to')));
      this.setDateFormatted();
    },
    currentStartFormatted: '',
    currentEndFormatted: '',
    setDateFormatted: function setDateFormatted() {
      set(this, 'currentStartFormatted', (0, _moment.default)(this.dateRangeStart).format(viewingDateFormat));
      set(this, 'currentEndFormatted', (0, _moment.default)(this.dateRangeEnd).format(viewingDateFormat));
    },
    startDateLocal: computed('dateRangeStart', function () {
      if (!this.dateRangeStart) return;
      return (0, _moment.default)(this.dateRangeStart).format('YYYY-MM-DD');
    }),
    endDateLocal: computed('dateRangeEnd', function () {
      if (!this.dateRangeEnd) return;
      return (0, _moment.default)(this.dateRangeEnd).format('YYYY-MM-DD');
    }),
    actions: {
      dateRangeStartChosen: function dateRangeStartChosen(date) {
        this.set('dateRangeStart', date);
      },
      dateRangeEndChosen: function dateRangeEndChosen(date) {
        this.set('dateRangeEnd', date);
      },
      updateFilters: function updateFilters() {
        var start = this.dateRangeStart;
        var end = this.dateRangeEnd;
        this.setDateFormatted();
        if (start && end) {
          this.updateSearchQuery({
            dateRange: {
              from: start.toISOString(),
              to: end.toISOString()
            }
          });
        }
      }
    }
  });
  _exports.default = _default;
});