define("tmp-for-all/services/comment-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    addComment: function addComment(model, text, files) {
      var modelType = get(model, 'type');
      if (modelType.indexOf('-draft') !== -1) return;
      var commentPromise;
      var baseModelType = modelType.indexOf('worksite') !== -1 ? 'worksite' : 'tmp';
      var data = {
        text: text
      };
      if (files) {
        data["".concat(baseModelType, "Type")] = modelType;
        data["".concat(baseModelType, "Id")] = get(model, 'id');
        data.files = files;
        commentPromise = get(this, 'ajax').commentWithFiles(data, baseModelType);
      } else {
        data[baseModelType] = model;
        commentPromise = get(this, 'store').createRecord("".concat(baseModelType, "Comment"), data).save();
      }
      return commentPromise.finally(function () {
        model.get('history').reload();
      });
    }
  });
  _exports.default = _default;
});