define("tmp-for-all/components/search-parameters/filters/filter-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rgDI7ZhB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-wrapper\",null,[[\"toggleModal\",\"translucentOverlay\",\"headingText\"],[[27,\"action\",[[22,0,[]],[23,[\"toggleModal\"]]],null],true,\"Configure search filter\"]],{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"concat\",[\"search-parameters/filters/filter-controls/\",[23,[\"filter\",\"name\"]]],null]],[[\"filter\",\"getQueryParams\",\"updateFilterParams\",\"searchModel\",\"addFilter\",\"toggleModal\"],[[23,[\"filter\"]],[23,[\"getQueryParams\"]],[23,[\"updateFilterParams\"]],[23,[\"searchModel\"]],[23,[\"addFilter\"]],[27,\"action\",[[22,0,[]],[23,[\"toggleModal\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-parameters/filters/filter-modal/template.hbs"
    }
  });
  _exports.default = _default;
});