define("tmp-for-all/components/form-field/label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pEh6Qsan",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"isRequired\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"form-field__required-asterisk\"],[9],[0,\"*\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"toolTipText\"]]],null,{\"statements\":[[4,\"shared/tool-tip\",null,null,{\"statements\":[[0,\"    \"],[1,[21,\"toolTipText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/form-field/label/template.hbs"
    }
  });
  _exports.default = _default;
});