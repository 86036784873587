define("tmp-for-all/routes/users/create", ["exports", "tmp-for-all/mixins/routes/user-create-route-mixin", "tmp-for-all/mixins/company-search", "tmp-for-all/models/permission"], function (_exports, _userCreateRouteMixin, _companySearch, _permission) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;
  function createAssignment(a, store) {
    var domainType = get(a, 'domainType');
    var domainId = get(a, 'domainId');
    return RSVP.hash({
      assignment: a,
      domainId: domainId,
      domainType: domainType,
      domainItem: domainId ? store.findRecord(domainType, domainId) : null,
      permission: get(a, 'permission'),
      user: get(a, 'user')
    });
  }
  var _default = Ember.Route.extend(_userCreateRouteMixin.default, _companySearch.default, {
    authorization: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (currentUser) {
        set(_this, 'currentUser', currentUser);
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'admin');
      }).catch(function () {
        return _this.replaceWith('index');
      });
    },
    model: function model() {
      return RSVP.hash({
        companyRoles: this.store.query('permission', _permission.default.companyRoleFilter),
        jurisdictionRoles: this.store.query('permission', _permission.default.jurisdictionRoleFilter),
        unscopedRoles: this.store.query('permission', _permission.default.unscopedRoleFilter),
        jurisdictions: this.store.findAll('jurisdiction'),
        user: this.store.createRecord('tmp-user'),
        isSuperUser: get(this, 'currentUser.isSuperUser'),
        assignments: []
      });
    },
    actions: {
      addPermissionAssignment: function addPermissionAssignment() {
        var _this2 = this;
        var domainType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var newAssignment = this.store.createRecord('permission-assignment', {
          user: get(this, 'controller.model.user'),
          domainType: domainType,
          domainId: get(this, 'currentUser.selectedDomainId')
        });
        createAssignment(newAssignment, this.store).then(function (assignment) {
          get(_this2, 'controller.model.assignments').pushObject(assignment);
        });
      },
      deletePermissionAssignment: function deletePermissionAssignment(assignment) {
        get(this, 'controller.model.assignments').removeObject(assignment);
        assignment.assignment.deleteRecord();
      },
      updatePermissionAssignment: function updatePermissionAssignment(assignment, domainId, permission) {
        assignment.setProperties({
          domainId: domainId,
          permission: permission
        });
      }
    }
  });
  _exports.default = _default;
});