define("tmp-for-all/components/maps/select-basemap/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u7oO2HAI",
    "block": "{\"symbols\":[\"tlOption\"],\"statements\":[[7,\"label\"],[11,\"class\",\"map-page-panel-select-label\"],[9],[0,\"Base map\"],[10],[0,\"\\n\"],[7,\"select\"],[11,\"class\",\"map-page-panel-select-input t-base-map-select\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],[23,[\"setTileComponentName\"]]],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"tileLayerOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[28,[[22,1,[\"componentName\"]]]]],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/select-basemap/template.hbs"
    }
  });
  _exports.default = _default;
});