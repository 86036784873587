define("tmp-for-all/templates/worksite-submission/edit/fees", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FzZMtH74",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Manage Worksite Fees\"]],{\"statements\":[[0,\"  \"],[1,[27,\"worksite-view/manage-fees\",null,[[\"worksite\",\"fees\",\"worksiteFees\",\"selectFee\",\"deleteFee\",\"save\",\"saveAndReturn\",\"statusChange\",\"invoicePriorToApprovalChange\",\"cancel\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"fees\"]],[23,[\"model\",\"worksite\",\"worksiteFees\"]],[27,\"route-action\",[\"selectFee\"],null],[27,\"route-action\",[\"deleteFee\"],null],[27,\"route-action\",[\"save\"],null],[27,\"route-action\",[\"saveAndReturn\"],null],[27,\"route-action\",[\"statusChange\"],null],[27,\"route-action\",[\"invoicePriorToApprovalChange\"],null],[27,\"route-action\",[\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite-submission/edit/fees.hbs"
    }
  });
  _exports.default = _default;
});