define("tmp-for-all/mixins/routes/modals/new-edit-deployment-route-mixin", ["exports", "tmp-for-all/mixins/stms-search"], function (_exports, _stmsSearch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    computed = Ember.computed;
  var _default = Ember.Mixin.create(_stmsSearch.default, {
    ajax: Ember.inject.service(),
    modal: Ember.inject.service(),
    deployment: computed.alias('controller.model.deployment'),
    tmp: computed.alias('controller.model.tmp'),
    dataIsDirty: computed.alias('deployment.hasChanges'),
    model: function model(params) {
      var deployment = params.deployment_id ? get(this, 'store').findRecord('deployment', params.deployment_id) : get(this, 'store').createRecord('deployment');
      return RSVP.hash({
        // Loads the tmp from the parent route (which is passed :tmp_id)
        tmp: this.modelFor('tmp'),
        layout: this.modelFor('tmp.layouts.view'),
        deployment: deployment
      });
    },
    afterModel: function afterModel(model) {
      // add in the snapshot here
      return get(model, 'deployment').snapshotBelongsTo(['stmsUser']);
    },
    openModal: function openModal(deployment) {
      var _this = this;
      this.set('modal.options', {
        title: 'Discard Changes?',
        text: 'You have made unsaved changes to this deployment',
        confirmButton: 'Discard',
        confirmAction: function confirmAction() {
          RSVP.resolve(_this.cleanup()).then(function () {
            _this.get('modal').toggle();
            _this.transition(get(_this, 'tmp'), 'tmp.view');
          });
        }
      });
      this.get('modal').toggle();
    },
    cleanup: function cleanup() {
      return get(this, 'deployment').destroyOrRollback();
    },
    transition: function transition(tmp, transitionPath) {
      this.transitionTo(transitionPath, get(tmp, 'id'));
    },
    actions: {
      cancelDeployment: function cancelDeployment() {
        this.cleanup();
        this.transition(get(this, 'tmp'), 'tmp.view');
      },
      saveDeployment: function saveDeployment(deployment) {
        var _this2 = this;
        var action = get(deployment, 'isNew') ? 'created' : 'updated';
        return deployment.save().then(function () {
          get(_this2, 'flashMessages').success("Deployment has been ".concat(action, "."));
          _this2.replaceWith('tmp');
        }).catch(function (err) {
          return get(_this2, 'flashMessages').danger(err.message);
        });
      },
      saveDeploymentSeries: function saveDeploymentSeries(originalDeployment, dates) {
        var _this3 = this;
        var deployments = dates.map(function (date) {
          var deployment = _this3.store.createRecord('deployment', originalDeployment.toJSON());
          deployment.set('startDate', date.toDate());
          deployment.set('endDate', date.toDate());
          deployment.set('layout', originalDeployment.get('layout'));
          deployment.set('stmsUser', originalDeployment.get('stmsUser'));
          return deployment;
        });
        return RSVP.allSettled(deployments.map(function (deployment) {
          return deployment.save();
        })).then(function (states) {
          var errorStates = states.filter(function (state) {
            return state.state === 'rejected';
          });
          if (errorStates.length > 0) {
            get(_this3, 'flashMessages').danger('One or more deployments could not be saved: ' + errorStates[0].reason.message);
          } else {
            get(_this3, 'flashMessages').success('Deployments have been created');
            _this3.replaceWith('tmp');
          }
        }).catch(function (err) {
          // Not run. (This block would only be called if allSettled had failed,
          // for instance if passed an incorrect argument type.)
          console.error(err);
        });
      },
      deleteDeployment: function deleteDeployment(deployment) {
        var _this4 = this;
        deployment.destroyRecord().then(function () {
          get(_this4, 'flashMessages').success('Deployment has been deleted.');
          _this4.replaceWith('tmp');
        }).catch(function (err) {
          return get(_this4, 'flashMessages').danger(err.message);
        });
      },
      willTransition: function willTransition(transition) {
        var deployment = get(this, 'deployment');
        if (get(deployment, 'isNew')) {
          deployment.destroyRecord();
        } else if (get(this, 'dataIsDirty')) {
          transition.abort();
          this.openModal(deployment);
        }
      }
    }
  });
  _exports.default = _default;
});