define("tmp-for-all/models/nzta-detour", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    Model = _emberData.default.Model;
  var _Ember = Ember,
    get = _Ember.get,
    computed = _Ember.computed;
  var _default = Model.extend({
    activeTrafficManagementFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    activeTrafficManagement: attr('string'),
    isPublished: attr('boolean', {
      defaultValue: false
    }),
    detourName: attr('string'),
    detourPart: attr('number'),
    srcRefLink: attr('string'),
    geojson: attr(),
    geojsonNztaProvider: attr(),
    waypoints: attr(),
    nocArea: attr('string'),
    detourNumber: attr('string'),
    journeyTime: attr('number'),
    journeyLength: attr('number'),
    extraJourneyTime: attr('string'),
    extraJourneyLength: attr('number'),
    planNumber: attr('string'),
    startLocation: attr('string'),
    endLocation: attr('string'),
    comments: attr('string'),
    noOfficialDetour: attr('boolean'),
    surfaceType: attr('string'),
    oneWayFlow: attr('string'),
    oneWayFlowFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    heightRestriction: attr('string'),
    heightRestrictionFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    weightRestriction: attr('string'),
    weightRestrictionFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    lengthRestriction: attr('string'),
    lengthRestrictionFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    weatherRestriction: attr('string'),
    weatherRestrictionFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    speedRecommendation: attr('string'),
    speedRecommendationFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    timeOfDay: attr('string'),
    timeOfDayFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    noOfOneLaneBridges: attr('string'),
    noOfOneLaneBridgesFlag: attr('string', {
      defaultValue: 'Unknown'
    }),
    stateHighway: attr('string'),
    detourLink: computed('detourNumber', function () {
      // IE doesn't support window.URL constructor
      var url = window.location.href;
      url = url.indexOf('?') >= 0 ? url.substring(0, url.indexOf('?')) : url;
      return url + '?detourNumber=' + get(this, 'detourNumber');
    })
  });
  _exports.default = _default;
});