define("tmp-for-all/models/worksite-contact", ["exports", "ember-data", "tmp-for-all/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Model.extend({
    company: belongsTo('company'),
    jurisdiction: belongsTo('jurisdiction'),
    details: attr('string'),
    type: attr('string'),
    user: belongsTo('tmp-user'),
    worksite: belongsTo('worksite'),
    worksiteDraft: belongsTo('worksite-draft'),
    worksiteSubmission: belongsTo('worksite-submission'),
    contactLabel: computed('company.isUtility', 'type', function () {
      var companyutility = get(this, 'company.isUtility');
      var contacttype = get(this, 'type');
      if (companyutility && contacttype === _constants.WORKSITE_CONTACT_TYPE_KEYS.PRINCIPAL) {
        return 'Utility Operator Primary Contact';
      } else {
        return _constants.WORKSITE_CONTACT_TYPES[contacttype];
      }
    })
  });
  _exports.default = _default;
});