define("tmp-for-all/components/content-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['content-box'],
    classNameBindings: ['hasNav:content-box--has-nav']
  });
  _exports.default = _default;
});