define("tmp-for-all/components/shared/app-alerts/app-alert/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    linkText: computed('alert.type', function () {
      var alertType = get(this, 'alert.type');
      var linkText = 'View more'; // default link text
      if (alertType === 'feature') {
        linkText = 'View instructions here';
      } else if (alertType === 'warning') {
        linkText = 'View more details';
      } else if (alertType === 'alert') {
        linkText = 'View the changes';
      }
      return linkText;
    }),
    /*
     * Potential alert types: alert, warning, feature
     */
    iconName: computed('alert.type', function () {
      switch (get(this, 'alert.type')) {
        case 'feature':
          return 'feature';
        case 'warning':
          return 'hazard';
        default:
          return 'bell';
      }
    }),
    isExternalLink: computed('alert.link', function () {
      var alertLink = get(this, 'alert.link');
      var isExternalLink = false;
      if (alertLink && alertLink.includes('://')) {
        isExternalLink = true;
      }
      return isExternalLink;
    })
  });
  _exports.default = _default;
});