define("tmp-for-all/components/worksite-view/documents/documentation-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UfI5wY6H",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"doc-type-col\"],[9],[1,[27,\"document-block-name\",[[23,[\"documentationBlock\",\"type\"]]],null],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"doc-text-col\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"documentationBlock\",\"text\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"text-grey-dark\"],[9],[0,\"No documentation added\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"shared/simple-expand-contract\",null,[[\"initialHeight\",\"linkColor\"],[75,\"blue\"]],{\"statements\":[[0,\"      \"],[7,\"pre\"],[9],[1,[23,[\"documentationBlock\",\"text\"]],false],[10],[0,\"\"]],\"parameters\":[]},null]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"doc-files-col\"],[9],[0,\"\\n\"],[4,\"if-permitted\",[\"viewWorksiteFile\"],[[\"model\"],[[23,[\"worksite\"]]]],{\"statements\":[[0,\"    \"],[1,[27,\"shared/file-list\",null,[[\"files\"],[[23,[\"documentationBlock\",\"files\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/documents/documentation-block/template.hbs"
    }
  });
  _exports.default = _default;
});