define("tmp-for-all/utils/animations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.jiggleALittleBit = jiggleALittleBit;
  var run = Ember.run;
  /**
   * @param element a JQuery element, e.g. `$('.some-selector')`
   */
  function jiggleALittleBit(element) {
    if (element && element.length) {
      element.addClass('lil-bit-jiggly');
      run.later(function () {
        element.removeClass('lil-bit-jiggly');
      }, 900);
    }
  }
});