define("tmp-for-all/components/shared/form/radio-buttons-boolean/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    name: '',
    value: false,
    options: computed('name', 'value', 'disabled', function () {
      return [{
        name: this.get('name'),
        value: true,
        label: 'Yes',
        checked: this.get('value') === true,
        disabled: this.get('disabled')
      }, {
        name: this.get('name'),
        value: false,
        label: 'No',
        checked: this.get('value') === false,
        disabled: this.get('disabled')
      }];
    }),
    actions: {
      onChange: function onChange(selectedValue) {
        this.get('onChange')(selectedValue);
      }
    }
  });
  _exports.default = _default;
});