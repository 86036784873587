define("tmp-for-all/components/company-merge/confirm-merge/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = Ember.Component.extend({
    mergeResults: computed('testResults', function () {
      return this.testResults.results;
    }),
    actions: {
      mergeTheOrganisation: function mergeTheOrganisation(companyToMerge) {
        this.closeModal();
        this.mergeOrganisation(companyToMerge);
      }
    }
  });
  _exports.default = _default;
});