define("tmp-for-all/routes/worksites/create/ownership", ["exports", "tmp-for-all/mixins/routes/edit-worksite/ownership-route-mixin"], function (_exports, _ownershipRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RSVP = Ember.RSVP,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Route.extend(_ownershipRouteMixin.default, {
    ajax: Ember.inject.service(),
    nextStep: 'worksites.create.wizard.details',
    queryParams: {
      isGlobalPermit: {
        refreshModel: true
      }
    },
    model: function model(params, transition) {
      var worksite = this.store.peekAll('worksite-draft').find(function (worksite) {
        return !worksite.id;
      }) || this.store.createRecord('worksite-draft');
      if (params.isGlobalPermit && params.isGlobalPermit === 'true') {
        set(worksite, 'applicationType', 'GLOBALPERMIT');
      }
      if (transition && transition.to.queryParams.fromGlobalPermitLayout) {
        this.store.findRecord('layout', transition.to.queryParams.fromGlobalPermitLayout).then(function (globalPermitLayout) {
          return set(worksite, 'globalPermitLayout', globalPermitLayout);
        });
      }
      return RSVP.hash({
        worksite: worksite,
        project: get(worksite, 'project'),
        clientCompany: get(worksite, 'clientCompany'),
        clientContact: get(worksite, 'contacts').then(function (contacts) {
          return contacts.findBy('type', 'PRINCIPAL');
        }),
        jurisdictions: this.store.findAll('jurisdiction'),
        contactRole: this.store.query('permission', {
          filter: {
            where: {
              name: 'contact',
              isRole: true
            }
          }
        }).then(function (permissions) {
          return get(permissions, 'firstObject');
        })
      });
    }
  });
  _exports.default = _default;
});