define("tmp-for-all/components/shared/error-messages/inline/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: ''
  });
  _exports.default = _default;
});