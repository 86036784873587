define("tmp-for-all/helpers/permission/edit-workitems", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.editWorkItemsCheck = editWorkItemsCheck;
  function editWorkItemsCheck(worksiteType) {
    return {
      'worksite-draft': 'editDraftWorksite',
      'worksite-submission': 'editSubmittedWorkItems',
      worksite: 'editSubmittedWorkItems'
    }[R.head(worksiteType)];
  }
  var _default = Ember.Helper.helper(editWorkItemsCheck);
  _exports.default = _default;
});