define("tmp-for-all/mixins/routes/conditions-base", ["exports", "tmp-for-all/utils/action-description", "tmp-for-all/constants"], function (_exports, _actionDescription, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set;
  var _default = Ember.Mixin.create({
    authManager: Ember.inject.service('session'),
    authorization: Ember.inject.service(),
    ajax: Ember.inject.service(),
    getActionDescription: _actionDescription.default,
    // resolves condition groups and their conditions based on a condition type property
    // this condition type is intended for use in child routes to determine what sort of
    // condition is being dealt with.
    conditionType: '',
    model: function model() {
      var _this = this;
      return get(this, 'authorization.hasAuthLoaded').then(function () {
        return get(_this, 'authManager.currentUser');
      }).then(function (user) {
        if (get(user, 'selectedDomain') !== _constants.DOMAIN_TYPES.JURISDICTION) {
          get(_this, 'flashMessages').danger('Must be operating under an RCA');
          return _this.transitionTo('index');
        }
        return RSVP.hash({
          conditionGroups: _this.store.query('condition-group', {
            filter: {
              where: {
                and: [{
                  conditionType: get(_this, 'conditionType')
                }, {
                  jurisdictionId: get(user, 'selectedDomainId')
                }]
              },
              include: 'conditions'
            }
          }),
          conditionType: get(_this, 'conditionType')
        });
      });
    },
    addGroupRelationship: function addGroupRelationship(condition, group) {
      var relationship = this.store.createRecord('condition-group-to-condition');
      relationship.set('condition', condition);
      relationship.set('conditionGroup', group);
      condition.get('conditionGroupToConditions').pushObject(relationship);
    },
    // for now a condition belongs to just one group so remove existing relationships before saving a new one
    removeExistingRelationships: function removeExistingRelationships(condition) {
      return condition.get('conditionGroupToConditions').then(function () {
        // it seems operating on the resolved conditionGroupToConditions is not the same as re getting them...
        return RSVP.all(condition.get('conditionGroupToConditions').map(function (cgc) {
          return cgc.deleteRecord();
        }));
      });
    },
    saveAllGroupRelationships: function saveAllGroupRelationships(condition) {
      return RSVP.all(condition.get('conditionGroupToConditions').map(function (model) {
        return model.save();
      }));
    },
    saveCondition: function saveCondition(condition, group, action) {
      var _this2 = this;
      // see mixins/action-description
      var actionDescriptions = this.getActionDescription(action);
      var conditionType = get(condition, 'conditionType');
      return this.removeExistingRelationships(condition).then(function () {
        return get(_this2, 'authManager.currentUser');
      }).then(function (user) {
        // User must belong to a jurisdiction to be able to create a condition group
        var domain = get(user, 'domain');
        if (domain) return RSVP.Promise.resolve(domain);
        // create under the AMA jurisdiction if the user is admin
        return get(_this2, 'ajax').getJurisdictionByName('AMA');
      }).then(function (domain) {
        _this2.addGroupRelationship(condition, group);
        set(condition, 'jurisdiction', domain);
        return condition.save();
      }).then(function () {
        return _this2.saveAllGroupRelationships(condition);
      }).then(function () {
        get(_this2, 'flashMessages').success("Condition has been ".concat(actionDescriptions[0], " successfully."));

        // ensure this route is refreshed, this is where route actions get a tad confusing. The action is handled
        // at the list level so that refresh can be called then we transition to the list route.
        _this2.refresh();
        _this2.transitionTo("conditions.".concat(conditionType, ".list"));
      }).catch(function (errors) {
        get(_this2, 'flashMessages').danger("There was a problem ".concat(actionDescriptions[1], " this Condition"));
      });
    },
    actions: {
      saveCondition: function saveCondition(condition, group, action) {
        this.saveCondition(condition, group, action);
      },
      toggleArchiveState: function toggleArchiveState(condition, group) {
        var action = condition.toggleProperty('isArchived') ? 'archived' : 'unarchived';
        this.saveCondition(condition, group, action);
      }
    }
  });
  _exports.default = _default;
});