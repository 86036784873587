define("tmp-for-all/components/worksite-view/manage-fees/component", ["exports", "tmp-for-all/constants", "date-fns/isWithinInterval"], function (_exports, _constants, _isWithinInterval) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  function isFeeActive(fee) {
    var cost = get(fee, 'currentCost');
    var currentDate = new Date();
    if ((cost === null || cost === void 0 ? void 0 : cost.value) === null) return false;
    if ((cost === null || cost === void 0 ? void 0 : cost.startsAt) > currentDate) return false;
    if (!(cost !== null && cost !== void 0 && cost.endsAt)) return true;
    return (0, _isWithinInterval.default)(currentDate, {
      start: cost === null || cost === void 0 ? void 0 : cost.startsAt,
      end: cost === null || cost === void 0 ? void 0 : cost.endsAt
    });
  }
  var _default = Ember.Component.extend({
    worksiteIsAccepted: computed.equal('worksite.type', _constants.WORKSITE_MODEL_TYPES.ACCEPTED),
    activeFees: computed.filter('fees.@each.currentCost', function (fee) {
      return isFeeActive(fee);
    }),
    statuses: computed(function () {
      return Object.keys(_constants.WORKSITE_FEE_STATUSES).filter(function (status) {
        return status !== 'INVOICED';
      }).map(function (key) {
        return _constants.WORKSITE_FEE_STATUSES[key];
      });
    }),
    nonDeletedFees: computed.filter('worksiteFees.@each.isDeleted', function (fee) {
      return !get(fee, 'isDeleted');
    }),
    total: computed('nonDeletedFees.[]', 'nonDeletedFees.@each.price', function () {
      return get(this, 'nonDeletedFees').map(function (worksiteFee) {
        return parseFloat(get(worksiteFee, 'price'), 10);
      }).reduce(function (prev, curr) {
        return prev + curr;
      }, 0);
    }),
    hasWorksiteFees: computed.notEmpty('nonDeletedFees'),
    actions: {
      costPerUnitFocusOut: function costPerUnitFocusOut(event) {
        var value = this.$(event.target).val();
        if (!/\./.test(value)) {
          this.$(event.target).val(value + '.00');
          this.$(event.target).change();
        } else if (/\.[\d]$/.test(value)) {
          this.$(event.target).val(value + '0');
          this.$(event.target).change();
        }
      },
      // More hackery because of integer storage
      adjustFeesAndSave: function adjustFeesAndSave(worksite, action) {
        function adjustCost(worksiteFee) {
          if (typeof get(worksiteFee, 'costPerUnit') === 'string') {
            set(worksiteFee, 'costPerUnit', Number(get(worksiteFee, 'costPerUnit').replace('.', '')));
            set(worksiteFee, 'adjusted', true);
          }
        }
        function reAdjustCostForDisplay(worksiteFee) {
          // Necessary in case they click "save" instead of "save and return"
          if (get(worksiteFee, 'adjusted')) {
            set(worksiteFee, 'costPerUnit', (get(worksiteFee, 'costPerUnit') / 100).toFixed(2));
            set(worksiteFee, 'adjusted', false);
          }
        }
        get(worksite, 'worksiteFees').forEach(adjustCost);
        action(worksite).then(function () {
          return get(worksite, 'worksiteFees').forEach(reAdjustCostForDisplay);
        });
      }
    }
  });
  _exports.default = _default;
});