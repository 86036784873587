define("tmp-for-all/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;
  function formatCurrency(params) {
    var dollarsAndCents = (params[0] / 100).toFixed(2);
    return '$' + dollarsAndCents;
  }
  var _default = Ember.Helper.helper(formatCurrency);
  _exports.default = _default;
});