define("tmp-for-all/components/search-results/worksites/results-table/item/worksite-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OM/dFkSL",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isDraft\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"newLink\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[21,\"mwsApplyLink\"]],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[4,\"link-to\",[[23,[\"searchResult\",\"type\"]],[23,[\"searchResult\",\"worksiteId\"]]],null,{\"statements\":[[1,[21,\"label\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"link-to\",[[23,[\"searchResult\",\"type\"]],[23,[\"searchResult\",\"worksiteId\"]]],null,{\"statements\":[[1,[21,\"label\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/worksites/results-table/item/worksite-link/template.hbs"
    }
  });
  _exports.default = _default;
});