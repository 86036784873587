define("tmp-for-all/components/maps/select-basemap/component", ["exports", "tmp-for-all/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['map-page-panel-select'],
    intl: Ember.inject.service(),
    tileLayerOptions: computed('linzAerialLayer', function () {
      var layerOptions = [{
        name: 'Mapbox Streets',
        componentName: 'mapbox-streets-tile-layer'
      }, {
        name: 'Mapbox Satellite',
        componentName: 'mapbox-satellite-tile-layer'
      }];
      if (get(this, 'intl.locale')[0] === _constants.LOCALES.EN_NZ) {
        layerOptions.push({
          name: 'LINZ Aerial',
          componentName: 'linz-aerial-tile-layer'
        });
      }
      return layerOptions;
    })
  });
  _exports.default = _default;
});