define("tmp-for-all/components/worksite-view/work-list/work-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: '',
    topRowClass: computed('deployment.notes', function () {
      return get(this, 'deployment.notes') ? 'double-row double-row-top' : '';
    }),
    notesRowIsVisible: false,
    toggleNotesButtonText: computed('notesRowIsVisible', function () {
      return get(this, 'notesRowIsVisible') ? 'Hide' : 'Show';
    }),
    roadLocations: computed('workItem.roadLocations', function () {
      var locations = get(this, 'workItem.roadLocations');
      return locations ? locations.join(', ') : '';
    }),
    actions: {
      toggleNotesRow: function toggleNotesRow() {
        this.toggleProperty('notesRowIsVisible');
      }
    }
  });
  _exports.default = _default;
});