define("tmp-for-all/helpers/impact-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.impactStatus = impactStatus;
  function impactStatus(params) {
    var status = params[0];
    var statusMap = {
      open: 'Open',
      reduced: 'Restricted',
      closed: 'Closed'
    };
    return statusMap[status];
  }
  var _default = Ember.Helper.helper(impactStatus);
  _exports.default = _default;
});