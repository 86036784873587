define("tmp-for-all/components/shared/conditions-list/group/component", ["exports", "tmp-for-all/mixins/components/show-hide-mixin"], function (_exports, _showHideMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend(_showHideMixin.default, {
    classNames: ['condition-group', 'conditions-list', 'rounded-wrapper', 'margin-btm-10', 'hide-overflow'],
    hasConditions: computed.gt('conditions.length', 0),
    visibleConditionsCount: computed('conditions', 'showArchived', function () {
      var numConditions = get(this, 'conditions.length');
      if (!get(this, 'showArchived')) {
        numConditions = get(this, 'conditions').filterBy('isArchived', false).length;
      }
      return numConditions;
    }),
    conditionEditLink: computed('conditionType', function () {
      return "conditions.".concat(get(this, 'conditionType'), ".edit");
    })
  });
  _exports.default = _default;
});