define("tmp-for-all/components/shared/read-only-conditions/component", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed,
    RSVP = Ember.RSVP;
  var _default = Ember.Component.extend({
    classNames: ['static-conditions', 't-read-only-conditions'],
    amendedGroups: computed('conditionGroups.[]', 'selectedConditions.[]', function () {
      var _this = this;
      var conditionGroups = get(this, 'conditionGroups') || [];
      return conditionGroups.map(function (group) {
        var hasConditions = R.any(function (condition) {
          var conditionsGroups = get(condition.toJSON(), 'conditionGroups') || [];
          return R.contains(get(group, 'id'), conditionsGroups);
        }, get(_this, 'selectedConditions'));
        return {
          group: group,
          hasConditions: hasConditions
        };
      });
    }),
    customGroup: {
      name: 'Custom Conditions'
    },
    customConditions: computed('parent.customConditions', function () {
      return get(this, 'parent.customConditions').map(function (condition) {
        return {
          text: condition
        };
      });
    }),
    filteredConditions: computed('parent.customConditions', 'filterId', function () {
      var filterId = get(this, 'filterId');
      return _emberData.default.PromiseArray.create({
        promise: RSVP.filter(get(this, 'parent.customConditions').toArray(), function (condition) {
          return get(condition, 'jurisdiction').then(function (jurisdiction) {
            return get(jurisdiction, 'id') === filterId;
          });
        })
      });
    })
  });
  _exports.default = _default;
});