define("tmp-for-all/components/search-results/projects/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6AWZ2l5R",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[7,\"table\"],[11,\"class\",\"table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Project Title\"],[10],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Summary of Work\"],[10],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Organisation or Private Individual\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"projects\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"search-results/projects/list/item\",null,[[\"project\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"shared/fixed-actions-bar\",null,null,{\"statements\":[[4,\"link-to\",[\"projects.create\"],[[\"class\"],[\"primary-action\"]],{\"statements\":[[0,\"    \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"plus\"]]],false],[0,\" Add project\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-results/projects/list/template.hbs"
    }
  });
  _exports.default = _default;
});