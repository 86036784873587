define("tmp-for-all/templates/tmps/queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+ofIkH89",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"isFullWidth\",\"isListingPage\"],[true,true]],{\"statements\":[[0,\"\\n  \"],[4,\"listing-page-heading\",null,null,{\"statements\":[[0,\"Processing Queue\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"search-parameters\",null,[[\"filterTypes\",\"searchType\",\"updateFilterParams\"],[[23,[\"filterTypes\"]],\"tmps\",[27,\"action\",[[22,0,[]],\"updateFilterParams\"],null]]]],false],[0,\"\\n\\n  \"],[1,[27,\"search-results\",null,[[\"allowClaim\",\"claim\",\"createDraft\",\"handlePaginationClick\",\"isProcessingQueue\",\"order\",\"queryFilter\",\"results\",\"searchType\",\"setOrder\",\"userDomainId\"],[true,[27,\"route-action\",[\"claimTmp\"],null],[27,\"route-action\",[\"createDraft\"],null],[27,\"action\",[[22,0,[]],\"pageClicked\"],null],true,[23,[\"order\"]],[23,[\"model\",\"queryFilter\"]],[23,[\"model\",\"tmps\"]],\"tmps\",[27,\"action\",[[22,0,[]],\"setOrder\"],null],[23,[\"model\",\"userDomainId\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/tmps/queue.hbs"
    }
  });
  _exports.default = _default;
});