define("tmp-for-all/services/alerts", ["exports", "tmp-for-all/constants", "tmp-for-all/config/environment"], function (_exports, _constants, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var Service = Ember.Service,
    get = Ember.get,
    run = Ember.run;
  var _default = Service.extend({
    refreshPeriod: _constants.ALERT_SERVICE_REFRESH,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    fetch: function fetch() {
      var store = get(this, 'store');
      return Promise.all([store.query('alert', {
        filter: {
          where: {
            expiresAt: {
              gt: new Date()
            }
          }
        }
      }), get(this, 'session.currentUser')]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          alerts = _ref2[0],
          user = _ref2[1];
        return store.query('viewed-alert', {
          filter: {
            where: {
              tmpUserId: get(user, 'id'),
              alertId: {
                inq: alerts.map(function (alert) {
                  return get(alert, 'id');
                })
              }
            },
            include: ['alert']
          }
        }).then(function (viewedAlerts) {
          return alerts.filter(function (alert) {
            var found = viewedAlerts.find(function (viewedAlert) {
              return get(viewedAlert, 'alert.id') === get(alert, 'id');
            });
            return !found;
          });
        });
      });
    },
    start: function start(callback) {
      var _this = this;
      var update = function update() {
        _this.fetch().then(callback).then(function () {
          // hack to fix tests. Async scheduling makes tests hang so disable
          // rescheduling of update
          if (_environment.default.environment !== 'test') {
            run.later(update, get(_this, 'refreshPeriod'));
          }
        });
      };
      update();
    }
  });
  _exports.default = _default;
});