define("tmp-for-all/routes/tmp/layouts/edit/detour", ["exports", "tmp-for-all/mixins/routes/layout-detour-route-mixin", "tmp-for-all/constants"], function (_exports, _layoutDetourRouteMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_layoutDetourRouteMixin.default, {
    returnRoute: 'tmp.layouts.edit.details',
    siteSpecificRoutePermission: 'editAcceptedLayout',
    tmpType: _constants.TMP_TYPES.ACCEPTED
  });
  _exports.default = _default;
});