define("tmp-for-all/models/worksite-meta", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var _default = _emberData.default.Model.extend({
    status: attr('string'),
    workStartDate: attr('date'),
    workStartDateLoggedAt: attr('date'),
    workCompletionDate: attr('date'),
    workCompletionDateLoggedAt: attr('date'),
    workCompletionAcceptedAt: attr('date'),
    workStoppedAt: attr('date'),
    warrantyCompleteDate: attr('date'),
    maintenanceCompleteIssuedAt: attr('date'),
    maintenanceCompleteAcceptedAt: attr('date')
  });
  _exports.default = _default;
});