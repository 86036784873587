define("tmp-for-all/components/svg-region-map/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['svg-region-map'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      $('body').addClass('planning-map-page');
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      $('body').removeClass('planning-map-page');
    }
  });
  _exports.default = _default;
});