define("tmp-for-all/controllers/companies/list", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin", "tmp-for-all/constants"], function (_exports, _listPaginationControllerMixin, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set;
  var _default = Ember.Controller.extend(_listPaginationControllerMixin.default, {
    queryParams: ['search', 'showArchivedResults', 'relatedPartiesFilter'],
    search: null,
    showArchivedResults: false,
    relatedPartiesFilter: _constants.RELATED_PARTIES_ALL,
    relatedPartiesOptions: _constants.RELATED_PARTIES_FILTER_OPTIONS,
    // 'All','Related parties only','Unrelated parties only'],

    actions: {
      searchUpdated: function searchUpdated(term) {
        set(this, 'page', 1);
        set(this, 'search', term);
      },
      // This is to create a 'hard' page change in order to trigger
      // a computed value in 'search-results/organisations/list/component.js'
      // that removes duplicate values from the table
      pageClicked: function pageClicked(page) {
        set(this, 'page', page);
      },
      updateShowArchivedResults: function updateShowArchivedResults(bool) {
        set(this, 'showArchivedResults', bool);
      },
      updateRelatedPartiesFilter: function updateRelatedPartiesFilter(val) {
        set(this, 'relatedPartiesFilter', val);
      }
    }
  });
  _exports.default = _default;
});