define("tmp-for-all/utils/route-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getChildRouteName = getChildRouteName;
  _exports.getParentRouteName = getParentRouteName;
  _exports.getSiblingRouteName = getSiblingRouteName;
  _exports.routeIsDescendant = routeIsDescendant;
  /**
   * Given the following route structure:
   *
   * worksites.edit
   * worksites.edit.ownership
   *
   * worksites.edit is the parent of worksite.edit.ownership
   *
   * @see: http://emberjs.com/api/classes/Ember.Route.html#property_routeName
   * @param {String} routeName  e.g. worksites.edit.ownership
   * @return {String} e.g. worksites.edit
   */
  function getParentRouteName(routeName) {
    return routeName.split('.').slice(0, -1).join('.');
  }

  /**
   * Given the following route structure:
   *
   * worksites.edit
   * worksites.edit.ownership
   * worksites.edit.details
   *
   * ownership and details are sibling routes: both children of worksites.edit
   *
   * @param {String} routeName  e.g. worksites.edit.ownership
   * @param {String} siblingRoutePath e.g. worksites.edit.details
   * @return {String} e.g. true
   */
  function getSiblingRouteName(routeName, siblingRoutePath) {
    return R.join('.', [getParentRouteName(routeName), siblingRoutePath]);
  }

  /**
   * Given the following route structure:
   *
   * worksites.edit
   * worksites.edit.ownership
   * worksites.edit.details
   *
   * ownership and details are both children of worksites.edit
   *
   * @param {String} routeName e.g. worksites.edit
   * @param {String} childRoutePath e.g. ownership
   * @return {String} e.g. worksites.edit.ownership
   */
  function getChildRouteName(routeName, childRoutePath) {
    return R.join('.', [routeName, childRoutePath]);
  }

  /**
   * Given the following route structure
   *
   * worksites
   * worksites.edit
   * worksites.edit.ownership
   * worksites.edit.ownership.new-contact
   *
   * worksites.edit.ownership.new-contact is a descendant of the following routes:
   * worksites, worksites.edit, worksites.edit.ownership
   *
   * Note: A route is not considered a descendant of itself
   *
   * @param {String} parentRouteName e.g. worksites.edit
   * @param {String} childRouteName e.g. worksites.edit.ownership
   * @return {boolean}  e.g. true
   */
  function routeIsDescendant(parentRouteName, childRouteName) {
    if (parentRouteName === childRouteName) return false;
    return childRouteName.startsWith(parentRouteName + '.');
  }
});