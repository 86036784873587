define("tmp-for-all/templates/worksite-submission/edit/extent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kpLgvNdl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"worksite/extent-form\",null,[[\"worksite\",\"updateTrafficManagementLevel\",\"updateIsFullClosureRequested\",\"removeManualRoadLevel\",\"updateRoadType\",\"updateMotorwayWorkType\",\"finishDetails\",\"next\",\"saveProgress\",\"saveAndReturn\"],[[23,[\"model\"]],[27,\"route-action\",[\"updateTrafficManagementLevel\"],null],[27,\"route-action\",[\"updateIsFullClosureRequested\"],null],[27,\"route-action\",[\"removeManualRoadLevel\"],null],[27,\"route-action\",[\"updateRoadType\"],null],[27,\"route-action\",[\"updateMotorwayWorkType\"],null],[27,\"route-action\",[\"finishDetails\"],null],[27,\"route-action\",[\"next\"],null],[27,\"route-action\",[\"saveProgress\"],null],[27,\"route-action\",[\"saveAndReturn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite-submission/edit/extent.hbs"
    }
  });
  _exports.default = _default;
});