define("tmp-for-all/components/maps/draw-polygon/select-layer/worksite-popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    set = Ember.set;
  var tabs = {
    DETAILS: 'DETAILS',
    CONTACTS: 'CONTACTS',
    WORKITEMS: 'WORKITEMS'
  };
  var _default = Ember.Component.extend({
    tabs: tabs,
    activeTab: tabs.DETAILS,
    isDetailTabActive: computed.equal('activeTab', tabs.DETAILS),
    isContactsTabActive: computed.equal('activeTab', tabs.CONTACTS),
    isWorkItemsTabActive: computed.equal('activeTab', tabs.WORKITEMS),
    actions: {
      setActiveTab: function setActiveTab(type) {
        set(this, 'activeTab', type);
      }
    }
  });
  _exports.default = _default;
});