define("tmp-for-all/components/nzta-detour/edit-detour/component", ["exports", "tmp-for-all/utils/nzta-detour-helper"], function (_exports, _nztaDetourHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    lrmHelpExpanded: false,
    roadSurfaces: ['Sealed', 'Unsealed', 'Both'],
    extraTimeOptions: _nztaDetourHelper.extraTimeOptions,
    weightRestrictionOptions: ['None', '< 50 Max', '< 44 Max', 'Light Commercial Vehicles'],
    actions: {
      saveAndAddDetour: function saveAndAddDetour(detour) {
        var _this = this;
        this.saveSection(detour).then(function () {
          return _this.addDetour();
        }).catch(function (e) {
          return console.error('SAVE FAILED', e);
        });
      }
    }
  });
  _exports.default = _default;
});