define("tmp-for-all/components/x-modal-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    modal: Ember.inject.service(),
    classNames: ['modal-container'],
    show: computed.alias('modal.show'),
    dialogTitle: computed('modal.options.title', function () {
      return this.get('modal.options.title') || 'Really?';
    }),
    dialogText: computed('modal.options.text', function () {
      return this.get('modal.options.text') || 'Are you sure you want to do this?';
    }),
    dialogHtml: computed.alias('modal.options.html').readOnly(),
    cancelText: computed('modal.options.cancelButton', function () {
      return this.get('modal.options.cancelButton') || 'Cancel';
    }),
    removeCancelLink: computed.alias('modal.options.removeCancelLink').readOnly(),
    confirmText: computed('modal.options.confirmButton', function () {
      return this.get('modal.options.confirmButton') || 'Confirm';
    }),
    actions: {
      cancel: function cancel() {
        var cancelAction = get(this, 'modal.options.cancelAction');
        if (cancelAction) return cancelAction();
        set(this, 'modal.show', false);
      },
      confirm: function confirm() {
        get(this, 'modal.options.confirmAction')();
      }
    }
  });
  _exports.default = _default;
});