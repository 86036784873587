define("tmp-for-all/routes/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend({
    authorization: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'authorization').checkAllowableNavigation(this, 'admin').catch(function () {
        return _this.replaceWith('index');
      });
    }
  });
  _exports.default = _default;
});