define("tmp-for-all/helpers/permission/edit-worksite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.editWorksite = editWorksite;
  function editWorksite(worksiteType) {
    switch (R.head(worksiteType)) {
      case 'worksite':
        return 'editAcceptedWorksite';
      case 'worksite-submission':
        return 'editSubmittedWorksite';
      case 'worksite-draft':
        return 'editDraftWorksite';
      default:
        return '';
    }
  }
  var _default = Ember.Helper.helper(editWorksite);
  _exports.default = _default;
});