define("tmp-for-all/components/maps/detour-control/works-layers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dxXaN8k+",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"maps/detour-control/works-layers/works-layer\",null,[[\"works\",\"determineSelectedItems\",\"detailModels\"],[[23,[\"worksiteLayer\"]],[27,\"action\",[[22,0,[]],[23,[\"determineSelectedItems\"]]],null],[23,[\"detailModels\"]]]]],false],[0,\"\\n\"],[1,[27,\"maps/detour-control/works-layers/works-layer\",null,[[\"works\",\"determineSelectedItems\",\"detailModels\"],[[23,[\"closedLayoutLayer\"]],[27,\"action\",[[22,0,[]],[23,[\"determineSelectedItems\"]]],null],[23,[\"detailModels\"]]]]],false],[0,\"\\n\"],[1,[27,\"maps/detour-control/works-layers/works-layer\",null,[[\"works\",\"determineSelectedItems\",\"detailModels\"],[[23,[\"reducedLayoutLayer\"]],[27,\"action\",[[22,0,[]],[23,[\"determineSelectedItems\"]]],null],[23,[\"detailModels\"]]]]],false],[0,\"\\n\"],[1,[27,\"maps/detour-control/works-layers/works-layer\",null,[[\"works\",\"determineSelectedItems\",\"detailModels\"],[[23,[\"openLayoutLayer\"]],[27,\"action\",[[22,0,[]],[23,[\"determineSelectedItems\"]]],null],[23,[\"detailModels\"]]]]],false],[0,\"\\n\"],[1,[27,\"maps/detour-control/works-layers/works-layer\",null,[[\"works\",\"determineSelectedItems\",\"detailModels\"],[[23,[\"detourLayer\"]],[27,\"action\",[[22,0,[]],[23,[\"determineSelectedItems\"]]],null],[23,[\"detailModels\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/maps/detour-control/works-layers/template.hbs"
    }
  });
  _exports.default = _default;
});