define("tmp-for-all/routes/password/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.UnprotectedRoute.extend({
    model: function model(params) {
      return params.token;
    }
  });
  _exports.default = _default;
});