define("tmp-for-all/components/worksite/stop-work/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set;
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'minResolveDeadline', new Date());
    }
  });
  _exports.default = _default;
});