define("tmp-for-all/utils/worksite-filters", ["exports", "tmp-for-all/utils/search-filter", "tmp-for-all/constants", "moment"], function (_exports, _searchFilter, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    RSVP = Ember.RSVP;

  // build list of options for the select box from a static object
  // {[TYPENAME]: <nameString>} -> [{name, id}]
  var getModelFromHash = R.pipe(R.mapObjIndexed(function (value, key) {
    return {
      name: value,
      id: key
    };
  }), R.values);
  var worksiteTypeOptions = Object.keys(_constants.WORKSITE_APPLICATION_TYPES).map(function (key) {
    return {
      name: _constants.WORKSITE_APPLICATION_TYPES[key],
      value: key
    };
  });
  var workCategoryOptions = Object.keys(_constants.WORKSITE_WORK_CATEGORIES).map(function (key) {
    return {
      name: key,
      value: _constants.WORKSITE_WORK_CATEGORIES[key]
    };
  });
  var makeWorksiteWorkWindowFilter = function makeWorksiteWorkWindowFilter() {
    var filter = (0, _searchFilter.makeSingleDateFilter)('Worksite', 'Worksite work window');
    filter.queryParams = ['fromDate', 'toDate'];
    filter.name = 'worksite-work-window';
    filter.useCustomParams = true;
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) whereFilter.and = [];
      if (params.toDate || params.fromDate) {
        if (!filter.appliedOptions.length) {
          var fromParts = params.fromDate.split('|');
          var toParts = params.toDate.split('|');
          set(filter, 'appliedOptions', fromParts.map(function (from, index) {
            return [from, toParts[index]];
          }));
        }
        var parts = filter.appliedOptions;
        whereFilter.and.push({
          or: parts.map(function (range, index) {
            // Construct moment strings. If the range provided is falsy, create an invalid moment
            var fromDate = (0, _moment.default)(range[0] || 'invalid');
            var toDate = (0, _moment.default)(range[1] || 'invalid');

            // Construct the individual parts of the `and` filter
            var endPart = fromDate.isValid() ? {
              endDate: {
                gte: fromDate.startOf('day').toISOString()
              }
            } : null;
            var startPart = toDate.isValid() ? {
              startDate: {
                lte: toDate.endOf('day').toISOString()
              }
            } : null;

            // Construct the and filter, filtering out invalid parts.
            var and = [endPart, startPart].filter(Boolean);
            return {
              and: and
            };
          })
        });
      }
      delete params.toDate;
      delete params.fromDate;
    };
    return filter;
  };
  var _default = [makeWorksiteWorkWindowFilter(), (0, _searchFilter.makeDiscreteList)('Basic', 'Project name', 'project', 'projectId'), (0, _searchFilter.makeDiscreteList)('Basic', 'Utility/principal', 'company', 'clientId'), (0, _searchFilter.makeDiscreteList)('Basic', 'Applicant', 'company', 'applicantId'), (0, _searchFilter.makeDiscreteList)('RCA', 'RCA', 'jurisdiction', 'jurisdictionId'), (0, _searchFilter.makeDiscreteList)('RCA', 'RCA area', 'jurisdiction-area', 'jurisdictionAreaName', 'name'), function () {
    var filter = (0, _searchFilter.makeDiscreteList)('Basic', 'Type of Work', '', 'workType');
    filter.getOptions = function (controller) {
      return RSVP.resolve(getModelFromHash(_constants.WORKSITE_TYPES));
    };
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.workType) {
        whereFilter.and.push({
          or: params.workType.split('|').filter(Boolean).map(function (val) {
            return {
              worksiteType: val
            };
          })
        });
      }
      delete params.workType;
    };
    return filter;
  }(), function () {
    var filter = (0, _searchFilter.makeDiscreteList)('Work Status', 'Work status', '', 'workStatus');
    filter.getOptions = function (controller) {
      // add option 'Work Complete',
      // this is manipulated below in updateWhereFilter() to 'IN_WARRANTY' or 'CLOSED'
      var workStatusOptions = R.merge(_constants.WORKSITE_META_STATUSES, {
        COMPLETE: 'Work Complete'
      });
      return RSVP.resolve(getModelFromHash(workStatusOptions));
    };
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.workStatus) {
        var statusParts = params.workStatus.split('|').filter(Boolean);
        if (statusParts.length === 1) {
          whereFilter.and.push({
            workStatus: statusParts[0]
          });
        } else {
          // TODO: multiple work statuses in the filter currently does not work,
          // it will just match all statuses.
          whereFilter.and.push({
            or: statusParts.map(function (status) {
              if (status === 'COMPLETE') {
                return {
                  workStatus: {
                    regexp: /^(IN_WARRANTY|CLOSED)$/
                  }
                };
              } else {
                return {
                  workStatus: status
                };
              }
            })
          });
        }
        delete params.workStatus;
      }
    };
    return filter;
  }(), function () {
    var filter = (0, _searchFilter.makeDiscreteList)('Worksite', 'Work items', '', 'workItemTypes');
    filter.getOptions = function (controller) {
      var workItemTypeOptions = Object.values(_constants.WORKSITE_WORKITEM_TYPES).map(function (value) {
        return Object.values(value.options).map(function (opt) {
          return {
            name: opt.value,
            id: opt.value
          };
        });
      });
      var options = R.pipe(R.flatten, R.uniq)(workItemTypeOptions);
      return RSVP.resolve(options);
    };
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.workItemTypes) {
        var parts = params.workItemTypes.split('|');
        if (parts.length === 1) {
          whereFilter.and.push({
            workItemTypes: {
              ilike: "%".concat(parts[0], "%")
            }
          });
        } else {
          whereFilter.and.push({
            workItemTypes: {
              regexp: "/".concat(parts.join('|'), "/")
            }
          });
        }
      }
      delete params.workItemTypes;
    };
    return filter;
  }(), function () {
    var filter = (0, _searchFilter.makeDiscreteList)('RCA', 'RCA user', '', 'ownerId');
    filter.getOptions = function (controller) {
      return controller.store.query('permission-assignment', {
        filter: {
          where: {
            permissionId: 9
          },
          include: 'user'
        }
      }).then(R.pipe(
      // [DS.RecordArray<permission-assignment>] -> Promise<[DS.User]>
      R.toArray, R.pluck('user'), RSVP.all)).then(R.map(function (user) {
        return {
          // . -> [{name, id}]
          name: get(user, 'fullname'),
          id: user.id
        };
      }));
    };
    return filter;
  }(), (0, _searchFilter.makeSingleDateFilter)('Worksite', 'Submitted date', 'submittedAt'), (0, _searchFilter.makeSingleDateFilter)('Work Status', 'Work start date', 'workStartDate'), (0, _searchFilter.makeSingleDateFilter)('Work Status', 'Work start date (submitted)', 'workStartDateLoggedAt'), (0, _searchFilter.makeSingleDateFilter)('Warranty Completion', 'Work completion date', 'workCompletionDate'), (0, _searchFilter.makeSingleDateFilter)('Warranty Completion', 'Work completion date (submitted)', 'workCompletionDateLoggedAt'), (0, _searchFilter.makeSingleDateFilter)('Warranty Completion', 'Warranty end date', 'warrantyCompleteDate'), (0, _searchFilter.makeSingleDateFilter)('Worksite', 'Worksite start date', 'startDate'), (0, _searchFilter.makeSingleDateFilter)('Worksite', 'Worksite end date', 'endDate'), (0, _searchFilter.makeOptionsFilter)('Worksite', 'Type of application', worksiteTypeOptions, 'applicationType'), (0, _searchFilter.makeOptionsFilter)('Worksite', 'Work category', workCategoryOptions, 'workCategory'), function () {
    var filter = (0, _searchFilter.makeBooleanFilter)('Worksite', 'Created from Global Permit Layout', 'globalPermitLayoutId', true);
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.globalPermitLayoutId) {
        whereFilter.and.push({
          globalPermitLayoutId: {
            neq: 'null'
          }
        });
      }
      delete params.globalPermitLayoutId;
    };
    return filter;
  }(), function () {
    var filter = (0, _searchFilter.makeBooleanFilter)('Worksite', 'Client is a utility', 'clientIsUtility', true);
    filter.updateWhereFilter = function (params, whereFilter) {
      if (!whereFilter.and) {
        whereFilter.and = [];
      }
      if (params.clientIsUtility) {
        whereFilter.and.push({
          clientIsUtility: true
        });
      }
      delete params.clientIsUtility;
    };
    return filter;
  }(), (0, _searchFilter.makeTextFilter)('Worksite', 'Street name', 'Street', 'street'), (0, _searchFilter.makeTextFilter)('Worksite', 'Suburb name', 'Suburb', 'suburb')];
  _exports.default = _default;
});