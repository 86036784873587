define("tmp-for-all/helpers/boolean/yes-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.booleanYesNo = booleanYesNo;
  _exports.default = void 0;
  var isNone = Ember.isNone;
  function booleanYesNo(params) {
    if (isNone(params[0])) return '';
    return params[0].toString() === 'true' ? 'Yes' : 'No';
  }
  var _default = Ember.Helper.helper(booleanYesNo);
  _exports.default = _default;
});