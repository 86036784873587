define("tmp-for-all/helpers/format-array-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatArrayIndex = formatArrayIndex;
  function formatArrayIndex(params) {
    var index = params[0] || 0;
    return index + 1;
  }
  var _default = Ember.Helper.helper(formatArrayIndex);
  _exports.default = _default;
});