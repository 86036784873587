define("tmp-for-all/initializers/ember-preferences", ["exports", "ember-preferences/setup", "tmp-for-all/-ember-preferences-internal", "tmp-for-all/preferences"], function (_exports, _setup, _emberPreferencesInternal, _preferences) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var merge = Ember.assign || Ember.merge;
  function initialize(application) {
    // FIXME: We test the application to know if we're using ember 1.12, 1.13 or +2.0
    var container = application.register ? application : application.container,
      defaults = (0, _emberPreferencesInternal.default)(),
      userPreferences = (0, _preferences.default)();
    (0, _setup.register)(container, merge(defaults, userPreferences));
  }
  var _default = {
    name: 'ember-preferences',
    initialize: initialize
  };
  _exports.default = _default;
});