define("tmp-for-all/components/search-parameters/filters/filter-controls/text-filter/component", ["exports", "tmp-for-all/mixins/components/worksite-filter-field"], function (_exports, _worksiteFilterField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend(_worksiteFilterField.default, {
    isDisabled: computed('value', function () {
      return get(this, 'value') != null && !get(this, 'value.length');
    }),
    actions: {
      updateValue: function updateValue(newValue) {
        set(this, 'value', newValue);
      },
      saveFilter: function saveFilter() {
        var _this = this;
        var updateFilterParams = get(this, 'updateFilterParams');
        var filter = get(this, 'filter');
        if (updateFilterParams && filter) {
          var index = get(this, 'addFilter')(filter);
          updateFilterParams(function (controller) {
            filter.setQueryParam(controller, get(_this, 'value'), index);
          });
        }

        // Exit modal
        this.toggleModal();
      }
    }
  });
  _exports.default = _default;
});