define("tmp-for-all/components/maps/map-control-panel/search/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['t-search-tab'],
    search: Ember.computed.alias('searchQuery.freetext'),
    actions: {
      updateQuery: function updateQuery() {
        this.updateSearchQuery({
          freetext: this.search
        });
      },
      clearQuery: function clearQuery() {
        this.updateSearchQuery({
          freetext: ''
        });
      }
    }
  });
  _exports.default = _default;
});