define("tmp-for-all/helpers/format-seconds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatSeconds = formatSeconds;
  function formatSeconds(params) {
    var seconds = params[0] || 0;
    return (seconds / 60).toFixed(2);
  }
  var _default = Ember.Helper.helper(formatSeconds);
  _exports.default = _default;
});