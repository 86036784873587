define("tmp-for-all/components/search-results/organisations/list/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['company-list-item']
  });
  _exports.default = _default;
});