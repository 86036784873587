define("tmp-for-all/components/worksite-view/tmps/tmp/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PjGuehns",
    "block": "{\"symbols\":[\"revision\"],\"statements\":[[7,\"div\"],[11,\"class\",\"heading-block\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"table-panel-heading\"],[9],[0,\"TMP: \"],[1,[21,\"key\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isGenericTmp\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"label-brown margin-left-5\"],[9],[0,\"Generic TMP\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"table-container\"],[9],[0,\"\\n  \"],[7,\"table\"],[11,\"class\",\"expandable-rows orange\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Version\"],[10],[0,\"\\n      \"],[7,\"th\"],[9],[0,\"Category\"],[10],[0,\"\\n      \"],[7,\"th\"],[9],[7,\"span\"],[11,\"class\",\"hide-mob\"],[9],[0,\"Start Date\"],[10],[10],[0,\"\\n      \"],[7,\"th\"],[9],[7,\"span\"],[11,\"class\",\"hide-mob\"],[9],[0,\"End Date\"],[10],[10],[0,\"\\n      \"],[7,\"th\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"tmpRevisions\"]]],null,{\"statements\":[[4,\"unless\",[[27,\"or\",[[22,1,[\"isNew\"]],[22,1,[\"isDeleted\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"worksite-view/tmps/tmp/revision\",null,[[\"tmp\",\"updateSelectedRow\",\"reviseTmp\",\"deleteDraftTmp\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"updateSelectedRow\"],null],[23,[\"reviseTmp\"]],[23,[\"deleteDraftTmp\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"and\",[[22,1,[\"layouts\"]],[22,1,[\"isSelected\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"worksite-view/tmps/tmp/revision/additional-row\",null,[[\"tmp\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/tmps/tmp/template.hbs"
    }
  });
  _exports.default = _default;
});