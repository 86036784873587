define("tmp-for-all/components/worksite-view/submission-modal/component", ["exports", "tmp-for-all/constants", "tmp-for-all/utils/diff-utils"], function (_exports, _constants, _diffUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get,
    set = Ember.set;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['t-submission-modal'],
    acceptenceMessage: computed('currentUserName', function () {
      var name = get(this, 'currentUserName');
      return "I, ".concat(name, ", confirm my acceptance of the above.");
    }),
    wsSubmissionTmpRule: computed.alias('worksite.jurisdiction.wsSubmissionTmpRule'),
    tmpSubmissionSuggested: computed.equal('wsSubmissionTmpRule', 'SUGGESTED'),
    tmpSubmissionSuggestionText: _constants.WORKSITE_SUBMISSION_TMP_RULES.SUGGESTED,
    hasTmpSubmission: computed.notEmpty('worksite.tmpSubmissions'),
    hasTmp: computed.notEmpty('worksite.tmps'),
    hasSubmittedTmp: computed.or('hasTmp', 'hasTmpSubmission'),
    hasNotSubmittedTmp: computed.not('hasSubmittedTmp'),
    showSuggestion: computed.and('hasNotSubmittedTmp', 'tmpSubmissionSuggested'),
    gstDisplay: computed('worksite.jurisdiction.displayFeesIncludingGst', function () {
      if (get(this, 'worksite.jurisdiction.displayFeesIncludingGst')) return 'including GST';
      return 'excluding GST';
    }),
    hasDiff: computed('diffContent', function () {
      var theDiff = get(this, 'diffContent');
      return !!theDiff;
    }),
    diffEmpty: computed('diffContent', function () {
      var theDiff = get(this, 'diffContent');
      return !theDiff || Object.keys(theDiff).length === 0;
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var id = get(this, 'worksite.id');
      get(this, 'ajax').getWorksiteDraftDiffWithPreviousRevision(id).then(function (diff) {
        if (diff && diff !== {}) {
          var processedDiff = (0, _diffUtils.filteredDiffContent)(diff);
          set(_this, 'diffContent', processedDiff);
        }
      });
    }
  });
  _exports.default = _default;
});