define("tmp-for-all/components/shared/inline-help-block/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TWhHcfhR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"expandable-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inline-help-block bg-blue text-white\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/inline-help-block/template.hbs"
    }
  });
  _exports.default = _default;
});