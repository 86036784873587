define("tmp-for-all/routes/tmp/view/index", ["exports", "tmp-for-all/mixins/company-search", "tmp-for-all/mixins/routes/add-comment-route-mixin", "tmp-for-all/mixins/routes/add-global-permit-layout-mixin", "tmp-for-all/utils/conditions-util"], function (_exports, _companySearch, _addCommentRouteMixin, _addGlobalPermitLayoutMixin, _conditionsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP;
  var _default = Ember.Route.extend(_addCommentRouteMixin.default, _companySearch.default, _addGlobalPermitLayoutMixin.default, {
    routeNavigator: Ember.inject.service(),
    resetController: function resetController(controller, isExiting, _) {
      if (isExiting) {
        controller.set('openLayoutsOnly', true);
      }
    },
    model: function model() {
      var _this$paramsFor = this.paramsFor('tmp'),
        openLayoutsOnly = _this$paramsFor.openLayoutsOnly;
      if (openLayoutsOnly !== true) {
        openLayoutsOnly = false;
      }
      var tmp = this.modelFor('tmp');
      var conditionGroups = this.store.query('condition-group', {
        filter: {
          where: {
            conditionType: 'tmp'
          },
          include: 'conditions'
        }
      });
      return RSVP.hash({
        // Loads the tmp from the parent route (which is passed :tmp_id)
        tmp: tmp,
        tmpToPermittedCompanies: this.store.query('tmpToPermittedCompany', {
          filter: {
            where: {
              tmpNumber: tmp.id
            }
          }
        }),
        conditionGroups: conditionGroups,
        selectedConditions: (0, _conditionsUtil.getSortedSelectedConditions)(tmp, 'acceptanceConditions'),
        userIsContractor: this.isUserAContractor(),
        openLayoutsOnly: openLayoutsOnly
      });
    },
    actions: {
      declineTmp: function declineTmp(tmp, commentText) {
        var _this = this;
        tmp.decline(commentText).then(function () {
          return get(_this, 'flashMessages').success('TMP has been declined.');
        }).then(function () {
          return get(_this, 'routeNavigator').returnToWayPoint(_this, {
            defaultRouteName: 'tmps.queue',
            queryParams: {
              status: 'declined'
            }
          });
        }).catch(function (err) {
          // see https://github.com/emberjs/ember.js/issues/5566
          if (err && err.message === 'TransitionAborted') {} else {
            get(_this, 'flashMessages').danger(err.message);
          }
        });
      },
      createDraft: function createDraft(tmp) {
        var _this2 = this;
        tmp.createDraft().then(function () {
          return get(_this2, 'flashMessages').success('TMP draft revision has been created.');
        }).then(function () {
          return _this2.transitionTo('tmp-draft.view', tmp.id);
        }).catch(function (err) {
          return get(_this2, 'flashMessages').danger(err.message);
        });
      },
      editLayout: function editLayout(layout) {
        return this.transitionTo('tmp.layouts.edit.details', layout);
      },
      addPermittedCompany: function addPermittedCompany(tmp, company) {
        var _this3 = this;
        var mapping = this.store.createRecord('tmpToPermittedCompany', {
          tmpNumber: tmp.id,
          company: company
        });
        return mapping.save().then(function () {
          tmp.reloadRelations(['permittedCompanies']);
          _this3.refresh();
        });
      },
      removePermittedCompany: function removePermittedCompany(tmp, tmpToPermittedCompany) {
        var _this4 = this;
        return tmpToPermittedCompany.destroyRecord().then(function () {
          tmp.reloadRelations(['permittedCompanies']);
          _this4.refresh();
        });
      },
      changeDeployment: function changeDeployment(deployment, action) {
        return deployment.changeState(action);
      },
      createSiteSpecificWorksite: function createSiteSpecificWorksite(fromLayout) {
        return this.transitionTo('worksites.create.ownership', {
          queryParams: {
            fromGlobalPermitLayout: fromLayout.id
          }
        });
      },
      toggleOpenLayoutsOnly: function toggleOpenLayoutsOnly(currentValue) {
        return this.replaceWith({
          queryParams: {
            openLayoutsOnly: !currentValue
          }
        });
      }
    }
  });
  _exports.default = _default;
});