define("tmp-for-all/routes/tmp-submission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    queryParams: {
      openLayoutsOnly: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.store.findRecord('tmp-submission', params['tmp-submission_id'], {
        adapterOptions: {
          prefetch: params.openLayoutsOnly ? 'recent' : true
        }
      });
    }
  });
  _exports.default = _default;
});