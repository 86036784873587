define("tmp-for-all/controllers/tmps/queue", ["exports", "tmp-for-all/mixins/controllers/list-pagination-controller-mixin", "tmp-for-all/mixins/controllers/list-order-controller-mixin", "tmp-for-all/utils/search-filter", "tmp-for-all/utils/tmp-filters"], function (_exports, _listPaginationControllerMixin, _listOrderControllerMixin, _searchFilter, _tmpFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var processingQueueFilters = _tmpFilters.default.processingQueueFilters;
  var _Ember = Ember,
    set = _Ember.set;
  var controller = Ember.Controller.extend(_listPaginationControllerMixin.default, _listOrderControllerMixin.default, {
    filterTypes: processingQueueFilters,
    queryParams: R.concat(_searchFilter.default.getFilterQueryParams(processingQueueFilters), ['worksiteReference']),
    worksiteReference: null,
    actions: {
      setWorksiteReference: function setWorksiteReference(worksiteReference) {
        set(this, 'worksiteReference', worksiteReference);
        set(this, 'page', 1);
      },
      updateFilterParams: function updateFilterParams(callBack, params) {
        set(this, 'page', 1);
        callBack(this, params);
      }
    }
  });
  controller.reopen(_searchFilter.default.getParamsAssignments(processingQueueFilters));
  var _default = controller;
  _exports.default = _default;
});