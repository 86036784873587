define("tmp-for-all/components/shared/add-conditions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QLCfSVnA",
    "block": "{\"symbols\":[\"group\",\"index\"],\"statements\":[[4,\"each\",[[23,[\"conditionGroups\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"shared/add-conditions/group\",null,[[\"conditions\",\"groupName\",\"selectedConditions\",\"additionalLabelText\",\"onChange\",\"start\"],[[22,1,[\"nonArchivedConditions\"]],[22,1,[\"name\"]],[23,[\"selectedConditions\"]],\"Select all conditions within this group\",[27,\"action\",[[22,0,[]],\"update\"],null],[27,\"start-position\",[[23,[\"conditionGroups\"]],\"nonArchivedConditions\",[22,2,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[1,[27,\"shared/add-custom-conditions\",null,[[\"saveCustomCondition\",\"removeCustomCondition\",\"parent\",\"jurisdiction\",\"filterId\",\"start\"],[[23,[\"saveCustomCondition\"]],[23,[\"removeCustomCondition\"]],[23,[\"parent\"]],[23,[\"jurisdiction\"]],[23,[\"filterId\"]],[23,[\"customStart\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/add-conditions/template.hbs"
    }
  });
  _exports.default = _default;
});