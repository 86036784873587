define("tmp-for-all/helpers/format-date", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate(params) {
    if (!params[0]) return '';
    var date = params[0];
    var format = params[1] || _environment.default.dateFormat;
    return moment(date).format(format);
  }
  var _default = Ember.Helper.helper(formatDate);
  _exports.default = _default;
});