define("tmp-for-all/utils/data-push", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = pushAndReturnPayload;
  function pushAndReturnPayload(store, modelName, payload) {
    var modelClass = store.modelFor(modelName);
    var serializer = store.serializerFor(modelName);
    var normalize = serializer.normalizeSingleResponse.bind(serializer);
    function processSingleRecord(data) {
      var normalizedPayload = normalize(store, modelClass, {
        data: data
      }, null, true);
      return store.push(normalizedPayload);
    }
    if (Array.isArray(payload.data)) {
      return payload.data.map(processSingleRecord);
    } else {
      return processSingleRecord(payload.data);
    }
  }
});