define("tmp-for-all/templates/planning-map/select-region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YU2rU3mI",
    "block": "{\"symbols\":[\"region\",\"key\"],\"statements\":[[4,\"content-container\",null,[[\"isPlanningMapPage\"],[true]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"region-map-page-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mobile-region-select\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"underline-dotted\"],[9],[0,\"Select region\"],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"link-list\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"model\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"planning-map.region\",[22,2,[]]],[[\"class\"],[\"link-list-link-item\"]],{\"statements\":[[0,\"              \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"location\",\"22px\",\"20px\",\"link-list-link-item-icon\"]]],false],[0,\"\\n              \"],[7,\"span\"],[11,\"class\",\"link-list-link-item-text\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"region-map-container\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"region-map-heading\"],[9],[0,\"Select region\"],[10],[0,\"\\n      \"],[1,[27,\"svg-region-map\",null,[[\"navigateToRegion\"],[[27,\"route-action\",[\"transitionToRegion\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/planning-map/select-region.hbs"
    }
  });
  _exports.default = _default;
});