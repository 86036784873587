define("tmp-for-all/mixins/routes/tmp-search-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Mixin.create({
    actions: {
      createDraft: function createDraft(tmpSearchResult) {
        var _this = this;
        var id = get(tmpSearchResult, 'tmpId');
        var tmpType = get(tmpSearchResult, 'type');
        return this.store.findRecord(tmpType, id).then(function (tmp) {
          return tmp.createDraft();
        }).then(function () {
          return get(_this, 'flashMessages').success('TMP draft revision has been created.');
        }).then(function () {
          return _this.transitionTo('tmp-draft.view', id);
        }).catch(function (err) {
          return get(_this, 'flashMessages').danger(err.message);
        });
      },
      claimTmp: function claimTmp(tmpSearchResult, isSecondary) {
        var _this2 = this;
        var id = get(tmpSearchResult, 'tmpId');
        return this.store.findRecord('tmp-submission', id).then(function (tmp) {
          return tmp.claim(isSecondary);
        }).then(function () {
          return _this2.refresh();
        }).catch(function (err) {
          return get(_this2, 'flashMessages').danger(err.message);
        });
      }
    }
  });
  _exports.default = _default;
});