define("tmp-for-all/helpers/lane-closure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.laneClosure = laneClosure;
  function laneClosure(params) {
    var laneIndex = parseInt(params[0], 10);
    if (isNaN(laneIndex) || laneIndex > 5 || laneIndex < 0) {
      throw new Error('Lane Index should be a number between 0 and 5.');
    }
    var lanes = ['Shoulder', 'Lane 1', 'Lane 2', 'Lane 3', 'Lane 4', 'Lane 5'];
    return lanes[laneIndex];
  }
  var _default = Ember.Helper.helper(laneClosure);
  _exports.default = _default;
});