define("tmp-for-all/mixins/routes/edit-worksite/details-route-mixin", ["exports", "tmp-for-all/mixins/routes/edit-worksite/base-mixin"], function (_exports, _baseMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var set = Ember.set,
    get = Ember.get;
  var _default = Ember.Mixin.create(_baseMixin.default, {
    setupController: function setupController(controller, model) {
      var _this = this;
      get(model, 'jurisdiction').then(function (jurisdiction) {
        _this.store.query('jurisdiction-area', {
          filter: {
            where: {
              jurisdictionId: get(jurisdiction, 'id')
            }
          }
        }).then(function (areas) {
          set(controller, 'rcaAreas', areas);
        });
      });
      return this._super.apply(this, arguments);
    },
    actions: {
      updateWorksiteType: function updateWorksiteType(_ref) {
        var value = _ref.value;
        set(this, 'worksite.worksiteType', value);
      },
      updateRetro: function updateRetro(checked) {
        set(this, 'worksite.isRetrospective', checked);
      },
      setRCAArea: function setRCAArea(area, worksite) {
        set(worksite, 'jurisdictionArea', area);
      },
      updateAccessActs: function updateAccessActs(labels, values) {
        set(this, 'worksite.accessActs', values);
      },
      updateLocalGovernmentAct: function updateLocalGovernmentAct(selected) {
        set(this, 'worksite.localGovernmentAct', selected.value);
      }
    }
  });
  _exports.default = _default;
});