define("tmp-for-all/routes/tmp-draft/create-with-worksite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get;
  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    authorization: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      return get(this, 'session.currentUser').then(function (user) {
        return get(_this, 'authorization').checkAllowableNavigation(_this, 'createDraftTmp', get(user, 'domain')).catch(function () {
          return _this.replaceWith('index');
        });
      });
    },
    actions: {
      createTMP: function createTMP(tmp) {
        var _this2 = this;
        return tmp.save().then(function () {
          get(_this2, 'flashMessages').success('TMP creation was successful');
          return _this2.transitionTo('tmp-draft', tmp);
        }).catch(function () {
          get(_this2, 'flashMessages').danger('There was a problem creating this TMP');
        });
      },
      willTransition: function willTransition() {
        var currentTMP = this.get('controller.model.tmp');
        if (currentTMP) {
          currentTMP.destroyOrRollback();
        }
      },
      searchProjects: function searchProjects() {},
      searchCompanies: function searchCompanies() {}
    }
  });
  _exports.default = _default;
});