define("tmp-for-all/routes/projects/list", ["exports", "ember-cli-pagination/remote/route-mixin", "escape-regex-string"], function (_exports, _routeMixin, _escapeRegexString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_routeMixin.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (params.search) {
        params.filter = {
          where: {
            or: [{
              name: {
                regexp: "/".concat((0, _escapeRegexString.default)(params.search), "/i")
              }
            }, {
              reference: {
                regexp: "/".concat((0, _escapeRegexString.default)(params.search), "/i")
              }
            }, {
              summary: {
                regexp: "/".concat((0, _escapeRegexString.default)(params.search), "/i")
              }
            }]
          }
        };
      }
      return this.findPaged('project', params);
    }
  });
  _exports.default = _default;
});