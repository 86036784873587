define("tmp-for-all/mixins/routes/user-create-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    isEmpty = Ember.isEmpty,
    set = Ember.set,
    computed = Ember.computed,
    setProperties = Ember.setProperties;
  var _default = Ember.Mixin.create({
    defaultDomain: computed.alias('controller.model.user.permissionAssignments.firstObject'),
    actions: {
      addPermissionAssignment: function addPermissionAssignment(domainType) {
        this.store.createRecord('permission-assignment', {
          user: get(this, 'controller.model.user'),
          domainType: domainType
        });
      },
      deletePermissionAssignment: function deletePermissionAssignment(assignment) {
        get(this, 'store').deleteRecord(assignment);
        get(this, 'controller.model.user.permissionAssignments').reload();
      },
      updatePermissionAssignment: function updatePermissionAssignment(assignment, domainId, permission) {
        setProperties(assignment, {
          domainId: domainId,
          permission: permission
        });
      },
      saveUser: function saveUser(user) {
        var _this = this;
        if (isEmpty(get(user, 'selectedDomain'))) {
          set(user, 'selectedDomain', get(this, 'defaultDomain.domainType'));
          set(user, 'selectedDomainId', get(this, 'defaultDomain.domainId'));
        }
        user.save().then(function () {
          return RSVP.all(get(user, 'permissionAssignments').map(function (assignment) {
            if (get(assignment, 'domainId') || get(assignment, 'permission') && get(assignment, 'permission.domainType') === null) {
              set(assignment, 'user', user);
              return assignment.save();
            } else {
              return _this.store.deleteRecord(assignment);
            }
          }));
        }).then(function () {
          get(_this, 'flashMessages').success('User was created successfully.');
          _this.transitionTo('users.list');
        }).catch(function (errors) {
          get(_this, 'flashMessages').danger('There was a problem creating this User.');
        });
      },
      willTransition: function willTransition() {
        var user = get(this, 'controller.model.user');
        if (user) {
          user.destroyOrRollback();
        }
      }
    }
  });
  _exports.default = _default;
});