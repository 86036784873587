define("tmp-for-all/helpers/days-of-week", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.daysOfWeek = daysOfWeek;
  _exports.default = void 0;
  function daysOfWeek(params, hash) {
    var dayIndex = params[0];
    if (typeof dayIndex !== 'number' || dayIndex > 6 || dayIndex < 0) {
      throw new Error('Day index should be a number between 0 and 6.');
    }
    var daysFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    if (hash) {
      if (hash.format === 'short') return daysShort[dayIndex];
    }
    return daysFull[dayIndex];
  }
  var _default = Ember.Helper.helper(daysOfWeek);
  _exports.default = _default;
});