define("tmp-for-all/mixins/routes/layout-edit-mixin", ["exports", "tmp-for-all/mixins/stms-search", "tmp-for-all/utils/route-helpers", "tmp-for-all/classes/file-wrapper"], function (_exports, _stmsSearch, _routeHelpers, _fileWrapper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    RSVP = Ember.RSVP,
    set = Ember.set,
    computed = Ember.computed;

  /**
   * This mixin is for Editing layouts only, not creating layouts.
   * Create layouts has different cleanup/transition requirements
   */
  var _default = Ember.Mixin.create(_stmsSearch.default, {
    modal: Ember.inject.service(),
    authorization: Ember.inject.service(),
    layout: computed.alias('controller.model.layout'),
    tmp: computed.alias('controller.model.tmp'),
    model: function model() {
      return RSVP.hash({
        layout: this.modelFor("".concat(get(this, 'tmpType'), ".layouts.edit")),
        tmp: this.modelFor(get(this, 'tmpType'))
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;
      // Needed to do the permission check in the afterModel instead of beforeModel because the model was needed for the permission check
      var permission = get(model.tmp, 'isGenericTmp') ? 'editGlobalPermissionLayout' : get(this, 'siteSpecificRoutePermission');
      var tmpType = get(this, 'tmpType');
      get(this, 'authorization').checkAllowableNavigation(this, permission, this.modelFor(tmpType)).catch(function () {
        return _this.replaceWith('index');
      });
      var tmpId = get(model, 'tmp.id');
      return get(model, "layout.".concat(get(this, 'relatedModel'))).then(function (relatedModel) {
        if (get(relatedModel, 'id') !== tmpId) {
          return _this.transitionTo("".concat(get(_this, 'tmpType'), ".view"), tmpId);
        }
      });
    },
    transition: function transition() {
      return this.transitionTo("".concat(get(this, 'tmpType'), ".view"), get(this, 'tmp'));
    },
    openModal: function openModal() {
      var _this2 = this;
      set(this, 'modal.options', {
        title: 'Discard Changes?',
        text: 'You have made unsaved changes to this layout',
        confirmButton: 'Discard',
        confirmAction: function confirmAction() {
          RSVP.resolve(_this2.cleanup()).then(function () {
            _this2.get('modal').toggle();
            _this2.transition();
          });
        }
      });
      get(this, 'modal').toggle();
    },
    cleanup: function cleanup() {
      var layout = this.layout;
      return layout.destroyOrRollback();
    },
    actions: {
      saveLayout: function saveLayout(layout) {
        var _this3 = this;
        var layoutSaveSuccess = function layoutSaveSuccess() {
          return layout.saveFiles().then(function () {
            get(_this3, 'flashMessages').success('Layout was updated successfully');
            return _this3.transitionTo("".concat(get(_this3, 'tmpType'), ".view"));
          }).catch(function () {
            get(_this3, 'flashMessages').danger('There was a problem uploading your file(s)');
          });
        };
        var layoutSaveError = function layoutSaveError() {
          get(_this3, 'flashMessages').danger('There was a problem saving this Layout');
        };
        return layout.save().then(function () {
          return layout.get("".concat(get(_this3, 'relatedModel'))).then(function (tmp) {
            return tmp.reload();
          });
        }).then(function () {
          return layout.get("".concat(get(_this3, 'relatedModel'), ".history")).reload();
        }).then(layoutSaveSuccess, layoutSaveError); // layoutSaveError should not catch errors from layoutSaveSuccess
      },
      cancelLayout: function cancelLayout() {
        return this.transitionTo("".concat(get(this, 'tmpType'), ".view"));
      },
      addNewUser: function addNewUser() {
        this.replaceWith((0, _routeHelpers.getChildRouteName)(get(this, 'routeName'), 'new-contact'));
      },
      saveNewUser: function saveNewUser(_ref) {
        var _this4 = this;
        var contact = _ref.contact,
          replaceWithRoute = _ref.replaceWithRoute;
        contact.set('meta', {
          creationType: 'addLayoutDesigner',
          tmpCode: get(this, 'tmp.tmpCode')
        });
        (contact.get('isNew') ? contact.save() : Promise.resolve()).then(function () {
          return set(_this4, 'controller.model.layout.designer', contact);
        }).then(function () {
          return _this4.replaceWith(replaceWithRoute);
        }).then(function () {
          return get(_this4, 'flashMessages').success('A new contact has been added.');
        }).catch(function () {
          return get(_this4, 'flashMessages').danger('There was an error saving the new contact.');
        });
      },
      willTransition: function willTransition(transition) {
        set(this, 'layout.filesForUpload', _fileWrapper.FileWrapperArrayProxy.create({
          content: []
        }));
        if (this.layout.files) {
          RSVP.all(this.layout.files.map(function (file) {
            return file.rollbackAttributes();
          }));
        }
        get(this, 'layout.deployments').reload();
        if (get(this, 'dataIsDirty')) {
          transition.abort();
          this.openModal();
        }
      }
    }
  });
  _exports.default = _default;
});