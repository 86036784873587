define("tmp-for-all/components/search-parameters/filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    isShowingModal: false,
    isDisabled: computed('selectedFilter', function () {
      return get(this, 'selectedFilter') === undefined;
    }),
    actions: {
      toggleModal: function toggleModal() {
        var _this = this;
        var selectedFilter = get(this, 'selectedFilter');
        // If boolean filter, no config required so add filter immediately:
        if (selectedFilter.name === 'boolean-filter') {
          var updateFilterParams = get(this, 'updateFilterParams');
          updateFilterParams(function (controller) {
            selectedFilter.setQueryParam(controller, get(_this, 'queryFilter'));
          });
          get(this, 'addFilter')(selectedFilter);
          // For all other filter types, show configuration options modal:
        } else {
          this.toggleProperty('isShowingModal');
        }
      }
    }
  });
  _exports.default = _default;
});