define("tmp-for-all/components/shared/form/check-boxes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    tagName: 'fieldset',
    classNames: ['check-boxes'],
    isChecked: function isChecked(option) {
      var initialState = Ember.A(this.get('initialState'));
      return initialState.indexOf(option.value) !== -1;
    },
    // This is supposed to update whenever the checked status changes on each
    // item, but it is currently broken @see https://github.com/emberjs/ember.js/issues/12475
    // Workaround: set options to a new reference (e.g. clone array)
    checkboxes: computed('options', 'options.@each.checked', function () {
      var _this = this;
      return this.get('options').map(function (option) {
        return Ember.ObjectProxy.create({
          content: option,
          checked: option.checked ? true : _this.isChecked(option)
        });
      });
    }),
    selected: computed.filterBy('checkboxes', 'checked', true),
    selectedContent: Ember.computed.mapBy('selected', 'content'),
    selectedValues: Ember.computed.mapBy('selectedContent', 'value'),
    selectedLabels: Ember.computed.mapBy('selectedContent', 'label'),
    actions: {
      update: function update(checked, label, value) {
        var selectedLabels = get(this, 'selectedLabels');
        var selectedValues = get(this, 'selectedValues');
        if (checked) {
          selectedLabels.push(label);
          selectedValues.push(value);
        } else {
          var indexLabel = selectedLabels.indexOf(label);
          var indexValue = selectedValues.indexOf(value);
          selectedLabels.splice(indexLabel, 1);
          selectedValues.splice(indexValue, 1);
        }
        this.get('update')(selectedLabels, selectedValues);
      }
    }
  });
  _exports.default = _default;
});