define("tmp-for-all/mixins/routes/layout-detour-route-mixin", ["exports", "tmp-for-all/utils/route-helpers", "tmp-for-all/mixins/routes/get-tooltip-context-mixin", "tmp-for-all/constants", "ember-copy"], function (_exports, _routeHelpers, _getTooltipContextMixin, _constants, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed,
    RSVP = Ember.RSVP,
    setProperties = Ember.setProperties;
  var _default = Ember.Mixin.create(_getTooltipContextMixin.default, {
    authorization: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model() {
      var layout = this.modelFor((0, _routeHelpers.getParentRouteName)(get(this, 'routeName')));
      var detours = get(layout, 'detours');
      var worksite = this.modelFor((0, _routeHelpers.getParentRouteName)((0, _routeHelpers.getParentRouteName)(get(this, 'routeName')))).get('latestWorksite');
      return Ember.RSVP.hash({
        layout: layout,
        worksite: worksite,
        detours: detours
      });
    },
    afterModel: function afterModel(model) {
      var _this = this;
      var tmpType = get(this, 'tmpType');
      var tmp = this.modelFor(tmpType);
      // Needed to do the permission check in the afterModel instead of beforeModel because the model was needed for the permission check
      var permission = tmp.isGenericTmp ? 'editGlobalPermissionLayout' : get(this, 'siteSpecificRoutePermission');
      get(this, 'authorization').checkAllowableNavigation(this, permission, tmp).catch(function () {
        return _this.replaceWith('index');
      });
      model.detours.forEach(function (d) {
        if (!d || !d.id) model.detours.removeObject(d);
      });
      var searchQuery;
      // We need start and end dates for the nearby worksite/impact/detour search
      if (get(model.layout, 'startDate') && get(model.layout, 'endDate')) {
        searchQuery = (0, _emberCopy.copy)(_constants.DEFAULT_PLANNINGMAP_SEARCH_QUERY, true);
        searchQuery.dateRange = {
          from: get(model.layout, 'startDate').toISOString(),
          to: get(model.layout, 'endDate').toISOString()
        };
      }
      set(this, 'searchQuery', searchQuery);
    },
    layout: computed.alias('controller.model.layout'),
    actions: {
      returnToEdit: function returnToEdit() {
        this.replaceWith(get(this, 'returnRoute'));
      },
      saveDetours: function saveDetours() {
        var _this2 = this;
        if (get(this, 'layout.id')) {
          return RSVP.all(get(this, 'layout.detours').map(function (detour) {
            return detour.save();
          })).then(function () {
            _this2.replaceWith(get(_this2, 'returnRoute'));
          }).catch(function (err) {
            get(_this2, 'flashMessages').danger(err.message);
          });
        } else {
          this.replaceWith(get(this, 'returnRoute'));
        }
      },
      getWorksiteTooltipContext: function getWorksiteTooltipContext(clash) {
        this.worksiteTooltipContext(clash.properties).then(function (context) {
          return setProperties(clash, context);
        });
      },
      createNewDetour: function createNewDetour(layout, ordinal) {
        return get(this, 'store').createRecord('detour', {
          layout: layout,
          ordinal: ordinal
        });
      },
      updateClashes: function updateClashes(boundingBox) {
        var searchQuery = get(this, 'searchQuery');
        searchQuery.boundingBox = boundingBox;
        set(this, 'searchQuery', searchQuery);
        return get(this, 'ajax').getPlanningMapFeatures(searchQuery).then(function (results) {
          return results.data;
        });
      },
      getDetail: function getDetail(latLng, zoom) {
        var detailQuery = R.merge(get(this, 'searchQuery'), {
          position: {
            latLng: latLng,
            zoom: zoom
          }
        });
        delete detailQuery.boundingBox;
        return get(this, 'ajax').getPlanningMapFeatureDetail(detailQuery);
      }
    }
  });
  _exports.default = _default;
});