define("tmp-for-all/components/search-parameters/selected-filters/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tXP9kvQZ",
    "block": "{\"symbols\":[\"filter\",\"filterOption\",\"index\"],\"statements\":[[7,\"h4\"],[11,\"class\",\"pill-box-list__heading\"],[9],[0,\"Active filters\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"selectedFilters\"]]],null,{\"statements\":[[4,\"each\",[[22,1,[\"appliedOptions\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"search-parameters/selected-filters/selected-filter\",null,[[\"index\",\"filter\",\"filterOption\",\"removeFilter\",\"updateFilterParams\"],[[22,3,[]],[22,1,[]],[22,2,[]],[23,[\"removeFilter\"]],[23,[\"updateFilterParams\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"p\"],[11,\"class\",\"pill-box-list__no-content-message\"],[9],[0,\"No filters applied.\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/search-parameters/selected-filters/template.hbs"
    }
  });
  _exports.default = _default;
});