define("tmp-for-all/components/worksite-view/outstanding-notices/notice-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    clearButtonText: computed('notice.noticeType', function () {
      switch (get(this, 'notice.noticeType')) {
        case 'COMPLETE_WORK':
        case 'WARRANTY_COMPLETE':
          return 'Accept Notice';
        default:
          return 'Clear Notice';
      }
    }),
    rejectButtonText: computed('notice.noticeType', function () {
      switch (get(this, 'notice.noticeType')) {
        case 'WARRANTY_COMPLETE':
        case 'COMPLETE_WORK':
          return 'Reject Notice';
        default:
          return 'Reject Notice';
      }
    })
  });
  _exports.default = _default;
});