define("tmp-for-all/components/edit-users-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    showArchived: false,
    hideArchived: computed.not('showArchived'),
    archivedElementId: computed('elementId', function () {
      return "archived-input-".concat(get(this, 'elementId'));
    }),
    userSorting: ['lastName:asc', 'firstName:asc'],
    sortedUsers: computed.sort('users', 'userSorting')
  });
  _exports.default = _default;
});