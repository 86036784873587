define("tmp-for-all/components/shared/array-or-primitive-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    isArray: computed('value', function () {
      return Array.isArray(get(this, 'value'));
    }),
    displayValue: computed('value', function () {
      var _get;
      return (_get = get(this, 'value')) !== null && _get !== void 0 ? _get : '';
    })
  });
  _exports.default = _default;
});