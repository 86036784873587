define("tmp-for-all/components/shared/sortable-column-heading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NPRIpfQ/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"heading\",\"sortName\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"heading-inner\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"table-column-sort-icon\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"ascending\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"chevron-up\",\"18px\",\"18px\",\"icon--align-top\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"svg-icon\",null,[[\"type\",\"width\",\"height\",\"classNames\"],[\"chevron-down\",\"18px\",\"18px\",\"icon--align-top\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\" \"],[1,[23,[\"heading\",\"columnName\"]],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"updateSort\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,[\"heading\",\"columnName\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/sortable-column-heading/template.hbs"
    }
  });
  _exports.default = _default;
});