define("tmp-for-all/components/register-account/component", ["exports", "tmp-for-all/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var get = Ember.get,
    set = Ember.set,
    computed = Ember.computed;
  var _default = Ember.Component.extend({
    classNames: ['password-reset-wrap'],
    ajax: Ember.inject.service(),
    preferredNameIconColor: computed('preferredName', function () {
      return get(this, 'preferredName') ? 'blue' : 'grey';
    }),
    lastNameIconColor: computed('lastName', function () {
      return get(this, 'lastName') ? 'blue' : 'grey';
    }),
    emailIconColor: computed('email', function () {
      return get(this, 'email') ? 'blue' : 'grey';
    }),
    registerButtonDisabled: computed('preferredName', 'lastName', 'email', function () {
      var preferredName = get(this, 'preferredName');
      var lastName = get(this, 'lastName');
      var email = get(this, 'email');
      return [preferredName, lastName, email].every(function (property) {
        return property && property.length > 0;
      }) ? '' : 'disabled';
    }),
    termsOfUseUrl: _environment.default.termsOfUseUrl,
    privacyPolicyUrl: _environment.default.privacyPolicyUrl,
    actions: {
      registerAccount: function registerAccount() {
        var _this = this;
        var preferredName = get(this, 'preferredName');
        var lastName = get(this, 'lastName');
        var email = get(this, 'email');
        get(this, 'registerAccount')({
          preferredName: preferredName,
          lastName: lastName,
          email: email
        }).then(function () {
          set(_this, 'success', true);
        }).catch(function (err) {
          if (err.errors) {
            set(_this, 'message', err.errors[0].detail);
          } else {
            console.error(err);
            get(_this, 'flashMessages').danger(err.message);
          }
        });
      }
    }
  });
  _exports.default = _default;
});