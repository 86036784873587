define("tmp-for-all/components/search-parameters/filters/filter-controls/date-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global moment */
  var _default = [{
    label: 'Last calendar year',
    startDate: moment().startOf('year').subtract(1, 'year').toDate(),
    finishDate: moment().endOf('year').subtract(1, 'year').toDate()
  }, {
    label: 'Last calendar month',
    startDate: moment().subtract(1, 'month').startOf('month').toDate(),
    finishDate: moment().subtract(1, 'month').endOf('month').toDate()
  }, {
    label: 'Last calendar week',
    startDate: moment().subtract(1, 'week').startOf('week').toDate(),
    finishDate: moment().subtract(1, 'week').endOf('week').toDate()
  }, {
    label: 'Last 12 months',
    startDate: moment().subtract(12, 'months').toDate(),
    finishDate: new Date()
  }, {
    label: 'Last 6 months',
    startDate: moment().subtract(6, 'months').toDate(),
    finishDate: new Date()
  }, {
    label: 'Last 4 weeks',
    startDate: moment().subtract(4, 'weeks').toDate(),
    finishDate: new Date()
  }, {
    label: 'Last 2 weeks',
    startDate: moment().subtract(2, 'weeks').toDate(),
    finishDate: new Date()
  }, {
    label: 'Next 6 months',
    startDate: new Date(),
    finishDate: moment().add(6, 'months').toDate()
  }, {
    label: 'Custom range',
    startDate: new Date(),
    finishDate: null
  }];
  _exports.default = _default;
});