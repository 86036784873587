define("tmp-for-all/components/shared/threaded-history/item/files/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3WKVNfl5",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[4,\"if\",[[23,[\"areAllFilesDeleted\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"totalFilesDeleted\"],false],[0,\" \"],[1,[27,\"if\",[[27,\"eq\",[[23,[\"totalFilesDeleted\"]],1],null],\"file has\",\"files have\"],null],false],[0,\" been deleted.\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[23,[\"history\",\"files\"]]],null,{\"statements\":[[4,\"unless\",[[22,1,[\"fileRemoved\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"shared/threaded-history/item/file\",null,[[\"file\",\"history\",\"refreshHistory\"],[[22,1,[]],[23,[\"history\"]],[27,\"action\",[[22,0,[]],[23,[\"refreshHistory\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/shared/threaded-history/item/files/template.hbs"
    }
  });
  _exports.default = _default;
});