define("tmp-for-all/components/user-form/role-selector/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    assignedPermisisons: computed.map('assignments', function (assignment, index) {
      return get(assignment, 'permission');
    }),
    assignmentPermisisonIds: computed.map('assignedPermisisons', function (permission, index) {
      return get(permission, 'id');
    }),
    unassignedRoles: computed('roles', 'assignmentPermisisonIds.[]', function () {
      var roles = get(this, 'roles');
      var assignmentPermisisonIds = get(this, 'assignmentPermisisonIds');
      return roles.filter(function (role) {
        return !assignmentPermisisonIds.includes(get(role, 'id'));
      });
    }),
    showAddButton: computed('unassignedRoles.[]', 'assignmentPermisisonIds.[]', function () {
      var unassignedRoles = get(this, 'unassignedRoles');
      var assignmentPermisisonIds = get(this, 'assignmentPermisisonIds');
      return get(unassignedRoles, 'length') > 0 && assignmentPermisisonIds.every(function (permissionId) {
        return permissionId !== undefined;
      });
    }),
    actions: {
      roleDidChange: function roleDidChange(assignment, permission) {
        this.updatePermissionAssignment(assignment, null, permission);
      }
    }
  });
  _exports.default = _default;
});