define("tmp-for-all/templates/condition-groups/tmp/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WHYJ7q/c",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Create Condition Group\"]],{\"statements\":[[0,\"  \"],[1,[27,\"condition-group-form\",null,[[\"group\",\"actionName\",\"saveConditionGroup\"],[[23,[\"model\"]],\"create\",[27,\"route-action\",[\"saveConditionGroup\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/condition-groups/tmp/create.hbs"
    }
  });
  _exports.default = _default;
});