define("tmp-for-all/components/shared/read-only-conditions/group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNames: ['static-conditions-group', 'panel', 'bg-grey-light', 'margin-btm-10'],
    selectedGroupCondition: computed('group.id', 'selectedConditions.[]', function () {
      var _this = this;
      // Custom conditions don't have a group Id
      if (!get(this, 'group.id')) return get(this, 'selectedConditions');
      return R.filter(function (condition) {
        var conditionsGroups = get(condition.toJSON(), 'conditionGroups');
        return R.contains(get(_this, 'group.id'), conditionsGroups);
      }, get(this, 'selectedConditions')).sort(function (conditionA, conditionB) {
        return get(conditionA, 'id') - get(conditionB, 'id');
      });
    })
  });
  _exports.default = _default;
});