define("tmp-for-all/models/jurisdiction", ["exports", "ember-data", "tmp-for-all/models/domain"], function (_exports, _emberData, _domain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
    belongsTo = _emberData.default.belongsTo,
    hasMany = _emberData.default.hasMany;
  var _default = _domain.default.extend({
    name: attr('string'),
    displayName: attr('string'),
    automaticWorkCompletionPeriod: attr('number'),
    wsSubmissionTmpRule: attr('string'),
    address: attr('string'),
    location: attr(),
    enableBilling: attr('boolean'),
    feeConfirmationDisplay: attr('string'),
    denySubmitWorksites: attr('boolean'),
    worksiteSubmitDisplay: attr('string'),
    tmpSubmitDisplay: attr('string'),
    tmpUsers: hasMany('tmp-user', {
      async: true,
      inverse: 'jurisdictions'
    }),
    tmps: hasMany('tmp', {
      async: true
    }),
    tmpDrafts: hasMany('tmp-draft', {
      async: true
    }),
    tmpSubmissions: hasMany('tmp-submission', {
      async: true
    }),
    worksites: hasMany('worksite', {
      async: true
    }),
    worksiteSubmissions: hasMany('worksite-submission', {
      async: true
    }),
    worksiteDrafts: hasMany('worksite-draft', {
      async: true
    }),
    modelName: Ember.computed.alias('constructor.modelName'),
    director: belongsTo('tmp-user', {
      inverse: null
    }),
    jurisdictionAreas: hasMany('jurisdiction-area', {
      async: true
    })
  });
  _exports.default = _default;
});