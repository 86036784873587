define("tmp-for-all/templates/projects/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "STPhlSw3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\",\"pageHeadingTestClass\"],[[23,[\"model\",\"project\",\"name\"]],\"t-project-name\"]],{\"statements\":[[0,\"  \"],[1,[27,\"project-view\",null,[[\"project\",\"worksites\",\"deleteDraftWorksite\"],[[23,[\"model\",\"project\"]],[23,[\"model\",\"worksites\"]],[27,\"route-action\",[\"deleteDraftWorksite\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/projects/view.hbs"
    }
  });
  _exports.default = _default;
});