define("tmp-for-all/components/reports/deployment-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    fromDateLocal: computed('fromDate', function () {
      if (!get(this, 'fromDate')) return;
      return moment(get(this, 'fromDate')).format('YYYY-MM-DD');
    }),
    toDateLocal: computed('toDate', function () {
      if (!get(this, 'toDate')) return;
      return moment(get(this, 'toDate')).format('YYYY-MM-DD');
    })
  });
  _exports.default = _default;
});