define("tmp-for-all/instance-initializers/current-user", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var _default = {
    name: 'current-user',
    before: 'ember-simple-auth',
    initialize: function initialize(container) {
      _session.default.reopen({
        currentUser: computed('isAuthenticated', function () {
          if (this.get('isAuthenticated')) {
            var userId = this.get('data.authenticated.userId');
            if (userId) {
              return container.lookup('service:store').findRecord('tmp-user', userId);
            }
          }
        })
      });
    }
  };
  _exports.default = _default;
});