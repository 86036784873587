define("tmp-for-all/templates/terms-and-conditions-acceptance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H6LKN1TS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Terms of Use and Privacy Policy updated\"]],{\"statements\":[[0,\"  \"],[1,[27,\"terms-and-conditions-acceptance\",null,[[\"saveAction\",\"cancelAction\",\"user\"],[[27,\"route-action\",[\"save\"],null],[27,\"route-action\",[\"invalidateSession\"],null],[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/terms-and-conditions-acceptance.hbs"
    }
  });
  _exports.default = _default;
});