define("tmp-for-all/templates/conditions/tmp/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w2g0IMUn",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,[[\"pageHeadingText\"],[\"Edit TMP Condition\"]],{\"statements\":[[0,\"  \"],[1,[27,\"condition-form\",null,[[\"condition\",\"conditionGroups\",\"actionName\",\"saveCondition\",\"toggleArchiveState\"],[[23,[\"model\",\"condition\"]],[23,[\"model\",\"conditionGroups\"]],\"edit\",[27,\"route-action\",[\"saveCondition\"],null],[27,\"route-action\",[\"toggleArchiveState\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/conditions/tmp/edit.hbs"
    }
  });
  _exports.default = _default;
});