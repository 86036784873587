define("tmp-for-all/components/shared/time-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
    get = Ember.get;
  var _default = Ember.Component.extend({
    classNameBindings: ['priorityColor', 'submitted:time-label'],
    daysSinceSubmission: computed('submitted', function () {
      var today = moment().startOf('day');
      var submitted = moment(get(this, 'submitted')).startOf('day');
      return today.diff(submitted, 'days');
    }),
    message: computed('daysSinceSubmission', function () {
      var days = get(this, 'daysSinceSubmission');
      if (days === 0) return 'Today';
      if (days === 1) return 'Yesterday';
      return "".concat(days, " days ago");
    }),
    priorityColor: computed('submitted', 'daysSinceSubmission', 'type', function () {
      if (!get(this, 'submitted')) return 'text-black';
      var type = get(this, 'type');
      var days = get(this, 'daysSinceSubmission');
      if (type === 'tmp') {
        if (days <= 3) return 'bg-green';
        if (days <= 10) return 'bg-orange-light';
        if (days > 10) return 'bg-red';
      } else if (type === 'worksite') {
        if (days <= 20) return 'bg-green';
        if (days > 20 && days <= 30) return 'bg-orange-light';
        if (days > 30) return 'bg-red';
      }
      return '';
    })
  });
  _exports.default = _default;
});