define("tmp-for-all/templates/worksite-draft/edit/contacts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HKFdtZ1c",
    "block": "{\"symbols\":[],\"statements\":[[4,\"content-container\",null,null,{\"statements\":[[0,\"  \"],[1,[27,\"worksite/contacts-form\",null,[[\"worksite\",\"companies\",\"selectCompany\",\"selectUser\",\"addNewCompany\",\"addNewUser\",\"searchCompanies\",\"addContact\",\"deleteContact\",\"restoreContact\",\"next\",\"finish\",\"saveProgress\",\"saveAndReturn\"],[[23,[\"model\",\"worksite\"]],[23,[\"model\",\"companies\"]],[27,\"route-action\",[\"selectCompany\"],null],[27,\"route-action\",[\"selectUser\"],null],[27,\"route-action\",[\"addNewCompany\"],null],[27,\"route-action\",[\"addNewUser\"],null],[27,\"route-action\",[\"searchCompanies\"],null],[27,\"route-action\",[\"addContact\"],null],[27,\"route-action\",[\"deleteContact\"],null],[27,\"route-action\",[\"restoreContact\"],null],[27,\"route-action\",[\"next\"],null],[27,\"route-action\",[\"finish\"],null],[27,\"route-action\",[\"saveProgress\"],null],[27,\"route-action\",[\"saveAndReturn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/templates/worksite-draft/edit/contacts/index.hbs"
    }
  });
  _exports.default = _default;
});