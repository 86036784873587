define("tmp-for-all/components/worksite-view/issue-access-permit-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zLQ8Dxym",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"submit-options-inner issue-access-permit-box\"],[9],[0,\"\\n  \"],[7,\"h3\"],[11,\"class\",\"underline-coloured-blue-grey\"],[9],[0,\"Issue Access Permit\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isAMA\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-18 text-grey-dark margin-btm-20\"],[9],[0,\"On behalf of the NZ Transport Agency\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"label\"],[9],[0,\"Notes\"],[10],[0,\"\\n  \"],[1,[27,\"shared/form/text-editor\",null,[[\"value\",\"classNames\"],[[23,[\"notes\"]],\"t-accept-textarea small-text-editor\"]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"align-right review-actions\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"tertiary-action\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],[23,[\"onClose\"]]]],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"primary-action primary-action--listed-right \",[27,\"if\",[[23,[\"submitButtonDisabled\"]],\"primary-action--disabled\"],null]]]],[12,\"disabled\",[21,\"submitButtonDisabled\"]],[9],[0,\"\\n        \"],[1,[27,\"svg-icon\",null,[[\"classNames\",\"type\"],[\"primary-action__icon-left\",\"submit\"]]],false],[0,\" \"],[1,[21,\"submissionLabel\"],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[23,[\"onSubmit\"]],[23,[\"historyDescription\"]],[23,[\"notes\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "tmp-for-all/components/worksite-view/issue-access-permit-box/template.hbs"
    }
  });
  _exports.default = _default;
});